import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useNotifier } from '../hooks/useNotify'

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import {
  MemberPolicyAcceptData,
  MemberTOUAcceptData,
  PrivacyPolicy,
  TermsOfUse,
  isValidResponse,
} from '../../types'
import { useConfigService } from '../config/useConfigService'
import { useRegistrationService } from '../registration/useRegistrationService'
import { CompleteSsoPayload, useSsoService } from './useSsoService'

import { SsoSmsOptInSection } from './SsoSmsOptInSection'

import { ssoInitPrivayPolicy, ssoInitTermsOfUse } from './ssoutils'

import { useHistory } from 'react-router'
import { parseJwt, useMemberAppContext } from '../MemberAppContext'
import { ROUTE_COMPLETE_LOGIN } from '../PortalRoutes'
import {
  PortalApiEx,
  getMicroserviceApiDetails,
} from '../apiexec/portalapiexec'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
} from '../member/phone-field-utils'
import {
  // CompleteSsoPayload,
  acceptCernerPolicyEndpoint,
} from '../member/useMemberService'
import { All3LicensesAgreement } from '../registration/All3Licenses'
import { validateEmail } from '../registration/EditContactInfo'
import { CustomTextField } from '../registration/components/CustomTextField'
import SButton from '../system/customcomponents/SButton'
import { LOG } from '../v2/applog'
import { v4 as uuidv4 } from 'uuid'
import { TargetRoute } from '../v2/SavingGuide'

export interface SsoAdditionalInfo {
  email?: string
  mobileNumber?: string
  acceptedPrivacy?: boolean
  acceptedTerms?: boolean
  smsOptIn?: string
  smsAcceptedTerms?: boolean // needs to be true when smsOptIn = true
}
export interface SsoInitResponse {
  askToRegister: boolean
  //carry jwt here, in case user needs to complete registration first
  jwt: string
}

export interface SmsValidationErrors {
  emailAlreadyExist: boolean
  invalidContactPhoneNumber: boolean
}

export function SsoCompleteFirstTimeAccessForm() {
  const { t } = useSTranslate('sso')
  const { t: tRegister } = useSTranslate('register')

  const configService = useConfigService()
  const ssoService = useSsoService()
  const registrationService = useRegistrationService()
  //member id and tenant id will be here
  const { ssoInitResponse, setJwt } = useMemberAppContext()
  const [targetRoute, setTargetRoute] = useState<TargetRoute>()
  function pushToSubrouteViaEffect(subroute: string) {
    //we use the object, not a simple string, to ensure useEffect triggers
    const route: TargetRoute = {
      //todo - this guid is probably not needed anymore, we use a object in state
      uuid: uuidv4(),
      route: subroute,
    }

    setTargetRoute(route)
  }
  useEffect(() => {
    if (targetRoute) {
      history.push(targetRoute.route)
    }
  }, [targetRoute])

  const { sendMsg } = useNotifier()

  const [completeErrorMsg, setCompleteErrorMsg] = useState<string | undefined>()

  const history = useHistory()

  const [whyDisabled, setWhyDisabled] = useState<string | undefined>()

  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(false)
  const [phoneAlreadyUsed, setPhoneAlreadyUsed] = useState(false)
  const [errCompleteProfile, setErrCompleteProfile] = useState<
    string | undefined
  >()

  const [ssoAdditionalInfo, setSsoAdditionalInfo] = useState<SsoAdditionalInfo>(
    {} as any,
  )

  const [privacyPolicy, setPrivacyPolicy] = useState<
    PrivacyPolicy | undefined
  >()
  const [termsOfUse, setTermsOfUse] = useState<TermsOfUse | undefined>()

  const [acceptedAll3, setAcceptedAll3] = useState<boolean>(false)

  const [isValidEmail, setIsValidEmail] = useState(false)

  //dont start showing errors until they start type in the field
  const [isEmailTouched, setIsEmailTouched] = useState(false)

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)
  const [isLoadingBackDropOpen, setLoadingBackDropOpen] =
    useState<boolean>(false)

  useEffect(() => {
    ssoInitPrivayPolicy(configService, setPrivacyPolicy, setErrCompleteProfile)
    ssoInitTermsOfUse(configService, setTermsOfUse, setErrCompleteProfile)
  }, [])

  // checking on every additioninfo update to enable finish button
  // when all fields are filled and disable when not
  useEffect(() => {
    setWhyDisabled('')
    let isValidEmail = validateEmail(
      ssoAdditionalInfo.email ? ssoAdditionalInfo.email : '',
    )

    setIsValidEmail(isValidEmail)
    //update why disabled
    if (!isValidEmail) {
      setWhyDisabled(t('disabledHintInvalidEmail'))
      setIsSubmitDisabled(true)
      return
    }
    if (!acceptedAll3) {
      setWhyDisabled('You must accept terms and conditions')
      setIsSubmitDisabled(true)
      return
    }

    //if user wants to optin, make sure they have a valid phone number and accepted the sms terms
    if (ssoAdditionalInfo.smsOptIn === 'yes') {
      //if usrs opts in, make sure the phone number is valid and they accepted the terms

      if (!ssoAdditionalInfo.smsAcceptedTerms) {
        setWhyDisabled(t('disabledHintAcceptSmsTerms'))
        setIsSubmitDisabled(true)
        return
      }

      if (!ssoAdditionalInfo.mobileNumber) {
        setWhyDisabled(t('disabledHintInvalidPhone'))
        setIsSubmitDisabled(true)
        return
      }

      if (!isDisplayFriendlyStringValidNumber(ssoAdditionalInfo.mobileNumber)) {
        setWhyDisabled(t('disabledHintInvalidPhone'))
        setIsSubmitDisabled(true)
        return
      }

      setIsSubmitDisabled(false)
      setWhyDisabled('')
      return
    } else {
      setIsSubmitDisabled(false)
      setWhyDisabled('')
      return
    }
  }, [ssoAdditionalInfo, acceptedAll3])

  if (!privacyPolicy || !termsOfUse) {
    return (
      <Box display={'flex'} sx={{ padding: '20px' }} justifyContent={'center'}>
        <CircularProgress color="inherit" />
      </Box>
    )
  }

  const isEmailErrorField = emailAlreadyUsed || !isValidEmail

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ padding: '30px' }}
    >
      <Grid item xs={12}></Grid>
      <Grid item xs={12} sx={{ margin: '20px 0px 20px 0px' }}>
        <Typography
          align="center"
          style={{ fontWeight: 700, fontSize: '1.5rem' }}
        >
          {t('welcome')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" style={{ fontSize: '1.125rem' }}>
          {t('introText')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ padding: '20px 0px 16px 0px' }}>
        <Typography sx={{ fontWeight: 700, fontSize: '1.125rem' }}>
          {t('emailFieldHeader')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontSize: '1rem' }}>
          {t('emailDescription')}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ paddingTop: '16px' }}>
        <CustomTextField
          // variant="outlined"
          id="email"
          sx={{ maxWidth: '300px', width: '300px' }}
          name="email"
          placeholder={tRegister('enterYourEmailAddress')}
          customLabelNode={
            <Box display="flex">
              <Typography sx={{ fontSize: '0.8rem' }}>
                {t('emailFieldLabel')}
              </Typography>
              <Typography sx={{ color: 'red' }}>*</Typography>
            </Box>
          }
          value={ssoAdditionalInfo.email}
          // required
          onChange={(e: any) => {
            setIsEmailTouched(true)
            if (e.target.value.length < 44) {
              setSsoAdditionalInfo({
                ...ssoAdditionalInfo,
                email: e.target.value,
              })
            }
          }}
          error={isEmailTouched && isEmailErrorField}
          //if email is touched and error show helper text indicating why
          //could be invalid email or already used
          helperText={
            isEmailTouched && isEmailErrorField
              ? emailAlreadyUsed
                ? t('emailAlreadyUsed')
                : t('disabledHintInvalidEmail')
              : ''
          }
        />
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        style={{ marginTop: '32px' }}
      >
        <SsoSmsOptInSection
          initAcceptSignup={'yes'}
          initAgreed={false}
          onSmsSignupChanged={(optIn: string, agreed: boolean) => {
            setSsoAdditionalInfo({
              ...ssoAdditionalInfo,
              smsOptIn: optIn,
              smsAcceptedTerms: agreed,
            })
          }}
          phoneAlreadyUsed={phoneAlreadyUsed}
          ssoAdditionalInfo={ssoAdditionalInfo}
          setSsoAdditionalInfo={setSsoAdditionalInfo}
          onPhoneNumberChanged={(phoneNumber: string) => {
            //as soon as they change mark it so, we will check it again during resubmit
            setPhoneAlreadyUsed(false)
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          // textAlign: 'right',
          display: 'flex',
          // alignItems: 'center',
          marginLeft: '-20px',
          padding: '0px 0px 0px 0px',
        }}
      >
        <Checkbox
          checked={acceptedAll3 ? true : false}
          onChange={(e) => setAcceptedAll3(e.target.checked)}
          // onKeyPress={handleEnterPressedForAll3}
          // sx={{ float: 'left', padding: '8px', margin: '0px' }}
        />
        <All3LicensesAgreement
          customFontSize="1rem"
          shouldShowUnderline={true}
        />
      </Grid>

      <Grid item container xs={12} style={{ marginTop: '32px' }}>
        <>
          <SButton
            variant="contained"
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '40px',

              fontSize: '1rem',
            }}
            disabled={isSubmitDisabled || isLoadingBackDropOpen}
            onClick={async () => {
              setLoadingBackDropOpen(true)

              if (ssoAdditionalInfo.smsOptIn === 'no') {
                ssoAdditionalInfo.mobileNumber = ''
              }

              try {
                const completeSsoProfilePayload: CompleteSsoPayload = {
                  email: ssoAdditionalInfo.email!,
                  'sms-opt-in':
                    ssoAdditionalInfo.smsOptIn === 'yes' ? true : false,
                  //in case they decided not to opt in, but there is leftover phone number, remove it as it may be invalid
                  //if its yes, it has already been validated, strip out non digits and add 1
                  phone:
                    ssoAdditionalInfo.smsOptIn === 'yes' &&
                    ssoAdditionalInfo.mobileNumber
                      ? `1${toDigitsOnly(ssoAdditionalInfo.mobileNumber)}`
                      : '',
                }

                if (!ssoInitResponse || !ssoInitResponse.jwt) {
                  LOG.error(
                    'sso',
                    'cannot complete sso profile, no ssoInitResp or no jwt found sso init resp=',
                    ssoInitResponse,
                  )
                  throw new Error('cannot complete sso profile, no jwt found..')
                }

                const completeSsoResp =
                  await ssoService.submitCompleteSsoProfile(
                    ssoInitResponse!.jwt,
                    // ssoInitResponse!.memberId,
                    completeSsoProfilePayload,
                  )
                let responseStatus = checkMicroServiceResponseStatus(
                  completeSsoResp,
                  true,
                )
                if (!responseStatus.isError) {
                  //first make sure we acceppt terms and privacy
                  //before trying to complete registration
                  const policyAcceptData: MemberPolicyAcceptData = {
                    tenantId: parseJwt(ssoInitResponse!.jwt).tenant_key,
                    memberId: parseJwt(ssoInitResponse!.jwt).id_key as any,
                    policyId: privacyPolicy?.id,
                  }

                  const touAcceptData: MemberTOUAcceptData = {
                    tenantId: parseJwt(ssoInitResponse!.jwt).tenant_key,
                    memberId: parseJwt(ssoInitResponse!.jwt).id_key as any,
                    versionId: termsOfUse?.id,
                  }

                  const policyAcceptResp =
                    await registrationService.memberAcceptPolicyApi(
                      policyAcceptData,
                    )
                  const touAcceptDataResp =
                    await registrationService.memberAcceptTOUApi(touAcceptData)

                  //need to manually prepare api exec details for this one
                  const tempJwt = ssoInitResponse.jwt
                  const portalApi = new PortalApiEx()
                  portalApi.setJwt(tempJwt)

                  let { url, ops } = getMicroserviceApiDetails(
                    acceptCernerPolicyEndpoint,
                  )
                  const msResp = await portalApi.postMicro<any>(url, {}, ops)

                  let allowUndefined = true
                  const msRespCheck = checkMicroServiceResponseStatus(
                    msResp,
                    allowUndefined,
                  )

                  if (
                    isValidResponse(policyAcceptResp) &&
                    isValidResponse(touAcceptDataResp) &&
                    !msRespCheck.isError
                  ) {
                    setLoadingBackDropOpen(false)

                    sendMsg('Registered successfully', 'success')
                    //now we can set the login token back to context and continue to main page

                    setJwt(tempJwt)

                    //TODO - is this push even needed? setting the JWT shold be enough to trigger the normal login flow
                    pushToSubrouteViaEffect(ROUTE_COMPLETE_LOGIN)
                  } else {
                    LOG.error(
                      'sso',
                      'Error accepting policy and terms or cerner terms',
                      { policyAcceptResp, touAcceptDataResp, msRespCheck },
                    )
                    setLoadingBackDropOpen(false)
                    setErrCompleteProfile(
                      t('errorUnableToAcceptTermsAndPolicy'),
                    )
                    let msgToShow =
                      "Unfortunately, we're unable to complete your registration at this time. Please contact our member support."

                    if (
                      policyAcceptResp &&
                      (policyAcceptResp as any).description
                    ) {
                      msgToShow = msgToShow =
                        'Unable to accept Privacy Policy:' +
                        (policyAcceptResp as any).description.description
                    }
                    if (touAcceptDataResp && touAcceptDataResp.description) {
                      msgToShow = msgToShow =
                        'Cannot accept Terms:' + touAcceptDataResp.description
                    }

                    setCompleteErrorMsg(msgToShow)
                  }
                } else {
                  LOG.error(
                    'sso',
                    'Unable to complete the SSO first-time user access form. ',
                    completeSsoResp,
                  )
                  let msgToShow =
                    "Unfortunately, we're unable to complete your registration at this time. Please contact our member support."

                  //try to chck if re

                  if (completeSsoResp && completeSsoResp.status === 401) {
                    msgToShow =
                      msgToShow + 'Additional Details: 401 Unauthorized'
                  }
                  //try to resolve the full response as json
                  try {
                    let resolvedData = await completeSsoResp.json()
                    if (resolvedData.message) {
                      // msgToShow =
                      //   msgToShow + 'Additional Details:' + resolvedData.message
                    }
                    setCompleteErrorMsg(msgToShow)

                    //TODO - better error handling
                    if (
                      resolvedData.message &&
                      resolvedData.message.indexOf('email is not available') >
                        -1
                    ) {
                      setEmailAlreadyUsed(true)
                      setPhoneAlreadyUsed(false)
                      setCompleteErrorMsg(undefined)
                    }
                    if (
                      resolvedData.message &&
                      resolvedData.message.indexOf('phone is not avaialable') >
                        -1
                    ) {
                      setEmailAlreadyUsed(false)
                      setPhoneAlreadyUsed(true)
                      setCompleteErrorMsg(undefined)
                    }
                  } catch (e) {
                    // LOG.error('sso', 'Error resolving full response as json', e)
                  }
                }

                setLoadingBackDropOpen(false)
              } catch (e) {
                setLoadingBackDropOpen(false)
                LOG.error('sso', 'Error in submit additional info ', e)
                setCompleteErrorMsg(
                  "Unfortunately, we're unable to complete your registration at this time. Please contact our member support.",
                )
                // sendMsg(<div>Something went wrong.</div>, 'error')
                //handle in generic page
                // throw e
              }
            }}
          >
            Submit
          </SButton>
        </>
      </Grid>
      {completeErrorMsg && (
        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'red',
              fontSize: '1rem',
              marginTop: '16px',
              textAlign: 'center',
            }}
          >
            {completeErrorMsg}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
