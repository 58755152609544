import { Link } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useHistory } from 'react-router'
import { GAService } from '../application/ga/GAService'
import {
  ROUTE_LEARN_ABOUT_WAYS_TO_SAVE,
  URL_PARAM_WAYS_TO_SAVE_ACCORDION,
} from '../v2/NewMemberPortalMain'
import { useFooterStyles } from '../application/PortalFooter'

export function OurSavingsStrategiesLink({
  customLinkText,
}: {
  customLinkText?: string
}) {
  const gaService = new GAService()
  const { t } = useSTranslate('footer')
  const classes = useFooterStyles()
  const history = useHistory()
  return (
    <Link
      variant="body2"
      className={classes.footerLinkText}
      underline="none"
      onClick={() => {
        history.push(
          `${ROUTE_LEARN_ABOUT_WAYS_TO_SAVE}?${URL_PARAM_WAYS_TO_SAVE_ACCORDION}=true`,
        )
        window.scrollTo({ top: 0, behavior: 'smooth' })
        gaService.trackEvent(
          gaService.eventMap.generate_footer_link_event(
            customLinkText ? customLinkText : t('oursavingstrategies'),
          ),
        )
      }}
      sx={{ cursor: 'pointer' }}
    >
      {customLinkText ? customLinkText : t('oursavingstrategies')}
    </Link>
  )
}
