import * as React from 'react'
// import { ROUTE_MY_MEDS, ROUTE_SAVINGS } from '../PortalRoutes'
import {
  faFilePrescription,
  faMagnifyingGlass,
  faTicket,
  faUser,
} from '@fortawesome/pro-regular-svg-icons'
import { useMemberAppContext } from '../MemberAppContext'
import SBottomNavigation from '../system/customcomponents/SBottomNavigation'
import {
  MainAppSectionName,
  ROUTE_NEW_COUPONS_MAIN,
  ROUTE_NEW_MY_ACCOUNT_MAIN,
  ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN,
  ROUTE_NEW_SEARCH,
} from './NewMemberPortalMain'
import { useAppLocationFromRoute, useSizes } from './useNewHooks'

// TODO: internationalize the labels

export function BottomNavigationBar() {
  const { isDesktop } = useSizes()
  const { currentSectionId } = useAppLocationFromRoute()
  const { isMobileBottomNavbarVisible } = useMemberAppContext()

  //check if coupons feature are disabled
  const { featureConfig } = useMemberAppContext()

  // const { mainSection, setMainSection } = useNavContext()
  const { history } = useMemberAppContext()

  // if (isDesktop) {
  //   return null
  // }

  //get value from location
  // const location = useLocation()

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: MainAppSectionName,
  ) => {
    // setMainSection(newValue as MainAppSectionName)
    switch (newValue) {
      case 'my_opportunities':
        history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)
        break

      case 'my_account':
        history.push(ROUTE_NEW_MY_ACCOUNT_MAIN)
        break
      case 'my_coupons':
        history.push(ROUTE_NEW_COUPONS_MAIN)
        break
      case 'search':
        history.push(ROUTE_NEW_SEARCH)
        break
      default:
        history.push('/')
    }
  }

  if (!isMobileBottomNavbarVisible) {
    return null
  }

  if (featureConfig === undefined || featureConfig.isInitialized === false) {
    return null
  }

  let items = [
    {
      label: 'My savings',
      value: 'my_opportunities',
      icon: faFilePrescription,
    },
  ]
  if (!featureConfig?.isCouponDisabled) {
    items.push({ label: 'My coupons', value: 'my_coupons', icon: faTicket })
  }
  items.push({ label: 'Search', value: 'search', icon: faMagnifyingGlass })
  items.push({ label: 'Account', value: 'my_account', icon: faUser })

  return (
    <>
      <SBottomNavigation
        navigationItems={items}
        value={currentSectionId}
        onChange={(event, newValue) => handleChange(event, newValue)}
      />
    </>
  )
}
