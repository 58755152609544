//need all these methods in a react hook so we can
//access other services i.e. to get a list of coupons
//as well as memeber context and other variables

import { useMemberAppContext } from '../MemberAppContext'
import { PharmacyPriceBackEndObject } from '../coupons/types'
import { useDrugSearchService } from '../search/useDrugSearchService'

import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { MemberProfile } from 'src/types'
import { getBrowserStorageService } from '../member/getPreferencesService'
import {
  MainAppSectionName,
  ROUTE_NEW_COUPONS_MAIN,
  ROUTE_NEW_MY_ACCOUNT_MAIN,
  ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN,
  ROUTE_NEW_SEARCH,
} from './NewMemberPortalMain'
import { LOG } from './applog'
import { BookmarkedOpportunity, CouponDetails } from './saving-utils'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'

//TODO - should we track this in some context or just always guess on locaiton
//we should always know the location/route, it should be better for analytics/back browser anyways
export function useAppLocationFromRoute() {
  //TODO - move saving guide subpages here as well
  const [currentSectionId, setCurrentSectionId] = useState<
    MainAppSectionName | undefined
  >(undefined)

  //guess main page based on the path
  const location = useLocation()
  useEffect(() => {
    const path = location && location.pathname ? location.pathname : ''

    if (!path) {
      setCurrentSectionId(undefined)
      return
    }

    if (path.startsWith(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)) {
      setCurrentSectionId('my_opportunities')
      return
    }

    switch (path) {
      case ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN:
        setCurrentSectionId('my_opportunities')
        break
      case ROUTE_NEW_COUPONS_MAIN:
        setCurrentSectionId('my_coupons')
        break
      case ROUTE_NEW_MY_ACCOUNT_MAIN:
        setCurrentSectionId('my_account')
        break
      case ROUTE_NEW_SEARCH:
        setCurrentSectionId('search')
        break
      default:
        break
      // setCurrentSectionId(undefined)
    }
  }, [location])

  return { currentSectionId }
}

//WARNIGN - this is a bit misleading - it meands really isMobile = small version of our design, everything else is desktop
export function useSizes() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  //this is not corret - not above isMobile is down from anything below md
  // const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  return { isMobile, isDesktop }
}

//need better name
export function useXServiceTodoBettername() {
  const drugSearchService = useDrugSearchService()
  const { currentMemberProfile, savingReport, setSavingReport } =
    useMemberAppContext()
  //store to local storage for now till backend read
  //key by tenantId_memberId so it works for multiple users and not overrite
  const BOOKMARKS_STORAGE_KEY = `${currentMemberProfile?.tenantId}_${currentMemberProfile?.memberId}_bookmarked_opportunities`

  //TODO - do not use alternative model
  //to refresh value in the member app context after it has been added
  const { setBookmarkedOpportunities } = useMemberAppContext()
  //todo - use just drugId when avaiable, no need for mmaId any more
  async function getCouponItemsForMember(
    zipCode: string,
    quantity: number,
    drugId: number,
  ) {
    let items: CouponDetails[] = []
    if (!currentMemberProfile?.zip) {
      LOG.error(
        'coupons',
        'unexpected call to getCouponItems without required zip code on member, cannot get coupons',
      )
      return []
    }

    // const res = await drugSearchService.getCouponPricingResultsForMmaId(
    //   mmaId,
    //   zipCode,
    //   quantity,
    // )
    try {
      let res = await drugSearchService.getCouponPricingResultsForDrugId(
        drugId,
        zipCode,
        quantity as any as string,
      )
      const checkResp = checkMicroServiceResponseStatus(res)
      if (checkResp.isError) {
        LOG.error('coupons', 'error getting coupons', res)
        return []
      }

      // let res = MOCK_COUPONS_RESPONSE
      const buildAddress = (backendItem: PharmacyPriceBackEndObject) => {
        const address1 = backendItem.address1 || ''
        const address2 = backendItem.address2 ? ' ' + backendItem.address2 : ''
        const city = backendItem.city ? ', ' + backendItem.city : ''
        const state = backendItem.state ? ', ' + backendItem.state : ''
        const zipcode = backendItem.zipcode ? ' ' + backendItem.zipcode : ''

        return `${address1}${address2}${city}${state}${zipcode}`
      }

      if (res && res['pharmacy-pricing']) {
        res['pharmacy-pricing'].forEach(
          (backendItem: PharmacyPriceBackEndObject) => {
            let couponItem: CouponDetails = {
              pharmacyLogoUrl: backendItem['logo-url'],
              pharmacyName: backendItem.name,
              priceCash: backendItem.price,
              distance: backendItem.distance,
              physicalAddress: buildAddress(backendItem),
              // pharmacyId: backendItem.id,
            }
            items.push(couponItem)
          },
        )
      }
      return items
    } catch (e) {
      LOG.error('coupons', 'error getting coupons', e)
      return []
    }
  }

  //temp for local store testing
  async function clearStorageBookmarks(memberProfile: MemberProfile) {
    const { storeItem, getItem } = getBrowserStorageService(memberProfile)

    storeItem(BOOKMARKS_STORAGE_KEY, [])
  }

  async function getBookmarkedOpportunitiesFromStorage(
    memberProfile: MemberProfile,
  ): Promise<BookmarkedOpportunity[]> {
    try {
      const { storeItem, getItem } = getBrowserStorageService(memberProfile)

      const bookmarkedOpps = getItem(BOOKMARKS_STORAGE_KEY)

      //convert json string to object
      return bookmarkedOpps
        ? new Promise((resolve) => {
            resolve(JSON.parse(bookmarkedOpps))
          })
        : []
    } catch (e) {
      console.error('error getting bookmarked opps', e)
      LOG.error('bookmarks', 'error getting bookmarks', e)
      return new Promise((resolve) => {
        resolve([])
      })
    }
  }
  async function saveBookmarkOpportunityToStorageAndContext(
    currentMemberProfile: MemberProfile,
    opp: BookmarkedOpportunity,
  ) {
    try {
      const { storeItem, getItem } =
        getBrowserStorageService(currentMemberProfile)

      const bookmarkedOppsStr = getItem(BOOKMARKS_STORAGE_KEY)
      const bookmarkedOpps: BookmarkedOpportunity[] = bookmarkedOppsStr
        ? JSON.parse(bookmarkedOppsStr)
        : undefined

      //check if this same original is saved before
      //if so, its an update
      //if not, its a new save
      const existing = bookmarkedOpps?.find(
        (b) => b.originalDrugId === opp.originalDrugId,
      )
      if (existing) {
        //update
        const newBookmarkedOpps = bookmarkedOpps?.map((b) =>
          b.originalDrugId === opp.originalDrugId ? opp : b,
        )
        storeItem(BOOKMARKS_STORAGE_KEY, JSON.stringify(newBookmarkedOpps))
        setBookmarkedOpportunities(newBookmarkedOpps)
        return
      } else {
        //new
        const newBookmarkedOpps = bookmarkedOpps
          ? [...bookmarkedOpps, opp]
          : [opp]

        storeItem(BOOKMARKS_STORAGE_KEY, JSON.stringify(newBookmarkedOpps))
        setBookmarkedOpportunities(newBookmarkedOpps)
      }
    } catch (e) {
      LOG.error('bookmarks', 'error storing bookmarks', e)

      console.error('error saving bookmark', e)
    }
  }

  return {
    getCouponItemsForMember,
    getBookmarkedOpportunitiesFromStorage,
    saveBookmarkOpportunityToStorageAndContext,
    clearStorageBookmarks,
  }
}
