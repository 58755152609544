import { Box, Grid, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { useEffect, useState } from 'react'
import { useMemberAppContext } from '../MemberAppContext'
import DataNotFoundImage from './../../images/data_not_found.png'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

export const useLoginDisabledStyles = makeStyles((theme: Theme) => {
  return createStyles({
    backDrop: {
      background: 'white',
    },
    dialogActions: {
      marginRight: '55px',
      marginBottom: '55px',
    },
  })
})

export function MemberLoginDisabledDialog() {
  const { featureConfig } = useMemberAppContext()
  const [isOpen, setIsOpen] = useState(false)
  const classes = useLoginDisabledStyles()
  const PORTAL_APP_CONFIG = usePortalAppConfig()
  const { t } = useSTranslate(['login', 'application'])

  useEffect(() => {
    if (featureConfig && featureConfig.isLoginDisabled) {
      setIsOpen(true)
    }
  }, [featureConfig])

  return (
    <Dialog
      open={isOpen}
      title="Member Portal Temporarily Unavailable"
      PaperProps={{
        style: { borderRadius: '20px' },
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogContent sx={{ textAlign: 'center' }}>
        <Box sx={{ padding: 2 }}>
          <img
            src={DataNotFoundImage}
            alt="Member Portal Temporarily Unavailable"
            width={'200px'}
            height={'200px'}
          />
        </Box>
        <Typography fontWeight={700} fontSize={20} textAlign={'center'}>
          {t('login:memberPortalNotAvail')}
        </Typography>
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ marginTop: '10px' }}
        >
          <Grid item xs={12}>
            <Typography>{t('login:portalTemperorilyNotAvail')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">
              {t('application:memberSupport')}{' '}
              {PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
