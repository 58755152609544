import React, { useEffect, useState } from 'react'

import { useMemberService } from '../member/useMemberService'
import { useMemberAppContext } from '../MemberAppContext'
import { IS_INTEGRATION, IS_PROD } from '../apiexec/utils'

export const SINGLE_SESSION_MONITOR_INTERVAL_MILLIS = 60000
export const UI_MESSAGE_WHEN_EXPIRED =
  'Your current session has expired because you have logged in elsewhere.'

export function ConcurrentSessionHanlder() {
  const [isSingleSessionClient, setIsSingleSessionClient] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const memberService = useMemberService()

  const { currentMemberProfile, logout } = useMemberAppContext()

  async function checkIsSingleSessionClient(clientId: number) {
    try {
      const isSingle = await memberService.isSingleSessionClientApi(clientId)
      setIsSingleSessionClient(isSingle)
    } catch (error) {
      console.error('Error checking if its single session client...')
    }
  }

  async function checkIsSessionExpiredAsync() {
    try {
      const isExpired = await memberService.isSingleSessionExpiredApi()
      if (isExpired) {
        setIsSessionExpired(true)
      }
    } catch (error) {
      console.error('Error checking concurrent sessions...')
    }
  }

  //setup monitoring flag based on client id of current member profile
  useEffect(() => {
    if (currentMemberProfile && currentMemberProfile.tenantId) {
      checkIsSingleSessionClient(currentMemberProfile.tenantId)
    }
  }, [currentMemberProfile])

  //if enable, every X seconds check for session expiration
  useEffect(() => {
    if (isSingleSessionClient) {
      setInterval(() => {
        if (IS_PROD()) {
          checkIsSessionExpiredAsync()
        }
      }, SINGLE_SESSION_MONITOR_INTERVAL_MILLIS)
    }
  }, [isSingleSessionClient, currentMemberProfile])

  //finally effect to trigger a logout if session is moniored and its expired
  useEffect(() => {
    if (isSessionExpired) {
      logout(UI_MESSAGE_WHEN_EXPIRED)
    }
  }, [isSessionExpired])
  return <></>
}
