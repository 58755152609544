import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { Member } from '../../types'
import { CustomTextField } from '../registration/components/CustomTextField'
import { validateEmail } from '../registration/EditContactInfo'
import { CLASS_NAMES } from '../shared/constants'
import SButton from '../system/customcomponents/SButton'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'

export function DepsInviteCell({
  dep,
  inviteDep,
}: {
  dep: Member
  inviteDep: (depId: number, email: string) => void
}) {
  const initialEmail = dep.enrollmentEmail ? dep.enrollmentEmail : ''
  const { t } = useSTranslate('deps')
  const [finalEmail, setFinalEmail] = useState(initialEmail)
  const [isEditMode, setIsEditMode] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState<boolean>()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileMode: boolean = isSmall

  //auto enable edit mode when no email exist
  useEffect(() => {
    if (!initialEmail) {
      setIsEditMode(true)
    }
  }, [initialEmail])

  useEffect(() => {
    if (validateEmail(finalEmail)) {
      setIsValidEmail(true)
    } else {
      setIsValidEmail(false)
    }
  }, [finalEmail, initialEmail])

  const displayName = `${dep.firstName} ${dep.lastName}`

  return (
    <Grid container>
      <Grid item xs={12} container>
        {!isEditMode && (
          <Grid container>
            <Grid item xs={12}>
              <STypography
                variant="body_bold"
                sx={{ textTransform: 'capitalize' }}
              >
                {displayName.toLowerCase()}
              </STypography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  minWidth: '100%',
                }}
              >
                <Typography fontSize={13} fontWeight={400}>
                  {finalEmail}
                </Typography>

                <Typography
                  className={CLASS_NAMES.editDepEmailButton}
                  onClick={() => {
                    setShowEdit(true)
                    setIsEditMode(true)
                  }}
                  fontSize={13}
                  sx={{
                    marginLeft: 'auto',
                    fontWeight: 600,
                    color: '#2D8DFF',
                    fontStyle: 'italic',
                    cursor: 'pointer',
                  }}
                >
                  {t('edit')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        {isEditMode && !showEdit && (
          <Grid container>
            <Grid item xs={12}>
              <STypography
                variant="body_bold"
                sx={{ textTransform: 'capitalize' }}
              >
                {displayName.toLowerCase()}
              </STypography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={CLASS_NAMES.enterDepEmailButton}
                fontSize={13}
                onClick={() => setShowEdit(true)}
                sx={{
                  fontWeight: 600,
                  color: '#2D8DFF',
                  fontStyle: 'italic',
                  cursor: 'pointer',
                }}
              >
                {!isMobileMode && t('email')}
                {isMobileMode && t('emailMobile')}
              </Typography>
            </Grid>
          </Grid>
        )}
        {isEditMode && showEdit && (
          <Grid container>
            <Grid item xs={12}>
              <STypography
                variant="body_bold"
                sx={{ textTransform: 'capitalize' }}
              >
                {displayName.toLowerCase()}
              </STypography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex' }}>
                <CustomTextField
                  type="text"
                  id="email"
                  name="email"
                  placeholder={t('validateEmail')}
                  label={t('emailAddress')}
                  value={finalEmail}
                  onChange={(e) => {
                    setFinalEmail(e.target.value)
                  }}
                  sx={{
                    marginBottom: 2.5,
                    height: '48px',
                    minWidth: isMobileMode ? '300px' : '320px',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: SPACING._1 }}>
          <SButton
            className={CLASS_NAMES.inviteDepButton}
            disabled={!isValidEmail}
            onClick={(e) => {
              inviteDep(dep.id, finalEmail)
            }}
            noCaps
          >
            {t('invite')}
          </SButton>
        </Grid>
        {/* )} */}
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ padding: '5px' }} />
      </Grid>
    </Grid>
  )
}
