import STypography, { STypographyProps } from './customcomponents/STypography'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { SPACING } from './theme2'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

export interface DrugDetailsProps {
  variant?: 'default' | 'labels'
  drugDosage?: string
  drugForm?: string
  drugCount?: string
  sx?: SxProps<Theme>
  textVariant?: STypographyProps['variant']
}

export default function DrugDetails({
  variant = 'default',
  drugDosage,
  drugForm,
  drugCount,
  sx,
  textVariant = 'bodysmall_regular',
}: DrugDetailsProps) {
  const { t } = useSTranslate('newComponents')

  if (variant === 'labels') {
    return (
      <Grid container direction={'column'} gap={SPACING._025}>
        {drugDosage && (
          <Grid item>
            <STypography variant={'bodysmall_regular'}>
              {t('dosage')}: {drugDosage}
            </STypography>
          </Grid>
        )}
        {drugForm && (
          <Grid item>
            <STypography variant={'bodysmall_regular'}>
              {t('form')}: {drugForm}
            </STypography>
          </Grid>
        )}
        {drugCount && (
          <Grid item>
            <STypography variant={'bodysmall_regular'}>
              {t('quantity')}: {drugCount}
            </STypography>
          </Grid>
        )}
      </Grid>
    )
  }

  const items = [drugDosage, drugForm, drugCount].filter(
    (item) => item !== undefined,
  )
  const text = items.join(' | ')

  return (
    <STypography
      variant={textVariant}
      sx={{
        wordWrap: 'break-word',
        ...sx,
      }}
    >
      {text}
    </STypography>
  )
}
