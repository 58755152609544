import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import PointerEventBlocker from '../shared/PointerEventBlocker'
import { RenderingLocation } from '../saving/saving-types'
import { CLASS_NAMES } from '../shared/constants'
import { useSColors } from '../styles/scripta-theme'

export function VideoWithPoster({
  video,
  isSmallScreen,
  title,
  renderLocation,
}: {
  video: string
  isSmallScreen: boolean
  title: string
  renderLocation?: RenderingLocation
}) {
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const { COLORS } = useSColors()
  const { t } = useSTranslate('strategies')
  return showVideo ? (
    <video
      style={{ aspectRatio: '16 / 9' }}
      width={'100%'}
      controls
      autoPlay
      controlsList="nodownload"
    >
      <source src={video} type="video/mp4" />
      {t('browserNotSupportVideoTag')}
    </video>
  ) : (
    <Box
      id={`${CLASS_NAMES.savingsStrategyVideoOverlay}-${title}`}
      className={`${CLASS_NAMES.savingsStrategyVideoOverlay} ${title} ${renderLocation}`}
      onClick={() => setShowVideo(true)}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <PointerEventBlocker>
        <Box
          sx={{
            cursor: 'pointer',
            backgroundColor: 'rgba(239, 239, 240, 1)',
            borderRadius: '5px',
            aspectRatio: '16 / 9',
            padding: '5px',
          }}
          display="flex"
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignContent="center"
        >
          <Typography
            color={COLORS.PRIMARY_BRAND}
            fontSize={isSmallScreen ? 20 : 30}
            fontWeight={'bold'}
            sx={{
              opacity: '.6',
            }}
          >
            {t('savingsStrgy')}
          </Typography>
          <Typography
            color={COLORS.PRIMARY_BRAND}
            fontSize={isSmallScreen ? 30 : 40}
            fontWeight={'bold'}
            width={'100%'}
            textAlign={'center'}
            sx={{
              opacity: '.6',
            }}
          >
            {title.toUpperCase()}
          </Typography>
        </Box>

        <PlayArrowRoundedIcon
          sx={{
            position: 'absolute',
            color: 'gray',
            left: '50%',
            top: '50%',
            fontSize: '5rem',
            marginLeft: '-2.5rem',
            marginTop: '-2.5rem',
          }}
        />
      </PointerEventBlocker>
    </Box>
  )
}
