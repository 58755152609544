import { Grid } from '@mui/material'
import STabs from './customcomponents/STabs'
import { useState } from 'react'
import { SavingStrategy } from '../strategies/types'
import CardContainer from './CardContainer'
import { SPACING } from './theme2'
import DrugDetails from './DrugDetails'
import STypography from './customcomponents/STypography'
import PriceElement, { PriceElementType } from './PriceElement'
import { DrugInfoModalLink } from './DrugInfoModal'
import { SavingMethodModalLink } from './SavingMethodModal'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { SeparatesParam } from '../drugprofile/types'
import { useSColors } from '../styles/scripta-theme'

//https://www.figma.com/file/an31SwER8wz64AGXh421jp/%5BScripta-Insights%5D-Design-System?type=design&node-id=844-6466&mode=dev

export interface RxCompareDrugPriceDataModel {
  drugId?: string // for Drug info modal button
  // SEPARATEs drugIds for tabs
  separatesParams?: SeparatesParam[]

  mmaId?: string // for Drug info modal button
  drugName: string
  drugDosage?: string
  drugForm?: string
  drugCount?: string
  priceBeforeDed?: string
  priceAfterDed?: string
  priceCopay?: string //i.e. $8.30
  priceCoupon?: string
  priceCash?: string
  priceType: PriceElementType
  strategy?: SavingStrategy // for Saving method modal button
  variant?: 'online_cash_pharmacy' | 'inhouse_pharmacy' | 'coupon' // for Saving method modal button
  tags?: React.ReactNode
}

export interface RxCompareProps {
  newRx: RxCompareDrugPriceDataModel
  currentRx: RxCompareDrugPriceDataModel
  customWidth?: string
}

export default function RxCompare({
  newRx,
  currentRx,
  customWidth,
}: RxCompareProps) {
  const { t } = useSTranslate('newComponents')

  const [tabIndex, setTabIndex] = useState(0)

  function handleOnTabChange(e: React.ChangeEvent<{}>, newValue: number) {
    setTabIndex(newValue)
  }

  return (
    <Grid container direction={'column'} gap={SPACING._1}>
      <Grid item alignSelf={'center'}>
        <STabs
          labels={[t('rxCompareYourNewRx'), t('rxCompareYourCurrentRx')]}
          activeTabIndex={tabIndex}
          onTabChange={handleOnTabChange}
        />
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          item
          alignSelf={'center'}
          sx={{ width: '100%', maxWidth: customWidth ? customWidth : '343px' }}
        >
          {tabIndex === 0 ? (
            <DrugCard
              drugPriceData={newRx}
              shouldInsurancePriceDisclaimer={true}
              customWidth={customWidth}
            />
          ) : (
            <DrugCard
              drugPriceData={currentRx}
              shouldInsurancePriceDisclaimer={false}
              customWidth={customWidth}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

function DrugCard({
  drugPriceData,
  shouldInsurancePriceDisclaimer = false,
  customWidth,
}: {
  drugPriceData: RxCompareDrugPriceDataModel
  shouldInsurancePriceDisclaimer?: boolean
  customWidth?: string
}) {
  const { COLORS } = useSColors()

  const { t } = useSTranslate('newComponents')

  const insurancePriceDisclaimerText = t(
    'rxCompareInsurancePriceDisclaimerText',
  )

  const shouldRenderModalLinksContainer =
    drugPriceData.drugId || drugPriceData.mmaId || drugPriceData.strategy

  return (
    <CardContainer
      sx={{
        padding: SPACING._1,
        maxWidth: customWidth ? customWidth : '343px',
        width: '100%',
      }}
    >
      <Grid container direction={'column'} gap={SPACING._1} alignItems="center">
        {drugPriceData.tags ? (
          <Grid container item gap={SPACING._05} justifyContent={'center'}>
            {drugPriceData.tags}
          </Grid>
        ) : null}
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <STypography variant="title3_bold" sx={{ textAlign: 'center' }}>
              {drugPriceData.drugName}
            </STypography>
          </Grid>
          <Grid item>
            <DrugDetails
              drugDosage={drugPriceData.drugDosage}
              drugForm={drugPriceData.drugForm}
              drugCount={drugPriceData.drugCount}
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            />
          </Grid>
        </Grid>
        {shouldRenderModalLinksContainer ? (
          <Grid container item justifyContent={'center'} gap={SPACING._075}>
            {drugPriceData.drugId || drugPriceData.mmaId ? (
              <Grid item>
                <DrugInfoModalLink
                  key={drugPriceData.drugId}
                  drugIdParams={{
                    drugId: Number(drugPriceData.drugId),
                    separatesParams: drugPriceData.separatesParams,
                  }}
                />
              </Grid>
            ) : null}
            {drugPriceData.strategy ? (
              <Grid item>
                <SavingMethodModalLink strategy={drugPriceData.strategy} />
              </Grid>
            ) : null}
          </Grid>
        ) : null}
        <Grid item>
          <PriceElement
            priceCoupon={drugPriceData.priceCoupon}
            priceCopay={drugPriceData.priceCopay}
            priceCash={drugPriceData.priceCash}
            priceBeforeDed={drugPriceData.priceBeforeDed}
            priceAfterDed={drugPriceData.priceAfterDed}
            priceType={drugPriceData.priceType}
            priceVariant="body_bold"
            shouldShowSuffixAsterisk={shouldInsurancePriceDisclaimer}
            sx={{ justifyContent: 'center' }}
          />
        </Grid>
        {shouldInsurancePriceDisclaimer &&
        (drugPriceData.priceType === 'insurance_ded' ||
          drugPriceData.priceType === 'insurance_copay') ? (
          <Grid item>
            <STypography
              variant="bodysmall_regular"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              {insurancePriceDisclaimerText}
            </STypography>
          </Grid>
        ) : null}
      </Grid>
    </CardContainer>
  )
}
