import { Grid } from '@mui/material'
import { NavBar, NavBarCombination } from '../system/NavBar'
import { MyDependentsManagementContainer } from '../member/MyDependentsManagement'
import { SPACING } from '../system/theme2'
import { MyAccount } from '../member/MyAccount'
import { ChangePassword } from '../member/ChangePassword'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

//TODO - rename these after old portal is gone
let SUBPAGE_BAR_VARIANT: NavBarCombination = 'bodybig_title_center_white'

export function NewSettingsPasswordPage() {
  const { t } = useSTranslate('newMyAccount')
  const passwordTitle = t('passwordTitle')

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar variant={SUBPAGE_BAR_VARIANT} title={passwordTitle} />
      </Grid>
      <Grid item xs={12} sx={{ padding: SPACING._1 }}>
        <ChangePassword forceMobileMode={true} />
      </Grid>
    </Grid>
  )
}

export function NewSettingsPreferencesPage() {
  const { t } = useSTranslate('newMyAccount')
  const preferencesTitle = t('preferencesTitle')

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar variant={SUBPAGE_BAR_VARIANT} title={preferencesTitle} />
      </Grid>
      <Grid item xs={12} sx={{ padding: SPACING._1 }}>
        <MyAccount forceMobileMode={true} variant="preferences" />
      </Grid>
    </Grid>
  )
}

export function NewSettingsDependentsPage() {
  const { t } = useSTranslate('newMyAccount')
  const dependentsTitle = t('dependentsTitle')

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar variant={SUBPAGE_BAR_VARIANT} title={dependentsTitle} />
      </Grid>
      <Grid item xs={12} sx={{ padding: SPACING._1 }}>
        <MyDependentsManagementContainer
          renderAsRegistrationDialogIfHasDeps={false}
          onDialogClose={() => {}}
        />
      </Grid>
    </Grid>
  )
}
