import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useIdleTimer } from 'react-idle-timer'
import { IS_PROD } from '../apiexec/utils'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import { useMemberAppContext } from '../MemberAppContext'

export function LogoutTimer() {
  const { t } = useSTranslate(['login', 'common'])

  const PORTAL_APP_CONFIG = usePortalAppConfig()
  const {
    currentMemberProfile,
    clearJwt,
    setCurrentMemberProfile,
    logout,
    history,
  } = useMemberAppContext()

  const timeout = IS_PROD()
    ? PORTAL_APP_CONFIG.TIMEOUT_MILIS
    : PORTAL_APP_CONFIG.TESTING_TIMEOUT_MILIS
  const [remaining, setRemaining] = useState(timeout)
  const handleOnIdle = () => {
    logout(t('autoLogout'))
  }
  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  })

  useEffect(() => {
    setRemaining(getRemainingTime())
    setInterval(() => {
      setRemaining(getRemainingTime())
    }, 1000)
  }, [])
  return <></>
}
