import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useSTranslate } from 'src/app/hooks/useSTranslate'
import Select from 'react-select'
import {
  AUTHORITY_TYPE_GUARDIAN,
  AUTHORITY_TYPE_OTHER,
  AUTHORITY_TYPE_PARENT,
  AUTHORITY_TYPE_POWER_OF_ATTORNEY,
  UiFormData,
} from './types'
import { useState } from 'react'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import SButton from '../system/customcomponents/SButton'

const useRepDialogStyles = makeStyles((theme: Theme) => {
  return createStyles({
    dialogPaper: {
      borderRadius: '20px',
    },
  })
})

const useRepDialogDesktopStyles = makeStyles((theme: Theme) => {
  return createStyles({
    dialogPaper: {
      borderRadius: '20px',
      width: '560px',
      minHeight: '590px',
    },
  })
})

export function LegalAuthorityRepDetails({
  patientData,
  onPatientDataChange,
  onCancel,
  isSmallDisplay,
}: {
  patientData: UiFormData
  onPatientDataChange: (patientData: UiFormData) => void
  onCancel: () => void
  isSmallDisplay: boolean
}) {
  const { t } = useSTranslate('contactMyPrescriber')
  const classes = useRepDialogStyles()
  const desktopClasses = useRepDialogDesktopStyles()
  const [open, setOpen] = useState(true)

  const ops = [
    { value: AUTHORITY_TYPE_PARENT, label: t('releaseAuthorityOptionParent') },
    {
      value: AUTHORITY_TYPE_GUARDIAN,
      label: t('releaseAuthorityOptionGuardian'),
    },
    {
      value: AUTHORITY_TYPE_POWER_OF_ATTORNEY,
      label: t('releaseAuthorityOptionAttorneyPower'),
    },
    { value: AUTHORITY_TYPE_OTHER, label: t('releaseAuthorityOptionOther') },
  ]

  //todo - move these to theme so they can be used in all dropdowns
  const SELECT_OPTION_STANDARD_BACKGROUND = '#FFFFFF'
  const SELECT_OPTION_STANDARD_TEXT_COLOR = '#201F22'
  const SELECT_OPTION_HOVER_BACKGROUND = '#E7E0EC'
  const SELECT_OPTION_SELECTED_BACKGROUND = SELECT_OPTION_HOVER_BACKGROUND
  const SELECT_OPTION_HOVER_TEXT_COLOR = '#201F22'

  let customSelectStyles: any = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isSelected
        ? SELECT_OPTION_SELECTED_BACKGROUND
        : SELECT_OPTION_STANDARD_BACKGROUND,
      color: state.isSelected
        ? SELECT_OPTION_STANDARD_TEXT_COLOR
        : SELECT_OPTION_STANDARD_TEXT_COLOR,
      // backgroundColor: 'white',
      // fontWeight: state.isSelected ? '400' : '',
      font: 'Proxmia Nova',
      // color: '#201F22',
      '&:hover': {
        // overriding hover
        ...styles, // apply initial styles
        fontWeight: '600',
        // backgroundColor: state.isSelected ? '#E7E0EC' : '#e6c7ff',
        backgroundColor: SELECT_OPTION_HOVER_BACKGROUND,
        color: state.isSelected
          ? SELECT_OPTION_STANDARD_TEXT_COLOR
          : SELECT_OPTION_STANDARD_TEXT_COLOR,
      },
    }),
  }

  let selectedOption = null
  if (patientData && patientData.authorityType) {
    selectedOption = ops.find((op) => op.value === patientData.authorityType)
  }

  return (
    <Dialog
      disableEscapeKeyDown
      onClose={(event: object, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          //do nothing, they must click continue or exit anyway
        }
      }}
      PaperProps={{
        classes: {
          root: isSmallDisplay
            ? classes.dialogPaper
            : desktopClasses.dialogPaper,
        },
      }}
      open={open}
      // maxWidth={isSmallDisplay ? 'xs' : 'sm'}
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            onCancel()
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers={false}
        sx={{
          marginTop: '10px',
          maxWidth: isSmallDisplay ? 'xs' : '600px',
          padding: isSmallDisplay ? '20 px' : '20px',
          margin: isSmallDisplay ? '0px' : '30px',
        }}
      >
        <Grid
          item
          container
          xs={12}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: '1.2rem', fontWeight: 600, textAlign: 'center' }}
            >
              {t('releaseAuthorityRepresentativeDesc')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '20px' }}>
            <TextField
              fullWidth
              autoComplete="false"
              value={patientData.authorityFullName}
              size="small"
              placeholder={t('releaseAuthorityRepresentativeFullNameLabel')}
              label={t('releaseAuthorityRepresentativeFullNameLabel')}
              onChange={(e: any) => {
                onPatientDataChange({
                  ...patientData,
                  authorityFullName: e.target.value,
                })
              }}
              inputProps={{
                autoComplete: 'nope',
              }}
            />
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '14px', marginLeft: '5px' }}>
                {t('releaseAuthorityTypeOptionSelectLabel')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Select
                options={ops}
                menuPortalTarget={document.body}
                value={selectedOption}
                // styles={{ : '250px' }}
                placeholder={t('releaseAuthorityOptionSelect')}
                onChange={(e) => {
                  onPatientDataChange({
                    ...patientData,
                    authorityType: e?.value,
                  })
                }}
                styles={customSelectStyles}
              />
            </Grid>
          </Grid>
          {patientData &&
            patientData.authorityType === AUTHORITY_TYPE_OTHER && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  value={
                    patientData.authorityOtherDesc
                      ? patientData.authorityOtherDesc
                      : ''
                  }
                  placeholder={t('releaseAuthorityOptionOther')}
                  label={t('releaseAuthorityOptionOtherDesc')}
                  multiline
                  rows={3}
                  onChange={(e: any) => {
                    onPatientDataChange({
                      ...patientData,
                      authorityOtherDesc: e.target.value,
                    })
                  }}
                />
              </Grid>
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{ margin: '20px' }}
        >
          <Grid item>
            <SButton
              size="small"
              noCaps
              onClick={() => {
                setOpen(false)
              }}
              variant="outlined"
            >
              {t('releaseAuthorityBtnCancel')}
            </SButton>
          </Grid>
          <Grid item>
            <SButton
              noCaps
              size="small"
              variant="contained"
              onClick={() => {
                setOpen(false)
                // onChanged(repData!)
              }}
            >
              {t('releaseAuthorityBtnSubmit')}
            </SButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
