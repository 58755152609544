import { Checkbox, DialogContent, Grid, Link, Typography } from '@mui/material'
import { useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useMemberAppContext } from '../MemberAppContext'
import { useCommonPortalStyles } from '../styles/common-portal-styles'
import { useSColors } from '../styles/scripta-theme'
import { LegalAuthorityRepDetails } from './LegalAuthorityRepDetails'
import { UiFormData } from './types'
import { BottomActions } from './Wizard'

export function LegalReleaseForm({
  patientData,
  onPatientDataChange,
  isSmallDisplay,
  acceptedPrimary,
  acceptedSecondary,
  setAcceptedPrimary,
  setAcceptedSecondary,
  onContinue,
  onBack,
}: {
  patientData: UiFormData
  onPatientDataChange: (patientData: UiFormData) => void
  isSmallDisplay: boolean
  acceptedPrimary: boolean
  acceptedSecondary: boolean
  setAcceptedPrimary: (accepted: boolean) => void
  setAcceptedSecondary: (accepted: boolean) => void
  onContinue: () => void
  onBack: () => void
}) {
  const { COLORS } = useSColors()
  const { t } = useSTranslate('contactMyPrescriber')

  const baseClasses = useCommonPortalStyles()
  const [renderAuthorityDetailsDialog, setRenderAuthorityDetailsDialog] =
    useState(false)
  const [renderAuthorityDetailsDialogKey, setRenderAuthorityDetailsDialogKey] =
    useState(Math.random())

  const { currentMemberProfile } = useMemberAppContext()
  const displayName =
    currentMemberProfile?.firstName + ' ' + currentMemberProfile?.lastName

  const mergedIfNotText = `${t('releaseIfNot1')}${displayName}${t(
    'releaseIfNot2',
  )}${displayName}'s${t('releaseIfNot3')}`

  var rightNow = new Date()
  let currentDay = String(rightNow.getDate()).padStart(2, '0')
  let currentMonth = String(rightNow.getMonth() + 1).padStart(2, '0')
  let currentYear = rightNow.getFullYear()

  let currentDate = `${currentMonth}/${currentDay}/${currentYear}`

  const releaseConsentMergedTextWithName = `${t(
    'releaseConsetToTermsPrefix',
  )} ${displayName} ${t('releaseConsetToTermsSuffix')}`

  const relaseConsentToContactPrescriberMergedWithName = `${t(
    'releaseConsentToContactPrescriberPrefix',
  )} ${displayName} ${t('releaseConsentToContactPrescriberSuffix')}`

  return (
    <>
      <DialogContent>
        <Grid item container xs={12}>
          <Grid item xs={12} sx={{ marginTop: '10px', marginBottom: '5px' }}>
            <Typography display="inline">{t('releaseNameLabel')}</Typography>
            <Typography display="inline" sx={{ fontWeight: 600 }}>
              {displayName}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '10px', marginBottom: '10px' }}>
            <Typography display="inline">{t('releaseDateLabel')}</Typography>
            <Typography display="inline" sx={{ fontWeight: 600 }}>
              {currentDate}
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Grid item>
              <Typography variant="caption">{mergedIfNotText}</Typography>
              <Link
                component="button"
                variant="body2"
                sx={{
                  fontSize: '0.9em',
                  fontWeight: 700,
                  color: COLORS.PRIMARY_BRAND,
                  textDecoration: 'none',
                }}
                onClick={() => {
                  setRenderAuthorityDetailsDialogKey(Math.random())
                  setRenderAuthorityDetailsDialog(true)
                }}
              >
                {t('releaseClickHere')}
              </Link>
            </Grid>
          </Grid>
          {patientData.authorityFullName && patientData.authorityType && (
            <Grid item xs={12} container sx={{ marginTop: '10px' }}>
              <Grid item>
                <Typography display="inline" sx={{ fontWeight: 600 }}>
                  {t('releaseSelected')} {patientData.authorityFullName}
                </Typography>
                <Link
                  component="button"
                  variant="body2"
                  sx={{
                    fontSize: '0.9em',
                    fontWeight: 700,
                    color: COLORS.PRIMARY_BRAND,
                    textDecoration: 'none',
                    marginLeft: '10px',
                  }}
                  onClick={() => {
                    onPatientDataChange({
                      ...patientData,
                      authorityFullName: '',
                      authorityType: '',
                      authorityOtherDesc: '',
                    })
                  }}
                >
                  {isSmallDisplay
                    ? t('releaseRemoveMobile')
                    : t('releaseRemove')}
                </Link>
              </Grid>
            </Grid>
          )}
          {renderAuthorityDetailsDialog && (
            <LegalAuthorityRepDetails
              isSmallDisplay={isSmallDisplay}
              onCancel={() => {
                setRenderAuthorityDetailsDialog(false)
                setRenderAuthorityDetailsDialogKey(Math.random())
              }}
              patientData={patientData}
              onPatientDataChange={onPatientDataChange}
              key={renderAuthorityDetailsDialogKey}
            />
          )}
          <Grid item xs={12} sx={{ marginTop: '15px' }}>
            <Checkbox
              checked={acceptedPrimary}
              onChange={(e) => {
                setAcceptedPrimary(e.target.checked)
              }}
              sx={{ float: 'left', padding: '3px 10px 0px 0px', margin: '0px' }}
            />
            <Typography sx={{ fontSize: '0.8rem' }}>
              {releaseConsentMergedTextWithName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: 'center',
                fontWeight: 600,
                fontSize: '1rem',
                marginBottom: '15px',
                marginTop: '15px',
              }}
            >
              {t('releaseConsetToContactPrescriberTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checked={acceptedSecondary}
              onChange={(e) => {
                setAcceptedSecondary(e.target.checked)
              }}
              sx={{ float: 'left', padding: '3px 10px 0px 0px', margin: '0px' }}
            />

            <Typography sx={{ fontSize: '0.8rem' }}>
              {relaseConsentToContactPrescriberMergedWithName}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <BottomActions
        isSmallDisplay={isSmallDisplay}
        continueDisabled={!acceptedPrimary || !acceptedSecondary}
        onContinue={onContinue}
        onCancel={onBack}
      />
    </>
  )
}
