import Box from '@mui/material/Box'
import BottomNavigation, {
  BottomNavigationProps,
} from '@mui/material/BottomNavigation'
import BottomNavigationAction, {
  BottomNavigationActionProps,
} from '@mui/material/BottomNavigationAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useSColors } from 'src/app/styles/scripta-theme'

export interface SBottomNavigationProps extends BottomNavigationProps {
  navigationItems: { label: string; value?: string; icon: IconProp }[]
}

export default function SBottomNavigation(props: SBottomNavigationProps) {
  return (
    <Box
      sx={{
        boxShadow: '0px 0px 32px 0px rgba(0, 0, 0, 0.15)',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <BottomNavigation {...props} showLabels>
        {props.navigationItems.map((item, index) => (
          <SBottomNavigationAction
            key={item.label}
            label={item.label}
            value={item.value || index.toString()}
            icon={
              <FontAwesomeIcon
                className="MuiSvgIcon-root"
                icon={item.icon}
                fontSize={'16px'}
                height={'16px'}
              />
            }
          />
        ))}
      </BottomNavigation>
    </Box>
  )
}

function SBottomNavigationAction(props: BottomNavigationActionProps) {
  const { COLORS } = useSColors()

  return (
    <BottomNavigationAction
      data-testid={`bottom-navigation-action-${props.value}`}
      {...props}
      sx={{
        '& .MuiBottomNavigationAction-label': {
          fontSize: '13px',
          fontWeight: '400',
          lineHeight: '16px',
          color: COLORS.TEXT_GREY_DARK,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '16px',
          padding: '6px 24px',
          color: COLORS.TEXT_GREY_DARK,
        },
        '&.Mui-selected': {
          '& .MuiBottomNavigationAction-label': {
            fontWeight: '600',
            fontSize: '13px',
            color: COLORS.PRIMARY_BRAND,
            lineHeight: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
            color: 'white',
            backgroundColor: COLORS.PRIMARY_BRAND,
            borderRadius: '20px',
            padding: '6px 24px',
          },
        },
        ...props.sx,
      }}
    />
  )
}
