import { Box, Grid, Paper, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { DrugIdParams } from '../drugprofile/types'
import { useNotifier } from '../hooks/useNotify'
import { useMemberService } from '../member/useMemberService'

import { useMemberAppContext } from '../MemberAppContext'
import { DrugSuggestion } from '../search/types'
import { useSColors } from '../styles/scripta-theme'
import { MyMedsSavedMedsListItem } from '../system/ListItems'
import { SPACING } from '../system/theme2'
import { ROUTE_NEW_SEARCH_OPPORTUNITY } from '../v2/NewMemberPortalMain'
import { useSizes } from '../v2/useNewHooks'

export interface FavoritesResp {
  'favorite-drugs': FavoriteDrug[]
}
export interface FavoriteDrug {
  id: number
  name: string
  'short-descriptions': string[]
}

export function BookmarksMainContainer() {
  const service = useMemberService()
  const theme = useTheme()
  const { isMobile } = useSizes()
  const isTabletOrDekstop = !isMobile

  const [myFavs, setMyFavs] = useState<FavoriteDrug[]>([])
  const notifier = useNotifier()

  const [visibleFavs, setVisibleFavs] = useState<FavoriteDrug[]>([])

  async function loadFavorites() {
    const favsResp = await service.getAllFavorites()
    if (favsResp && favsResp['favorite-drugs']) {
      setMyFavs(favsResp['favorite-drugs'])
      setVisibleFavs(favsResp['favorite-drugs'])
    } else {
      setMyFavs([])
      setVisibleFavs([])
    }
  }

  useEffect(() => {
    loadFavorites()
  }, [])

  function handleFilter(letter: string) {
    if (letter === 'Show All' || letter === 'All') {
      setVisibleFavs(myFavs)
    } else {
      const cardsFiltered = myFavs.filter(
        (card) => card.name.charAt(0).toUpperCase() === letter,
      )
      setVisibleFavs(cardsFiltered)
    }
  }

  return (
    <Box sx={{ marginTop: SPACING._2 }}>
      <Grid item container xs={12} style={{ backgroundColor: 'white' }}>
        {/* //for tablet or desktop show alpahbet filter on top of the screen */}
        {isTabletOrDekstop && (
          <Grid item xs={12} style={{ padding: '15px' }}>
            <Paper elevation={3} style={{ padding: '15px' }}>
              <LetterFilter
                onFilterClicked={handleFilter}
                isTabletOrDesktop={isTabletOrDekstop}
              />
            </Paper>
          </Grid>
        )}

        <Grid
          item
          container
          // xs={11}
          // sm={12}
          // alignItems="flex-start"
          // style={{ marginBottom: '20px', padding: '15px' }}
        >
          <ListOfBookmarks
            favorites={visibleFavs}
            onClickRemove={async (drugId: number) => {
              const drugIdParams: DrugIdParams = {
                drugId: drugId,
              }
              const removeResp = await service.removeDrugFromFavorites(
                drugIdParams,
              )
              const validation = checkMicroServiceResponseStatus(
                removeResp,
                true,
              )
              if (!validation.isError) {
                notifier.sendMsg(
                  'Drug successfully removed from saved meds',
                  'success',
                )
              } else {
                notifier.sendMsg(
                  'Error removing drug from saved meds. Please try again or contact our member support',
                  'error',
                )
              }
              loadFavorites()
            }}
          />
        </Grid>
        {/* {!isTabletOrDekstop && (
          <Grid
            item
            container
            justifyContent="flext-start"
            xs={1}
            style={{ backgroundColor: 'white', marginTop: '5px' }}
          >
            {visibleFavs && visibleFavs.length > 0 && (
              <Paper
                elevation={3}
                style={{ paddingLeft: '3px', paddingRight: '3px' }}
              >
                <LetterFilter
                  onFilterClicked={handleFilter}
                  isTabletOrDesktop={isTabletOrDekstop}
                />
              </Paper>
            )}
          </Grid>
        )} */}
      </Grid>
    </Box>
  )
}

export function ListOfBookmarks({
  favorites,
  onClickRemove,
}: {
  favorites: FavoriteDrug[]
  onClickRemove: (drugId: number) => void
}) {
  const { t } = useSTranslate('myMeds')
  const { setSelectedSuggestion } = useMemberAppContext()
  const { history } = useMemberAppContext()
  return (
    <Grid item container spacing={2} xs={12} sx={{ padding: SPACING._1 }}>
      {favorites.map((f) => {
        const desc =
          f['short-descriptions'] && f['short-descriptions'].length > 0
            ? f['short-descriptions'].join('')
            : ''

        return (
          <Grid
            item
            xs={12}
            sx={{ cursor: 'pointer' }}
            // sx={{ backgroundColor: 'red' }}
            // style={{ marginBottom: '10px' }}
          >
            <MyMedsSavedMedsListItem
              drugDescription={desc}
              drugName={f.name}
              onBookMarkButtonClick={() => {
                onClickRemove(f.id)
              }}
              onItemClick={() => {
                const bookmarkAsSuggestion: DrugSuggestion = {
                  id: f.id,
                  name: f.name,
                  code: f.name,
                  'is-generic': false,
                }
                setSelectedSuggestion(bookmarkAsSuggestion)
                history.push(ROUTE_NEW_SEARCH_OPPORTUNITY)
              }}
            />
          </Grid>
        )
      })}
      {favorites.length === 0 && (
        <Grid item xs={12} textAlign="center" style={{ marginTop: '20px' }}>
          <Typography>{t('savedMedsNotFound')}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export function LetterFilter({
  onFilterClicked,
  isTabletOrDesktop,
}: {
  onFilterClicked: (letter: string) => void
  isTabletOrDesktop: boolean
}) {
  const { COLORS } = useSColors()
  const alphabet = [
    isTabletOrDesktop ? 'Show All' : 'All',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]
  return (
    <Grid
      container
      justifyContent={isTabletOrDesktop ? 'space-between' : 'flex-start'}
    >
      {alphabet.map((letter) => {
        const LETTER = (
          <Typography
            key={'letter' + letter}
            color={COLORS.TEXT_SECONDARY}
            sx={{ cursor: 'pointer', fontSize: '0.8rem' }}
            onClick={() => {
              onFilterClicked(letter)
            }}
          >
            {' '}
            {letter}{' '}
          </Typography>
        )
        if (isTabletOrDesktop) {
          return (
            <Grid item style={{ padding: '2px' }}>
              {LETTER}
            </Grid>
          )
        } else {
          return (
            <Grid item xs={12}>
              {LETTER}
            </Grid>
          )
        }
      })}
    </Grid>
  )
}
