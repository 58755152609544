import { Checkbox, DialogContent, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

import { UiFormData, ValidationResult, validateUiData } from './types'
import { BottomActions, DESKTOP_DIALOG_SX, MOBILE_DIALOG_SX } from './Wizard'

export function PrefContactMethod({
  contactData,
  onContactDataChange,
  isSmallDisplay,
  onContinue,
  onBack,
  forceTriggerValidation,
  validationResult,
  setValidationResult,
}: {
  contactData: UiFormData
  onContactDataChange: (data: UiFormData) => void
  isSmallDisplay: boolean
  onContinue: () => void
  onBack: () => void
  forceTriggerValidation: boolean
  validationResult: ValidationResult
  setValidationResult: (validationResult: ValidationResult) => void
}) {
  const { t } = useSTranslate('contactMyPrescriber')
  let renderBottomActions = isSmallDisplay

  //dont validate immediatelly, let user enter some data first and click continue once
  const [enableValidation, setEnableValidation] = useState<boolean>(false)

  useEffect(() => {
    if (forceTriggerValidation) {
      setEnableValidation(true)
    }
  }, [forceTriggerValidation])

  useEffect(() => {
    if (enableValidation) {
      const validationResult = validateUiData(contactData)
      setValidationResult(validationResult)
    }
  }, [contactData, enableValidation])

  return (
    <>
      <DialogContent sx={isSmallDisplay ? MOBILE_DIALOG_SX : DESKTOP_DIALOG_SX}>
        <Grid
          item
          container
          spacing={isSmallDisplay ? 2 : 1}
          // sx={{ backgroundColor: 'red' }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: isSmallDisplay ? '1.15rem' : '1.15rem' }}
            >
              {t('prefContactMethod')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Checkbox
              checked={contactData.emailOk}
              onChange={(e) => {
                onContactDataChange({
                  ...contactData,
                  emailOk: e.target.checked,
                })
              }}
              sx={{
                float: 'left',
                padding: '3px 5px 0px 0px',
                margin: '0px',
              }}
            />
            <Typography
              sx={{
                fontSize: isSmallDisplay ? '1rem' : '0.8rem',
                marginTop: '5px',
              }}
            >
              {t('prefEmailLabel')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Checkbox
              checked={contactData.phoneOk}
              onChange={(e) => {
                onContactDataChange({
                  ...contactData,
                  phoneOk: e.target.checked,
                })
              }}
              sx={{ float: 'left', padding: '3px 5px 0px 0px', margin: '0px' }}
            />
            <Typography
              sx={{
                fontSize: isSmallDisplay ? '1rem' : '0.8rem',
                marginTop: '5px',
              }}
            >
              {t('prefPhoneLabel')}
            </Typography>
          </Grid>
          {enableValidation && !validationResult.validAtLeastPhoneOrEmailOk && (
            <Grid item xs={12}>
              <Typography variant="caption" color="error">
                {t('errorPatientMethodField')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {renderBottomActions && (
        <BottomActions
          isSmallDisplay={isSmallDisplay}
          // continueDisabled={!acceptedPrimary || !acceptedSecondary}
          onContinue={() => {
            setEnableValidation(true)
            const ret = validateUiData(contactData)
            setValidationResult(ret)
            if (ret.validAtLeastPhoneOrEmailOk) {
              onContinue()
            } else {
            }
          }}
          onCancel={onBack}
          // useBackLabelForCancel={true}
        />
      )}
    </>
  )
}
