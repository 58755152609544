import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Link, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { DrugIdParams, SeparatesParam } from '../drugprofile/types'
import { useSColors } from '../styles/scripta-theme'
import { DrugProfileComp } from '../v2/NewDrugProfilePage'
import { useSizes } from '../v2/useNewHooks'
import { SDialog } from './customcomponents/SDialog'
import STypography from './customcomponents/STypography'
import { SPACING } from './theme2'

export function DrugInfoModalLink({
  drugIdParams,
}: {
  drugIdParams: DrugIdParams
}) {
  const { COLORS } = useSColors()
  const { t } = useSTranslate('newComponents')
  const [open, setOpen] = useState(false)

  return (
    <>
      <Link
        component={'button'}
        sx={{
          color: COLORS.TEXT_SECONDARY,
          textDecorationColor: COLORS.TEXT_SECONDARY,
        }}
        onClick={() => setOpen(true)}
      >
        <STypography
          variant="bodysmall_semibold"
          sx={{
            color: COLORS.TEXT_SECONDARY,
            textDecorationColor: COLORS.TEXT_SECONDARY,
          }}
        >
          {t('drugInfo')}
        </STypography>
      </Link>
      <DrugInfoModal
        open={open}
        onClose={() => setOpen(false)}
        drugIdParams={drugIdParams}
      />
    </>
  )
}

export default function DrugInfoModal({
  drugIdParams,
  open,
  onClose,
}: {
  drugIdParams: DrugIdParams
  open: boolean
  onClose: () => void
}) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 350)

  const { isMobile } = useSizes()

  //if user clicks a different tab in case of separates
  const [clickedSeparateDrugId, setClickedSeparateDrugId] = useState<
    undefined | DrugIdParams
  >(undefined)

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight - 350)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <SDialog
      open={open}
      onClose={() => {
        onClose()
      }}
      customPaperSx={{
        minWidth: isMobile ? '95vw' : 650,
        width: isMobile ? '95vw' : 650,
        paddingLeft: 0,
        paddingRight: 0,
      }}
      customContentSx={{
        paddingLeft: isMobile ? '15px' : '30px',
        paddingRight: isMobile ? '15px' : '30px',
      }}
    >
      {drugIdParams.separatesParams && (
        <SeparatesTabHeader
          separatesParams={drugIdParams.separatesParams}
          onSeparatesDrugTabSelected={(tabDrugId: DrugIdParams) => {
            setClickedSeparateDrugId(tabDrugId)
          }}
        />
      )}
      {clickedSeparateDrugId && (
        <DrugProfileComp drugIdParams={clickedSeparateDrugId} />
      )}
      {!clickedSeparateDrugId && (
        <DrugProfileComp drugIdParams={drugIdParams} />
      )}
    </SDialog>
  )
}

function SeparatesTabHeader({
  separatesParams,
  onSeparatesDrugTabSelected,
}: {
  separatesParams: SeparatesParam[]
  onSeparatesDrugTabSelected: (tabDrugId: DrugIdParams) => void
}) {
  const { COLORS } = useSColors()
  const [selected, setSelected] = useState(
    separatesParams && separatesParams.length > 0
      ? separatesParams[0].drugId
      : undefined,
  )

  if (!separatesParams) {
    return null
  }
  if (separatesParams.length === 0) {
    return null
  }
  return (
    <Box>
      <Tabs
        value={selected}
        TabIndicatorProps={{
          style: {
            backgroundColor: COLORS.PRIMARY_BRAND,
            height: '5px',
            borderRadius: 5,
          },
        }}
        allowScrollButtonsMobile={true}
        ScrollButtonComponent={(props) => {
          if (props.direction === 'left' && !props.disabled) {
            return (
              <FontAwesomeIcon
                icon={faChevronLeft}
                color={COLORS.TEXT_SECONDARY}
                height={'26px'}
                fontSize={'26px'}
                style={{
                  padding: '4px',
                  marginTop: '7px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )
          } else if (props.direction === 'right' && !props.disabled) {
            return (
              <FontAwesomeIcon
                icon={faChevronRight}
                color={COLORS.TEXT_SECONDARY}
                height={'26px'}
                fontSize={'26px'}
                style={{
                  padding: '4px',
                  marginTop: '7px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )
          } else {
            return null
          }
        }}
        onChange={(event, newValue) => {
          setSelected(newValue)
          const separateDrugToShow: DrugIdParams = {
            drugId: newValue,
          }

          onSeparatesDrugTabSelected(separateDrugToShow)
        }}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Multiple Drugs Tab for separates>"
        sx={{
          paddingBottom: SPACING._2,

          '& .MuiTabs-indicator': { color: COLORS.PRIMARY_BRAND },
        }}
      >
        {separatesParams.map((separate) => (
          <Tab
            sx={{
              // border: '1px solid #D0CFD3',
              textTransform: 'capitalize',
              color: COLORS.TEXT_BLACK,
              fontSize: '24px',
            }}
            value={separate.drugId}
            label={separate.displayName}
          />
        ))}
      </Tabs>
    </Box>
  )
}
