import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  CustomizedDependentInviteData,
  MemberDependents,
  MemberProfile,
  RegistrationSetupData,
  isValidResponse,
} from '../../types'
import { useMemberAppContext } from '../MemberAppContext'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import { GAService } from '../application/ga/GAService'
import { useNotifier } from '../hooks/useNotify'

import { DepsInviteCell } from './DepsInviteCell'
import { useMemberService } from './useMemberService'
import { useSizes } from '../v2/useNewHooks'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import { useSColors } from '../styles/scripta-theme'
import { LOG } from 'src/app/v2/applog'

export function MyDependentsManagementContainer({
  renderAsRegistrationDialogIfHasDeps = false,
  onDialogClose,
}: {
  renderAsRegistrationDialogIfHasDeps: boolean
  onDialogClose: () => void
}) {
  const { isMobile } = useSizes()
  const gaService = new GAService()
  const service = useMemberService()
  const [deps, setDeps] = useState<MemberDependents>()
  const [errorMsg, setErrorMsg] = useState<string | undefined>()

  const [isLoading, setIsLoading] = useState(false)
  const [isErrorLoading, setIsErrorLoading] = useState(false)
  const { currentMemberProfile } = useMemberAppContext()
  const { sendMsg } = useNotifier()
  const theme = useTheme()
  const { t } = useSTranslate('deps')

  const [isDialogVariantOpen, setIsDialogVariantOpen] = useState(false)

  async function loadDepsAsync() {
    const regSetup: Partial<RegistrationSetupData> = {
      memberId: currentMemberProfile!.memberId,
      tenantId: currentMemberProfile!.tenantId,
    }
    const s = await service.getMemberDepsApi(regSetup)
    if (isValidResponse<MemberDependents>(s)) {
      setIsLoading(false)
      setDeps(s)
      //warn if no dependents were found - why would they be here then....

      //the input flag means its a new user registering so we autmatically check for depenedts
      //in that case we dont want to log the warning
      // let isAutomaticWindow = renderAsRegistrationDialogIfHasDeps
      // if (s.dependents.length === 0 && !isAutomaticWindow) {
      //   LOG.warn(
      //     'dependents',
      //     'User visited the Manage Dependents screen, but no dependents were found by the system',
      //   )
      // }
    } else {
      // LOG.error(
      //   'dependents',
      //   'Error response returned when fetching dependencts',
      //   s,
      // )
      setIsLoading(false)
      setIsErrorLoading(true)
      try {
        const errorJson = await (s as any).json()
        setErrorMsg(errorJson.description)
      } catch (error) {}
    }
  }

  async function sendInvitesAsync(depId: number, email: string) {
    if (renderAsRegistrationDialogIfHasDeps) {
      gaService.trackEvent(gaService.eventMap.home_page_dependent_invite_sent)
    }

    const inviteData: CustomizedDependentInviteData = {
      memberId: currentMemberProfile!.memberId,
      tenantId: currentMemberProfile!.tenantId,
      userName: currentMemberProfile!.userName,
      inviteEntries: [{ dependentId: depId, email: email }],
    }

    const resp = await service.sendCustomizedDepsInviteApi(inviteData)

    if (isValidResponse<any>(resp)) {
      sendMsg(t('message'), 'success')
    } else {
      //lets dont bomb out fully and prevent app usage just for dependent invite error
      sendMsg(
        'We were unable to send the dependents invitation. Please contact member support',
        'error',
      )
    }
  }

  useEffect(() => {
    if (currentMemberProfile) {
      setIsLoading(true)
      loadDepsAsync()
    }
  }, [currentMemberProfile])

  if (renderAsRegistrationDialogIfHasDeps) {
    return (
      <DepsAsDialogWrapper
        isSmall={isMobile}
        currentMemberProfile={currentMemberProfile}
        deps={deps}
        sendInvitesAsync={sendInvitesAsync}
        onDialogClose={onDialogClose}
      />
    )
  }

  return (
    <DepsContent
      isMobileMode={isMobile}
      currentMemberProfile={currentMemberProfile}
      deps={deps}
      sendInvitesAsync={sendInvitesAsync}
    />
  )
}

function Disclaimer({ isMobileMode }: { isMobileMode: boolean }) {
  const { t } = useSTranslate('deps')

  return (
    <Box
      sx={{
        padding: isMobileMode ? '10px' : '16px',
        backgroundColor: '#F9F9F9',
        border: '1px solid #D4C5C7',
        borderRadius: '8px',
        marginTop: '20px',
      }}
    >
      <Typography
        sx={{
          fontSize: isMobileMode ? '12px' : '14px',
          fontWeight: 600,
        }}
      >
        {t('disclaimerTitle')}
      </Typography>
      <Typography
        sx={{
          fontSize: isMobileMode ? '12px' : '13px',
          fontWeight: 400,
          color: '#201F22',
          opacity: '0.7',
        }}
      >
        {t('disclaimer')}
      </Typography>
    </Box>
  )
}

function DepsContent({
  isMobileMode,
  currentMemberProfile,
  deps,
  sendInvitesAsync,
}: {
  isMobileMode: boolean
  currentMemberProfile: MemberProfile | undefined
  deps: MemberDependents | undefined
  sendInvitesAsync: any
}) {
  const { t } = useSTranslate('deps')
  if (!currentMemberProfile) {
    return null
  }
  const displayName = `${currentMemberProfile.firstName} ${currentMemberProfile.lastName}`

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <STypography variant="body_regular">{t('description')}</STypography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: SPACING._1 }}>
          <STypography variant="body_bold" sx={{ textTransform: 'capitalize' }}>
            {displayName.toLowerCase()}
          </STypography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={13}>Member</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
          <Divider />
        </Grid>
        <Grid container item xs={12} sx={{ marginTop: '10px' }}>
          {deps?.dependents.map((dep, i) => {
            return (
              <Grid item xs={12} sx={{ marginTop: SPACING._1 }}>
                {i === 0 && (
                  <STypography
                    sx={{ marginBottom: SPACING._1 }}
                    variant="bodybig_bold"
                  >
                    Dependents
                  </STypography>
                )}
                <DepsInviteCell
                  key={dep.id}
                  dep={dep}
                  inviteDep={sendInvitesAsync}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Disclaimer isMobileMode={isMobileMode} />
      </Grid>
    </Grid>
  )
}

export const useDepsAsDialogStyles = makeStyles((theme: Theme) => {
  return createStyles({
    backDrop: {
      background: 'white',
    },
    dialogActions: {
      marginRight: '55px',
      marginBottom: '55px',
    },
    dialogPaper: {
      [theme.breakpoints.down(480)]: {
        margin: 14,
      },
    },
  })
})

function DepsAsDialogWrapper({
  isSmall,
  currentMemberProfile,
  deps,
  sendInvitesAsync,
  onDialogClose,
}: {
  isSmall: boolean
  currentMemberProfile: MemberProfile | undefined
  deps: MemberDependents | undefined
  sendInvitesAsync: any
  onDialogClose: () => void
}) {
  const { COLORS } = useSColors()
  const theme = useTheme()
  const classes = useDepsAsDialogStyles()
  const { t } = useSTranslate('deps')

  const [isOpen, setIsOpen] = useState(true)
  if (!deps || !deps.dependents || deps.dependents.length === 0) {
    return null
  }

  return (
    <NewPortalDialogDesign
      title={t('title')}
      open={true}
      upperRightX={false}
      onClose={() => {
        onDialogClose()
      }}
      customTitleSx={{ color: COLORS.TEXT_BLACK, paddingTop: '20px' }}
      customPaperSx={
        isSmall
          ? {
              width: '90%',
            }
          : undefined
      }
      singleActionProps={{
        text: 'Close',
        onClick: () => {
          onDialogClose()
        },
        buttonSx: {
          width: '100px',
        },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <DepsContent
            isMobileMode={isSmall}
            currentMemberProfile={currentMemberProfile}
            deps={deps}
            sendInvitesAsync={sendInvitesAsync}
          />
        </Grid>
      </Grid>
    </NewPortalDialogDesign>
  )
}
