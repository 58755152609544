import WarningIcon from '@mui/icons-material/Warning'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import LikeIcon from '../../images/like_icon.png'
import SButton from '../system/customcomponents/SButton'

export function ThankYouForSwitchDialog({
  // onExitAnyay,
  onOk,
  isErrorVariant,
}: // isSmallDisplay,
{
  // onExitAnyay: () => void
  onOk: () => void
  isErrorVariant?: boolean
  // isSmallDisplay: boolean
}) {
  const [open, setOpen] = useState(true)

  const { t } = useSTranslate('contactMyPrescriber')
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallDisplay = !isDesktop

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: '20px',
          padding: isSmallDisplay ? '0px' : '20px',
        },
      }}
    >
      <DialogContent>
        <Grid container alignItems={'center'} justifyContent="center">
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            justifyContent="center"
          >
            {!isErrorVariant && (
              <Grid item>
                <img src={LikeIcon} />
              </Grid>
            )}
            {isErrorVariant && (
              <Grid item>
                <WarningIcon fontSize="large" color="primary" />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: isErrorVariant ? '1.4rem' : '1.4rem',
                fontWeight: 700,
              }}
            >
              {isErrorVariant ? t('errorSubmittedTitle') : t('submittedTitle')}
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ marginTop: '10px' }}>
            <Typography sx={{ textAlign: 'center', fontSize: '1rem' }}>
              {isErrorVariant ? t('errorSubmittedMsg1') : t('submittedMsg1')}
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ marginTop: '10px' }}>
            <Typography
              sx={{ textAlign: 'center', fontSize: '1rem', lineHeight: '1rem' }}
            >
              {isErrorVariant ? t('errorSubmittedMsg2') : t('submittedMsg2')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems={'center'}
            justifyContent="center"
            style={{ marginTop: '15px' }}
          >
            {!isErrorVariant && (
              <SButton
                size="small"
                variant="contained"
                onClick={() => {
                  setOpen(false)
                }}
              >
                Okay
              </SButton>
            )}

            {isErrorVariant && (
              <Grid
                item
                xs={12}
                container
                alignItems={'center'}
                justifyContent="center"
              >
                <Grid
                  item
                  xs={isSmallDisplay ? 12 : 6}
                  sx={{ marginTop: '10px' }}
                >
                  <SButton
                    size="small"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      setOpen(false)
                    }}
                  >
                    {t('errorBtnContactSupport')}
                  </SButton>
                </Grid>
                <Grid
                  item
                  xs={isSmallDisplay ? 12 : 12}
                  sx={{ marginTop: '10px' }}
                >
                  <SButton
                    size="small"
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setOpen(false)
                    }}
                  >
                    {t('errorBtnCancel')}
                  </SButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
