import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Dialog,
  Box,
  DialogContent,
  Grid,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { useToastContext } from '../application/ScriptaSnackbarProvider'
import { DrugIdParams } from '../drugprofile/types'
import { useMemberService } from '../member/useMemberService'
import { useMemberAppContext } from '../MemberAppContext'
import { ROUTE_MY_MEDS } from '../PortalRoutes'
import { useDrugSearchService } from '../search/useDrugSearchService'
import { DiscontinueDrugFeedbackDialog } from './DiscontinueDrugFeedbackDialog'
import Typography from '@mui/material/Typography'

import LikeIcon from '../../images/like_icon.png'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import { GAService } from '../application/ga/GAService'
import { useLocation } from 'react-router'
import { CLASS_NAMES } from '../shared/constants'
import { RenderingLocation } from '../saving/saving-types'
import DrugInfoModal from '../system/DrugInfoModal'
import { ROUTE_NEW_SEARCH_OPPORTUNITY } from '../v2/NewMemberPortalMain'
import { LOG } from 'src/app/v2/applog'

//we need to know when hiding a drug is it original or alternative as the UI is different
export type ElipsisMenuDrugContext = 'original' | 'alternative'
export interface ElipsisActions {
  showDrugProfile?: boolean
  showMyPricing?: boolean
  showHideDrug?: boolean
  showUnhideDrug?: boolean
}

export interface ElipsisOps {
  drugIdParams: DrugIdParams
  drugContext: ElipsisMenuDrugContext
  actions?: ElipsisActions
  onActionCompleted?: (action: ElipsisMenuActionCompleted) => void
  //this is always true for new design
  // showDrugInfoAsModal: boolean
}

//utility function to prepare menu options object
//TODO - name is a bit misleading, it is also used in my pricing on the alternatives
//its more concerned if we have original drug id or mmaId (see below when we have mmaid)
export function buildMenuOpsWithDrugId(
  id: number,
  showPricing: boolean,
  pricingQuantityDefault: number,
): ElipsisOps {
  let ops: ElipsisOps = {
    drugContext: 'original',
    actions: {
      showDrugProfile: true,
      showMyPricing: showPricing,
      showHideDrug: true,
    },
    drugIdParams: {
      drugId: id,
      pricingQuantityDefault: pricingQuantityDefault,
      useLatestForLabelOnDrugProfile: true,
    },
  }

  return ops
}

//called from savings report alternative - there we only know the mma id
//in this case we also don't want to allow again pricing from the drug profile page
//(bc we are already showing this alternative as a cheaper option, don't go now again for cheaper options for it)
export function buildSavingsReportAlternativeMenuOpsByMmaId(
  mmaId: number,
  showPricing: boolean,
  pricingQuantityDefault: number,
): ElipsisOps {
  let ops: ElipsisOps = {
    drugContext: 'alternative',
    actions: {
      showDrugProfile: true,
      showMyPricing: showPricing,
      showHideDrug: true,
    },
    drugIdParams: {
      mmaId: mmaId,
      pricingQuantityDefault: pricingQuantityDefault,
      //when seeing drug profile page for alternative, do not drill down again to pricing
      disableMyPricingFromProfile: true,
    },
  }

  return ops
}
export function MyMedsElipsisMenu({
  id,
  mmaId,
  drugContext,
  showHideDrug,
  showUnhideDrug,
  showMyPricing,
  onActionCompleted,
  pricingQuantityDefault,
}: {
  id: number
  mmaId?: number
  drugContext: ElipsisMenuDrugContext
  showHideDrug: boolean
  showUnhideDrug: boolean
  showMyPricing: boolean
  onActionCompleted?: (action: ElipsisMenuActionCompleted) => void
  pricingQuantityDefault: number
}) {
  //
  let ops: ElipsisOps = {
    drugContext: drugContext,
    actions: {
      showDrugProfile: true,
      showMyPricing: showMyPricing,
      showHideDrug: showHideDrug,
      showUnhideDrug: showUnhideDrug,
    },
    onActionCompleted: onActionCompleted,
    drugIdParams: { drugId: id, mmaId: mmaId, pricingQuantityDefault },
  }

  return <DrugElipsisMenu ops={ops} />
}

export type ElipsisMenuActionCompleted = 'hide_drug' | 'unhide_drug'

//used when pushign thru various props from savings report down to druginfoview etc
export function DrugElipsisMenu({ ops }: { ops: ElipsisOps }) {
  const location = useLocation()
  const gaService = new GAService()
  const { history } = useMemberAppContext()
  const APPCONFIG = usePortalAppConfig()
  // const notifier = useNotifier()
  const { addErrorMsg, addSuccessMsg } = useToastContext()
  const memberService = useMemberService()
  const drugService = useDrugSearchService()
  const { t } = useSTranslate('application')
  const [open, setOpen] = useState<boolean>(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  //alwasy make sure my pricing(search) is hidden if that feature is disabled
  const { featureConfig } = useMemberAppContext()

  const [renderHideDrugDialog, setRenderHideDrugDialog] =
    useState<boolean>(false)

  //new dialog shown upon hiding/unhiding of a drug to tell the user effect will be next monthly savings report
  const [
    renderMonthlyEffectReminderDialog,
    setRenderMonthlyEffectReminderDialog,
  ] = useState(false)
  //can be hide or unhide, different message to show to user
  const [monthlyEffectMsg, setMonthlyEffectMsg] = useState<
    undefined | 'hidden' | 'unhidden'
  >(undefined)

  const [renderDrugInfoModalDialog, setRenderDrugInfoModalDialog] =
    useState(false)

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  function getAdditionalClassName() {
    if (ops?.actions?.showHideDrug === true) {
      return RenderingLocation.nonHiddenMed
    } else if (ops?.actions?.showUnhideDrug === true) {
      return RenderingLocation.hiddenMeds
    }
  }

  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          padding: 0,
          color: '#201F22',
          justifyContent: 'end',
          width: 'auto',
          minWidth: '25px',
        }}
      >
        <MoreHorizIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 2, width: '254px', borderRadius: '12px' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 1)',
                borderRadius: '12px',
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                  sx={{ padding: 0 }}
                  component="div"
                >
                  <MenuItem
                    className={`${
                      CLASS_NAMES.ellipsisViewDrugInfoItem
                    } ${getAdditionalClassName()}`}
                    onClick={async () => {
                      const resolvedDrugId =
                        await drugService.resolveDrugIdParams(ops.drugIdParams)
                      //lets do the mmaId to drugId conversion here (in case its needed) before sending to my pricing and
                      //and converting there. this is mostly because mypricing is already complicated enough...
                      if (resolvedDrugId) {
                        setOpen(false)
                        setRenderDrugInfoModalDialog(true)
                      } else {
                        addErrorMsg(
                          'Cannot navigate to drug profile at the moment -  unable to convert to a valid drug id',
                        )
                        LOG.error(
                          'generic_error',
                          'Cannot navigate to drug profile at the moment -  unable to convert to a valid drug id',
                        )
                      }
                    }}
                    sx={{
                      paddingLeft: '16px',
                      borderTopRightRadius: '12px',
                      borderTopLeftRadius: '12px',
                    }}
                  >
                    {t('drugInfo')}
                  </MenuItem>
                  <Divider sx={{ my: '0px !important' }} />
                  {/* //DISABLE MY PRICING IN THE NEW DESIGN FOR NOW */}
                  {ops.actions &&
                    ops.actions.showMyPricing &&
                    !featureConfig.isSearchDisabled && (
                      <MenuItem
                        className={`${
                          CLASS_NAMES.ellipsisGetPricingItem
                        } ${getAdditionalClassName()}`}
                        onClick={async () => {
                          //first get the proper drug id in case we are dealing with the mma (alternative)
                          const resolvedDrugId =
                            await drugService.resolveDrugIdParams(
                              ops.drugIdParams,
                            )
                          //lets do the mmaId to drugId conversion here (in case its needed) before sending to my pricing and
                          //and converting there. this is mostly because mypricing is already complicated enough...
                          if (resolvedDrugId) {
                            const convertedParams: DrugIdParams = {
                              drugId: resolvedDrugId,
                              pricingQuantityDefault:
                                ops.drugIdParams.pricingQuantityDefault,
                            }
                            history.push({
                              pathname: ROUTE_NEW_SEARCH_OPPORTUNITY,
                              state: { drugIdParams: convertedParams },
                            })
                          } else {
                            addErrorMsg(
                              'Cannot navigate to drug pricing at the moment - unable to convert to a valid drug id',
                            )
                          }
                        }}
                        sx={{ paddingLeft: '16px' }}
                      >
                        {t('pricing')}
                      </MenuItem>
                    )}
                  <Divider sx={{ my: '0px !important' }} />
                  {ops.actions && ops.actions.showHideDrug && (
                    <MenuItem
                      className={`${
                        CLASS_NAMES.ellipsisHideDrugItem
                      } ${getAdditionalClassName()}`}
                      onClick={() => {
                        setRenderHideDrugDialog(true)
                        setOpen(false)
                      }}
                      sx={{
                        paddingLeft: '16px',
                        borderBottomRightRadius: '12px',
                        borderBottomLeftRadius: '12px',
                      }}
                    >
                      {t('hidemed')}
                    </MenuItem>
                  )}
                  {ops.actions && ops.actions.showUnhideDrug && (
                    <MenuItem
                      className={`${
                        CLASS_NAMES.ellipsisUnhideDrugItem
                      } ${getAdditionalClassName()}`}
                      onClick={async () => {
                        try {
                          const unhideResp =
                            await memberService.removeHiddenDrug(
                              ops.drugIdParams,
                              ops.drugContext,
                              //  reason,
                            )
                          //success response for this can be undefind, if its error we will have it
                          const allowUndefineReponse = true

                          const validation = checkMicroServiceResponseStatus(
                            unhideResp,
                            allowUndefineReponse,
                          )

                          if (validation.isError) {
                            addErrorMsg(
                              'Unable to remove this hidden drug. ' +
                                validation.errorMsg,
                            )
                          } else {
                            //this is now handled via secondary dialog
                            // addSuccessMsg(
                            //   'Drug successfully removed from the hidden list.',
                            // )
                            setRenderMonthlyEffectReminderDialog(true)
                            setMonthlyEffectMsg('unhidden')
                          }
                        } catch (error) {
                          addErrorMsg(
                            'Unable to remove this hidden drug.  Please try again.',
                          )
                        }
                      }}
                      sx={{
                        paddingLeft: '16px',
                        borderBottomRightRadius: '12px',
                        borderBottomLeftRadius: '12px',
                      }}
                    >
                      {t('unhidemed')}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {renderHideDrugDialog && (
        <DiscontinueDrugFeedbackDialog
          //for now determine if hiding original or alternative based on input, will need something better
          drugContext={ops.drugContext}
          data="any"
          isOpen={true}
          handleClose={() => {
            setRenderHideDrugDialog(false)
          }}
          handleHideMed={async (reason: any) => {
            if (ops.drugContext === 'original') {
              gaService.trackEvent(
                location.pathname === ROUTE_MY_MEDS
                  ? {
                      ...gaService.eventMap.my_meds_hide_med,
                      value: reason,
                    }
                  : {
                      ...gaService.eventMap.savings_report_hide_drug_A,
                      value: reason,
                    },
              )
            } else {
              gaService.trackEvent({
                ...gaService.eventMap.savings_report_hide_drug_B,
                value: reason,
              })
            }
            try {
              // let variant: HiddenDrugUiType = drugIdParams.drugId
              //   ? 'original'
              //   : 'alternative'
              //for now only by drug id is supported on the original

              const hideResp = await memberService.addNewHiddenDrug(
                ops.drugIdParams,
                ops.drugContext,
                reason,
              )
              //success response for this can be undefind, if its error we will have it
              const allowUndefineReponse = true

              const validation = checkMicroServiceResponseStatus(
                hideResp,
                allowUndefineReponse,
              )

              if (validation.isError) {
                //check specific drug already hidden error code
                if (
                  hideResp &&
                  (hideResp as any).scriptaErrorCode &&
                  (hideResp as any).scriptaErrorCode === 701
                ) {
                  if (
                    //TODO - quick has to fix messages. this is bc invalid mmaId and already supprssed both return same errorCode 701
                    (hideResp as any).message &&
                    (hideResp as any).message.indexOf('already') > -1
                  ) {
                    addErrorMsg(
                      'This drug has already been hidden, please check back next month to see the updated savings report.',
                    )
                  } else {
                    addErrorMsg(
                      'Unable to hide this drug at the moment. Please contact our member support. ' +
                        APPCONFIG.MEMBER_SUPPORT_NUMBER +
                        ' or ' +
                        APPCONFIG.MEMBER_SUPPORT_EMAIL,
                    )
                  }
                } else {
                  addErrorMsg(
                    //do not show backend error message
                    'Unable to hide this drug at the moment. ',
                  )
                }
              } else {
                //this is now handled via secondary dialog
                // addSuccessMsg('Drug hidden successfully')
                setRenderMonthlyEffectReminderDialog(true)
                setMonthlyEffectMsg('hidden')

                setOpen(false)
                setRenderHideDrugDialog(false)
                // notifier.sendMsg('Drug hidden successfully', 'success')
              }
            } catch (error) {
              addErrorMsg('Unable to hide this drug at the moment.')
            }
          }}
        />
      )}
      {renderMonthlyEffectReminderDialog && (
        <Dialog
          open={renderMonthlyEffectReminderDialog}
          onClose={handleClose}
          maxWidth="xs"
          PaperProps={{
            sx: {
              borderRadius: 7,
            },
          }}
        >
          <DialogContent>
            <Grid container alignItems={'center'} justifyContent="center">
              <Grid
                item
                xs={12}
                container
                alignItems={'center'}
                justifyContent="center"
              >
                <Grid item>
                  <img src={LikeIcon} />
                </Grid>
              </Grid>

              {monthlyEffectMsg === 'hidden' && (
                <>
                  <Grid item>
                    <Typography sx={{ fontWeight: 700 }}>
                      {t('medicationHidden')}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: '10px' }}>
                    <Typography variant="caption">
                      {t('noLongerAppearInMyMeds')}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} style={{}}>
                    <Typography variant="caption">
                      {t('noLongerAppearFollowUp')}
                    </Typography>
                  </Grid>
                </>
              )}
              {monthlyEffectMsg === 'unhidden' && (
                <>
                  <Grid item>
                    <Typography sx={{ fontWeight: 700 }}>
                      {t('medicationAdded')}
                    </Typography>
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: '10px' }}>
                    <Typography variant="caption">
                      {t('unHiddenInMyMeds')}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                container
                alignItems={'center'}
                justifyContent="center"
                style={{ marginTop: '15px' }}
              >
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setRenderMonthlyEffectReminderDialog(false)
                    setOpen(false)
                    setRenderHideDrugDialog(false)

                    if (ops.onActionCompleted) {
                      if (monthlyEffectMsg === 'unhidden') {
                        ops.onActionCompleted('unhide_drug')
                      } else if (monthlyEffectMsg === 'hidden') {
                        ops.onActionCompleted('hide_drug')
                      }
                    }
                  }}
                >
                  Okay
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {renderDrugInfoModalDialog && (
        <DrugInfoModal
          drugIdParams={ops.drugIdParams}
          onClose={() => setRenderDrugInfoModalDialog(false)}
          open={renderDrugInfoModalDialog}
        />
      )}
    </div>
  )
}
