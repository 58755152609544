import { useEffect, useState } from 'react'
import { useMemberAppContext } from '../MemberAppContext'
import { usePortalThemeContext } from 'src/app/styles/PortalThemeContext'
import { useRegistration } from 'src/app/registration/RegistrationContext'
//should we rely on hooks? or just get it via vindow?
//probably both bc for ex. sso error case will not know jwtdetails
export function useAmazon() {
  const { jwtDetails, isAmazon, setIsAmazon } = useMemberAppContext()
  const { setSelectedThemeByName } = usePortalThemeContext()
  //processed in first page of registration
  const { matchedTenant } = useRegistration()
  //TODO - get this from  prod
  const AZ_CLIENT_ID = [
    '671c2967-8ccf-4c06-b391-c93f3ff453b1',
    //prod amzn client id = 251
    'f8024918-cdbe-4e17-98d4-d73d66307d40',
  ]

  useEffect(() => {
    if (
      matchedTenant?.clientUuid &&
      AZ_CLIENT_ID.includes(matchedTenant.clientUuid)
    ) {
      setIsAmazon(true)
      setSelectedThemeByName('amazon-blue')
    }
  }, [matchedTenant])

  useEffect(() => {
    if (
      jwtDetails &&
      jwtDetails.tenant_short_code_key &&
      (jwtDetails.tenant_short_code_key.indexOf('amazon') > -1 ||
        jwtDetails.tenant_short_code_key.indexOf('amzn') > -1)
    ) {
      setIsAmazon(true)
      setSelectedThemeByName('amazon-blue')
    }
    //temp users for testing
    if (
      jwtDetails &&
      jwtDetails.user_name_key &&
      (jwtDetails.user_name_key.indexOf('uat_az') > -1 ||
        jwtDetails.user_name_key.indexOf('uat_az1') > -1 ||
        jwtDetails.user_name_key.indexOf('uat_az2') > -1 ||
        jwtDetails.user_name_key.indexOf('uat_clinical_1') > -1 ||
        jwtDetails.user_name_key.indexOf('uat_az3') > -1)
    ) {
      setIsAmazon(true)
      setSelectedThemeByName('amazon-blue')
    }
  }, [jwtDetails])

  //can be set by saml login based on passed in short code
  useEffect(() => {
    if (isAmazon) {
      setSelectedThemeByName('amazon-blue')
    }
  }, [isAmazon])

  return { isAmazon, setIsAmazon }
}
