import {
  Box,
  Grid,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { ChangeEvent, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  Member,
  RegistrationData,
  isServerErrorResponse,
  isValidResponse,
} from '../../types'
import { IS_DEV, checkMicroServiceResponseStatus } from '../apiexec/utils'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import { useMemberAppContext } from '../MemberAppContext'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import { GAService } from '../application/ga/GAService'
import {
  CompanyOrMemberNotFoundHandler,
  GotItDialogForSupportRequest,
} from './FindTenantComponent'
import { RegistrationFormFieldsBox } from './RegistrationContainer'
import {
  PrevValuesCache,
  RegToPreregJumpState,
  useRegistration,
} from './RegistrationContext'
import {
  REPONSE_CODE_NO_MATCHING_MEMBER,
  SSOAlreadyRegisteredError,
} from './RegistrationErrorHandler'
import {
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_FIND_TENANT,
  REG_ROUTE_IDENTITY_VALIDATION,
  REG_ROUTE_LOGIN,
  REG_ROUTE_PREREGISTER,
  REG_ROUTE_SERVER_ERROR,
} from './RegistrationSubRoutes'
import { CustomTextField } from './components/CustomTextField'
import {
  NewTenantRadioGroup,
  NewTenantRadioGroupOptions,
} from './components/RadioGroups'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import {
  MemberNotFoundSupportRequest,
  useRegistrationService,
} from './useRegistrationService'
import { dateWithYearMonthDay } from './utils/Formaters'
import MemberNotFoundImage from '../../images/member_not_found_image.png'
import { toDigitsOnly } from '../member/phone-field-utils'
import { toServerDate } from '../helpmeswitch/types'
import { LOG } from '../v2/applog'
import { useAmazon } from 'src/app/hooks/useAmazon'

export type MatchType = RegistrationData['matchType']

export function NewTenantAccount() {
  const gaService = new GAService()
  const {
    setApiResponse,
    matchedTenant,
    history,
    setMember,
    setRegistrationData,
    setPrevValuesCache,
    prevValuesCache,
  } = useRegistration()
  //will be set in previous componet
  //it is needed in case we need to jump to preregistration for recently added plan members
  //when we cannot find them
  const { clientUuid } = useMemberAppContext()
  const service = useRegistrationService()
  const { featureConfig } = useMemberAppContext()

  const { isAmazon } = useAmazon()
  const [renderAmazonPrimaryMemberDialog, setRenderAmazonPrimaryMemberDialog] =
    useState(false)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [isRegisteredDisabled, setIsRegistrationDisabled] =
    useState<boolean>(false)
  const { t } = useSTranslate(['register', 'common'])
  if (!matchedTenant || !matchedTenant.id) {
    history.push(REG_ROUTE_LOGIN)

    // throw Error('Cannot find account without matched tenant..')
  }

  const firstNameCache = prevValuesCache?.firstName || ''
  const lastNameCache = prevValuesCache?.lastName || ''
  const matchTypeCache = prevValuesCache?.matchType || ''
  //1993-01-01
  const firstNameValue = IS_DEV() ? 'JAYSON' : firstNameCache
  const lastNameValue = IS_DEV() ? 'SCHULIST' : lastNameCache
  const validationNumberValue = IS_DEV() ? '6515' : ''
  const dateOfBirthCache = IS_DEV()
    ? '12/07/1988'
    : prevValuesCache?.dateOfBirth || ''
  const [firstName, setFirstName] = useState(firstNameValue)
  const [lastName, setLastName] = useState(lastNameValue)
  const [dateOfBirth, setDateOfBirth] = useState(dateOfBirthCache)
  const [validationNumber, setValidationNumber] = useState(
    validationNumberValue,
  )
  const [securityValidationType, setSecurityValidationType] =
    useState(matchTypeCache)
  const fieldsAreEmpty =
    !firstName || !lastName || !dateOfBirth || !validationNumber
  const isDOBIncorrect =
    !!dateOfBirth &&
    !/^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(
      dateOfBirth,
    )
  const shouldDisableNextButton =
    fieldsAreEmpty || !securityValidationType || isDOBIncorrect

  //first prompt the user if recent add to the plan, in which case if yes they will be sent to prereg
  const [renderAskIfRecentPlanMember, setRenderAskIfRecentPlanMember] =
    useState(false)
  //if they say NO in that dialog, we will let them submit optional member support request
  const [
    renderMemberNotFoundSupportRequest,
    setRenderMemberNotFoundSupportRequest,
  ] = useState(false)

  //once they provide their feedback in case of follow up support request, we will show them this dialog
  const [renderOkGotIt, setRenderOkGotIt] = useState(false)

  //fix the issue where if only memberid is available for type of match, it should default to it
  useEffect(() => {
    if (
      matchedTenant?.memberIdAvailable &&
      !matchedTenant?.ssnAvailable &&
      !matchedTenant?.companyIdAvailable
    ) {
      setSecurityValidationType(NewTenantRadioGroupOptions.MEMBER_ID)
    }
  }, [])

  //new alert - if all 3 matche types are disabled, we cannot register, something is wrong in client config
  useEffect(() => {
    //wait for feature config to be initialized - so we don't
    if (featureConfig && featureConfig.isInitialized) {
      let isRegFeatureDisabled = featureConfig.isRegistrationDisabled

      if (isRegFeatureDisabled) {
        setIsRegistrationDisabled(true)
        LOG.error(
          'registration',
          'Member is trying to register - but registration is disabled via feature flag for client =',
          matchedTenant,
        )
      }
      if (matchedTenant && matchedTenant.id) {
        const isAllDisabled =
          !matchedTenant.memberIdAvailable &&
          !matchedTenant.ssnAvailable &&
          !matchedTenant.companyIdAvailable
        if (isAllDisabled && !isRegFeatureDisabled) {
          LOG.error(
            'registration',
            'All member identification types are disabled (BUT registration feature is enabled) - members will not be able to register',
            matchedTenant,
          )
          setIsRegistrationDisabled(true)
        }
      }
    }
  }, [matchedTenant, featureConfig, isAmazon])

  if (!matchedTenant)
    throw Error('Invalid state - cannot register without matched tenant')

  const matchVerificationType = () => {
    let result: any = { matchType: securityValidationType }
    switch (securityValidationType) {
      case 'SSN':
        result.last4SSN = validationNumber
        break
      case 'MID':
        result.memberId = validationNumber
        break
      case 'EID':
        result.employeeId = validationNumber
        break
    }

    return result
  }
  const doFindAccount = async () => {
    const memberDOB = dateWithYearMonthDay(dateOfBirth)
    const registrationData: RegistrationData = {
      tenantId: matchedTenant?.id?.toString() || '',
      memberFirstName: firstName,
      memberLastName: lastName,
      memberDOB,
      ...matchVerificationType(),
    }

    await asyncFindAccount(registrationData, isAmazon)

    gaService.trackEvent({
      ...gaService.eventMap.step_2_reg_next,
      value: securityValidationType,
    })
  }

  async function asyncFindAccount(
    registrationData: RegistrationData,
    isAmazon: boolean,
  ) {
    try {
      const resp = await service.findAccountApi(registrationData)

      if (isServerErrorResponse<Member>(resp)) {
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<Member>(resp)) {
        setApiResponse(resp)
        setMember(resp)
        //now we know how it is, update the alert logger
        LOG.initMember(resp.id, registrationData.tenantId as any)

        //for amazon primary members, need to instruct them to go to amazon a to z.
        //there are two ways we get this - if they already accessed once before via so,
        //we get a correct response from backend with code RESPONSE_CODE_SSO_USER_ALREADY_REGISTERED
        //so that would already be handled above via the registration error handler

        //but if they never accessed before, we dont get that, so instead we will check here manually for now
        if (isAmazon && resp.memberRelationshipId === 'primary') {
          LOG.error(
            'registration',
            'amazon primary member trying to register - will be instructed to use A to Z',
          )
          setRenderAmazonPrimaryMemberDialog(true)
          return
        } else {
          setRegistrationData({ ...registrationData, memberId: resp.id })
          history.push(REG_ROUTE_IDENTITY_VALIDATION)
        }
      } else {
        setApiResponse(resp)

        //new custom handler for member not found
        if (
          resp &&
          resp.code &&
          resp.code === REPONSE_CODE_NO_MATCHING_MEMBER
        ) {
          setRenderAskIfRecentPlanMember(true)
        } else {
          LOG.error('registration', 'error in find account', resp)
          history.push(REG_ROUTE_ERROR_HANDLER)
        }
      }
    } catch (e) {
      LOG.error('registration', 'error in find account api execution', e)
    }
  }

  const formatDateString = (value: string) => {
    const date = value.replace(/\D/g, '') || ''
    const length = date.length
    if (length < 3) return date
    if (length < 5) return `${date.slice(0, 2)}/${date.slice(2)}`
    return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4)}`
  }

  const onChangeFirstNameInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFirstName(event.target.value)
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      firstName: event.target.value,
    }
    setPrevValuesCache(updatedCache)
  }

  const onChangeLastNameInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setLastName(event.target.value)
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      lastName: event.target.value,
    }
    setPrevValuesCache(updatedCache)
  }

  const onChangeDateOfBirthNameInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const date = formatDateString(event.target.value)
    setDateOfBirth(date)
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      dateOfBirth: date,
    }
    setPrevValuesCache(updatedCache)
  }

  const onChangeValidationNumberInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setValidationNumber(event.target.value)
  }

  const onChangeMatchType = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const matchType = (event.target as HTMLInputElement)
      .value as NewTenantRadioGroupOptions
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      matchType,
    }
    setSecurityValidationType(matchType)
  }

  if (renderAmazonPrimaryMemberDialog) {
    return <SSOAlreadyRegisteredError />
  }

  if (isRegisteredDisabled) {
    console.log('------isRegisteredDisabled', isRegisteredDisabled)
    return (
      <RegistrationStepCard
        registrationType="registration"
        progressBarStep={2}
        // title={title}
        // message=""
        // message={t('registrationIsDisabledAtTheMoment')}
        cancelButtonTitle={t('common:back')}
        onClickNextButton={doFindAccount}
        onClickCancelButton={() => history.push(REG_ROUTE_FIND_TENANT)}
        nextButtonDisabled={true}
      >
        <Box sx={{ width: '100%', paddingTop: '30px' }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>{t('registrationIsDisabledAtTheMoment')}</Typography>
            </Grid>
          </Grid>
        </Box>
      </RegistrationStepCard>
    )
  }

  if (renderAskIfRecentPlanMember) {
    return (
      <NewPortalDialogDesign
        upperRightX={true}
        open={true}
        headerImageImport={MemberNotFoundImage}
        customPaperSx={{
          width: isSmall ? '358px' : undefined,
          // padding: isSmall ? '0px' : undefined,
        }}
        dualActionProps={{
          yesButtonSx: { width: '80px', minWidth: '80px', padding: '10px' },
          onYes: () => {
            //go to prereg
            //prepare custom state for prereg
            const customState: RegToPreregJumpState = {
              clientUuid: clientUuid,
              firstName: firstName,
              lastName: lastName,
              dob: dateOfBirth,
            }

            const targetRoute = `${REG_ROUTE_PREREGISTER}`
            history.push(targetRoute, customState)
          },
          onNo: () => {
            //go to support request
            setRenderAskIfRecentPlanMember(false)
            setRenderMemberNotFoundSupportRequest(true)
          },
        }}
        //in case they click close x button, we want to go back to normal flow
        onClose={() => {
          setRenderAskIfRecentPlanMember(false)
          setRenderMemberNotFoundSupportRequest(false)
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ padding: '0px 15px 0px 15px' }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1.5rem',
                fontWeight: 700,
                paddingLeft: isSmall ? '12px' : '15px',
                paddingRight: isSmall ? '12px' : '15px',
              }}
            >
              {t('oops')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: isSmall ? '10px' : '20px',
              padding: isSmall ? '5px' : '0px 30px 0px 30px',
            }}
          >
            <Typography sx={{ textAlign: 'center', fontSize: '1.15rem' }}>
              {t('recentAdd')}
            </Typography>
          </Grid>
        </Grid>
      </NewPortalDialogDesign>
    )
  }

  function getBackendIdentificationType(uiIdType?: MatchType | string) {
    switch (uiIdType) {
      case 'MID':
        return 'MEMBER_ID'
      case 'EID':
        return 'EMPLOYEE_ID'
      case 'SSN':
        return 'SOCIAL_SECURITY_NUMBER'
      default:
        return 'MEMBER_ID'
    }
  }

  if (renderMemberNotFoundSupportRequest) {
    return (
      <CompanyOrMemberNotFoundHandler
        variant="member"
        onClickBack={() => {
          setRenderMemberNotFoundSupportRequest(false)
        }}
        onClickSubmitFeedback={async (email, phone) => {
          const supportRequest: MemberNotFoundSupportRequest = {
            'support-type': 'Member not found',
            'member-data': {
              'client-name': matchedTenant.name,
              'client-id': matchedTenant.id.toString(),
              'user-email': email,
              'user-phone': phone ? toDigitsOnly(phone) : '',
              'identification-type': getBackendIdentificationType(
                securityValidationType,
              ),
              // | 'SOCIAL_SECURITY_NUMBER'
              'first-name': firstName,
              'last-name': lastName,
              dob: toServerDate(dateOfBirth),
              'identification-value': validationNumber,
            },
          }
          const resp = await service.submitCompanyNotFoundSupportRequest(
            supportRequest,
          )
          const respStatus = checkMicroServiceResponseStatus(resp)
          if (!respStatus.isError) {
            setRenderMemberNotFoundSupportRequest(false)
            setRenderOkGotIt(true)
          } else {
            LOG.error('registration', 'error in submit support request', resp)
            setRenderMemberNotFoundSupportRequest(false)
          }
        }}
      />
    )
  }

  return (
    <>
      <RegistrationStepCard
        registrationType="registration"
        progressBarStep={2}
        // step={t('step', { current: 2, total: 7 })}
        // title={title}
        // message={t('scriptaRegistrationDescription')}
        cancelButtonTitle={t('common:back')}
        onClickNextButton={doFindAccount}
        onClickCancelButton={() => history.push(REG_ROUTE_FIND_TENANT)}
        nextButtonDisabled={shouldDisableNextButton}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: isSmall ? '1.125rem' : '1.5rem',
                fontWeight: 700,
                paddingBottom: '20px',
                paddingTop: '0px',
                // marginTop: isSmall ? '-15px' : '0px',
                lineHeight: '21.92px',
              }}
            >
              {t('genericHiMember')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingBottom: '20px',
              textAlign: 'center',
              paddingLeft: isSmall ? '20px' : '10px',
              paddingRight: isSmall ? '20px' : '10px',
              lineHeight: '20px',
            }}
          >
            <Typography sx={{ lineHeight: '20px' }}>
              {t('scriptaRegistrationDescription')}
            </Typography>
          </Grid>
        </Grid>
        <RegistrationFormFieldsBox>
          <CustomTextField
            data-testid="first-name-input"
            value={firstName}
            onChange={onChangeFirstNameInput}
            label={t('register:legalFirstName')}
            placeholder={t('enterYourFirstName')}
          />
          <CustomTextField
            data-testid="last-name-input"
            containerSx={{ marginTop: '20px' }}
            value={lastName}
            onChange={onChangeLastNameInput}
            label={t('register:legalLastName')}
            placeholder={t('enterYourLastName')}
          />

          <CustomTextField
            data-testid="dob-input"
            containerSx={{ marginTop: '20px' }}
            value={dateOfBirth}
            onChange={onChangeDateOfBirthNameInput}
            label={t('dob')}
            placeholder={t('MM/DD/YYYY')}
            inputProps={{ maxLength: 10 }}
            startAdornment={
              <InputAdornment position="start">
                <CalendarTodayOutlinedIcon sx={{ color: '#96939B' }} />
              </InputAdornment>
            }
            error={isDOBIncorrect}
          />
        </RegistrationFormFieldsBox>
        <Grid
          container
          sx={{
            paddingTop: '25px',
            paddingBottom: '20px',
            textAlign: 'left',
            // marginLeft: '20px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: isSmall ? '20px' : undefined,
              paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            <Typography
              // my={'27px'}
              sx={(theme) => ({
                textAlign: isSmall ? 'left' : 'center',
                // paddingTop: '10px',
                fontSize: 18,
                fontWeight: '400',
                lineHeight: '21.92px',
              })}
            >
              {t('provideOneOfTheFollowing')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: isSmall ? '10px' : '20px',
            paddingRight: isSmall ? '10px' : undefined,
          }}
        >
          <NewTenantRadioGroup
            isSmall={isSmall}
            matchedTenant={matchedTenant}
            value={securityValidationType}
            onChange={onChangeMatchType}
          />
        </Grid>
        <RegistrationFormFieldsBox>
          <CustomTextField
            data-testid="identification-value-input"
            value={validationNumber}
            onChange={onChangeValidationNumberInput}
            placeholder={t('enterThatNumber')}
            disabled={!securityValidationType}
          />
        </RegistrationFormFieldsBox>
        {renderOkGotIt && (
          <GotItDialogForSupportRequest
            onOk={() => {
              setRenderOkGotIt(false)
            }}
          />
        )}
      </RegistrationStepCard>
      {/* //TODO - review this footer spacer was added when new landing page was added */}
      {/* <Box
        sx={(theme) => ({
          minHeight: '30px',
        })}
      ></Box> */}
    </>
  )
}
