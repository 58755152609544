import { Box, DialogContent, Grid, Typography } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import IntroScreenImage from 'src/images/intro_screen.svg'
import { BottomActions } from './Wizard'

export function IntroScreen({
  isSmallDisplay,
  onContinue,
  onCancel,
}: {
  isSmallDisplay: boolean
  onContinue: () => void
  onCancel: () => void
}) {
  const { t } = useSTranslate('contactMyPrescriber')
  return (
    <>
      <DialogContent>
        <Grid item container xs={12} spacing={isSmallDisplay ? 2 : 2}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography
              display="inline"
              variant="body2"
              sx={{ fontWeight: 700, fontSize: '1.4rem' }}
            >
              {t('introHeader')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <img
              src={IntroScreenImage}
              style={{
                width: '100%',
                height: isSmallDisplay ? '190px' : '240px',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              display="inline"
              variant="body2"
              sx={{ fontWeight: 600, fontSize: '1.2rem' }}
            >
              {t('introTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              display="inline"
              variant="body2"
              sx={{ fontWeight: 700 }}
            >
              {t('introLabel1')}
            </Typography>
            <Typography display="inline" variant="body2">
              {t('introDesc1')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              display="inline"
              variant="body2"
              sx={{ fontWeight: 700 }}
            >
              {t('introLabel2')}
            </Typography>
            <Typography display="inline" variant="body2">
              {t('introDesc2')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              display="inline"
              variant="body2"
              sx={{ fontWeight: 700 }}
            >
              {t('introLabel3')}
            </Typography>
            <Typography display="inline" variant="body2">
              {t('introDesc3')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              display="inline"
              variant="body2"
              sx={{ fontWeight: 700 }}
            >
              {t('introLabel4')}
            </Typography>
            <Typography display="inline" variant="body2">
              {t('introDesc4')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                display="inline"
                sx={{
                  textAlign: 'center',
                  fontSize: '0.9rem',
                  fontWeight: 700,
                  fontStyle: 'italic',
                }}
              >
                {t('introReadyToStart')}
              </Typography>
            </Box>
          </Grid>
          {/* <Grid item xs={12} sx={{ marginTop: '10px' }}></Grid> */}
        </Grid>
      </DialogContent>

      <BottomActions
        isSmallDisplay={isSmallDisplay}
        onContinue={onContinue}
        onCancel={() => {
          onCancel()
        }}
        useCancelLabelForBack={true}
      />
    </>
  )
}
