import { CircularProgress, Link, Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { IS_NATIVE } from 'src/app/apiexec/utils'
import { useMemberService } from 'src/app/member/useMemberService'
import { useMemberAppContext } from 'src/app/MemberAppContext'
import { REG_ROUTE_SERVER_ERROR } from 'src/app/registration/RegistrationSubRoutes'
import { NativeMessage, nativeApi_sendMessage } from '../../apiexec/utils'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { getDateString } from '../utils'
import { RenderingLocation } from 'src/app/saving/saving-types'
import clsx from 'clsx'
import { CLASS_NAMES } from 'src/app/shared/constants'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { useSColors } from 'src/app/styles/scripta-theme'
import SButton from 'src/app/system/customcomponents/SButton'

export function callNativeDownloadCoupon(jwt: string) {
  if (IS_NATIVE()) {
    const msg: NativeMessage = {
      actionName: 'download_coupon',
      actionJwt: jwt,
      actionExtra: undefined,
    }

    nativeApi_sendMessage(msg)

    return
  }
}

export default function SaveCouponButton({
  renderLocation,
  variant = 'button',
  customFontColor,
}: {
  renderLocation?: RenderingLocation
  variant?: 'link' | 'button'
  customFontColor?: string
}) {
  const { COLORS } = useSColors()

  const { t } = useSTranslate('coupons')
  const memberService = useMemberService()
  const { savingReport, currentMemberProfile, history, jwt } =
    useMemberAppContext()
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [downloadInProgress, setDownloadInProgress] = useState(false)
  const [localErrorMsg, setLocalErrorMsg] = useState<string | undefined>()
  const downloadFileName = `pharmacy_savings_card_${getDateString()}.pdf`

  const handleDownload = async (e: any) => {
    if (!jwt) {
      console.warn('WARNING -cannot download coupon, no valid jwt found..')
      return
    }

    if (IS_NATIVE()) {
      callNativeDownloadCoupon(jwt)
      e.preventDefault()
      setBtnDisabled(true)

      // the purpose of this timeout is to disable user clicking download multiple times in mobile app
      // we don't really know exactly how long the download will take, so 5 seconds is just a guess
      // by that time they should get android/ios download manager notification
      // that at least that the download has started
      setTimeout(() => setBtnDisabled(false), 5000)
      return
    } else {
      //clean up previous
      setLocalErrorMsg(undefined)
      setDownloadInProgress(true)
      setBtnDisabled(true)
      const resp = await memberService.getMemberCouponCardPDFApi()
      // try {
      // const statusCode = JSON.parse(resp)
      if (resp && resp.code == 30) {
        setLocalErrorMsg('Error downloading coupon card pdf')
        setDownloadInProgress(false)
        history.push(REG_ROUTE_SERVER_ERROR)
        // throw new Error('Error downloading report')
        //   }
      }

      try {
        const blob = await (resp as any).blob()
        var data = new Blob([blob], {
          type: 'application/octet-stream',
        })
        //IE11 & Edge
        if ((navigator as any).msSaveBlob) {
          ;(navigator as any).msSaveBlob(data, 'doc.fileName')
        } else {
          //In FF link must be added to DOM to be clicked
          var link = document.createElement('a')
          link.href = window.URL.createObjectURL(data)
          link.setAttribute('download', downloadFileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      } catch (error) {
        setLocalErrorMsg('Error download report')

        history.push(REG_ROUTE_SERVER_ERROR)
      }
      setDownloadInProgress(false)
      setBtnDisabled(false)
    }
  }

  if (variant === 'link') {
    return (
      <Grid
        container
        gap={SPACING._05}
        justifyContent={'center'}
        sx={{ marginTop: SPACING._1 }}
      >
        <Grid item alignSelf={'center'}>
          {btnDisabled ? (
            <CircularProgress size={16} color="secondary" />
          ) : null}
        </Grid>
        <Grid item>
          <Link
            disabled={btnDisabled}
            component={'button'}
            sx={{
              textDecorationColor: customFontColor ?? COLORS.TEXT_SECONDARY,
              textDecorationThickness: 0.5,
              color: customFontColor ?? COLORS.TEXT_SECONDARY,
            }}
            onClick={handleDownload}
          >
            <STypography variant="body_semibold">
              {t('downloadCoupon')}
            </STypography>
          </Link>
        </Grid>
        <Grid item>
          <FontAwesomeIcon
            icon={faDownload}
            color={customFontColor ?? COLORS.TEXT_SECONDARY}
            height={'16px'}
            fontSize={'16px'}
            style={{ padding: '4px' }}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <SButton
      className={clsx(renderLocation, CLASS_NAMES.saveCouponButton)}
      onClick={handleDownload}
      disabled={btnDisabled}
      startIcon={
        btnDisabled ? <CircularProgress size={24} color="secondary" /> : null
      }
    >
      {t('downloadCoupon')}
    </SButton>
  )
}
