import { Theme, useTheme } from '@mui/material'
import { getScriptaTheme } from '../styles/theme-config-scripta'
import { CustomColors } from '../styles/theme-extender'
import { ReactSVG } from 'react-svg'

interface ThemedSVGRemoteProps {
  importUrl: string
  //needed as number in pixed for proper sizing of svg
  width: number
  height: number
  alt: string
  onClick?: () => void
  styleButNoWidthOrHeight?: React.CSSProperties
  disableTheming?: boolean
  imgDataTestId?: string
}

function getColorMap(currentTheme: Theme) {
  //add a set of unique colors and debug to console that start with #
  const uniqueColors = new Set()
  const scriptaTheme = getScriptaTheme()
  const colorThemeKeys = Object.keys(scriptaTheme.customized.colors).filter(
    (k) => k && k.indexOf('gradient') === -1,
  ) as Array<keyof CustomColors>

  const colorMap: Record<string, string> = {}

  colorThemeKeys.forEach((key) => {
    const currentThemeColor = currentTheme.customized.colors[key]
    const scriptaThemeColor = scriptaTheme.customized.colors[key]

    if (currentThemeColor && currentThemeColor.startsWith('#')) {
      uniqueColors.add(currentThemeColor)
    }

    if (currentThemeColor !== scriptaThemeColor) {
      colorMap[scriptaThemeColor] = currentThemeColor
    }
  })

  return colorMap
}
export function ThemedSVGRemote({
  importUrl,
  width,
  height,
  alt: altText,
  onClick,
  styleButNoWidthOrHeight,
  disableTheming,
  imgDataTestId,
}: //   style,
ThemedSVGRemoteProps) {
  const theme = useTheme()

  const colorMap = getColorMap(theme)

  return (
    <ReactSVG
      data-testid={imgDataTestId}
      beforeInjection={(svgElement) => {
        svgElement.setAttribute('style', `width: ${width}`)
        svgElement.setAttribute('style', `height: ${height}`)
        //set width and height to the svg element
        svgElement.setAttribute('width', width.toString())
        svgElement.setAttribute('height', height.toString())

        if (!disableTheming) {
          // Replace colors based on the colorMap
          // Replace colors based on the colorMap
          if (theme && theme.customized.name !== 'scripta') {
            const elements = svgElement.querySelectorAll('*')

            elements.forEach((el) => {
              if (el instanceof SVGElement) {
                const currentFill = el.getAttribute('fill')
                if (currentFill && colorMap[currentFill]) {
                  el.setAttribute('fill', colorMap[currentFill])
                }
              }
            })
          }
        }
      }}
      style={styleButNoWidthOrHeight}
      //these do not work to override the width in svg itsel
      // width={width}
      // height={height}
      src={importUrl}
      onClick={onClick}
      wrapper="div"
      aria-label={altText}
    />
  )
}
