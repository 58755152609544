import { useState } from 'react'
import {
  FormControl,
  useTheme,
  useMediaQuery,
  DialogActions,
  Dialog,
  TextField,
  Box,
  Button,
  Grid,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'

import { useMemberAppContext } from '../MemberAppContext'
import { useNotifier } from '../hooks/useNotify'
import { useMemberService } from '../member/useMemberService'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { CLASS_NAMES } from '../shared/constants'
import SButton from '../system/customcomponents/SButton'
import { useSColors } from '../styles/scripta-theme'
import { LOG } from '../v2/applog'
import { CircleWrappedFontIcon } from '../system/CircleWrappedFontIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function UserFeedback() {
  const { COLORS } = useSColors()
  const [openThanksModal, setOpenThanksModal] = useState(false)
  const [ratingValue, setRaingValue] = useState(0)
  const [additionalText, setAdditionalText] = useState('')
  const { t } = useSTranslate('userFeedback')
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileMode: boolean = isSmall
  const { showFeedbackModal, setShowFeedbackModal } = useMemberAppContext()
  const service = useMemberService()
  const notifier = useNotifier()

  const handleClose = () => {
    setShowFeedbackModal(false)
  }

  const saveBtnValue = (val: number) => {
    setRaingValue(val)
  }

  const handleSubmit = async () => {
    try {
      const resp = await service.sendFeedback(ratingValue, additionalText)
      const allowUndefinedResp = true
      const validation = checkMicroServiceResponseStatus(
        resp,
        allowUndefinedResp,
      )
      if (validation.isError) {
        notifier.sendMsg(
          'Sorry, there was a problem sending your feedback. Please try again or contact our member support',
          'error',
        )
        LOG.error('generic_error', 'Error sending feedback', resp)
      } else {
        setOpenThanksModal(true)
      }
    } catch (error) {
      notifier.sendMsg(
        'Sorry, there was a problem sending your feedback. Please try again or contact our member support',
        'error',
      )
      LOG.error('generic_error', 'Error sending feedback', error)
    }
  }

  const handleCloseThankYouModal = () => {
    setOpenThanksModal(false)
    handleClose()
  }

  const styles = {
    button: {
      maxWidth: isMobileMode ? '24px' : '40px',
      maxHeight: isMobileMode ? '28px' : '40px',
      minWidth: isMobileMode ? '24px' : '40px',
      minHeight: isMobileMode ? '28px' : '40px',
      padding: '0px',
      fontSize: 14,
    },

    clicked: {
      backgroundColor: COLORS.PRIMARY_BRAND,
      border: `1px solid ${COLORS.PRIMARY_BRAND}`,
      color: 'white',
    },

    unclicked: {
      color: COLORS.TEXT_GREY_MEDIUM,
      border: `1px solid ${COLORS.BORDER_GREY_LIGHT}`,
    },
  }

  const ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <div>
      <Dialog
        //dont show this dialog when thank you is open
        open={showFeedbackModal && !openThanksModal}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: 7,
          },
        }}
      >
        <Box
          sx={{
            paddingLeft: isMobileMode ? '20px' : '40px',
            paddingRight: isMobileMode ? '18px' : '36px',
          }}
        >
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <Box display="flex" justifyContent="center" mb={2}>
              <CircleWrappedFontIcon
                icon={<FontAwesomeIcon icon={faThumbsUp} />}
              />
            </Box>
            {/* <img src={LikeIcon} alt="Logo" /> */}
            <p style={{ marginBottom: '2px' }}>
              <Typography style={{ fontSize: 20, fontWeight: 600 }}>
                {' '}
                {t('question')}{' '}
              </Typography>
            </p>
            <p style={{ marginTop: '0px' }}>
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: '#66636C',
                  paddingLeft: isMobileMode ? '30px' : '0px',
                  paddingRight: isMobileMode ? '30px' : '0px',
                }}
              >
                {' '}
                {t('title')}{' '}
              </Typography>
            </p>
          </div>
          <Box sx={{ '& button': { m: 0.5 } }}>
            <Grid container xs={12} justifyContent="space-between">
              {ratings.map((rating, idx) => (
                <Grid key={rating} item xs={1}>
                  <Button
                    key={idx}
                    style={{
                      ...styles.button,
                      ...(ratingValue === rating
                        ? styles.clicked
                        : styles.unclicked),
                    }}
                    variant="outlined"
                    onClick={() => saveBtnValue(rating)}
                  >
                    {rating}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '4px',
                marginRight: '4px',
              }}
            >
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {' '}
                {t('hate')}{' '}
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {' '}
                {t('love')}{' '}
              </Typography>
            </div>
          </Box>
          <Box sx={{ marginLeft: '4px', marginRight: '4px' }}>
            <p style={{ marginBottom: '8px' }}>
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                {t('feedback')}{' '}
              </Typography>
            </p>
            <TextField
              style={{ width: '100%' }}
              id="outlined-textarea"
              placeholder={t('feedbackPlaceholder')}
              maxRows={4}
              inputProps={{
                style: {
                  height: '60px',
                },
              }}
              value={additionalText}
              multiline
              onChange={(e) => {
                if (e.target.value.length < 200) {
                  setAdditionalText(e.target.value)
                }
              }}
            />
            {additionalText && additionalText.length === 199 && (
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                {t('maxCharsLimit')}
              </Typography>
            )}
          </Box>
          <div
            style={{
              marginBottom: 30,
              marginTop: 10,
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <SButton
              className={CLASS_NAMES.feedbackPopupCancel}
              onClick={handleClose}
              variant="outlined"
              noCaps
            >
              {t('cancelFeedback')}
            </SButton>
            <SButton
              className={CLASS_NAMES.feedbackPopupSubmit}
              disabled={ratingValue === 0}
              onClick={handleSubmit}
              noCaps
              size="small"
              sx={{ marginLeft: '10px', width: '104px' }}
              // style={{ height: '35px', width: '104px', textTransform: 'none' }}
              variant="contained"
            >
              {t('sendFeedback')}
            </SButton>
          </div>
        </Box>
      </Dialog>
      <Dialog
        open={openThanksModal}
        onClose={handleCloseThankYouModal}
        PaperProps={{
          sx: {
            borderRadius: 7,
            width: '100%',
          },
        }}
      >
        <FormControl
          sx={{ mt: 2 }}
          style={{
            textAlign: 'center',
            width: '80%',
            marginRight: 'auto',
            marginLeft: 'auto',
            marginTop: isMobileMode ? '30px' : '40px',
          }}
        >
          <Box sx={{ '& button': { m: 1 } }}>
            <div className="text-center" style={{ textAlign: 'center' }}>
              <Box display="flex" justifyContent="center" mb={2}>
                <CircleWrappedFontIcon
                  icon={<FontAwesomeIcon icon={faThumbsUp} />}
                />
              </Box>
              <h3 style={{ marginBottom: '3px' }}>{t('thanks')} </h3>
              <p
                style={{
                  marginTop: '0px',
                  marginBottom: isMobileMode ? '3px' : '16px',
                }}
              >
                {t('comment')}{' '}
              </p>
            </div>
            <DialogActions
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                marginBottom: isMobileMode ? '10px' : '30px',
              }}
            >
              <SButton
                onClick={handleCloseThankYouModal}
                style={{
                  height: '35px',
                  width: '104px',
                  textTransform: 'none',
                }}
              >
                {t('common:close')}
              </SButton>
            </DialogActions>
          </Box>
        </FormControl>
      </Dialog>
    </div>
  )
}
