import { Grid, GridProps } from '@mui/material'
import { SPACING } from './theme2'

const CARD_BORDER_WIDTH = '1px'
const CARD_BORDER_RADIUS = '8px'
const CARD_BORDER_COLOR = '#D0CFD3'
const CARD_BACKGROUND_COLOR = '#FFFFFF'

const CARD_SIDE_PADDING = '16px'
const CARD_VERTICAL_PADDING = '12px'

interface CardContainerProps extends GridProps {
  onClick?: () => void
  children: React.ReactNode
  sx?: any
}

export default function CardContainer({
  onClick,
  children,
  sx,
  ...gridProps
}: CardContainerProps) {
  const defaultStyles = {
    padding: `${CARD_VERTICAL_PADDING} ${CARD_SIDE_PADDING}`,
    backgroundColor: CARD_BACKGROUND_COLOR,
    borderRadius: CARD_BORDER_RADIUS,
    border: `${CARD_BORDER_WIDTH} ${CARD_BORDER_COLOR} solid`,
    gap: SPACING._05,
    cursor: onClick ? 'pointer' : 'default',
    // ...(onClick && {
    //   '&:hover': {
    //     boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', 
    //   },
    // }),
  }

  return (
    <Grid
      container
      direction="column"
      sx={{ ...defaultStyles, ...sx }}
      onClick={onClick}
      {...gridProps}
    >
      {children}
    </Grid>
  )
}
