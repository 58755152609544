import {
  CircularProgress,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router'
import { isProvider, useMemberAppContext } from '../MemberAppContext'
import { ROUTE_SAML_LOGIN, ROUTE_SAML_LOGIN_ERROR } from '../PortalRoutes'
import React, { Suspense, lazy } from 'react'

import {
  IS_NATIVE,
  NativeMessage,
  getUrlParameter,
  isCompleteSsoRegistration,
  isPreregistration,
  isQuickLinkRegistration,
  isSamlSsoLogin,
  isSamlSsoLoginError,
  nativeApi_sendMessage,
} from '../apiexec/utils'
import { URL_PARAM_QUICKLINK_PROCESS_LATER } from '../app-root-types'
import {
  REG_ROUTE_FIND_TENANT_MEMBER_FROM_QR,
  REG_ROUTE_LOGIN,
  REG_ROUTE_PREREGISTER,
  REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE,
  REG_ROUTE_SERVER_ERROR,
} from '../registration/RegistrationSubRoutes'

// import { NewMemberPortalMain } from '../v2/NewMemberPortalMain'
import { URL_PARAM_MOBILE_APP_VERSION } from './ForceUpgradeDialog'
import STypography from 'src/app/system/customcomponents/STypography'
import { ModalAmazonPharmacy90DaysSupply } from 'src/app/system/modals/Modals'
import { useAmazon } from 'src/app/hooks/useAmazon'
import { capitalizeFirstName } from 'src/app/v2/NewMemberPortalMain'
// import { ProviderContainer } from '../provider/ProviderMain'

const NewMemberPortalMain = lazy(() =>
  import('../v2/NewMemberPortalMain').then((module) => ({
    default: module.NewMemberPortalMain,
  })),
)

const ProviderContainer = lazy(() =>
  import('../provider/ProviderMain').then((module) => ({
    default: module.ProviderContainer,
  })),
)

export function PortalMain() {
  const {
    jwt,
    currentMemberProfile,
    jwtDetails,
    mainDataLoading,
    isReportOpportunitiesPageFirstLoad,
  } = useMemberAppContext()
  const { isAmazon } = useAmazon()
  const [
    isAmazonPharmacy90DaysSupplyModalOpen,
    setIsAmazonPharmacy90DaysSupplyModalOpen,
  ] = useState(false)

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true)
  const [alreadyShowedLoadingPage, setAlreadyShowedLoadingPage] =
    useState<boolean>(false)

  const theme = useTheme()
  //to work for spanish, use location path to determine when to show the greeting
  //for root and /savingsreport
  const location = useLocation()

  const isErrorRoute = location.pathname === REG_ROUTE_SERVER_ERROR

  useEffect(() => {
    if (jwt && currentMemberProfile) {
      if (IS_NATIVE()) {
        const msg: NativeMessage = {
          actionName: 'member_authenticated',
          actionJwt: jwt,
          actionExtra: currentMemberProfile,
        }

        nativeApi_sendMessage(msg)

        if (!alreadyShowedLoadingPage) {
          setShowLoadingPage(true)
          setAlreadyShowedLoadingPage(true)
          setTimeout(() => setShowLoadingPage(false), 3000)
        }
      }
    }
  }, [jwt, currentMemberProfile])

  useEffect(() => {
    if (jwt && currentMemberProfile) {
      if (isAmazon && isReportOpportunitiesPageFirstLoad) {
        setIsAmazonPharmacy90DaysSupplyModalOpen(true)
      }
    }
  }, [jwt, currentMemberProfile, isAmazon, isReportOpportunitiesPageFirstLoad])

  //NOTES - by default , if we dont have jwt, redirect to registration+login
  //except for some custom registration cases - quicklink, prereg or ssoreg
  if (!jwt) {
    let redirectUrl = REG_ROUTE_LOGIN

    if (isQuickLinkRegistration()) {
      //its quick link
      //check if delayed processing
      // i.e.only if users clicks Regiter link(ex Member outreach emails)
      if (
        getUrlParameter(URL_PARAM_QUICKLINK_PROCESS_LATER) !== undefined &&
        //these values have to be short bc of sms limit
        getUrlParameter(URL_PARAM_QUICKLINK_PROCESS_LATER) === 't'
      ) {
        //yes so go to normal login page + params
        redirectUrl = REG_ROUTE_LOGIN + window.location.search
      } else {
        //regular registration
        redirectUrl =
          REG_ROUTE_FIND_TENANT_MEMBER_FROM_QR + window.location.search
      }
    }

    if (isPreregistration()) {
      redirectUrl = REG_ROUTE_PREREGISTER + location.search
    }

    //there are two different sso routes - basic entry point for all
    //saml login - all members by default are sent here from the backend after SSO exchange
    if (isSamlSsoLogin()) {
      if (isSamlSsoLoginError()) {
        redirectUrl = ROUTE_SAML_LOGIN_ERROR + window.location.search
      } else {
        //dont strip away saml login parmeters
        redirectUrl = ROUTE_SAML_LOGIN + window.location.search
      }
    }

    //there are also sso users  that have never logged in before - they need to complete sso type of registration
    if (isCompleteSsoRegistration()) {
      redirectUrl =
        REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE + window.location.search
    }

    //preserve mobile app version in the url
    if (getUrlParameter(URL_PARAM_MOBILE_APP_VERSION)) {
      const version = getUrlParameter(URL_PARAM_MOBILE_APP_VERSION)
      redirectUrl = `${redirectUrl}?${URL_PARAM_MOBILE_APP_VERSION}=${version}`
    }

    const isAlreadyUnderRegistration =
      window.location.href.indexOf('/register') > -1 ||
      window.location.href.indexOf('/ssoregister') > -1 ||
      window.location.href.indexOf('/preregister') > -1

    if (isAlreadyUnderRegistration) {
      return null
    } else return <Redirect to={redirectUrl} />
  }

  if (isProvider(jwtDetails)) {
    return (
      <Suspense fallback={<div>Loading Provider Portal...</div>}>
        <ProviderContainer />
      </Suspense>
    )
  } else {
    if (mainDataLoading) {
      return (
        // TODO - nicer loader - NOTE - there is a similar loader in CompleteLoginHandler for SSO users, as they would not come thur here
        <Box sx={{ padding: '50px' }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress variant="indeterminate" size={30} />
            </Grid>
            <Grid item xs={12}>
              <STypography
                variant="body_regular"
                sx={{ textAlign: 'center', paddingTop: '20px' }}
              >
                Please wait, loading your savings report....
              </STypography>
            </Grid>
          </Grid>
        </Box>
      )
    }
    return (
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* this is shown until react has finished loading the code splitted bundle */}
            <CircularProgress size={30} />
          </div>
        }
      >
        {isAmazon ? (
          <ModalAmazonPharmacy90DaysSupply
            open={isAmazonPharmacy90DaysSupplyModalOpen}
            onClose={() => {
              setIsAmazonPharmacy90DaysSupplyModalOpen(false)
            }}
            firstName={capitalizeFirstName(currentMemberProfile?.firstName)}
          />
        ) : null}
        <NewMemberPortalMain />
      </Suspense>
    )
  }
}
