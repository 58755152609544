import { Button, ThemeProvider } from '@mui/material'
import STypography from './customcomponents/STypography'

import chatIconImgSrc from './images/floating_button.svg'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { ThemedSVGRemote } from 'src/app/v2/ThemedSVGRemote'
import { getScriptaTheme } from 'src/app/styles/theme-config-scripta'

export default function MemberSupportButton() {
  const scriptaTheme = getScriptaTheme()
  const COLORS = scriptaTheme.customized.colors

  const { t } = useSTranslate('newComponents')
  const supportText = t('memberSupportChat')

  function handleOnClick() {
    showChatWindow()
  }

  const showChatWindow = () => {
    const zohoValue = (window as any).$zoho

    if (
      zohoValue &&
      zohoValue.salesiq &&
      zohoValue.salesiq.floatwindow &&
      typeof zohoValue.salesiq.floatwindow.visible === 'function'
    ) {
      zohoValue.salesiq.floatwindow.visible('show')
    } else {
    }
  }

  return (
    <ThemeProvider theme={scriptaTheme}>
      <Button
        component="label"
        role={undefined}
        variant="text"
        tabIndex={-1}
        startIcon={
          <ThemedSVGRemote
            importUrl={chatIconImgSrc}
            width={40}
            height={40}
            alt={'support button'}
            styleButNoWidthOrHeight={{ height: '40px' }}
          />
        }
        sx={{ textTransform: 'none', borderRadius: '8px', width: '100%' }}
        onClick={handleOnClick}
      >
        <STypography variant="bodysmall_bold" sx={{ color: COLORS.TEXT_BRAND }}>
          {supportText}
        </STypography>
      </Button>
    </ThemeProvider>
  )
}
