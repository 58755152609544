import { Box, Grid, List, ListItem, ListItemText } from '@mui/material'
import { RADIUS, SPACING } from './theme2'
import currentLocationImgSrc from './images/current_location_illustration.svg'
import selectOptionImgSrc from './images/select_option_illustration.svg'
import pharmacistImgSrc from './images/pharmacist_illustration.svg'
import talkToPharmacistImgSrc from './images/talk_to_pharmacist_illustration.svg'
import StepCard from './StepCard'
import STypography from './customcomponents/STypography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { Fragment, FunctionComponent, SVGProps } from 'react'
import PharmaciesWithCoupons from './PharmaciesWithCoupons'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'
import { TagLabel } from 'src/app/system/TagLabel'

type Step = {
  imgSrc: string
  imgWidth: number
  imgHeight: number
  summary: string
  detailedDescription?: string[]
}

export default function HowToSaveUsingCoupons() {
  const { COLORS } = useSColors()
  const { t } = useSTranslate('howToSaveUsingCoupons')

  const STEPS: Step[] = [
    {
      imgSrc: currentLocationImgSrc,
      summary: t('step1Summary'),
      imgWidth: 160,
      imgHeight: 160,
    },
    {
      imgSrc: selectOptionImgSrc,
      summary: t('step2Summary'),
      imgWidth: 160,
      imgHeight: 160,
    },
    {
      imgSrc: pharmacistImgSrc,
      summary: t('step3Summary'),
      imgWidth: 191,
      imgHeight: 160,
      detailedDescription: [
        t('step3DetailedDescription1'),
        t('step3DetailedDescription2'),
        t('step3DetailedDescription3'),
        t('step3DetailedDescription4'),
      ],
    },
    {
      imgSrc: talkToPharmacistImgSrc,
      summary: t('step4Summary'),
      imgWidth: 260,
      imgHeight: 160,
      detailedDescription: [t('step4DetailedDescription')],
    },
  ]

  return (
    <Grid
      container
      gap={SPACING._1}
      direction={'column'}
      sx={{ paddingBottom: SPACING._025 }}
    >
      {STEPS.map((step, index) => (
        <Fragment key={step.summary}>
          <Grid item alignSelf={'center'}>
            <ThemedSVGRemote
              importUrl={step.imgSrc}
              alt={`Step ${index + 1}`}
              width={step.imgWidth}
              height={step.imgHeight}
            />
          </Grid>
          <Grid item>
            <StepCard step={index + 1} stepDescription={step.summary} />
          </Grid>
          {step.detailedDescription && (
            <Grid item>
              {step.detailedDescription.length > 1 ? (
                <List
                  component="ol"
                  disablePadding
                  sx={{
                    listStyle: 'decimal',
                    fontFamily: 'Proxima Nova',
                    paddingLeft: 3,
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  {step.detailedDescription.map((description, index) => (
                    <ListItem
                      key={description}
                      disablePadding
                      sx={{ display: 'list-item' }}
                    >
                      <ListItemText primary={`${description}`} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <STypography
                  variant="body_regular"
                  sx={{ paddingLeft: SPACING._05, paddingRight: SPACING._05 }}
                >
                  {step.detailedDescription[0]}
                </STypography>
              )}
            </Grid>
          )}
        </Fragment>
      ))}
      <Grid item>
        <Grid container justifyContent={'center'} gap={SPACING._1}>
          <Grid item>
            <TagLabel size={'big'} color={'blue'} text={'Coupon'} />
          </Grid>
          <Grid item>
            <TagLabel size={'big'} color={'blue'} text={'Coupons available'} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          sx={{
            border: '1px solid grey',
            borderRadius: RADIUS.sm,
            padding: '10px',
          }}
        >
          <STypography
            component="span"
            variant="bodybig_bold"
            sx={{ color: COLORS.PRIMARY_BRAND }}
          >
            {t('step4Reminder')}
          </STypography>
          <STypography component="span" variant="bodybig_regular">
            {' '}
            {t('step4ReminderDescription')}
          </STypography>
        </Box>
      </Grid>
      <Grid item>
        <PharmaciesWithCoupons />
      </Grid>
    </Grid>
  )
}
