import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

//TODO - this was ported from savings dashboard - should be consolidated when into shared styles when
//adding new dashboard
export const useSearchResultStyles = makeStyles((theme: Theme) => {
  return createStyles({
    //screen-reader only text
    srOnly: {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: 0,
      margin: '-1px',
      overflow: 'hiden',
      clip: 'rect(0,0,0,0)',
      border: 0,
      fontSize: '0px',
    },
    suggesterPlacholderText: {
      marginLeft: '5px',
      // color: theme.customized.colors.savingReportOriginalHeaderText,
      //TODO - better color in this new screen? this used to be big red letter
      color: '#96939B',
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '1rem',
      whiteSpace: 'nowrap'
    },
  })
})
export function getSelectControlStyles(
  theme: Theme,
  customStylesOverride: any,
  isSmallWidth: boolean,
) {
  const selectStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      //TODO: review why this minWidth was needed. it was removed when styling filter dropdowns
      minWidth:
        customStylesOverride && customStylesOverride.minWidth
          ? customStylesOverride.minWidth
          : '100%',
      display: 'flex',
      justifyContent: 'center',
    }),
    //check styling for mobile etc
    menu: (provided: any, state: any) => {
      return {
        ...provided,
        fontSize: isSmallWidth ? '0.6rem' : '0.9',
        marginTop: '2px',
        width: '240px',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          marginTop: '0px',
        },
        // width: selectWidth,
        // borderBottom: '1px dotted pink',
        // color: state.selectProps.menuColor,
        //ie styling
        // padding: 5,
      }
    },
    input: (provided: any, state: any) => {
      return {
        ...provided,
        color: '#201F22',
        fontSize: '1rem',
        fontWeight: 400,
        marginLeft: '8px',
      }
    },
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color: state.isFocused ? '#FFFF' : 'black',
        backgroundColor: state.isFocused ? theme.palette.primary.main : '#FFFF',
        fontSize: '15px',
        fontWeight: 500,
      }
    },

    //this reverses the order to place the search icon to the right
    control: (provided: any) => {
      return {
        ...provided,
        backgroundColor: 'white',
        padding:
          customStylesOverride && customStylesOverride.padding
            ? customStylesOverride.padding
            : '1px',
        height:
          customStylesOverride && customStylesOverride.height
            ? customStylesOverride.height
            : 40,
        minHeight:
          customStylesOverride && customStylesOverride.minHeight
            ? customStylesOverride.minHeight
            : 40,
        fontSize:
          customStylesOverride && customStylesOverride.fontSize
            ? customStylesOverride.fontSize
            : '0.9rem',
        border: '1px solid white',
        boxShadow: '0 0 0 0px white',
        fontWeight: 500,
        '&:hover': {
          border: '1px solid white',
          boxShadow: '0 0 0 0px white',
        },
        width: '240px',
        display: 'flex',
        justifyItems: 'center',
        borderRadius: '25px',
        [theme.breakpoints.down('sm')]: {
          width: '350px',
          height: '50px',
          marginTop: '15px',
          marginBottom: '15px',
        },
        //TODO: this will not work, it will flip all the indicators (i.e. loading etc)
        //we will need something customized
        // flexDirection: searchIconPosition === 'left' ? 'row-reverse' : 'row',
      }
    },
  }
  return selectStyles
}
