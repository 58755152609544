import { Grid } from '@mui/material'
import TimelineDot from '@mui/lab/TimelineDot'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

export function StepperDots({ activeStep }: { activeStep: number }) {
  const { t } = useSTranslate('contactMyPrescriber')
  if (activeStep < 2) return null
  const dots = []
  //first two steps are not numbered
  for (let i = 2; i < 6; i++) {
    dots.push(
      <Grid item key={i}>
        <TimelineDot
          // variant="outlined"
          color={activeStep === i ? 'primary' : 'grey'}
          sx={{ width: '10px' }}
        />
      </Grid>,
    )
  }
  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      alignItems="center"
      justifyContent="center"
      sx={{ marginBottom: '10px' }}
    >
      {dots}
    </Grid>
  )
}
