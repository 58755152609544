import React from 'react'
import { Box } from '@mui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { SPACING } from './theme2'
import { useSColors } from '../styles/scripta-theme'

interface InfoIconButtonProps {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function InfoIconButton({ onClick }: InfoIconButtonProps) {
  const { COLORS } = useSColors()
  return (
    <Box
      sx={{
        padding: SPACING._025,
        borderRadius: SPACING._05,
        backgroundColor: COLORS.PRIMARY_10,
        height: '32px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        width: '32px',
        ':hover': {
          backgroundColor: COLORS.PRIMARY_20,
        },
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick(e)
      }}
    >
      <FontAwesomeIcon
        icon={faCircleInfo}
        color={COLORS.PRIMARY_BRAND}
        height={'16px'}
        fontSize={'16px'}
        style={{ padding: '4px' }}
      />
    </Box>
  )
}
