import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Fragment } from 'react'
import { HashLink } from 'react-router-hash-link'
import { useSColors } from '../styles/scripta-theme'
import { useSTranlateForObjects_waitUntilReady } from 'src/app/hooks/useSTranslate'

export default function FaqContent() {
  const { COLORS } = useSColors()
  const { t, ready } = useSTranlateForObjects_waitUntilReady('faq')
  const content: any = t('content', { returnObjects: true })
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY
    const yOffset = isSmall ? -50 : -10
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }

  if (!ready) {
    return null
  }

  return (
    <>
      <Box id="faq-container" overflow={'auto'}>
        <Grid container>
          <Grid item>
            {content.map((c: any, index: number) => (
              <Box key={index} sx={{ paddingBottom: '15px' }}>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 400,
                    marginTop: '36px',
                    paddingBottom: '16px',
                  }}
                >
                  {c.title}
                </Typography>
                {c.questions?.map((q: any, indexq: number) => (
                  <HashLink
                    onClick={
                      isDesktop
                        ? (event) => {
                            event.preventDefault()
                            const element = document.getElementById(q.anchor)
                            element?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'center',
                              inline: 'start',
                            })
                          }
                        : undefined
                    }
                    scroll={
                      !isDesktop ? (el) => scrollWithOffset(el) : undefined
                    }
                    to={`/myaccount/faq#${q.anchor}`}
                    style={{ textDecoration: 'none' }}
                    key={q.anchor}
                  >
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        color: COLORS.BACKGROUND_SECONDARY_ACTIVE,
                        paddingBottom: '4px',
                      }}
                    >
                      <span style={{ fontSize: '20px', color: '#201F22' }}>
                        •{' '}
                      </span>
                      {q.question}
                    </Typography>
                  </HashLink>
                ))}
              </Box>
            ))}
          </Grid>
          <Grid item>
            {content.map((c: any, index: number) => (
              <Box key={index} sx={{ paddingBottom: '15px' }}>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 400,
                    marginTop: '36px',
                    paddingBottom: '9px',
                    color: '#6E6B73',
                  }}
                >
                  {c.title}
                </Typography>
                <Divider sx={{ borderWidth: '1px' }} />
                {c.questions?.map((q: any, indexq: number) => (
                  <Fragment key={indexq}>
                    <Typography
                      sx={{
                        paddingTop: '18px',
                        fontSize: '18px',
                        fontWeight: 600,
                        color: '#201F22',
                        marginBottom: '-14px',
                      }}
                      id={q.anchor}
                    >
                      {q.question}
                    </Typography>
                    {q.answers?.map((a: any, indexa: number) => (
                      <>
                        <Typography
                          key={indexa}
                          sx={{
                            fontSize: '18px',
                            color: '#201F22',
                            fontWeight: 400,
                            paddingTop: '21px',
                          }}
                        >
                          {a.answer}
                        </Typography>
                        {a.bullets && a.bullets.length > 0 && (
                          <ul>
                            {a.bullets.map((b: any, indexb: number) => (
                              <li key={indexb}>
                                <Typography
                                  sx={{
                                    fontSize: '18px',
                                    color: '#201F22',
                                    fontWeight: 400,
                                  }}
                                >
                                  {b}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    ))}
                    <Box sx={{ paddingBottom: '32px' }}></Box>
                  </Fragment>
                ))}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
