import { DialogContent, Grid, TextField, Typography } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
} from '../member/phone-field-utils'
import { MOBILE_DIALOG_SX, BottomActions, DESKTOP_DIALOG_SX } from './Wizard'
import { useEffect, useState } from 'react'
import { UiFormData, ValidationResult, validateUiData } from './types'

export function PharmacyDetails({
  pharmacyData,
  onPharmacyDataChange,
  validationResult,
  setValidationResult,
  isSmallDisplay,
  onContinue,
  onBack,
  forceTriggerValidation,
}: {
  pharmacyData: UiFormData
  onPharmacyDataChange: (pharmacyData: UiFormData) => void
  isSmallDisplay: boolean
  onContinue: () => void
  onBack: () => void
  forceTriggerValidation: boolean
  validationResult: ValidationResult
  setValidationResult: (validationResult: ValidationResult) => void
}) {
  const { t } = useSTranslate('contactMyPrescriber')
  let renderBottomActions = isSmallDisplay
  //dont validate immediatelly, let user enter some data first and click continue once
  const [enableValidation, setEnableValidation] = useState<boolean>(false)

  useEffect(() => {
    if (forceTriggerValidation) {
      setEnableValidation(true)
    }
  }, [forceTriggerValidation])

  useEffect(() => {
    if (enableValidation) {
      const validationResult = validateUiData(pharmacyData)
      setValidationResult(validationResult)
    }
  }, [pharmacyData, enableValidation])

  return (
    <>
      <DialogContent sx={isSmallDisplay ? MOBILE_DIALOG_SX : DESKTOP_DIALOG_SX}>
        <Grid item container spacing={isSmallDisplay ? 2 : 1}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '1.15rem' }}>
              {t('pharmacyInfoTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              size="small"
              fullWidth
              autoComplete="no"
              placeholder={t('pharmacyNameLabel')}
              label={t('pharmacyNameLabel')}
              // style={{ marginBottom: '20px', height: '48px' }}
              value={pharmacyData.pharmacyName}
              onChange={(e: any) => {
                onPharmacyDataChange({
                  ...pharmacyData,
                  pharmacyName: e.target.value,
                })
              }}
              error={enableValidation && !validationResult.validPharmacyName}
              helperText={
                enableValidation && !validationResult.validPharmacyName
                  ? t('errorPharmacyNameField')
                  : ''
              }
              inputProps={{
                autoComplete: 'chrome-off',
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              autoComplete="no"
              size="small"
              fullWidth
              placeholder={t('pharmacyAddressLabel')}
              label={t('pharmacyAddressLabel')}
              // style={{ marginBottom: '20px', height: '48px' }}
              value={pharmacyData.pharmacyAddress}
              onChange={(e) => {
                onPharmacyDataChange({
                  ...pharmacyData,
                  pharmacyAddress: e.target.value,
                })
              }}
              error={enableValidation && !validationResult.validPharmacyAddress}
              helperText={
                enableValidation && !validationResult.validPharmacyAddress
                  ? t('errorPharmacyAddressField')
                  : ''
              }
              inputProps={{
                autoComplete: 'nope',
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              size="small"
              autoComplete="no"
              fullWidth
              placeholder={t('pharmacyZipLabel')}
              label={t('pharmacyZipLabel')}
              // style={{ marginBottom: '20px', height: '48px' }}
              value={pharmacyData.pharmacyZip}
              onChange={(e) => {
                onPharmacyDataChange({
                  ...pharmacyData,
                  pharmacyZip: e.target.value,
                })
              }}
              error={enableValidation && !validationResult.validPharmacyZip}
              helperText={
                enableValidation && !validationResult.validPharmacyZip
                  ? t('errorPharmacyZipField')
                  : ''
              }
              inputProps={{
                autoComplete: 'nope',
              }}
            />
          </Grid>
          <Grid item xs={8} md={3}>
            <TextField
              size="small"
              autoComplete="no"
              placeholder={t('pharmacyPhoneLabel')}
              label={t('pharmacyPhoneLabel')}
              value={pharmacyData.pharmacyPhone}
              onChange={(e: any) => {
                //up to 10 digits
                if (toDigitsOnly(e.target.value).length <= 10) {
                  let displayNumberValue = toDisplayFriendlyString(
                    e.target.value,
                  )
                  const isValid = isDisplayFriendlyStringValidNumber(
                    e.target.value,
                  )
                  onPharmacyDataChange({
                    ...pharmacyData,
                    pharmacyPhone: displayNumberValue,
                  })
                }
              }}
              error={enableValidation && !validationResult.validPharmacyPhone}
              helperText={
                enableValidation && !validationResult.validPharmacyPhone
                  ? t('errorPharmacyPhoneField')
                  : ''
              }
              inputProps={{
                autoComplete: 'nope',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {renderBottomActions && (
        <>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                textAlign: 'center',
                fontStyle: 'italic',
                padding: '0px 20px 10px 10px',
              }}
            >
              {t('submitNote')}
            </Typography>
          </Grid>
          <BottomActions
            isSmallDisplay={isSmallDisplay}
            useSubmitLabelForContinue={true}
            // continueDisabled={!acceptedPrimary || !acceptedSecondary}
            onContinue={() => {
              setEnableValidation(true)
              const ret = validateUiData(pharmacyData)

              setValidationResult(ret)
              if (
                ret.validPharmacyName &&
                ret.validPharmacyPhone &&
                ret.validPharmacyZip &&
                ret.validPharmacyAddress
              ) {
                onContinue()
              } else {
              }
            }}
            onCancel={onBack}
            // useBackLabelForCancel={true}
          />
        </>
      )}
    </>
  )
}
