import { Box, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  useSTranlateForObjects_waitUntilReady,
  useSTranslate,
} from 'src/app/hooks/useSTranslate'
import CernerImage from '../../images/cerner_user_agreement_icon.svg'
import {
  MemberIdData,
  MemberPolicyAcceptData,
  MemberTOUAcceptData,
  MembersPolicyAndTermsAcceptanceStatus,
  PrivacyPolicy,
  TermsOfUse,
  isValidResponse,
} from '../../types'
import { useMemberAppContext } from '../MemberAppContext'
import { checkMicroServiceResponseStatus, toPascalCase } from '../apiexec/utils'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import { useConfigService } from '../config/useConfigService'
import { CernerLinkWithDialog } from '../drugprofile/CernerTermsDialog'
import { useRegistrationService } from '../registration/useRegistrationService'
import { LOG } from '../v2/applog'
import { PrivacyOrTermsLink } from '../widgets/PrivacyOrTermsLink'
import { useMemberService } from './useMemberService'

export function PolicyOrTermsChangedDialogContainer() {
  const { currentMemberProfile } = useMemberAppContext()

  const configService = useConfigService()
  const service = useMemberService()

  const [
    renderTermsOrPolicyChangedDialog,
    setRenderTermsOrPolicyChangedDialog,
  ] = useState(false)

  const [currentStatus, setCurrentStatus] = useState<
    MembersPolicyAndTermsAcceptanceStatus | undefined
  >()
  const [privacyPolicy, setPrivacyPolicy] = useState<
    PrivacyPolicy | undefined
  >()
  const [termsOfUse, setTermsOfUse] = useState<TermsOfUse | undefined>()

  useEffect(() => {
    configService
      .getPrivacyPolicyApi()
      .then((value) => {
        if (isValidResponse<PrivacyPolicy>(value)) {
          setPrivacyPolicy(value)
        } else {
          console.error('Not a valid reponse from get privacy policy api...')
        }
      })
      .catch((error) => {
        LOG.error('generic_error', 'Error loading privacy policy.', { error })
      })

    configService
      .getTermsOfUseApi()
      .then((value) => {
        if (isValidResponse<TermsOfUse>(value)) {
          setTermsOfUse(value)
        } else {
          LOG.error(
            'generic_error',
            'Not a valid reponse from get terms of use api...',
          )
        }
      })
      .catch((error) => {
        LOG.error('generic_error', 'Error loading terms of use.', { error })
      })
  }, [])

  useEffect(() => {
    if (currentMemberProfile) {
      loadAcceptanceStatusApi(
        currentMemberProfile.memberId,
        currentMemberProfile.tenantId,
      )
    }
  }, [currentMemberProfile])

  async function loadAcceptanceStatusApi(memberId: number, tenantId: number) {
    const memberIdData: Partial<MemberIdData> = {
      memberId,
      tenantId,
    }
    const respStatus = await service.getMemberPolicyAndTermsAcceptanceStatusApi(
      memberIdData,
    )

    if (isValidResponse<MembersPolicyAndTermsAcceptanceStatus>(respStatus)) {
      setCurrentStatus(respStatus)
    } else {
      LOG.error(
        'generic_error',
        'Unable to check current members policy acdeptance status..',
      )
    }
  }

  useEffect(() => {
    if (currentStatus) {
      if (
        !currentStatus.acceptedCurrentPPVersion ||
        !currentStatus.acceptedCurrentTNCVersion ||
        !currentStatus.acceptedCurrentCMAVersion
      ) {
        setRenderTermsOrPolicyChangedDialog(true)
      }
    }
  }, [currentStatus])

  return renderTermsOrPolicyChangedDialog &&
    currentStatus &&
    privacyPolicy &&
    termsOfUse ? (
    <PolicyOrTermsChangedDialog
      currentAcceptStatus={currentStatus}
      privacyPolicy={privacyPolicy}
      termsOfUse={termsOfUse}
    />
  ) : null
}

export function PolicyOrTermsChangedDialog({
  currentAcceptStatus,
  privacyPolicy,
  termsOfUse,
}: {
  currentAcceptStatus: MembersPolicyAndTermsAcceptanceStatus
  privacyPolicy: PrivacyPolicy
  termsOfUse: TermsOfUse
}) {
  //
  const memberService = useMemberService()
  const registrationService = useRegistrationService()
  const { t, ready } = useSTranlateForObjects_waitUntilReady('termsChanged')

  const { currentMemberProfile } = useMemberAppContext()

  const [open, setOpen] = useState(true)
  if (!ready) return null

  const {
    acceptedCurrentPPVersion,
    acceptedCurrentTNCVersion,
    acceptedCurrentCMAVersion,
  } = currentAcceptStatus

  const COMMON_LINK_SX = {
    fontSize: '1rem',
    fontWeight: 'bold',
    textDecoration: 'none',
  }

  const updatedContentText = getUpdatedContentText()

  function getUpdatedContentText(): string {
    let updatedTitleContent = ''
    if (!acceptedCurrentPPVersion) {
      updatedTitleContent += t('privacyPolicy')
    }
    if (!acceptedCurrentTNCVersion) {
      if (updatedTitleContent) {
        updatedTitleContent += ' and '
      }
      updatedTitleContent += t('terms')
    }
    return updatedTitleContent
  }

  return (
    <NewPortalDialogDesign
      upperRightX={false}
      open={open}
      singleActionProps={{
        text: 'I Agree',
        // sx: {
        //   fontSize: '1rem',
        // },
        onClick: async () => {
          //do we need to proess latest scripta policy?
          if (!acceptedCurrentPPVersion) {
            const policyAcceptData: MemberPolicyAcceptData = {
              memberId: currentMemberProfile?.memberId,
              tenantId: currentMemberProfile?.tenantId,
              policyId: privacyPolicy?.id,
            }

            //

            const policyAcceptResp =
              await registrationService.memberAcceptPolicyApi(policyAcceptData)
            if (isValidResponse<void>(policyAcceptResp)) {
              //do we need to process
              //setOpen(false)
            } else {
              LOG.error('generic_error', 'Failed accepting Privacy Policy')
            }
          }

          if (!acceptedCurrentTNCVersion) {
            const touAcceptData: MemberTOUAcceptData = {
              memberId: currentMemberProfile?.memberId,
              tenantId: currentMemberProfile?.tenantId,
              versionId: termsOfUse?.id,
            }

            //

            const touAcceptDataResp =
              await registrationService.memberAcceptTOUApi(touAcceptData)
            if (isValidResponse<void>(touAcceptDataResp)) {
              //do we need to process
              //setOpen(false)
            } else {
              LOG.error('generic_error', 'Failed accepting Terms Of Use')
            }
          }
          if (!acceptedCurrentCMAVersion) {
            try {
              //this is inside the microservices api
              const msResp = await memberService.acceptCernerPolicy()
              let undefinedOk = true
              const respCheck = checkMicroServiceResponseStatus(
                msResp,
                undefinedOk,
              )
              if (!respCheck.isError) {
                //do we need to process
                //setOpen(false)
              } else {
                LOG.error(
                  'generic_error',
                  'Failed accepting Cerner User Agreement',
                )
              }
            } catch (error) {
              LOG.error(
                'generic_error',
                'Failed accepting Cerner User Agreement',
                { error },
              )
            }
          }

          setOpen(false)
        },
      }}
      headerImageImport={CernerImage}
      //to push image and overly on top of dialog
      customPaperSx={{
        overflow: 'inherit',
        background: 'linear-gradient(180deg, #CADFEF 0%, #FFFFFF 100%);',
        width: {
          lg: 500,
        },
      }}
      customHeaderImageBoxSx={{
        position: 'absolute',
        top: '-50px',
        zIndex: 1,
        marginLeft: '-30px',
      }}
    >
      <Box sx={{ paddingTop: '50px' }}>
        <Grid container sx={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {t('hi')}{' '}
              {currentMemberProfile?.firstName
                ? toPascalCase(currentMemberProfile?.firstName)
                : ''}{' '}
              👋
            </Typography>
            <Typography
              sx={{
                marginTop: '10px',
                fontSize: '1rem',
                fontWeight: 'bold',
                fontStyle: 'italic',
              }}
            >
              {t('weUpdated', {
                what: updatedContentText,
              })}
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                marginTop: '10px',
                padding: '0px 0px 0px 0px',
                whiteSpace: 'pre-line',
              }}
            >
              {t('yourHealth')}
            </Typography>
            <Typography
              sx={{ fontSize: '1rem', marginTop: '0px', marginBottom: '10px' }}
            >
              {t('clickAgree', {
                what: updatedContentText,
              })}
            </Typography>

            {!acceptedCurrentPPVersion && (
              <Grid item xs={12}>
                <PrivacyOrTermsLink type="privacy" isBold />
              </Grid>
            )}
            {!acceptedCurrentTNCVersion && (
              <Grid item xs={12}>
                <PrivacyOrTermsLink type="terms" isBold />
              </Grid>
            )}

            {!acceptedCurrentCMAVersion && (
              <Grid item xs={12}>
                <CernerLinkWithDialog
                  cernerDialogType={'userAgreement'}
                  customLinkSx={COMMON_LINK_SX}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </NewPortalDialogDesign>
  )
}
