import { useCallback, useEffect, useState } from 'react'
import { useDrugSearchService, toUiSearchResult } from './useDrugSearchService'

export interface RecentSearch {
  id: number
  name: string
  code: string
  isRecentSearch?: boolean
}
export interface RecentSearchResp {
  'recent-searches': RecentSearch[]
}

export function useRecentSearches() {
  const [recentSearches, setRecentSearches] =
    useState<RecentSearch[]>(MOCK_RECENT_SEARCHES)

  const searchService = useDrugSearchService()
  async function loadPrevious() {
    try {
      const previous = await searchService.getRecentSearches()
      const res = toUiSearchResult(previous)
      //update local cache so its immedite in ui
      setRecentSearches(res)
    } catch (e) {
      console.error('Error loadPrevious recent searches from backend=', e)
    }
  }

  useEffect(() => {
    loadPrevious()
  }, [])

  async function addNew(search: RecentSearch) {
    if (!recentSearches.find((cachedSearch) => cachedSearch.id === search.id)) {
      
      const added = await searchService.addNewRecentSearch(search)
      
      const cachedList = [search, ...recentSearches]
      setRecentSearches(cachedList)
    }
  }

  async function removeById(id: number) {
    try {
      //call actual backend
      const previous = await searchService.deleteSingleRecenSearchByDrugId(id)
      //update local cache so its immedite in ui
      let filteredArray = recentSearches.filter((item) => item.id !== id)
      setRecentSearches(filteredArray)
    } catch (e) {
      console.error('Error deleteByDrugId recent searches from backend=', e)
    }
  }

  async function removeAll() {
    try {
      //call actual backend
      const resp = await searchService.deleteALLRecentSearches()
      //update local cache so its immedite in ui
      setRecentSearches([])
    } catch (e) {
      console.error('Error deleteAllSearches recent searches from backend=', e)
    }
  }
  return { recentSearches, removeById, removeAll, addNew }
}

const MOCK_RECENT_SEARCHES: RecentSearch[] = [
  {
    id: 14393,
    name: 'Amphetamine-Dextroamphet Er (Adderall XR)',
    code: 'amphetamine-dextroamphet-er',
  },
  {
    id: 14392,
    name: 'Adderall Xr (Amphetamine-Dextroamphet ER)',
    code: 'adderall-xr',
  },
  {
    id: 9109,
    name: 'Atorvastatin Calcium (Lipitor)',
    code: 'atorvastatin-calcium',
  },
  {
    id: 15288,
    name: 'Enfamil Enfacare Lipil',
    code: 'enfamil-enfacare-lipil',
  },
  {
    id: 27309,
    name: 'Enfagrow Premium Lipil',
    code: 'enfagrow-premium-lipil',
  },
  {
    id: 3466,
    name: 'Triderm (Triamcinolone Acetonide)',
    code: 'triderm',
  },
  {
    id: 987,
    name: 'Tridesilon (Desonide)',
    code: 'tridesilon',
  },
]
