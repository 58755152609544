import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CircularProgress, Grid, Link, Typography } from '@mui/material'
import { useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'
import { GAService } from '../application/ga/GAService'
import { useMemberService } from '../member/useMemberService'
import { useMemberAppContext } from '../MemberAppContext'
import { REG_ROUTE_SERVER_ERROR } from '../registration/RegistrationSubRoutes'
import { useSColors } from '../styles/scripta-theme'
import { LOG } from 'src/app/v2/applog'

export const DOWNLOAD_BUTTON_HEIGHT = 90
//only letters and numbers
function prepStringForSafeFileName(str?: string) {
  if (!str) {
    return ''
  }
  return str.replace(/[^a-zA-Z0-9]/g, '')
}

// no download on native anymore
// export function callNativeDownload(jwt?: string) {
//   if (IS_NATIVE()) {
//     const msg: NativeMessage = {
//       actionName: 'download_report',
//       actionJwt: jwt,
//       actionExtra: undefined,
//     }

//     nativeApi_sendMessage(msg)

//     return
//   }
// }

export function DownloadSavingsReportLink() {
  const { COLORS } = useSColors()
  const { savingReport, currentMemberProfile, history, jwt } =
    useMemberAppContext()
  const gaService = new GAService()

  const [btnDisabled, setBtnDisabled] = useState(false)

  const { t } = useSTranslate(['savingReport'])
  const { t: guideT } = useSTranslate('savingGuide')

  const [downloadInProgress, setDownloadInProgress] = useState(false)
  const [localErrorMsg, setLocalErrorMsg] = useState<string | undefined>()

  const memberService = useMemberService()
  if (!savingReport || !currentMemberProfile)
    return <div>{t('loadingSavingReport')}</div>

  const firstName = prepStringForSafeFileName(savingReport?.member.firstName)
  const lastName = prepStringForSafeFileName(savingReport?.member.lastName)
  var rightNow = new Date()
  var dateString = rightNow.toISOString().slice(0, 10).replace(/-/g, '')
  const downloadFileName = `SavingsReport_${firstName}_${lastName}_${dateString}.pdf`

  const handleDownload = async (e: any) => {
    gaService.trackEvent(gaService.eventMap.home_page_download_savings_report)
    // if (IS_NATIVE()) {
    //
    //   callNativeDownload(jwt)
    //   e.preventDefault()
    //   setBtnDisabled(true)

    //   // // **** here's the timeout ****
    //   // TODO: look into this
    //   setTimeout(() => setBtnDisabled(false), 5000)
    //   return
    // }
    //clean up previous
    setLocalErrorMsg(undefined)
    setDownloadInProgress(true)
    setBtnDisabled(true)
    const resp = await memberService.getMemberSavingsReportPDFApi()
    // try {
    // const statusCode = JSON.parse(resp)
    if (resp && resp.code == 30) {
      //what is this error code 30????
      LOG.error(
        'generic_error',
        'Error downloading savings report,backend error code 30,resp=',
        resp,
      )

      setLocalErrorMsg('Error download report')
      setDownloadInProgress(false)
      history.push(REG_ROUTE_SERVER_ERROR)
      // throw new Error('Error downloading report')
      //   }
    }

    try {
      const blob = await (resp as any).blob()
      var data = new Blob([blob], {
        type: 'application/octet-stream',
      })
      //IE11 & Edge
      if ((navigator as any).msSaveBlob) {
        ;(navigator as any).msSaveBlob(data, 'doc.fileName')
      } else {
        //In FF link must be added to DOM to be clicked
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(data)
        link.setAttribute('download', downloadFileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
      LOG.error('generic_error', 'Error downloading savings report,e=', error)
      setLocalErrorMsg('Error download report')

      history.push(REG_ROUTE_SERVER_ERROR)
    }
    setDownloadInProgress(false)
    setBtnDisabled(false)
  }

  return (
    <Grid container direction="column">
      <Grid
        container
        gap={SPACING._05}
        justifyContent={'center'}
        sx={{ marginTop: SPACING._05 }}
      >
        <Grid item alignSelf={'center'}>
          {btnDisabled ? (
            <CircularProgress size={16} color="secondary" />
          ) : null}
        </Grid>
        <Grid item>
          <Link
            disabled={btnDisabled}
            component={'button'}
            sx={{
              textDecorationColor: COLORS.TEXT_SECONDARY,
              textDecorationThickness: 0.5,
            }}
            onClick={handleDownload}
          >
            <STypography variant="body_semibold">
              {guideT('dlReport')}
            </STypography>
          </Link>
        </Grid>
        <Grid item>
          <FontAwesomeIcon
            icon={faDownload}
            color={COLORS.TEXT_SECONDARY}
            height={'16px'}
            fontSize={'16px'}
            style={{ padding: '4px' }}
          />
        </Grid>

        {localErrorMsg && (
          <Grid
            item
            style={{ paddingTop: '10px' }}
            aria-live="polite"
            aria-atomic="true"
            aria-relevant="additions"
          >
            <Typography variant="caption">{t('downloadError')}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
