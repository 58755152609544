import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import scriptaLogo from '../../../images/logo.png'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { usePortalAppConfig } from 'src/app/config/usePortalAppConfig'
import { useMemberCouponParams } from '../useMemberCouponParams'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'
import SaveCouponButton from './SaveCouponButton'
import WithAnimationWrapper from 'src/app/system/WithAnimation'
import { useSColors } from 'src/app/styles/scripta-theme'
import InfoCard from 'src/app/system/InfoCard'
import CardContainer from 'src/app/system/CardContainer'
import { useSizes } from 'src/app/v2/useNewHooks'
import { ROUTE_LEARN_ABOUT_DISCOUNT_CARD } from 'src/app/v2/NewMemberPortalMain'
import { useMemberAppContext } from 'src/app/MemberAppContext'

const useDetailedCouponCardStyles = makeStyles((theme: Theme) => {
  const COLORS = theme.customized?.colors
  return createStyles({
    container: {
      width: 'max-content',
      maxWidth: 360,
      borderRadius: 8,
      overflow: 'hidden',
      boxShadow: '0px 4px 16px 0px rgba(77, 0, 140, 0.10)',
      backgroundColor: 'white',
      border: 'solid',
      borderColor: COLORS.PRIMARY_BRAND,
      borderWidth: 1,
    },
    contentContainer: {
      backgroundColor: 'white',
      padding: SPACING._1,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      overflow: 'hidden',
    },
  })
})

export default function DetailedCouponCard({
  disableDownload,
  customCardStyle = {},
  customDownloadButtonColor,
}: {
  disableDownload?: boolean
  customCardStyle?: React.CSSProperties
  customDownloadButtonColor?: string
}) {
  const { COLORS } = useSColors()
  const { couponParams, isLoadingParams, isErrorLoadingParams, errorMsg } =
    useMemberCouponParams()
  const { t } = useSTranslate('coupons')
  const classes = useDetailedCouponCardStyles()
  const theme = useTheme()
  const PORTAL_APP_CONFIG = usePortalAppConfig()

  if (isLoadingParams || isLoadingParams === undefined) {
    return (
      <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <WithAnimationWrapper>
          <Stack
            className={classes.container}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '192px',
              minWidth: '327px',
            }}
            style={{ ...customCardStyle }}
          >
            <Box className={classes.contentContainer} alignSelf={'center'}>
              <CircularProgress />
            </Box>
          </Stack>
        </WithAnimationWrapper>
      </Box>
    )
  }

  //this can happen if required rxsense details are missing for client in v2 (I.e. rxsense username, group number etc)
  if (isLoadingParams === false && (!couponParams || isErrorLoadingParams)) {
    return (
      <Box display={'flex'} justifyContent={'center'}>
        <Stack
          className={classes.container}
          sx={{
            padding: SPACING._1,
            marginBottom: 2,
            minHeight: '192px',
            minWidth: '327px',
          }}
          style={{ ...customCardStyle }}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            style={{ marginBottom: '5px' }}
          >
            {t('unableToResolveCouponInfo')}
            <br />
            {errorMsg}
          </Box>
        </Stack>
      </Box>
    )
  }

  if (couponParams) {
    return (
      <WithAnimationWrapper>
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Stack className={classes.container} style={{ ...customCardStyle }}>
            <Box className={classes.contentContainer}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <img
                  data-testid="coupon-card-logo"
                  src={
                    couponParams.useCustomLogoForSavingsCard
                      ? couponParams.customLogoForSavingsCard
                      : scriptaLogo
                  }
                  alt="Logo"
                  style={{ height: 24 }}
                />
                <STypography
                  variant="bodysmall_semibold"
                  sx={{ color: COLORS.PRIMARY_BRAND }}
                >
                  {t('pharmacySavingsCard')}
                </STypography>
              </Box>
              <Box sx={{ marginTop: SPACING._05 }}>
                <CouponDetailTypeValue
                  dataTestId="coupon-card-rxsense-auth-num"
                  type={t('authNum')}
                  value={couponParams.rxAuthNum}
                />
              </Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                sx={{ marginTop: SPACING._05 }}
              >
                <CouponDetailTypeValue
                  dataTestId="coupon-card-rxsense-bin"
                  type={t('bin')}
                  value={couponParams.rxBin}
                />
                <CouponDetailTypeValue
                  dataTestId="coupon-card-rxsense-grp"
                  type={t('grp')}
                  value={couponParams.rxGrp}
                />
                <CouponDetailTypeValue
                  dataTestId="coupon-card-rxsense-pcn"
                  type={t('pcn')}
                  value={couponParams.rxPcn}
                />
                <Box></Box>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                padding: `${SPACING._075} ${SPACING._1}`,
              }}
            >
              <STypography variant="bodysmall_semibold">
                {t('useUpToOff')}
              </STypography>
              <Typography fontWeight={400} fontSize={11} lineHeight={'16px'}>
                {t('notInsurance')} | {t('customerSupport')}:{' '}
                {PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER}
              </Typography>
            </Box>
          </Stack>
        </Box>
        {!disableDownload && (
          <SaveCouponButton
            variant="link"
            customFontColor={customDownloadButtonColor}
          />
        )}
      </WithAnimationWrapper>
    )
  }

  return null
}

export function DetailedCouponCardWithAdditionalInfo() {
  const { isMobile } = useSizes()
  const { COLORS } = useSColors()
  const { history } = useMemberAppContext()

  return (
    <WithAnimationWrapper>
      <Box position={'relative'}>
        <Box position={'absolute'} width={'100%'}>
          <DetailedCouponCard
            customCardStyle={{
              boxShadow: '0px 4px 16px 0px rgba(255, 255, 255, 0.15)',
            }}
            customDownloadButtonColor={COLORS.TEXT_LIGHT_PRIMARY_WHITE}
          />
        </Box>
        <Box sx={{ paddingTop: isMobile ? '125px' : '105px' }}>
          <CardContainer
            sx={{
              background: COLORS.BACKGROUND_GRADIENT,
              padding: SPACING._1,
              paddingTop: 0,
              color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
              textAlign: 'center',
              width: '100%',
              border: 'unset',
              borderRadius: '16px',
            }}
          >
            <Box paddingTop={isMobile ? '120px' : '140px'} />
            <CardContainer
              sx={{
                background: 'rgba(255, 255, 255, 0.40)',
                padding: SPACING._1,
                color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
                textAlign: 'center',
                width: '100%',
                border: 'unset',
                borderRadius: '8px',
              }}
            >
              <Grid container spacing={isMobile ? SPACING._05 : SPACING._1}>
                <Grid item xs={isMobile ? 12 : 6} style={{ textAlign: 'left' }}>
                  <STypography variant="bodybig_bold">Keep in mind</STypography>
                  <ul style={{ marginLeft: '-16px', marginTop: SPACING._075 }}>
                    <li>
                      <STypography variant="bodysmall_regular">
                        Only use the Scripta discount card when we guide you to
                        do so in order to obtain savings.
                      </STypography>
                    </li>
                    <li style={{ marginTop: '8px' }}>
                      <STypography variant="bodysmall_regular">
                        Prescriptions purchased with coupons do not use your
                        insurance, so they don’t count towards your deductible.
                      </STypography>
                    </li>
                  </ul>
                </Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 6}
                  alignSelf={'center'}
                  textAlign={'left'}
                >
                  <InfoCard
                    title="How to use your discount card"
                    variant="navigation_primary"
                    onClick={() => {
                      history.push(ROUTE_LEARN_ABOUT_DISCOUNT_CARD)
                    }}
                  />
                </Grid>
              </Grid>
            </CardContainer>
          </CardContainer>
        </Box>
      </Box>
    </WithAnimationWrapper>
  )
}

function CouponDetailTypeValue({
  type,
  value,
  dataTestId,
}: {
  type: string
  value: string
  dataTestId: string
}) {
  return (
    <Stack data-testid={dataTestId}>
      <STypography variant="bodysmall_regular">{type}</STypography>
      <STypography variant="bodysmall_semibold" data-testid={dataTestId}>
        {value}
      </STypography>
    </Stack>
  )
}
