import { Grid, Theme, useTheme } from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { InputBaseProps } from '@mui/material/InputBase/InputBase'
import CardContainer from '../CardContainer'
import { RADIUS, SPACING } from '../theme2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import STypography from './STypography'
import { useState } from 'react'
import {
  faExclamationCircle,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons'

interface STextFieldStateStyle {
  inputBackgroundColor: string
  inputBoxShadow: string //using box shadow to emulate border to prevent container from resizing
  mainIconColor: string
  labelColor: string
  captionColor: string
}

function getStateStyles(
  theme: Theme,
): Record<
  'default' | 'focused' | 'disabled' | 'locked' | 'valid' | 'error',
  STextFieldStateStyle
> {
  const COLORS = theme.customized.colors
  return {
    default: {
      inputBackgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
      inputBoxShadow: `0 0 0 1px ${COLORS.BORDER_GREY_LIGHT}`,
      mainIconColor: COLORS.TEXT_GREY_MEDIUM,
      labelColor: COLORS.TEXT_BLACK,
      captionColor: COLORS.TEXT_GREY_MEDIUM,
    },
    focused: {
      inputBackgroundColor: '#FFFFFF',
      inputBoxShadow: `0 0 0 2px ${COLORS.PRIMARY_BRAND}`,
      mainIconColor: COLORS.TEXT_GREY_MEDIUM,
      labelColor: COLORS.TEXT_BLACK,
      captionColor: COLORS.TEXT_GREY_MEDIUM,
    },
    valid: {
      inputBackgroundColor: '#FFFFFF',
      inputBoxShadow: `0 0 0 2px #008800`,
      mainIconColor: COLORS.TEXT_GREY_MEDIUM,
      labelColor: COLORS.TEXT_BLACK,
      captionColor: '#008800',
    },
    error: {
      inputBackgroundColor: '#FFFFFF',
      inputBoxShadow: `0 0 0 2px ${COLORS.ERROR_RED}`,
      mainIconColor: COLORS.TEXT_GREY_MEDIUM,
      labelColor: COLORS.TEXT_BLACK,
      captionColor: COLORS.ERROR_RED,
    },
    disabled: {
      inputBackgroundColor: COLORS.BACKGROUND_GREY_LIGHT,
      inputBoxShadow: 'none',
      mainIconColor: COLORS.TEXT_GREY_LIGHT,
      labelColor: COLORS.TEXT_GREY_LIGHT,
      captionColor: COLORS.TEXT_GREY_LIGHT,
    },
    locked: {
      inputBackgroundColor: COLORS.BACKGROUND_GREY_LIGHT,
      inputBoxShadow: 'none',
      mainIconColor: COLORS.TEXT_GREY_MEDIUM,
      labelColor: COLORS.TEXT_BLACK,
      captionColor: COLORS.TEXT_GREY_MEDIUM,
    },
  }
}

export interface STextFieldProps extends InputBaseProps {
  label?: string
  icon: IconDefinition
  caption?: string
  valid?: boolean
  locked?: boolean
  onEnter?: (value: string) => void
}

export default function STextField({
  icon,
  label,
  caption,
  disabled,
  onEnter,
  onBlur,
  onFocus,
  error,
  valid,
  locked,
  ...rest
}: STextFieldProps) {
  const [focused, setFocused] = useState(false)
  const theme = useTheme()
  const stateStyles = getStateStyles(theme)

  const style = (() => {
    if (error) {
      return stateStyles.error
    } else if (disabled) {
      return stateStyles.disabled
    } else if (locked) {
      return stateStyles.locked
    } else if (valid) {
      return stateStyles.valid
    } else if (focused) {
      return stateStyles.focused
    } else {
      return stateStyles.default
    }
  })()

  return (
    <Grid container direction={'column'} gap={SPACING._05}>
      {label && (
        <Grid item>
          <STypography
            variant={'bodysmall_semibold'}
            sx={{ color: style.labelColor }}
          >
            {label}
          </STypography>
        </Grid>
      )}
      <CardContainer
        sx={{
          border: 'none',
          borderRadius: RADIUS.xs,
          padding: `${SPACING._075} ${SPACING._1}`,
          backgroundColor: style.inputBackgroundColor,
          boxShadow: style.inputBoxShadow, // effectively just a border
        }}
      >
        <Grid
          container
          alignItems={'center'}
          gap={SPACING._025}
          flexWrap={'nowrap'}
        >
          <FontAwesomeIcon
            icon={icon}
            width={'16px'}
            color={style.mainIconColor}
            style={{ padding: '4px' }}
          />
          <InputBase
            fullWidth
            disabled={disabled || locked}
            {...rest}
            inputProps={{
              ...rest.inputProps,
              style: {
                ...rest.inputProps?.style,
                fontSize: '16px',
              },
            }}
            onFocus={(event) => {
              setFocused(true)
              onFocus?.(event)
            }}
            onBlur={(event) => {
              setFocused(false)
              onBlur?.(event)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && event.currentTarget) {
                onEnter?.(event.currentTarget.value)
              }
            }}
          />
          {(error || valid) && (
            <FontAwesomeIcon
              icon={error ? faExclamationCircle : faCheck}
              width={'16px'}
              color={style.captionColor}
              style={{ padding: '4px' }}
            />
          )}
        </Grid>
      </CardContainer>
      {caption && (
        <Grid item>
          <STypography
            variant={'bodysmall_regular'}
            sx={{ color: style.captionColor }}
          >
            {caption}
          </STypography>
        </Grid>
      )}
    </Grid>
  )
}
