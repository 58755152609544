import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import IncompleteAlertCircle from '../../images/incomplete_alert_circle.svg'
import { useSColors } from '../styles/scripta-theme'
import SButton from '../system/customcomponents/SButton'
export const useReminderDialogStyles = makeStyles((theme: Theme) => {
  return createStyles({
    dialogPaper: {
      borderRadius: '20px',
    },
  })
})

export function IncompleteReminder({
  onExitAnyay,
  onContinueForm,
  isSmallDisplay,
}: {
  onExitAnyay: () => void
  onContinueForm: () => void
  isSmallDisplay: boolean
}) {
  const { COLORS } = useSColors()
  const [open, setOpen] = useState(true)
  const classes = useReminderDialogStyles()

  const { t } = useSTranslate('contactMyPrescriber')

  return (
    <Dialog
      disableEscapeKeyDown
      onClose={(event: object, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          //do nothing, they must click continue or exit anyway
        }
      }}
      open={open}
      maxWidth={'xs'}
      // maxWidth={isSmallDisplay ? 'xs' : ''}
      fullWidth={true}
      scroll="paper"
      PaperProps={{
        classes: {
          root: classes.dialogPaper,
        },
      }}
    >
      <DialogTitle
        sx={{ textAlign: 'center', margin: isSmallDisplay ? '10px' : '0px' }}
      >
        <img
          src={IncompleteAlertCircle}
          alt="Incomplete Alert Circle"
          style={{ width: isSmallDisplay ? '50px' : '70px' }}
        />
      </DialogTitle>
      <DialogContent
        dividers={false}
        sx={{ marginTop: isSmallDisplay ? '0px' : '5px' }}
      >
        <Grid
          item
          container
          xs={12}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={isSmallDisplay ? 12 : 12}>
            <Typography
              sx={{
                fontSize: '1.4rem',
                fontWeight: '700',
                textAlign: 'center',
                color: COLORS.TEXT_BLACK,
              }}
            >
              {t('incompleteTitle')}
            </Typography>
          </Grid>
          <Grid item xs={isSmallDisplay ? 12 : 9}>
            <Typography
              sx={{
                fontSize: '1rem',
                textAlign: 'center',
                color: COLORS.TEXT_BLACK,
                lineHeight: '1.1rem',
                padding: isSmallDisplay ? '5px' : '0 25px 0 25px',
              }}
            >
              {t('incompleteMsg')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          xs={12}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <SButton
              sx={{
                width: '300px',
              }}
              variant="contained"
              onClick={() => {
                onContinueForm()
              }}
            >
              {t('incompleteContinueWork')}
            </SButton>
          </Grid>
          <Grid item>
            <SButton
              sx={{ width: '300px' }}
              variant="outlined"
              onClick={() => {
                onExitAnyay()
              }}
            >
              {t('incompleteExitAnyway')}
            </SButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
