export function monthNumberToMonthName(
  monthNumber: number | undefined,
): string | undefined {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  if (monthNumber) {
    return monthNames[monthNumber - 1]
  }
}

export function getCurrentYear(): number {
  return new Date().getFullYear()
}
