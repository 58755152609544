import { Box, Grid, Typography } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useSColors } from '../styles/scripta-theme'
import { STEP_INTRO, STEP_LEGAL_RELEASE } from './types'

export function AuthorizationFormTitle({
  currentStep,
  isSmallDisplay,
}: {
  currentStep: number
  isSmallDisplay: boolean
}) {
  const { COLORS } = useSColors()
  const { t } = useSTranslate('contactMyPrescriber')

  if (currentStep === STEP_INTRO) return null

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      alignItems="flex-start"
      // sx={{ backgroundColor: 'red' }}
    >
      {currentStep === STEP_LEGAL_RELEASE && (
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontWeight: 700, fontSize: '1.3rem' }}>
            SCRIPTA INSIGHTS
          </Typography>
        </Grid>
      )}

      <Grid
        item
        xs={isSmallDisplay ? 12 : 6}
        sx={{
          textAlign: 'center',
          padding: isSmallDisplay ? '0px 10px 0px 10px' : '',
        }}
      >
        <Box
          sx={{
            padding: isSmallDisplay ? '15px' : '10px',
            borderRadius: '7px',
            backgroundColor:
              currentStep === STEP_LEGAL_RELEASE
                ? COLORS.PRIMARY_BRAND
                : 'white',
            marginTop: '15px',
            marginBottom: '5px',
            background:
              currentStep === STEP_LEGAL_RELEASE
                ? COLORS.BACKGROUND_GRADIENT
                : 'white',
          }}
        >
          <Typography
            sx={{
              color:
                currentStep === STEP_LEGAL_RELEASE
                  ? 'white'
                  : COLORS.PRIMARY_BRAND,
              fontWeight: 700,
              fontSize: '1.3rem',
            }}
          >
            {t('releaseTitle')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
