import Select, {
  components,
  ControlProps,
  ClearIndicatorProps,
  Props,
  StylesConfig,
  ThemeConfig,
} from 'react-select'
import { Box, SxProps } from '@mui/material'
import { SPACING } from '../theme2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-regular-svg-icons'
import STypography, {
  STypographyCombination,
  STypographyVariant,
} from './STypography'
import AsyncSelect from 'react-select/async'
import { useSColors } from 'src/app/styles/scripta-theme'

export interface SSelectProps extends Props {
  label?: string
  labelSx?: SxProps
  labelVariant?: STypographyCombination
  //need these for async select used in the search suggester
  isAsync?: boolean
  loadOptions?: any
  getOptionLabel?: any
  getOptionValue?: any
  defaultOptions?: any
  //need this for recent searches as a custom component to select
  customSelectComponents?: any
}

export default function SSelect({
  label,
  labelSx = {},
  labelVariant = 'bodysmall_semibold',
  isSearchable = false,
  isAsync = false,

  customSelectComponents,
  ...rest
}: SSelectProps) {
  const { COLORS } = useSColors()
  const IconControl = ({ children, ...props }: ControlProps) => {
    return (
      <components.Control {...props}>
        <span style={{ paddingLeft: 10 }}>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            color={COLORS.TEXT_GREY_MEDIUM}
            height={16}
          />
        </span>
        {children}
      </components.Control>
    )
  }

  const ClearIndicator = ({ children, ...props }: ClearIndicatorProps) => {
    return (
      <components.ClearIndicator {...props}>
        <FontAwesomeIcon
          icon={faXmark}
          color={COLORS.TEXT_GREY_MEDIUM}
          height={16}
        />
      </components.ClearIndicator>
    )
  }

  const selectStyles: StylesConfig = {
    noOptionsMessage: (baseStyles, state) => ({
      ...baseStyles,
      fontFamily: 'Proxima Nova',
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontFamily: 'Proxima Nova',
      padding: `4px 4px`,
      backgroundColor: state.isFocused
        ? 'white'
        : COLORS.BACKGROUND_GREY_LIGHTER,
      color: COLORS.TEXT_BLACK,
      borderColor: COLORS.BORDER_GREY_LIGHT,
    }),
    indicatorsContainer: (baseStyles, state) => ({
      ...baseStyles,
      color: COLORS.TEXT_GREY_MEDIUM,
    }),
    valueContainer: (baseStyles, state) => ({
      ...baseStyles,
      input: {
        marginLeft: 10,
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontFamily: 'Proxima Nova',
        backgroundColor: isSelected
          ? COLORS.PRIMARY_BRAND
          : isFocused
          ? COLORS.BACKGROUND_GREY_LIGHT
          : 'white',
      }
    },
  }

  const selectTheme: ThemeConfig = (theme) => ({
    ...theme,
    borderRadius: 4,
    colors: {
      ...theme.colors,
      primary25: 'hotpink',
      primary: COLORS.PRIMARY_BRAND,
    },
  })

  const selectComponents = isSearchable
    ? {
        IndicatorSeparator: null,
        Control: IconControl,
        DropdownIndicator: null,
        ClearIndicator,
        ...customSelectComponents,
      }
    : {
        IndicatorSeparator: null,
        ...customSelectComponents,
      }

  return (
    <Box>
      {label ? (
        <STypography
          variant={labelVariant ? labelVariant : 'bodysmall_semibold'}
          sx={{ marginBottom: SPACING._025, ...labelSx }}
        >
          {label}
        </STypography>
      ) : null}
      {isAsync && (
        <AsyncSelect
          styles={selectStyles}
          theme={selectTheme}
          menuPortalTarget={document.body}
          isSearchable={isSearchable}
          isClearable={isSearchable}
          components={selectComponents}
          {...rest}
        />
      )}

      {!isAsync && (
        <Select
          styles={selectStyles}
          theme={selectTheme}
          menuPortalTarget={document.body}
          isSearchable={isSearchable}
          isClearable={isSearchable}
          components={selectComponents}
          {...rest}
        />
      )}
    </Box>
  )
}
