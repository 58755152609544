export const ZIP_CODE_LENGTH: number = 5

export const getDateString = () => {
  const date = new Date()
  return `${date.getMonth() + 1}_${date.getDate()}_${date.getFullYear()}`
}

export const isValidZipLength = (zipCodeInput?: string) => {
  return zipCodeInput && zipCodeInput.length === ZIP_CODE_LENGTH
}
