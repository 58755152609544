// Compatability Types _ these types are used by the member app
// the backend has two variants of the Member Savings Report, the older one developed for the Member App
// and the more recent and complete one developed for producing the PDF report. The plan is to use the
// types used for PDF generation but for compatability purposes we need to retain the Member App Types
//
// These types are below:

export interface DrugData {
  id: number
  name: string
  strength: string
  form: string
  qty: number
  days: number
}

export interface AlternativeData extends DrugData {
  substitution: string
  savings: number
}

export interface SavingsData {
  original: DrugData
  alternatives: AlternativeData[]
}

export interface AccountSummary {
  summary: SavingsData[]
}

// Created to help with GTM tracking to distinguish where a reusable
// component is being rendered 
export enum RenderingLocation {
  savingsReport = 'savings-report',
  searchResult = 'search-result',
  savingsStrategiesPage = 'saving-strategies-page',
  nonHiddenMed = "non-hidden-meds",
  hiddenMeds = "hidden-meds"
}
