import {
  Box,
  Grid,
  Link,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import {
  useSTranlateForObjects_waitUntilReady,
  useSTranslate,
} from 'src/app/hooks/useSTranslate'
import { IS_NATIVE } from '../apiexec/utils'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import { CernerLinkWithDialog } from '../drugprofile/CernerTermsDialog'
import { DisclaimerLink } from '../saving/Disclaimer'
import { getCurrentYear } from '../shared/utils'
import { FOOTER_MAX_WIDTH } from '../styles/scripta-theme'
import { MobileAppLinks } from '../widgets/MobileAppLinks'
import { OurSavingsStrategiesLink } from '../widgets/OurSavingStrategiesLink'
import { SwitchAndSave } from '../widgets/SwitchAndSave'
import { GAService } from './ga/GAService'
import { Theme } from '@mui/material'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { PrivacyOrTermsLink } from '../widgets/PrivacyOrTermsLink'
export const useFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {},
    footerBoldText: {
      fontWeight: 500,
      fontSize: '1rem',
      color: theme.customized.colors.TEXT_LIGHT_PRIMARY_WHITE,
      whiteSpace: 'pre-line',
      lineHeight: '21.78px',
    },
    footerSeparator: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    footerContainer: {
      marginTop: '1px',
      height: '100%',
      backgroundColor: theme.customized.colors.BACKGROUND_BLACK,
      marginBottom: '0px',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'left',
        paddingLeft: '1.5rem',
        flexWrap: 'wrap',
      },
      // [theme.breakpoints.up(524)]: {},
    },
    footerFirstGridItem: {},
    footerSecondGridItem: {},
    languageSelect: {
      position: 'sticky',
      bottom: '43px',
      marginLeft: '8px',
      backgroundColor: 'white',
      height: '100%',
      display: 'inline-flex',
      borderRadius: '4px',
    },
    footerLinkText: {
      fontWeight: 400,
      whiteSpace: 'pre-line',
      fontSize: '18px',
    },
    footerLinkTextOption: {
      marginBottom: '1rem',
      textDecoration: 'none',
      fontSize: '18px !important',
      lineHeight: '22px',
      width: '100%',
      underline: 'none !important',
    },
    atag: {
      textDecoration: 'none',
      color: theme.customized.colors.BACKGROUND_SECONDARY,
    },
    copyrightSection: {
      borderTop: '1px solid rgb(255, 255, 255, .2)',
      color: '#FFFFFF',
      [theme.breakpoints.down('sm')]: {
        borderTop: '0px',
        marginTop: '30px',
      },
    },
  }),
)

export function PortalFooter() {
  const gaService = new GAService()
  const classes = useFooterStyles()
  const { t, ready } = useSTranlateForObjects_waitUntilReady('footer')

  const PORTAL_APP_CONFIG = usePortalAppConfig()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  let width = 150
  let height = 45
  if (theme.customized.name === 'amazon-blue') {
    width = 94
    height = 40
  }

  const [openHowToSaveVideoModal, setOpenHowToSaveVideoModal] =
    useState<boolean>(false)

  if (!ready) return null

  return (
    <Box>
      <SwitchAndSave />
      <div
        className={classes.footer}
        style={{
          backgroundColor: '#201F22',
        }}
      >
        {isSmallScreen && !IS_NATIVE() && (
          <Box
            display={'flex'}
            sx={{
              padding: '30px',
              backgroundColor: '#EEEEEE !important',
            }}
          >
            <MobileAppLinks />
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '40px',
          }}
        >
          <Box sx={{ maxWidth: FOOTER_MAX_WIDTH }}>
            <Box>
              <a target="_blank" href="https://www.scriptainsights.com">
                <img
                  src={theme.customized.whiteOnDarkLogoUrl}
                  alt="Logo"
                  style={{
                    marginTop: '1px',
                    maxHeight: 100,
                    width: width,
                    height: height,
                    // height: 'auto',
                  }}
                />
              </a>
            </Box>
            <Box
              display={'flex'}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                paddingTop: isSmallScreen ? '25px' : '40px',
                gap: 1,
              }}
            >
              <Grid item sm={12} md={4}>
                <Box
                  className={classes.footerLinkText}
                  display={'flex'}
                  flexWrap={'wrap'}
                  maxWidth={'300px'}
                >
                  {/* <Box
                    className={classes.footerLinkTextOption}
                    aria-label={t('howyousave')}
                  >
                    <Link
                      variant="body2"
                      className={classes.footerLinkText}
                      underline="none"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpenHowToSaveVideoModal(true)
                        gaService.trackEvent(
                          gaService.eventMap.generate_footer_link_event(
                            t('howyousave'),
                          ),
                        )
                      }}
                    >
                      {t('howyousave')}
                    </Link>
                  </Box> */}
                  <Box
                    className={classes.footerLinkTextOption}
                    aria-label={t('oursavingstrategies')}
                  >
                    <OurSavingsStrategiesLink />
                  </Box>
                  <Box
                    className={classes.footerLinkTextOption}
                    aria-label={t('privacypolicy')}
                  >
                    <PrivacyOrTermsLink
                      type="privacy"
                      customSx={{ fontSize: '18px' }}
                    />
                  </Box>
                  <Box
                    className={classes.footerLinkTextOption}
                    aria-label={t('termsofuse')}
                  >
                    <PrivacyOrTermsLink
                      type="terms"
                      customSx={{ fontSize: '18px' }}
                    />
                  </Box>
                  <Box
                    className={classes.footerLinkTextOption}
                    sx={{ marginTop: '-10px' }}
                  >
                    <DisclaimerLink />
                  </Box>
                  <Box
                    className={classes.footerLinkTextOption}
                    sx={{ marginTop: '-15px' }}
                  >
                    <CernerLinkWithDialog
                      cernerDialogType="userAgreement"
                      customLinkSx={{
                        fontSize: '1.125rem',
                        textDecoration: 'none',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sm={12}
                md={4}
                className={classes.footerSecondGridItem}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.footerBoldText}
                      width={'19rem'}
                      aria-label={t('contactquestion') + t('contactparagraph')}
                      tab-index={0}
                    >
                      {t('contactquestion')}
                      {'\n'}
                      {t('contactparagraph')}
                      {'\n\n'}
                    </Typography>
                    <Typography
                      className={classes.footerBoldText}
                      width={'19rem'}
                      component="div"
                    >
                      {t('contactemailopt')}
                      {'\n'}
                      <Typography
                        aria-label={
                          t('contactemailopt') +
                          PORTAL_APP_CONFIG.MEMBER_SUPPORT_EMAIL
                        }
                      >
                        <a
                          href={
                            'mailto:' + PORTAL_APP_CONFIG.MEMBER_SUPPORT_EMAIL
                          }
                          className={classes.atag}
                          id="emailcontact"
                        >
                          {PORTAL_APP_CONFIG.MEMBER_SUPPORT_EMAIL}
                        </a>
                      </Typography>
                    </Typography>
                    <Typography
                      className={classes.footerBoldText}
                      width={'19rem'}
                    >
                      {'\n'}
                      {t('contactphoneopt')}
                      {'\n'}
                      <label
                        aria-label={
                          t('contactphoneopt') +
                          PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER
                        }
                      >
                        <a
                          href={
                            'tel:' + PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER
                          }
                          className={classes.atag}
                        >
                          {PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER}
                        </a>
                      </label>{' '}
                      | M-F 9a-5p EST
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isSmallScreen && (
                <Box sx={{}}>
                  <MobileAppLinks />
                </Box>
              )}
              <Grid
                item
                sm={12}
                className={classes.copyrightSection}
                width={'100%'}
                sx={{ marginTop: '30px' }}
              >
                <Typography
                  sx={{
                    paddingTop: '20px',
                    fontSize: '0.75rem',
                  }}
                >
                  {t('copyright', { currentYear: getCurrentYear() })}
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Box>
      </div>
      <Modal
        open={openHowToSaveVideoModal}
        onClose={() => {
          setOpenHowToSaveVideoModal(false)
        }}
        aria-labelledby="howtosave-video"
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          width={isSmallScreen ? '330px' : '750px'}
          height="auto"
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <video
            width={isSmallScreen ? '330px' : '750px'}
            height="auto"
            preload="metadata"
            controls
          >
            <source src={t('howToSaveWithScriptaVideoUrl')} type="video/mp4" />
            {t('browserNotSupportVideoTag')}
          </video>
        </Box>
      </Modal>
    </Box>
  )
}
