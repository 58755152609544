import { Grid, List, ListItem, ListItemText } from '@mui/material'
import headerImage from './images/switch_pharmacy_illustration.svg'
import { SPACING } from './theme2'
import STypography from './customcomponents/STypography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useSColors } from '../styles/scripta-theme'

export default function HowToSwitchPharmacy() {
  const { COLORS } = useSColors()
  const { t } = useSTranslate('howToSwitchPharmacy')

  const titleText = t('titleText')
  const steps = [t('step1'), t('step2'), t('step3'), t('step4')]

  return (
    <Grid container gap={SPACING._1} direction={'column'} alignItems={'center'}>
      <Grid item>
        <img src={headerImage} alt="Switch Pharmacy" height="176px" />
      </Grid>
      <Grid item>
        <STypography variant="title3_bold" sx={{ color: COLORS.PRIMARY_BRAND }}>
          {titleText}
        </STypography>
      </Grid>
      <Grid item>
        <List
          component="ol"
          disablePadding
          sx={{
            listStyle: 'decimal',
            fontFamily: 'Proxima Nova',
            paddingLeft: 3,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
          }}
        >
          {steps.map((description, index) => (
            <ListItem
              key={description}
              disablePadding
              sx={{ display: 'list-item' }}
            >
              <ListItemText primary={`${description}`} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}
