import {
  useSTranlateForObjects_waitUntilReady,
  useSTranslate,
} from 'src/app/hooks/useSTranslate'
import { NewPortalDialogDesign } from './NewDialogs'
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { MyDependentsManagementContainer } from '../member/MyDependentsManagement'
import {
  MemberDependents,
  RegistrationSetupData,
  isValidResponse,
} from 'src/types'
import { useMemberService } from '../member/useMemberService'
import { useMemberAppContext } from '../MemberAppContext'
import { LOG } from '../v2/applog'
import { useSColors } from '../styles/scripta-theme'

//this is the dialog shown when the prereg user first tries to access the app
export function PreregFirstTimeAccessWelcomeDialog({
  onClose,
}: {
  onClose: () => void
}) {
  const { COLORS } = useSColors()
  const { t, ready } = useSTranlateForObjects_waitUntilReady(['prereg'])
  const { t: commonT } = useSTranslate(['common'])

  const service = useMemberService()
  const { jwtDetails } = useMemberAppContext()

  //need to show a loading screen until we know if there are deps or not
  const [isLoadingDeps, setIsLoadingDeps] = useState(true)

  //need to embed/include here under next deps modal (if any)
  const [hasDeps, setHasDeps] = useState(false)
  //we want to show deps if any when user clicks next in this dialog
  const [renderDepsDialog, setRenderDepsDialog] = useState(false)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const paras: string[] = t('yourAccountParagraphs', {
    returnObjects: true,
  }) as string[]

  async function loadDepsAsync() {
    try {
      setIsLoadingDeps(true)
      const regSetup: Partial<RegistrationSetupData> = {
        memberId: jwtDetails?.id_key as any,
        tenantId: jwtDetails?.tenant_key as any,
      }

      const s = await service.getMemberDepsApi(regSetup)
      if (isValidResponse<MemberDependents>(s)) {
        setIsLoadingDeps(false)
        if (s && s.dependents && s.dependents.length > 0) {
          setHasDeps(true)
        }
        // setDeps(s)
      } else {
        LOG.error(
          'dependents',
          'Error loading dependents as part of the prereg first time access dialog',
          s,
        )
      }
      setIsLoadingDeps(false)
    } catch (e) {
      setIsLoadingDeps(false)
      LOG.error('dependents', 'Error cought loading dependents', e)
    }
  }

  useEffect(() => {
    if (jwtDetails) {
      loadDepsAsync()
    } else {
      setIsLoadingDeps(false)
    }
  }, [jwtDetails])

  const handleButtonClick = useCallback(() => {
    if (hasDeps) {
      setRenderDepsDialog(true)
    } else {
      onClose()
    }
  }, [hasDeps, onClose])

  if (!ready) return null

  return (
    <NewPortalDialogDesign
      title={isLoadingDeps ? '' : t('yourAccountIsReady')}
      open={true}
      upperRightX={false}
      onClose={handleButtonClick}
      customTitleSx={{ color: COLORS.TEXT_BLACK, paddingTop: '30px' }}
      singleActionProps={
        isLoadingDeps
          ? undefined
          : {
              text: hasDeps ? commonT('next') : commonT('close'),
              onClick: handleButtonClick,
              buttonSx: {
                width: '100px',
              },
            }
      }
    >
      {isLoadingDeps && (
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: '1rem', textAlign: 'center', padding: '20px' }}
            >
              Checking for dependents...
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isLoadingDeps && (
        <>
          <Grid container>
            {paras.map((para: string, idx: number) => {
              return (
                <Grid item xs={12} key={idx}>
                  <Typography
                    sx={{
                      fontSize: '1rem',
                      lineHeight: '20px',
                      textAlign: 'center',
                      paddingTop: isSmall
                        ? idx === 0
                          ? '10px'
                          : '20px'
                        : '20px',
                      paddingLeft: isSmall ? '0px' : '30px',
                      paddingRight: isSmall ? '0px' : '30px',
                    }}
                  >
                    {para}
                  </Typography>
                </Grid>
              )
            })}
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '20px' }}>
            <hr />
          </Grid>
        </>
      )}
      {renderDepsDialog && (
        <MyDependentsManagementContainer
          renderAsRegistrationDialogIfHasDeps={true}
          onDialogClose={() => {
            onClose()
          }}
        />
      )}
    </NewPortalDialogDesign>
  )
}
