import { Box, Grid, Icon, Link, List, ListItem } from '@mui/material'
import {
  SDialog,
  SDialogOverflowHeaderImage,
  SDialogProps,
} from '../customcomponents/SDialog'
import dedIconSrc from '../images/ded_icon.svg'
import pharmacySwitchIconSrc from '../images/pharmacy_switch_icon.svg'
import daysSupplyIconSrc from '../images/days_supply_cal_icon.svg'
import daysSupplyIconBlueSrc from '../images/days_supply_cal_icon_blue.svg'
import STypography from '../customcomponents/STypography'
import { SPACING } from '../theme2'
import { ReactElement, useState } from 'react'
import { PharmacyType } from 'src/app/v2/saving-utils'
import { useSColors } from 'src/app/styles/scripta-theme'
import cashPaymentIconSrc from '../images/cash_payment2.svg'
import { ThemedSVGRemote } from 'src/app/v2/ThemedSVGRemote'
import { STrans, useSTranslate } from 'src/app/hooks/useSTranslate'
import SButton from 'src/app/system/customcomponents/SButton'

export function ModalWhatIsDed({ open, onClose }: SDialogProps) {
  const { t } = useSTranslate('newComponents')
  const { COLORS } = useSColors()

  return (
    <SDialog open={open} onClose={onClose}>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={SPACING._025}
      >
        <Grid item>
          <ThemedSVGRemote
            importUrl={dedIconSrc}
            width={72}
            height={72}
            alt="deductible"
          />
        </Grid>
        <Grid item>
          <STypography
            variant="title3_bold"
            sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
          >
            {t('whatIsADeductible')}
          </STypography>
        </Grid>
        <Grid item>
          <STypography variant="body_regular">
            {t('whatIsADeductibleDescription')}
          </STypography>
        </Grid>
      </Grid>
    </SDialog>
  )
}

export function ModalCashAndInsurance({ open, onClose }: SDialogProps) {
  const { t } = useSTranslate('newComponents')
  const { COLORS } = useSColors()

  return (
    <SDialog open={open} onClose={onClose}>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={SPACING._025}
      >
        <Grid item>
          {/* <CashIcon width={72} /> */}
          <ThemedSVGRemote
            importUrl={cashPaymentIconSrc}
            width={72}
            height={72}
            alt="cash payment"
          />
        </Grid>
        <Grid item>
          <STypography
            variant="title3_bold"
            sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
          >
            {t('cashAndInsurancePrice')}
          </STypography>
        </Grid>
        <Grid item>
          <STypography variant="body_regular">
            {t('cashAndInsurancePriceDescription1')}
            <br />
            <br />
            {t('cashAndInsurancePriceDescription2')}
          </STypography>
          <STypography variant="bodysmall_regular">
            <br />
            <br />
            {t('cashAndInsurancePriceDescription3')}
          </STypography>
        </Grid>
      </Grid>
    </SDialog>
  )
}

export function ModalTypesOfPharmacy({ open, onClose }: SDialogProps) {
  const { t } = useSTranslate('newComponents')
  const { COLORS } = useSColors()

  return (
    <SDialog open={open} onClose={onClose}>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={SPACING._025}
      >
        <Grid item>
          <ThemedSVGRemote
            importUrl={pharmacySwitchIconSrc}
            width={72}
            height={72}
            alt="pharmacy switch"
          />
        </Grid>
        <Grid item>
          <STypography
            variant="title3_bold"
            sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
          >
            {t('typesOfPharmacies')}
          </STypography>
        </Grid>
        <Grid item>
          <List
            component="ul"
            disablePadding
            sx={{
              listStyle: 'disc',
              fontFamily: 'Proxima Nova',
              paddingLeft: 3,
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '44px',
            }}
          >
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              <STypography variant="body_regular">
                <STrans
                  namespace="newComponents"
                  i18nKey={'typesOfPharmacies1'}
                />
              </STypography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              <STypography variant="body_regular">
                <STrans
                  namespace="newComponents"
                  i18nKey={'typesOfPharmacies2'}
                />
              </STypography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              <STypography variant="body_regular">
                <STrans
                  namespace="newComponents"
                  i18nKey={'typesOfPharmacies3'}
                />
              </STypography>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'list-item' }}>
              <STypography variant="body_regular">
                <STrans
                  namespace="newComponents"
                  i18nKey={'typesOfPharmacies4'}
                />
              </STypography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </SDialog>
  )
}

export function ModalPharmacyChange({ open, onClose }: SDialogProps) {
  const [isTypesOfPharmacyOpen, setIsTypesOfPharmacyOpen] = useState(false)
  const { t } = useSTranslate('newComponents')
  const { COLORS } = useSColors()

  return (
    <>
      <ModalTypesOfPharmacy
        open={isTypesOfPharmacyOpen}
        onClose={() => {
          setIsTypesOfPharmacyOpen(false)
        }}
      />
      <SDialog open={open} onClose={onClose}>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={SPACING._025}
        >
          <Grid item>
            <ThemedSVGRemote
              importUrl={pharmacySwitchIconSrc}
              width={72}
              height={72}
              alt="pharmacy switch"
            />
          </Grid>
          <Grid item>
            <STypography
              variant="title3_bold"
              sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
            >
              {t('pharmacyChange')}
            </STypography>
          </Grid>
          <Grid item>
            <STypography variant="body_regular">
              {t('pharmacyChangeDescription')}
            </STypography>
          </Grid>
          <Grid item>
            <STypography variant="body_semibold">
              <Link
                sx={{
                  color: COLORS.TEXT_BRAND,
                  fontWeight: 600,
                  cursor: 'pointer',
                  lineHeight: '24px',
                }}
                onClick={() => {
                  setIsTypesOfPharmacyOpen(true)
                }}
              >
                {t('pharmacyChangeLearnMore')}
              </Link>
            </STypography>
          </Grid>
        </Grid>
      </SDialog>
    </>
  )
}

export function ModalPharmacyStay({ open, onClose }: SDialogProps) {
  const [isTypesOfPharmacyOpen, setIsTypesOfPharmacyOpen] = useState(false)
  const { t } = useSTranslate('newComponents')
  const { COLORS } = useSColors()

  return (
    <>
      <ModalTypesOfPharmacy
        open={isTypesOfPharmacyOpen}
        onClose={() => {
          setIsTypesOfPharmacyOpen(false)
        }}
      />
      <SDialog open={open} onClose={onClose}>
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={SPACING._025}
        >
          <Grid item>
            <ThemedSVGRemote
              importUrl={pharmacySwitchIconSrc}
              width={72}
              height={72}
              alt="pharmacy switch"
            />
          </Grid>
          <Grid item>
            <STypography
              variant="title3_bold"
              sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
            >
              {t('pharmacyStay')}
            </STypography>
          </Grid>
          <Grid item>
            <STypography variant="body_regular">
              {t('pharmacyStayDescription')}
            </STypography>
          </Grid>
        </Grid>
      </SDialog>
    </>
  )
}

export interface ModalPharmacyTypeInfoProps extends SDialogProps {
  type?: PharmacyType
  is90DaysSupply?: boolean
  isMailOrder?: boolean
  isCashPharmacy?: boolean
  renderContentOnly?: boolean
}

export function ModalPharmacyTypeInfo({
  open,
  onClose,
  type,
  is90DaysSupply = false,
  isMailOrder = false,
  isCashPharmacy = false,
  renderContentOnly = false,
}: ModalPharmacyTypeInfoProps) {
  const { t } = useSTranslate('newComponents')
  // const pharmacySwitchIcon = <PharmacySwitchIcon width={72} />
  const cashPharmaycIcon = (
    <ThemedSVGRemote
      importUrl={cashPaymentIconSrc}
      width={72}
      height={72}
      alt="cash payment"
    />
  )

  const pharmacySwitchIcon = (
    <ThemedSVGRemote
      importUrl={pharmacySwitchIconSrc}
      width={72}
      height={72}
      alt="cash payment"
    />
  )
  // const daysSupplyIcon = <DaysSupplyIcon width={72} />
  const daysSupplyIcon = (
    <ThemedSVGRemote
      importUrl={daysSupplyIconSrc}
      width={72}
      height={72}
      alt="days supply"
    />
  )

  let title = ''
  let description = ''

  let titleMailOrder = t('mailOrderPharmacy')
  let descriptionMailOrder = t('mailOrderPharmacyDescription')

  let titleCashPharmacy = t('cashPricePharmacy')
  let descriptionCashPharmacy = t('cashPharmacyDescription')

  let title90DaysSupply = t('90DaysSupply')
  let description90DaysSupply = t('90DaysSupplyDescription')

  if (type) {
    switch (type) {
      case 'RETAIL':
        title = t('retailPharmacy')
        description = t('retailPharmacyDescription')
        break
      case 'INHOUSE':
        title = t('inHousePharmacy')
        description = t('inHousePharmacyDescription')
        break
      default:
        break
    }
  }

  const modalContent = (
    <Grid container gap={SPACING._1}>
      {type !== undefined && type !== 'PARTNER' ? (
        <IconTitleDescription
          title={title}
          description={description}
          icon={pharmacySwitchIcon}
        />
      ) : null}
      {isMailOrder ? (
        <IconTitleDescription
          title={titleMailOrder}
          description={descriptionMailOrder}
          icon={pharmacySwitchIcon}
        />
      ) : null}
      {isCashPharmacy ? (
        <IconTitleDescription
          title={titleCashPharmacy}
          description={descriptionCashPharmacy}
          icon={cashPharmaycIcon}
        />
      ) : null}
      {is90DaysSupply ? (
        <IconTitleDescription
          title={title90DaysSupply}
          description={description90DaysSupply}
          icon={daysSupplyIcon}
        />
      ) : null}
    </Grid>
  )

  if (renderContentOnly) {
    return modalContent
  }

  return (
    <SDialog open={open} onClose={onClose}>
      {modalContent}
    </SDialog>
  )
}

export const ModalAmazonPharmacy90DaysSupply = ({
  firstName,
  open,
  onClose,
}: {
  firstName?: string
  open: boolean
  onClose: () => void
}) => {
  const firstNameToUse = firstName ? ', ' + firstName : ''

  return (
    <SDialogOverflowHeaderImage
      open={open}
      onClose={onClose}
      headerImage={<img src={daysSupplyIconBlueSrc} height={96} width={109} />}
      headerImageHeight={96}
      headerImageWidth={109}
      headerTitleMarginTop={32}
      headerImageExtraMarginTop={16}
      title={'Hi' + firstNameToUse + ' 👋'}
      customPaperSx={{
        background: 'linear-gradient(180deg, #CADFEF 0%, #FFF 100%)',
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{ marginTop: SPACING._1, textAlign: 'center' }}
      >
        <Grid item>
          <STypography variant="bodybig_semibold" sx={{ color: '#4D4B51' }}>
            You might save even more with a 90-day supply at Amazon Pharmacy.
          </STypography>
        </Grid>
        <Grid item sx={{ width: '100%', marginTop: SPACING._1 }}>
          <SButton variant="contained" onClick={onClose} fullWidth noCaps>
            Okay
          </SButton>
        </Grid>
      </Grid>
    </SDialogOverflowHeaderImage>
  )
}

interface IconTitleDescriptionProps {
  title: string
  description: string
  icon: ReactElement
}

export const IconTitleDescription: React.FC<IconTitleDescriptionProps> = ({
  title,
  description,
  icon,
}) => {
  const { COLORS } = useSColors()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={SPACING._025}
    >
      <Grid item>{icon}</Grid>
      <Grid item>
        <STypography
          variant="title3_bold"
          sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
        >
          {title}
        </STypography>
      </Grid>
      <Grid item>
        <STypography variant="body_regular">{description}</STypography>
      </Grid>
    </Grid>
  )
}
