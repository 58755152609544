import {
  DialogContent,
  DialogActions,
  Dialog,
  Breakpoint,
  Grid,
  IconButton,
  Box,
} from '@mui/material'
import React, { ReactElement, useEffect } from 'react'
import { RADIUS, SPACING } from '../theme2'
import SButton, { SButtonColor } from './SButton'
import STypography, {
  STypographyCombination,
  STypographyVariant,
} from './STypography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useSColors } from 'src/app/styles/scripta-theme'

export function SDialogDualActions({
  children,
  open = true,
  onYes,
  onNo,
  onClose,
  yesText = 'Submit',
  noText = 'Back',
  yesButtonColor,
  noButtonColor,
  yesButtonSx,
  noButtonSx,
  title,
  maxWidth,
}: {
  children: any
  onYes: () => void
  onNo: () => void
  onClose?: () => void
  yesText?: string
  noText?: string
  yesButtonSx?: any
  noButtonSx?: any
  yesButtonColor?: SButtonColor
  noButtonColor?: SButtonColor
  title?: string
  open?: boolean
  maxWidth?: Breakpoint
}) {
  const [isOpen, setIsOpen] = React.useState(open)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <SDialog
      upperRightX={true}
      open={isOpen}
      dualActionProps={{
        onYes,
        onNo,
        yesText,
        noText,
        yesButtonSx,
        noButtonSx,
      }}
      title={title}
      maxWidth={maxWidth}
      yesButtonColor={yesButtonColor}
      noButtonColor={noButtonColor}
      onClose={onClose}
    >
      {children}
    </SDialog>
  )
}

export function SDialogSimpleOk({
  children,
  open = true,
  onOk,
}: {
  children: any
  onOk?: () => void
  open?: boolean
}) {
  const [isOpen, setIsOpen] = React.useState(open)
  useEffect(() => {
    setIsOpen(open)
  }, [open])
  return (
    <SDialog
      upperRightX={true}
      open={isOpen}
      singleActionProps={{
        text: 'OK',
        buttonSx: {
          width: '60px',
        },
        onClick: () => {
          if (onOk) {
            onOk()
          }
        },
      }}
      maxWidth="xs"
    >
      {children}
    </SDialog>
  )
}

export interface SDialogSingleActionProps {
  text?: string
  onClick?: () => void | Promise<void>
  buttonSx?: any
}
export interface SDialogDualButtonActionsProps {
  onYes: () => void | Promise<void>
  onNo: () => void | Promise<void>
  yesText?: string
  noText?: string
  yesButtonSx?: any
  noButtonSx?: any
  yesDisabled?: boolean
}

export function SDialogOverflowHeaderImage({
  children,
  open = true,
  okText,
  onOk,
  headerImage,
  headerImageHeight,
  headerImageWidth,
  headerTitleMarginTop = 0,
  headerImageExtraMarginTop = 0,
  onClose = () => {},
  title,
  customPaperSx = {},
}: {
  children: any
  okText?: string
  onOk?: () => void
  open?: boolean
  headerImage: ReactElement
  headerImageHeight: number
  headerImageWidth: number
  headerTitleMarginTop?: number
  headerImageExtraMarginTop?: number
  title: string
  customPaperSx?: any
  onClose?: () => void
}) {
  const [isOpen, setIsOpen] = React.useState(open)
  useEffect(() => {
    setIsOpen(open)
  }, [open])
  return (
    <SDialog
      upperRightX={false}
      open={isOpen}
      onClose={onClose}
      singleActionProps={
        onOk && okText
          ? {
              text: okText,
              buttonSx: {
                fullWidth: true,
                marginTop: SPACING._1,
                textTransform: 'none',
              },
              onClick: () => {
                if (onOk) {
                  onOk()
                }
              },
            }
          : undefined
      }
      maxWidth="xs"
      headerContent={
        <Box sx={{ textAlign: 'center', position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              top: -headerImageHeight / 2 + headerImageExtraMarginTop + 'px',
              left: `calc(50% - ${headerImageWidth / 2}px)`,
            }}
          >
            {headerImage}
          </Box>
        </Box>
      }
      customPaperSx={{
        overflow: 'visible',
        paddingTop: 0,
        maxHeight: `calc(100% - ${headerImageHeight}px)`,
        ...customPaperSx,
      }}
      customContentSx={{
        marginTop: `${headerImageHeight / 2 + headerTitleMarginTop}px`,
      }}
      title={title}
      titleVariant="title3_bold"
      customTitleSx={{ textAlign: 'center' }}
    >
      {children}
    </SDialog>
  )
}

export interface SDialogProps {
  upperRightX?: boolean
  singleActionProps?: SDialogSingleActionProps
  dualActionProps?: SDialogDualButtonActionsProps
  title?: string | React.ReactElement
  customTitleSx?: any
  open: boolean
  onClose?: () => void
  children?: any
  dividers?: boolean
  customPaperSx?: any
  customContentSx?: any
  maxWidth?: Breakpoint
  yesButtonColor?: SButtonColor
  noButtonColor?: SButtonColor
  headerContent?: ReactElement
  titleVariant?: STypographyCombination
  customXButtonSx?: any
}

export function SDialog({
  //upper right close button
  upperRightX = true,
  singleActionProps,
  dualActionProps,
  title,
  customTitleSx,
  open,
  onClose,
  children,
  dividers = false,
  customPaperSx,
  customContentSx,
  maxWidth,
  yesButtonColor,
  noButtonColor,
  headerContent,
  titleVariant = 'title2_bold',
  customXButtonSx = {},
}: SDialogProps) {
  const { COLORS } = useSColors()

  const [isOpen, setIsOpen] = React.useState(open)
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  function handleClose() {
    // setIsOpen(false)
    if (onClose) onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth={maxWidth ? maxWidth : 'sm'}
      fullWidth={true}
      PaperProps={{
        sx: {
          padding: `${SPACING._2} ${SPACING._1}`,
          paddingBottom: SPACING._2,
          borderRadius: RADIUS.md,
          ...customPaperSx,
        },
      }}
    >
      {upperRightX && (
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: SPACING._1,
            top: SPACING._1,
            padding: '3.5px',
            color: COLORS.TEXT_BLACK,
            ...customXButtonSx,
          }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={{ width: '26px', height: '24px', lineHeight: '16px' }}
          />
        </IconButton>
      )}

      {headerContent && headerContent}

      <DialogContent
        dividers={dividers}
        sx={{
          padding: 0,
          //add some extra space on top if no title to fix X overlap the scroll
          marginTop: !title ? SPACING._1 : undefined,
          ...customContentSx,
        }}
      >
        {title && (
          <STypography variant={titleVariant} sx={{ ...customTitleSx }}>
            {title}
          </STypography>
        )}
        {children}
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        {singleActionProps && (
          <SButton
            sx={{
              ...singleActionProps.buttonSx,
            }}
            onClick={() => {
              if (singleActionProps.onClick) {
                singleActionProps.onClick()
              }
            }}
            fullWidth
            size="small"
          >
            {singleActionProps.text ? singleActionProps.text : 'OK'}
          </SButton>
        )}
        {dualActionProps && (
          <Grid
            container
            direction={'column'}
            gap={SPACING._05}
            paddingTop={SPACING._1}
          >
            <SButton
              sx={{
                ...dualActionProps.yesButtonSx,
              }}
              onClick={() => {
                if (dualActionProps.onYes) {
                  dualActionProps.onYes()
                }
              }}
              disabled={dualActionProps.yesDisabled ? true : false}
              color={yesButtonColor}
            >
              {dualActionProps.yesText ? dualActionProps.yesText : 'Yes'}
            </SButton>
            <SButton
              variant="outlined"
              sx={{
                ...dualActionProps.noButtonSx,
              }}
              onClick={() => {
                if (dualActionProps.onNo) {
                  dualActionProps.onNo()
                }
              }}
              color={noButtonColor}
            >
              {dualActionProps.noText ? dualActionProps.noText : 'Cancel'}
            </SButton>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  )
}
