import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@mui/material'
import { SpeechTooltipWaysToSave } from './SpeechTooltip'
import {
  useSTranlateForObjects_waitUntilReady,
  useSTranslate,
} from 'src/app/hooks/useSTranslate'
import { SavingStrategy } from '../strategies/types'
import { VideoWithPoster } from '../strategies/VideoWithPoster'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'
import CardContainer from './CardContainer'
import STypography from './customcomponents/STypography'
import speachPersonImgSrc from './images/medical_prescription_amico.svg'
import { RADIUS, SPACING } from './theme2'
import { getIconForSavingStrategy } from './utils'
import { LOG } from 'src/app/v2/applog'
import { getScriptaTheme } from 'src/app/styles/theme-config-scripta'
import { useAmazon } from 'src/app/hooks/useAmazon'

export function WaysToSaveSectionTitle({ titleText }: { titleText: string }) {
  let { COLORS } = useSColors()
  const { isAmazon } = useAmazon()
  if (isAmazon) {
    COLORS = getScriptaTheme().customized.colors
  }

  return (
    <CardContainer
      sx={{
        backgroundColor: COLORS.PRIMARY_BRAND,
        padding: `${SPACING._075} ${SPACING._1}`,
        boxShadow: '0px 4px 12px 0px rgba(77, 0, 140, 0.15)',
        // ...sx,
      }}
    >
      <STypography variant="bodybig_bold" sx={{ color: 'white' }}>
        {titleText}
      </STypography>
    </CardContainer>
  )
}

function StrategyDescriptionItem({
  strategy,
  isAccordionMode = false,
}: {
  strategy: SavingStrategy
  isAccordionMode?: boolean
}) {
  let { COLORS } = useSColors()
  const { isAmazon } = useAmazon()
  if (isAmazon) {
    COLORS = getScriptaTheme().customized.colors
  }

  const { t, ready } = useSTranlateForObjects_waitUntilReady('strategies')
  if (!ready) {
    return null
  }
  let strategiesRoot: any = t(strategy, {
    returnObjects: true,
  })

  let strategyConfig = strategiesRoot['scripta']
  if (!strategyConfig) {
    LOG.error(
      'generic_error',
      'strategy config not found for strategy: ',
      strategy,
    )
    return null
  }

  if (isAccordionMode) {
    return (
      <Accordion
        sx={{
          margin: 0,
          marginBottom: 0,
          boxShadow: '0 0 0 1px lightgrey',
        }}
        style={{ margin: 0 }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            spacing={SPACING._1}
            alignItems="center"
          >
            <Grid item>
              <ThemedSVGRemote
                importUrl={getIconForSavingStrategy(strategy)}
                width={48}
                height={48}
                alt={strategy + ' icon'}
                disableTheming={isAmazon}
              />
              {/* <img
                src={getIconForSavingStrategy(strategy)}
                alt={strategy + ' icon'}
                width={48}
              /> */}
            </Grid>
            <Grid item>
              <STypography
                variant="bodybig_bold"
                sx={{ color: COLORS.PRIMARY_BRAND }}
              >
                {/* UI only override to display Pharmacy Change as label */}
                {strategy}
              </STypography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction={'column'}
            alignItems={'center'}
            sx={{ marginTop: '-10px' }}
          >
            <Grid item sx={{ textAlign: 'left' }}>
              <STypography variant="body_regular">
                {strategyConfig && strategyConfig.description}
              </STypography>
            </Grid>
            {strategyConfig && strategyConfig.video ? (
              <Grid
                item
                sx={{
                  borderRadius: RADIUS.sm,
                  overflow: 'hidden',
                  marginTop: SPACING._1,
                }}
                style={{ maxWidth: '413px', width: '100%' }}
              >
                <VideoWithPoster
                  isSmallScreen={true}
                  video={strategyConfig.video}
                  title={strategy}
                />
              </Grid>
            ) : null}
          </Grid>
        </AccordionDetails>
      </Accordion>
    )
  }

  if (strategyConfig !== undefined) {
    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        spacing={SPACING._1}
        alignItems="center"
      >
        <Grid item>
          <ThemedSVGRemote
            importUrl={getIconForSavingStrategy(strategy)}
            width={72}
            height={72}
            alt={strategy + ' icon'}
            disableTheming={isAmazon}
          />
          {/* <img
          src={getIconForSavingStrategy(strategy)}
          alt={strategy + ' icon'}
          width={72}
        /> */}
        </Grid>
        <Grid item>
          <STypography
            variant="body_semibold"
            sx={{ color: COLORS.PRIMARY_BRAND }}
          >
            {/* UI only override to display Pharmacy Change as label */}
            {strategy}
          </STypography>
          <STypography variant="bodysmall_regular">
            {strategyConfig && strategyConfig.description}
          </STypography>
        </Grid>
      </Grid>
    )
  } else return null
}

export interface WaysToSaveProps {
  variant?: 'default' | 'stayOnMedOnly' | 'provenAltOnly'
  isAccordionMode?: boolean
}

export default function WaysToSave({
  variant = 'default',
  isAccordionMode = false,
}: WaysToSaveProps) {
  const { isAmazon } = useAmazon()

  const STAY_ON_MED_STRATEGIES = [
    SavingStrategy.$4$9Program,
    SavingStrategy.Coupons,
    SavingStrategy.FormSwitch,
    SavingStrategy.FrequencyChange,
    SavingStrategy.MultiPill,
    SavingStrategy.PillSplit,
    SavingStrategy.PharmacySwitch,
  ]

  const PROVEN_ALT_STRATEGIES = [
    SavingStrategy.AlternateGeneric,
    SavingStrategy.Generic,
    SavingStrategy.NewBrand,
    SavingStrategy.OTC,
    SavingStrategy.Related,
    SavingStrategy.Separate,
  ]

  const { t } = useSTranslate('newComponents')
  const stayOnMedTitleText = t('stayOnMedTitle')
  const stayOnMedDescriptionText = t('stayOnMedDescription')
  const provenAltTitleText = t('provenAltTitle')
  const provenAltDescriptionText = t('provenAltDescription')

  function renderStrategyItems(strategies: SavingStrategy[]) {
    if (isAccordionMode) {
      return (
        <CardContainer
          sx={{ padding: 0, overflow: 'hidden', gap: 0, borderRadius: '16px' }}
        >
          {strategies.map((strategy) => (
            <StrategyDescriptionItem
              key={strategy}
              strategy={strategy}
              isAccordionMode={true}
            />
          ))}
        </CardContainer>
      )
    }

    return strategies.map((strategy) => (
      <Grid item key={strategy}>
        <StrategyDescriptionItem key={strategy} strategy={strategy} />
      </Grid>
    ))
  }

  return (
    <Grid container gap={SPACING._1} direction={'column'}>
      {variant === 'default' ? (
        <Grid item container direction="row" wrap="nowrap" spacing={0}>
          <Grid item>
            <ThemedSVGRemote
              importUrl={speachPersonImgSrc}
              width={72}
              height={153}
              alt={'Learn more'}
              disableTheming={isAmazon}
            />
          </Grid>
          <Grid item>
            <SpeechTooltipWaysToSave disableTheming={isAmazon} />
          </Grid>
        </Grid>
      ) : null}

      {variant !== 'provenAltOnly' ? (
        <>
          <Grid item sx={{ width: '100%' }}>
            <WaysToSaveSectionTitle titleText={stayOnMedTitleText} />
          </Grid>

          <Grid item>
            <STypography variant="body_regular">
              {stayOnMedDescriptionText}
            </STypography>
          </Grid>

          {renderStrategyItems(STAY_ON_MED_STRATEGIES)}
        </>
      ) : null}

      {variant !== 'stayOnMedOnly' ? (
        <>
          <Grid item sx={{ width: '100%' }}>
            <WaysToSaveSectionTitle titleText={provenAltTitleText} />
          </Grid>

          <Grid item>
            <STypography variant="body_regular">
              {provenAltDescriptionText}
            </STypography>
          </Grid>

          {renderStrategyItems(PROVEN_ALT_STRATEGIES)}
        </>
      ) : null}
    </Grid>
  )
}
