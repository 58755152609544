import {
  faMagnifyingGlass,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from '@mui/material'
import { useEffect } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useMemberAppContext } from '../MemberAppContext'
import { useSColors } from '../styles/scripta-theme'
import CardContainer from '../system/CardContainer'
import STypography from '../system/customcomponents/STypography'
import InfoCard from '../system/InfoCard'
import { NavBar } from '../system/NavBar'
import { SPACING } from '../system/theme2'
// import noSavingsImage from './../system/images/savings_report.svg'
import noSavingsImageSrc from './../system/images/savings_report.svg'
import {
  HeroGreeting,
  ROUTE_LEARN_ABOUT_WAYS_TO_SAVE,
  ROUTE_NEW_SEARCH,
} from './NewMemberPortalMain'
import { BackgroundContainer } from './NewPageContainer'
import { useSizes } from './useNewHooks'
import { ThemedSVGRemote } from './ThemedSVGRemote'
import OnboardingContainer from 'src/app/system/onboarding/OnboardingContainer'
import { useAmazon } from 'src/app/hooks/useAmazon'

interface CustomAccordionProps {
  title: string
  content: React.ReactNode
}

function CustomAccordion(props: CustomAccordionProps) {
  const { title, content } = props
  const { COLORS } = useSColors()
  return (
    <Accordion
      sx={{
        margin: 0,
        marginBottom: 0,
        boxShadow: 'none',
        '&.Mui-expanded': {
          boxShadow: `0 0 0 1px ${COLORS.BORDER_GREY_LIGHT}`,
        },
      }}
      style={{ margin: 0 }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          spacing={SPACING._1}
          alignItems="center"
        >
          <Grid item>
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Grid>
          <Grid item>
            <STypography
              variant="body_regular"
              sx={{ color: COLORS.TEXT_GREY_DARK }}
            >
              {title}
            </STypography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  )
}

//putting this to its own page /no_savings
//so that we can get a simple analytics how many users are getting such screens
//it means a bit copy paste from the standard main page... (i.e. topbar,hero greeting etc...)
export function NoSavingPage() {
  const { isMobile } = useSizes()
  const { isAmazon } = useAmazon()
  const { history } = useMemberAppContext()
  const { t } = useSTranslate('savingReport')
  const { COLORS } = useSColors()
  const BODY_TEXT_COLOR = COLORS.TEXT_GREY_DARK
  const { currentMemberProfile, savingReport } = useMemberAppContext()

  useEffect(() => {
    document.title = t('noSavingsPageTitle')
  }, [t])

  return (
    <Grid container>
      {isMobile && (
        <Grid item xs={12}>
          <NavBar variant="logo_purple" backButtonHidden />
        </Grid>
      )}

      <BackgroundContainer
        variant={isMobile ? 'purple_curved_elipsis' : 'clear_white'}
      >
        <Grid container gap={SPACING._1}>
          <Grid item xs={12}>
            <HeroGreeting
              userFirstName={currentMemberProfile?.firstName}
              savingReport={savingReport}
              isLoadingMainReport={savingReport === undefined ? true : false}
              isNoSavingsMode={true}
            />
          </Grid>

          <Grid item xs={12} container justifyContent={'center'}>
            <OnboardingContainer />
          </Grid>

          {/* <Grid item xs={12}>
            <STypography
              variant="body_semibold"
              sx={{
                color: COLORS.TEXT_GREY_MEDIUM,
                textAlign: isMobile ? 'center' : undefined,
              }}
            >
              {t('zeroSavingsIntro')}
            </STypography>
          </Grid> */}
          <Grid item xs={12} sx={{ marginBottom: SPACING._05 }}>
            <InfoCard
              onClick={() => {
                history.push(ROUTE_NEW_SEARCH)
              }}
              icon={faMagnifyingGlass}
              title={t('noSavingsWhileHere')}
              body={t('noSavingsFindCheaper')}
              variant="navigation_primary"
            />
          </Grid>
          <CardContainer
            sx={{
              padding: 0,
              marginBottom: SPACING._1,
              overflow: 'hidden',
              gap: 0,
              borderRadius: '16px',
            }}
          >
            <CustomAccordion
              title={t('zeroSavingsTitle')}
              content={
                <STypography
                  variant="bodysmall_regular"
                  sx={{
                    color: BODY_TEXT_COLOR,
                  }}
                >
                  {t('zeroSavingsPartOne')}
                </STypography>
              }
            />
            <CustomAccordion
              title={t('zeroSavingsTitleTwo')}
              content={
                <STypography
                  variant="bodysmall_regular"
                  sx={{
                    color: BODY_TEXT_COLOR,
                  }}
                >
                  {t('zeroSavingsPartTwo')}
                </STypography>
              }
            />
            <CustomAccordion
              title={t('zeroSavingsTitlethree')}
              content={
                <Box display="flex" flexDirection={'column'} gap={SPACING._05}>
                  <STypography
                    variant="bodysmall_regular"
                    sx={{
                      color: BODY_TEXT_COLOR,
                    }}
                  >
                    {t('zeroSavingsPartthree')}
                  </STypography>
                  <STypography
                    variant="bodysmall_regular"
                    sx={{
                      color: BODY_TEXT_COLOR,
                    }}
                  >
                    {t('zeroSavingsPartFour')}
                  </STypography>
                  <STypography
                    variant="bodysmall_regular"
                    sx={{
                      color: BODY_TEXT_COLOR,
                    }}
                  >
                    {isAmazon
                      ? t('contactMemberSupportOrChatUsingPortal_AZ')
                      : t('contactMemberSupportOrChatUsingPortal')}
                  </STypography>
                </Box>
              }
            />
          </CardContainer>
        </Grid>
      </BackgroundContainer>
    </Grid>
  )
}
