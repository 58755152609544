import { Button, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { NewPortalDialogDesign } from 'src/app/application/NewDialogs'
import { useFooterStyles } from 'src/app/application/PortalFooter'
import { GAService } from 'src/app/application/ga/GAService'
import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
export const useDisclaimerStyles = makeStyles((theme: Theme) => {
  return createStyles({
    dislaimerLink: {
      //   ...baseLink,
    },
  })
})

export function DisclaimerLink() {
  const gaService = new GAService()
  const { t } = useSTranslate('savingReport')

  const [showDialog, setShowDialog] = useState(false)
  const classes = useDisclaimerStyles()
  const footerClasses = useFooterStyles()

  return (
    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
      <Button
        className={classes.dislaimerLink}
        color={'secondary'}
        onClick={(e: any) => {
          setShowDialog(true)
          gaService.trackEvent(
            gaService.eventMap.generate_footer_link_event(t('disclaimer')),
          )
        }}
        sx={{
          padding: 0,
          textAlign: 'left',
          textTransform: 'none',
        }}
      >
        <Typography className={footerClasses.footerLinkText}>
          {t('disclaimer')}
        </Typography>
      </Button>
      <NewPortalDialogDesign
        open={showDialog}
        onClose={() => {
          setShowDialog(false)
        }}
        title={t('disclaimer')}
      >
        <DisclaimerContent />
      </NewPortalDialogDesign>
    </div>
  )
}
export function DisclaimerContent() {
  const { t } = useSTranslate('savingReport')
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="caption">{t('disclaimerString0')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{t('disclaimerString1')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{t('disclaimerString2')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption">{t('disclaimerString3')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption">{t('disclaimerString4')}</Typography>
      </Grid>
    </Grid>
  )
}
