import {
  AlternativeDrugsResponse,
  MemberPrescription,
  MemberPrescriptionAlternative,
  MemberPrescriptionSaving,
  MemberSavingReport,
  SearchResultsAlternative,
} from '../../types'
import { toRoundedNumber } from '../apiexec/utils'
import { SeparatesParam } from '../drugprofile/types'
import {
  DrugPriceInfo,
  OriginalPricingResp,
} from '../search/useDrugSearchService'
import { SavingStrategy } from '../strategies/types'
import { DrugListItemProps } from '../system/ListItems'
import { PriceElementType } from '../system/PriceElement'
import {
  RxCompareDrugPriceDataModel,
  RxCompareProps,
} from '../system/RxCompare'
import { LOG } from './applog'
import { AdditionalPharmacyDetailsByNpiLookupResp } from '../member/useMemberService'
import {
  isProvenAlternativeGroup,
  isSameMedicineGroup,
} from 'src/app/nodom-utils'

export interface SavingOpportunityDataModel {
  drugId: string | number
  drugName: string
  drugDosage: string
  drugForm: string
  drugCount: string
  upToAmount: string // i.e. $34.69/mo
  hasCouponsOnOriginal: boolean //if true, show "coupons available" tag
  priceBeforeDed?: string
  priceAfterDed?: string
  priceCopay?: string //i.e. $8.30
  isBookmarked: boolean
}
export type WayToSaveType = 'coupon' | 'basic_strategy' | 'pharmacy_change'

export type PaymentType = 'cash' | 'insurance'

// export type DaysSupplyType = '30' | '60' | '90' | '180' | number

export type PharmacyType = 'PARTNER' | 'INHOUSE' | 'RETAIL'

export interface PharmacyInfo {
  type: PharmacyType
  logoUrl?: string
  name?: string
  mailOrderRedirectUrl?: string
  physicalAddress?: string
  phoneNumber?: string
  distance?: string
  isMailOrder?: boolean
  isOnline?: boolean
  isCash: boolean
  pharmacyNpi?: string
}

//main model in the UI for a wat to save
//we take in the backend model structure and flatted it to this view, adding some UI-only flags (i.e new_med vs same_med etc)
export interface WayToSaveItem {
  //push this thru via item, so we always have easy access to what plan it is
  isCopay: boolean

  //new ui only grouping category based on saving strategy (i.e pill split = same med, alternate generic = new med)
  sameOrNewMedChoice: SameOrNewMedChoiceType

  //can be basic strategy, coupon or pharmacy change
  type: WayToSaveType
  paymentType: PaymentType
  //removing days supply from UI untill ready with 90 days supply
  // daysSupply?: number

  pharmacyInfo?: PharmacyInfo

  //do we need current pharmacy type? (i.e. in case we want to say "Stay on your current InHouse Pharmacy")
  currentPharmacyType?: PharmacyType

  originalId: number
  originalName: string
  originalDosage: string
  originalForm: string
  originalCount: string
  originalPriceCopay?: string
  originalPriceBeforeDed?: string
  originalPriceAfterDed?: string
  //TODO - is there a cash price on original? doesnt make much sense

  altId: number
  altName: string
  altDosage: string
  altForm: string
  //number is used when fetching coupons
  altCountNumber?: number
  altCountQtcStr: string

  //some of these are undefined based on type of saving item
  altPriceCopay?: string
  altPriceBeforeDed?: string
  altPriceAfterDed?: string

  //cannot have a dedicated coupon price - we need the exact one they pick from a coupon
  // altPriceCashCoupon?: string

  //for pharmacy yes in case of cash pharmacies
  altPriceCashPharmacy?: string

  savingStrategy: SavingStrategy

  //this is the computed exact value used in the UI to sort etc
  //this is because backend model uses different values i.e. cash, copay vs pre/post ded etc
  saveAmountResolved: number

  //needed for help me swtich
  altMmaIdForHelpMeSwitch?: number
  //new identifier for separates -used later to group
  separateIdentifier?: string
  //need simpler name (without form) for display when mergin separates
  altNameToUseWhenMerginSeparates: string
  //need single place to tracke all related separate drug ids / names for a group
  separatesParams?: SeparatesParam[]
}

//top element set in context either in saving opportunity list based on report
//or from search based on original medicine + search results alternatives
export interface SavingGuideData {
  selectedOpportunity: SavingOpportunityDataModel
  waysToSaveForOpportunity: WayToSaveItem[]
  source: 'saving_report' | 'search_results'
  //if coming from coupon view, we auto select the clicked coupon
  autoSelectCouponItem?: WayToSaveItem
}

export const DEFAULT_QUANITYT_FOR_COUPONS_FETCH_IF_NOT_SPECIFIED = 30

//new ui only grouping category based on saving strategy (i.e pill split = same med, alternate generic = new med)
export type SameOrNewMedChoiceType = 'same_med' | 'new_med'

// export type SameOrNewPharmacyChoiceType = 'same_pharmacy' | 'change_pharmacy'

// export type CashBasedFilterChoiceType = 'cash_coupon' | 'cash_pharmacy'

export interface BookmarkedOpportunity {
  //this is the main opportunitiy id for bookmarks
  //meaning they can bookmark one combination per original,
  //next one would replace the old one
  originalDrugId: number | string
  alternativeDrugId: number | string
  mmaIdForHelpMeSwitch: number
  //TODO - do bookmarsk even make sense in search results? we are only showing one "opportunity" at a time (one search -> one original)
  source: 'saving_report' | 'search_results'
  wayToSaveType: WayToSaveType
  selectedCoupon?: CouponDetails
}

export function isBookmarkedOpportunity(
  originalDrugId: string | number,
  bookmarks: BookmarkedOpportunity[],
): boolean {
  return bookmarks.some((item) => {
    return item.originalDrugId == originalDrugId
  })
}

export function sortWaysToSaveByAmount(items: WayToSaveItem[]) {
  let sorted = items.slice().sort((a, b) => {
    let aAmount = a.saveAmountResolved
    let bAmount = b.saveAmountResolved
    return bAmount - aAmount
  })
  return sorted
}

export function getOpportunitiesFromSavingReport(
  savingReport: MemberSavingReport,
  bookmarkedOpportunities: BookmarkedOpportunity[],

  //when in my coupons view, we cannot also process bookmarks - bc they might have bookmarked a pharmacy change
  processBookmarkedOpportunities: boolean,
): SavingOpportunityDataModel[] {
  let items: SavingOpportunityDataModel[] = []

  //get the distinct original drug ids from the list
  if (
    savingReport.memberPrescriptions &&
    savingReport.memberPrescriptions.length > 0
  ) {
    savingReport.memberPrescriptions.forEach((saving) => {
      const originalid = saving.prescription?.drug.id
      if (!originalid) {
        LOG.error(
          'saving_report_data',
          'getSavingOpportunities - originali drug id is missing in report json, will skip this saving',
          // saving,
        )
        return
      }
      if (!saving.alternatives || saving.alternatives.length === 0) {
        LOG.error(
          'saving_report_data',
          'getSavingOpportunities - alternatives are empty for saving=',
          saving,
        )
        return
      }
      //this is a little bit of a trick
      //lets get allthewaytosaveitems for the entire report
      //sort them by amount then get the highest one and use that to create the saving opportunity model
      //TODO - filter by drug name from the prescription instead?
      let allInReport = getAllWaysToSaveForReport(savingReport)
      let waysToSaveForThisOriginal = filterItemsPerOriginal(
        originalid,
        allInReport,
      )

      const highestWayToSaveItem = sortWaysToSaveByAmount(
        waysToSaveForThisOriginal,
      )[0]

      if (!highestWayToSaveItem) {
        LOG.error(
          'saving_report_data',
          'getSavingOpportunities - highestWayToSaveItem is undefined for saving=',
          saving,
        )
        return []
      }

      const anyHasCoupons = waysToSaveForThisOriginal.some((item) => {
        return item.savingStrategy === SavingStrategy.Coupons
      })

      const isBookmarked =
        processBookmarkedOpportunities &&
        isBookmarkedOpportunity(originalid, bookmarkedOpportunities)

      let savingOpportunity: SavingOpportunityDataModel = {
        drugId: highestWayToSaveItem.originalId,
        drugName: highestWayToSaveItem.originalName,
        drugForm: highestWayToSaveItem.originalForm,
        drugDosage: highestWayToSaveItem.originalDosage,
        drugCount: highestWayToSaveItem.originalCount,

        //make sure to pass in undefined for non-applicable prices
        //bc downstream controls use that to decide which price to show
        priceCopay: highestWayToSaveItem.isCopay
          ? `${highestWayToSaveItem.originalPriceCopay}`
          : undefined,
        priceBeforeDed: !highestWayToSaveItem.isCopay
          ? `${highestWayToSaveItem.originalPriceBeforeDed}`
          : undefined,
        priceAfterDed: !highestWayToSaveItem.isCopay
          ? `${highestWayToSaveItem.originalPriceAfterDed}`
          : undefined,

        upToAmount: highestWayToSaveItem.saveAmountResolved
          ? `$${toTwoDecimalNumberStr(
              highestWayToSaveItem.saveAmountResolved,
            )}/mo`
          : 'NA',

        hasCouponsOnOriginal: anyHasCoupons,
        isBookmarked: isBookmarked,
      }

      items.push(savingOpportunity)
    })
  }

  return items
}

export function toTwoDecimalNumberStr(num: number): string {
  //dont blow up fatally if data coming in is not a number
  if (typeof num !== 'number' || isNaN(num)) {
    console.warn(
      'Got a non-number value, unable to perform decimal rounding for value=',
      num + ', will return value as is',
      'error',
    )
    return ''
  }

  if (num === 0) {
    return '0.00'
  }

  //this may cause a merge conflict - it is a safe change, bc we were getting some null values in cash-price-amount
  if (!num) {
    return '0.00'
  }

  return num.toFixed(2)
}

//TODO - getting the original drug id from the prescription
export function getAllWaysToSaveForReport(
  savingReport: MemberSavingReport,
): WayToSaveItem[] {
  const debugMemberId = (savingReport as any).id
  let items: WayToSaveItem[] = []
  let isCopay = savingReport && savingReport.reportType === 'COPAY'
  if (
    savingReport.memberPrescriptions &&
    savingReport.memberPrescriptions.length > 0
  ) {
    savingReport.memberPrescriptions.forEach((saving) => {
      if (!saving.prescription) {
        LOG.error(
          'saving_report_data',
          'getSavingOpportunities - prescription is undefined for saving=',
          saving,
        )
        return
      }
      let originalDrugId = saving.prescription?.drug.id
        ? saving.prescription?.drug.id
        : saving.prescription?.drug.drugIdValidOnlyUnderPrescription
        ? saving.prescription?.drug.drugIdValidOnlyUnderPrescription
        : 0
      const allAlts = saving.alternatives
      allAlts.forEach((alt) => {
        const wayToSaveForAlt = getAllWaysToSaveForAlternative(
          isCopay,
          originalDrugId,
          alt,
          saving.prescription!,
          debugMemberId,
        )

        if (wayToSaveForAlt && wayToSaveForAlt.type === 'coupon') {
          let isAddedBefore =
            isThisSameCouponForDrugBItemAlreadyAddedDueToDifferentPharmacy(
              wayToSaveForAlt,
              items,
            )
          if (!isAddedBefore) {
            items.push(wayToSaveForAlt)
          } else {
          }
        } else {
          if (wayToSaveForAlt) {
            items.push(wayToSaveForAlt)
          }
        }
        //sort by amount though backend should do it
        items = sortWaysToSaveByAmount(items)
      })
    })
  }

  const postProcessedItemsWithGroupedSeparates =
    postProcessSeparatesToSingleWayToSaveItem(items)

  return postProcessedItemsWithGroupedSeparates
}

export function postProcessSeparatesToSingleWayToSaveItem(
  items: WayToSaveItem[],
): WayToSaveItem[] {
  //filter out any separate items by duplicate separate-identifier id
  let filteredItems: WayToSaveItem[] = []
  let separateIds: string[] = []
  items.forEach((item) => {
    if (item.savingStrategy === SavingStrategy.Separate) {
      if (!item.separateIdentifier) {
        LOG.error(
          'saving_report_data',
          'separate item missing expected separate id,skipping',
          item,
        )
        return
      }
      if (separateIds.indexOf(item.separateIdentifier) === -1) {
        //but first update the name and contact all the drug names with this separate identifier
        let sepId = item.separateIdentifier

        //separates
        const separatesParams: SeparatesParam[] = items
          .filter((i) => i.separateIdentifier === sepId)
          .map((i) => {
            return {
              drugId: i.altId,
              displayName: i.altNameToUseWhenMerginSeparates,
            }
          })
        item.separatesParams = separatesParams

        const newDrugName = items
          .filter((i) => i.separateIdentifier === sepId)
          .map((i) => i.altNameToUseWhenMerginSeparates)
          .join(' + ')

        item.altName = newDrugName

        //same for the dosage
        const newDosage = items
          .filter((i) => i.separateIdentifier === sepId)
          .map((i) => i.altDosage)
          .join(' + ')
        item.altDosage = newDosage

        //same for the form
        const newForm = items
          .filter((i) => i.separateIdentifier === sepId)
          .map((i) => i.altForm)
          .join(' + ')
        item.altForm = newForm

        //same for the count
        const newCount = items
          .filter((i) => i.separateIdentifier === sepId)
          .map((i) => i.altCountQtcStr)
          .join(' + ')
        item.altCountQtcStr = newCount

        filteredItems.push(item)

        separateIds.push(sepId)

        return
      } else {
        //
      }
    } else {
      //this is just a standard non-separates item so retrun it
      filteredItems.push(item)
    }
  })
  return filteredItems
}

//utility method to lookup the exact separates drug id

export function getAllWaysToSaveForAlternative(
  isCopay: boolean,
  originalDrugId: number,
  altRef: MemberPrescriptionAlternative,
  prescriptionRef: MemberPrescription,
  debugMemberId: number,
): WayToSaveItem | undefined {
  //TODO - check this in case of cash prices
  let saveAmountResolved = isCopay
    ? altRef.savings
    : altRef.beforeDeductibleSavings

  //TODO - should this be e fatal error?
  //is save amount resolved possible to have 0?
  //we should not be getting/showing anything to user if we dont have a save amount

  if (!saveAmountResolved) {
    console.error('WARNING - UNABLE TO DETERMINE SAVE UP TO AMOUNT TO USE')
    //TODO - disable log of this error for now
    //this is bc in the initial render the isCopy is set to true by default
    //regardless of the members plan
    //another (unrleated) error (i.e. in fetching profile) could set this late on first render
    //TODO - add "dataReady" = undefined initially flag to the context to prevent this
    // LOG.error(
    //   'saving_report_data',
    //   'invalid / missing / zero save amount for alternative=',
    //   altRef,
    // )
    saveAmountResolved = 0
  }

  let sameOrNewMedChoice: SameOrNewMedChoiceType | undefined = undefined
  if (isProvenAlternativeGroup(altRef.strategy as SavingStrategy)) {
    sameOrNewMedChoice = 'new_med'
  } else if (isSameMedicineGroup(altRef.strategy as SavingStrategy)) {
    sameOrNewMedChoice = 'same_med'
  } else {
    //error, cannot proceed to group this search result
    LOG.error(
      'saving_report_data',
      'unable to determine same or new med choice for alt=',
      altRef,
    )
    return undefined
  }

  let isCoupon =
    (altRef.coupon && altRef.coupon === true) ||
    altRef.strategy === SavingStrategy.Coupons
      ? true
      : false

  let isPharmacySwitch =
    (altRef['pharmacy-change'] && altRef['pharmacy-change'] === true) ||
    altRef.strategy === SavingStrategy.PharmacySwitch
      ? true
      : false

  let type: WayToSaveType = 'basic_strategy'
  if (isCoupon) {
    type = 'coupon'
  } else if (isPharmacySwitch) {
    type = 'pharmacy_change'
  }

  let pharmacyInfo: PharmacyInfo | undefined = undefined

  pharmacyInfo = getPharmacyInfoFromInputAlt('report_alt', altRef)

  //in case of coupons, clear out the pharmacy details
  //we need to wait for them to pick the coupon itself which holds pharmacy details
  if (isCoupon) {
    pharmacyInfo = undefined
  }

  let paymentType: PaymentType = 'insurance'
  if (isCoupon) {
    paymentType = 'cash'
  }
  if (isPharmacySwitch && pharmacyInfo && pharmacyInfo.isCash === true) {
    paymentType = 'cash'
  }

  //this is moslty here as a placeholder type so we can refactor/find it later
  //mostly trying to setup very exact case for 90days supply to render custom tags
  //price elements in the pharmacy card
  let daysSupply =
    altRef['day-supply'] && !isNaN(altRef['day-supply'])
      ? altRef['day-supply']
      : undefined
  // let daysSupply: DaysSupplyType | undefined = undefined
  // if (altRef['todo-custom-days-supply']) {
  //   if (altRef['todo-custom-days-supply'] == 30) {
  //     //TODO - for 30 = standard - nothing special about it
  //     daysSupply = '30'
  //   } else if (altRef['todo-custom-days-supply'] == 90) {
  //     daysSupply = '90'
  //   } else if (altRef['todo-custom-days-supply'] == 180) {
  //     daysSupply = '180'
  //   } else {
  //     daysSupply = altRef['todo-custom-days-supply']
  //   }
  // }

  //   if (altRef['pharmacy-type'] && altRef['pharmacy-type'] === 'INHOUSE')
  //     type = 'inhouse_pharmacy'
  //   else if (altRef['pharmacy-type'] && altRef['pharmacy-type'] === 'CASH') {
  //     type = 'online_cash_pharmacy'
  //   } else {
  //     //TODO - this is a temporary fix bc at the moment there is no 'pharmacy-type'
  //     //untill new backend code is deployed
  //     //but for Pharmacy Change on original - it means JPS
  //     if (
  //       altRef.strategy === SavingStrategy.PharmacySwitch &&
  //       altRef['pharmacy-name'] === 'JPS Pharmacy'
  //     ) {
  //       type = 'inhouse_pharmacy'
  //     } else {
  //       //but when will this every be true for pharmacy change
  //       type = 'insurance'
  //     }
  //   }
  // } else {
  //   type = 'insurance'
  // }

  let altMmaIdForHelpMeSwitch = undefined
  if (altRef.drug.mmaIdValidOnlyUnderAlternatives) {
    altMmaIdForHelpMeSwitch = altRef.drug.mmaIdValidOnlyUnderAlternatives
  } else {
    LOG.error(
      'saving_report_data',
      'missing expected mma id under alternative in saving report json',
      // altRef,
    )
  }

  const waytoSave: WayToSaveItem = {
    sameOrNewMedChoice: sameOrNewMedChoice,
    type: type,
    // daysSupply: daysSupply,
    paymentType: paymentType,

    pharmacyInfo: pharmacyInfo,

    isCopay: isCopay,

    originalId: originalDrugId,
    originalName: prescriptionRef?.drug.name ?? '',
    originalDosage: prescriptionRef?.drug.dosage ?? '',
    originalForm: prescriptionRef?.drug.form ?? '',
    originalCount:
      prescriptionRef?.drug.quantity !== undefined
        ? `${prescriptionRef.drug.quantity}ct`
        : '0',
    originalPriceCopay:
      prescriptionRef.avgCopay !== undefined
        ? `$${toTwoDecimalNumberStr(prescriptionRef.avgCopay)}`
        : 'NA',
    originalPriceBeforeDed:
      prescriptionRef?.beforeDeductibleCost !== undefined
        ? `$${toTwoDecimalNumberStr(prescriptionRef?.beforeDeductibleCost)}`
        : 'NA',
    originalPriceAfterDed:
      prescriptionRef?.afterDeductibleCost !== undefined
        ? `$${toTwoDecimalNumberStr(prescriptionRef?.afterDeductibleCost)}`
        : 'NA',

    altId: altRef.drug.id,
    altMmaIdForHelpMeSwitch: altMmaIdForHelpMeSwitch,

    separateIdentifier: altRef['separate-identifier']
      ? altRef['separate-identifier']
      : undefined,

    altNameToUseWhenMerginSeparates: altRef.drug.simpleName
      ? altRef.drug.simpleName
      : altRef.drug.name,

    altName: altRef.drug.name,
    altDosage: altRef.drug.dosage,
    altForm: altRef.drug.form ? altRef.drug.form : 'NA',
    altCountNumber:
      altRef.drug.quantity !== undefined ? altRef.drug.quantity : 0,
    altCountQtcStr:
      altRef.drug.quantity !== undefined ? `${altRef.drug.quantity}ct` : 'NA',

    //make sure to set this as undfined based on plan

    altPriceCopay:
      isCopay !== undefined && altRef.avgCopay !== undefined
        ? `$${toTwoDecimalNumberStr(altRef.avgCopay)}`
        : undefined,
    altPriceBeforeDed:
      !isCopay && altRef.beforeDeductibleCost !== undefined
        ? `$${toTwoDecimalNumberStr(altRef.beforeDeductibleCost)}`
        : undefined,
    altPriceAfterDed:
      !isCopay && altRef.afterDeductibleCost !== undefined
        ? `$${toTwoDecimalNumberStr(altRef.afterDeductibleCost)}`
        : undefined,

    altPriceCashPharmacy:
      (altRef as any)['cash-price-amount'] !== undefined
        ? `$${toTwoDecimalNumberStr((altRef as any)['cash-price-amount'])}`
        : undefined,

    savingStrategy: altRef.strategy as SavingStrategy,

    saveAmountResolved: saveAmountResolved,

    // pharmacyDetails:
    //   getUIPharmacyDetailsModel_SAVING_REPORT(altRef) ?? undefined,
  }

  return waytoSave
}

export function getNewMedTherapeuticAlternatesList(
  allWaysToSave: WayToSaveItem[],
): AlternateDrugModel[] {
  const alts = allWaysToSave.filter((saveItem) => {
    const stratNameCasted: SavingStrategy = saveItem.savingStrategy
    return isProvenAlternativeGroup(stratNameCasted)
  })

  //we want to simplify the list here given to user by drug name
  let distinctAlternativeDrugNames: string[] = Array.from(
    new Set(alts.map((item: WayToSaveItem) => item.altName)),
  )

  let altsModel: AlternateDrugModel[] = []
  distinctAlternativeDrugNames.forEach((altDrugName) => {
    //TODO - this is already being done in the toNewMedicinItem metho?
    const matchingAlternatives = alts.filter(
      (alt) => alt.altName === altDrugName,
    )

    const drugItem = toNewMedicineItem(altDrugName, matchingAlternatives)
    altsModel.push(drugItem)
  })
  return altsModel
}

function toNewMedicineItem(
  newMedicineName: string,
  allWaysToSave: WayToSaveItem[],
): AlternateDrugModel {
  //is this visible in the screens? for the list of alternatvies?
  const TODO_IS_BEST_PRICE = false
  // const allWaysToSave = getWaysToSaveItems(isCopay, savingRef)
  //for this drug
  const filteredByName = allWaysToSave.filter(
    (item) => item.altName === newMedicineName,
  )

  const highestWayToSaveItem =
    filteredByName.length > 0 ? filteredByName[0] : undefined

  // if (highestWayToSaveItem) {
  //   savingAmount =
  //     computeSaveUpToAmount_todo_whentouseafterded(highestWayToSaveItem)
  // }

  let amountStr = getDollarsPerMonthStr(
    highestWayToSaveItem?.saveAmountResolved,
  )

  //TODO - there can be multiple strategies per grouped alternative
  //for up to amount - we should grab the highest one
  let distinctStrategies = Array.from(
    new Set(filteredByName.map((alt) => alt.savingStrategy)),
  )
  const drugItem: AlternateDrugModel = {
    highestSavingAltDrugId: highestWayToSaveItem?.altId ?? 0,
    highestSavingAltMmaId: highestWayToSaveItem?.altMmaIdForHelpMeSwitch ?? 0,
    drugName: newMedicineName,
    isBestPrice: TODO_IS_BEST_PRICE,
    upToAmount: amountStr ? amountStr : 'NA',
    strategies: distinctStrategies,
    separatesParams: highestWayToSaveItem?.separatesParams,
  }

  return drugItem
}
// export function getHighestSavingAmountInList(
//   allWaysToSave: WayToSaveItem[],
//   filterByNewOrStaySameMed: SameOrNewMedChoiceType,
//   filterAdditionallyByPaymentType?: PaymentType,
//   filterAdditionallyByNewMedicationName?: string,
//   filterAdditionallyByStayOrChangePharmacy?:
//     | 'same_pharmacy'
//     | 'change_pharmacy',
//   // saving: MemberPrescriptionSaving,
// ): number | undefined {
//   const filteredWaysToSave = getFilteredItems(
//     allWaysToSave,
//     filterByNewOrStaySameMed,
//     filterAdditionallyByPaymentType,
//     filterAdditionallyByNewMedicationName,
//     filterAdditionallyByStayOrChangePharmacy,
//     // saving,
//   )

//

//   let highestWayToSaveItem =
//     filteredWaysToSave && filteredWaysToSave.length > 0
//       ? filteredWaysToSave[0]
//       : undefined

//   return highestWayToSaveItem
//     ? highestWayToSaveItem.saveAmountResolved
//     : undefined
// }

export function filterItemsPerOriginal(
  originalId: number | string,
  allWaysToSave: WayToSaveItem[],
): WayToSaveItem[] {
  return allWaysToSave.filter((item) => item.originalId == originalId)
}

//this is to take the original drug user searched for and create
//from it the same saving opportunity model as if that searched drug was one of the originals in savings report
//that way we can continue and use the rest of the savings report saving guide flow in search
export function getOpportunityModelFromSearchedOriginalDrug(
  quantity: number,
  upToAmount: string,
  originalDetailsResp: OriginalPricingResp,
  bookmarkedOpportunities: BookmarkedOpportunity[],
): SavingOpportunityDataModel | undefined {
  if (
    !originalDetailsResp['pricing-info'] ||
    originalDetailsResp['pricing-info'].length === 0
  ) {
    LOG.error(
      'search_results_data',
      'getOpportunityFromSearch,missing pricing info for original drug details in search,orginalResp=',
      originalDetailsResp,
    )
    return undefined
  }

  //this is always false as part of saving opportunity on the original
  //- its the one and same search drug
  let searchCouponsOnOriginal = false

  let priceInfo = getDrugInfoDict(originalDetailsResp['pricing-info'])
  let opportunity: SavingOpportunityDataModel = {
    drugId: originalDetailsResp.id,
    //add form to name (if there) to make it same as savings report
    drugName: originalDetailsResp['dosage-form']
      ? originalDetailsResp.name + ' ' + originalDetailsResp['dosage-form']
      : originalDetailsResp.name,
    drugForm: originalDetailsResp['dosage-form']
      ? originalDetailsResp['dosage-form']
      : 'NA',
    drugDosage:
      originalDetailsResp.strength +
      ' ' +
      originalDetailsResp['strength-unit-of-measure'],
    drugCount: quantity && quantity !== undefined ? `${quantity}ct` : '0ct',
    priceCopay:
      priceInfo.copay && priceInfo.copay.amount !== undefined
        ? getOriginalDollars(priceInfo.copay.amount)
        : undefined,
    priceBeforeDed:
      priceInfo.beforeDed && priceInfo.beforeDed.amount !== undefined
        ? getOriginalDollars(priceInfo.beforeDed.amount)
        : undefined,
    priceAfterDed:
      priceInfo.afterDed && priceInfo.afterDed.amount !== undefined
        ? getOriginalDollars(priceInfo.afterDed.amount)
        : undefined,
    upToAmount: upToAmount,
    hasCouponsOnOriginal: searchCouponsOnOriginal,
    isBookmarked: isBookmarkedOpportunity(
      originalDetailsResp.id,
      bookmarkedOpportunities,
    ),
  }
  return opportunity
}

//TODO - default quantity for original
export function getAllWaysToSaveForSearchedDrug(
  isCopay: boolean,
  originalDefaultQuantity: number,
  original: OriginalPricingResp,
  alts: AlternativeDrugsResponse,
): WayToSaveItem[] | undefined {
  let items: WayToSaveItem[] = []

  //TODO - default should come from original or most popular quantity?
  const originalQuantityCount = originalDefaultQuantity

  //TODO - add checks for invalid/missing pricing data here
  if (!original['pricing-info'] || original['pricing-info'].length === 0) {
    LOG.error(
      'search_results_data',
      'getAllWaysToSaveForSearchedDrug,missing pricing info for original drug details in search,orginalResp=',
      original,
    )
    return undefined
  }

  let originalPricingInfo = getDrugInfoDict(original['pricing-info'])

  //for each alternative in alts response, build the corresponding way to save item
  alts['alternative-drugs'].forEach((alt) => {
    //TODO - is this correclty always computed for us
    let saveAmountResolved = alt['before-deductible-total-saving-amount']

    const alternatePricingInfo = getDrugInfoDict(alt['drug-price-info'])

    let searchType: WayToSaveType = 'basic_strategy'

    if (alt['is-coupon'] || alt.strategy === SavingStrategy.Coupons) {
      searchType = 'coupon'
    } else if (
      alt['pharmacy-change'] ||
      alt.strategy === SavingStrategy.PharmacySwitch
    ) {
      searchType = 'pharmacy_change'
    }

    //payment type, days supply and pharmacy details
    let paymentType: PaymentType = 'insurance'
    if (alt['is-coupon'] || alt.strategy === SavingStrategy.Coupons) {
      paymentType = 'cash'
    }

    if (
      alt.pharmacyDetailsResolvedByNpi &&
      alt.pharmacyDetailsResolvedByNpi['cash-only'] === true
    ) {
      paymentType = 'cash'
    }

    let daysSupply =
      alt['day-supply'] && !isNaN(alt['day-supply'])
        ? alt['day-supply']
        : undefined

    // let daysSupply: DaysSupplyType | undefined = undefined
    // if (alt['todo-custom-days-supply']) {
    //   if (alt['todo-custom-days-supply'] == 30) {
    //     //TODO - for 30 = standard - nothing special about it
    //     daysSupply = undefined
    //   } else if (alt['todo-custom-days-supply'] == 90) {
    //     daysSupply = '90'
    //   } else if (alt['todo-custom-days-supply'] == 180) {
    //     daysSupply = '180'
    //   } else {
    //     daysSupply = alt['todo-custom-days-supply']
    //   }
    // }

    let pharmacyInfo: PharmacyInfo | undefined = undefined
    //always build details for the pharmacy
    pharmacyInfo = getPharmacyInfoFromInputAlt('search_alt', alt)

    //TODO - temporary fix for cost plus pharmacy not having cash payment
    //this is bc we dont have the NPI for the pharmacy
    //it is there, but would have to be resolved via NPI lookup
    if (pharmacyInfo && isMarkCubanCostPlustName(pharmacyInfo.name)) {
      paymentType = 'cash'
      pharmacyInfo.isCash = true
    }

    //prevent showing pharmacy info if its a coupon
    //we need to wait until they pick the coupon...
    if (searchType === 'coupon') {
      pharmacyInfo = undefined
    }

    //TODO - add checks for invalid/missing pricing data here
    if (!alt['drug-price-info'] || alt['drug-price-info'].length === 0) {
      LOG.error(
        'search_results_data',
        'missing pricing info for alternative drug,altResp=',
        alt,
      )
      return undefined
    }

    //TODO - amount when dealing with coupons vs pharmacies?
    if (!saveAmountResolved) {
      console.error('WARNING - UNABLE TO DETERMINE SAVE UP TO AMOUNT TO USE')
      saveAmountResolved = 0
    }

    let sameOrNewMedChoice: SameOrNewMedChoiceType | undefined = undefined
    if (isProvenAlternativeGroup(alt.strategy as SavingStrategy)) {
      sameOrNewMedChoice = 'new_med'
    } else if (isSameMedicineGroup(alt.strategy as SavingStrategy)) {
      sameOrNewMedChoice = 'same_med'
    } else {
      //error, cannot proceed to group this search result
      LOG.error(
        'search_results_data',
        'unable to determine same or new med choice for alt=',
        alt,
      )
      return
    }

    //TODO - check this?
    const d = (price: number | undefined) => {
      return price && price !== undefined ? `$${price}` : '$0.00'
    }

    const searchAlternateWayToSave: WayToSaveItem = {
      originalId: original.id,
      originalName: original.name,
      originalDosage:
        original.strength + ' ' + original['strength-unit-of-measure'],
      originalForm: original['dosage-form'] ?? 'NA',
      originalCount:
        originalQuantityCount && originalQuantityCount !== undefined
          ? `${originalQuantityCount}ct`
          : '0ct',

      //for original price
      originalPriceCopay:
        originalPricingInfo.copay &&
        originalPricingInfo.copay.amount !== undefined
          ? d(originalPricingInfo.copay.amount)
          : undefined,
      originalPriceBeforeDed:
        originalPricingInfo.beforeDed &&
        originalPricingInfo.beforeDed.amount !== undefined
          ? d(originalPricingInfo.beforeDed.amount)
          : undefined,
      originalPriceAfterDed:
        originalPricingInfo.afterDed &&
        originalPricingInfo.afterDed.amount !== undefined
          ? d(originalPricingInfo.afterDed.amount)
          : undefined,

      altId: alt['drug-id'],
      separateIdentifier: alt['separate-identifier']
        ? alt['separate-identifier']
        : undefined,

      //TODO - search doesnt include form in the name so we cn use the same for now
      altNameToUseWhenMerginSeparates: alt.name,
      // append the form to name when displaying to make it same as savings report
      altName: alt.form ? alt.name + ' ' + alt.form : alt.name,
      altDosage: alt.dosage + ' ' + alt['strength-unit-of-measure'],
      altForm: alt.form,

      altCountNumber:
        alt.quantity && alt.quantity !== undefined ? alt.quantity : 0,
      altCountQtcStr:
        alt.quantity && alt.quantity !== undefined
          ? alt.quantity + 'ct'
          : '0ct',

      //alternate pricing info - some of them can be undefined,
      //i.e. if cash or coupon, we want be having copay, before/after ded
      altPriceCopay:
        alternatePricingInfo.copay &&
        alternatePricingInfo.copay.amount !== undefined
          ? d(alternatePricingInfo.copay.amount)
          : undefined,
      altPriceBeforeDed:
        alternatePricingInfo.beforeDed &&
        alternatePricingInfo.beforeDed.amount !== undefined
          ? d(alternatePricingInfo.beforeDed.amount)
          : undefined,
      altPriceAfterDed:
        alternatePricingInfo.afterDed &&
        alternatePricingInfo.afterDed.amount !== undefined
          ? d(alternatePricingInfo.afterDed.amount)
          : undefined,

      altPriceCashPharmacy:
        alt['pharmacy-cash-amount'] && alt['pharmacy-cash-amount'] !== undefined
          ? d(alt['pharmacy-cash-amount'])
          : undefined,

      altMmaIdForHelpMeSwitch: alt['alternate-mma-id']
        ? alt['alternate-mma-id']
        : 0,

      type: searchType,

      isCopay: isCopay,

      savingStrategy: alt.strategy as SavingStrategy,

      saveAmountResolved: saveAmountResolved,
      sameOrNewMedChoice: sameOrNewMedChoice,
      paymentType: paymentType,
      // daysSupply: daysSupply,
      pharmacyInfo: pharmacyInfo,
    }

    if (searchAlternateWayToSave.type === 'coupon') {
      let isAddedBefore =
        isThisSameCouponForDrugBItemAlreadyAddedDueToDifferentPharmacy(
          searchAlternateWayToSave,
          items,
        )
      if (!isAddedBefore) {
        items.push(searchAlternateWayToSave)
      } else {
      }
    } else {
      items.push(searchAlternateWayToSave)
    }
  })

  //sort them first just in case
  const sorted = sortWaysToSaveByAmount(items)
  //then also process separates and group them to the same
  const postProcessedItemsWithGroupedSeparates =
    postProcessSeparatesToSingleWayToSaveItem(sorted)

  return postProcessedItemsWithGroupedSeparates
}

export function getDollarsPerMonthStr(num?: number): string | undefined {
  //make sure its a valid number
  //or return 0.00 if undefined?

  if (num === undefined) return undefined

  if (isNaN(num)) return undefined
  //In this modified function, it checks if num is not undefined instead of checking if num is truthy. This way, it will return '0.00' when num is 0.
  let numberOnly = num !== undefined ? num.toFixed(2).toString() : undefined
  //TODO - is it walsy per month in case of otehr prices
  let asDollar = `$${numberOnly}/mo`
  return asDollar
}

//this is used in search for ORIGINAL - per design, those are without month
//it is not clear yet what those pricing values are - per month?
export function getOriginalDollars(num?: number): string | undefined {
  //make sure its a valid number
  //or return 0.00 if undefined?

  if (num === undefined) return undefined

  if (isNaN(num)) return undefined
  //In this modified function, it checks if num is not undefined instead of checking if num is truthy. This way, it will return '0.00' when num is 0.
  let numberOnly = num !== undefined ? num.toFixed(2).toString() : undefined
  //TODO - is it walsy per month in case of otehr prices
  let asDollar = `$${numberOnly}`
  return asDollar
}

export function getDistanceStr(couponItem?: CouponDetails): string {
  if (!couponItem) return ''
  let distance = couponItem.distance
  let distanceStr = ''
  if (distance && !isNaN(distance)) {
    //to 2 decimal digits
    distanceStr = `${distance.toFixed(2)} miles away`
  }

  return distanceStr
}

//new alternate drug item
//we just use this in the druglistitem component, so we hijack those props via omit
export type AlternateDrugModel = Omit<
  DrugListItemProps,
  'ellipsisMenu' | 'onClick' | 'upToPrefix'
> & {
  //this is bc in the new ui, multiple alternatives are grouped by drug name
  //at the moment we can only grab one
  highestSavingAltDrugId: number
  highestSavingAltMmaId: number
  strategies: string[]
  //in case of separates, we will have multiple drug ids and names to process
  separatesParams?: SeparatesParam[]
}

export interface CouponDetails {
  pharmacyName: string
  pharmacyLogoUrl: string
  tags?: React.ReactNode //Best Price, Coupon
  distance: number //i.e.
  priceCash: string //i.e. $34.69/
  physicalAddress: string
}

//build RxCompare props for original and alternative from the way to save item
export function getRxCompareProps(
  waytoSave: WayToSaveItem,
  selectedCoupon?: CouponDetails,
): RxCompareProps {
  const priceTypeOriginal: PriceElementType = waytoSave.isCopay
    ? 'insurance_copay'
    : 'insurance_ded'

  const priceTypeAlternative: PriceElementType = selectedCoupon
    ? 'coupon'
    : waytoSave.type === 'pharmacy_change' &&
      waytoSave.pharmacyInfo &&
      waytoSave.pharmacyInfo.type === 'PARTNER' &&
      waytoSave.pharmacyInfo.isCash === true
    ? 'cash'
    : waytoSave.isCopay
    ? 'insurance_copay'
    : 'insurance_ded'

  const original = {
    drugId: waytoSave.originalId ? waytoSave.originalId.toString() : undefined,
    drugName: waytoSave.originalName,
    drugDosage: waytoSave.originalDosage,
    drugForm: waytoSave.originalForm,
    drugCount: waytoSave.originalCount,
    priceCopay: waytoSave.originalPriceCopay,
    priceBeforeDed: waytoSave.originalPriceBeforeDed,
    priceAfterDed: waytoSave.originalPriceAfterDed,

    priceType: priceTypeOriginal,
  }

  const alternative: RxCompareDrugPriceDataModel = {
    drugId: waytoSave.altId ? waytoSave.altId.toString() : undefined,
    separatesParams: waytoSave.separatesParams,
    drugName: waytoSave.altName,
    drugDosage: waytoSave.altDosage,
    drugForm: waytoSave.altForm,
    drugCount: waytoSave.altCountQtcStr,
    priceCopay: selectedCoupon ? undefined : waytoSave.altPriceCopay,
    priceBeforeDed: selectedCoupon ? undefined : waytoSave.altPriceBeforeDed,
    priceAfterDed: selectedCoupon ? undefined : waytoSave.altPriceAfterDed,
    priceCoupon: selectedCoupon ? `$${selectedCoupon.priceCash}` : undefined,
    priceCash: waytoSave.altPriceCashPharmacy,
    priceType: priceTypeAlternative,
    strategy: waytoSave.savingStrategy,

    //TODO - refacto these varaint values from the input props
    variant:
      waytoSave.type === 'coupon'
        ? 'coupon'
        : waytoSave.type === 'pharmacy_change' &&
          waytoSave.pharmacyInfo &&
          waytoSave.pharmacyInfo.type === 'PARTNER' &&
          waytoSave.pharmacyInfo.isCash === true
        ? 'online_cash_pharmacy'
        : waytoSave.pharmacyInfo && waytoSave.pharmacyInfo.type === 'INHOUSE'
        ? 'inhouse_pharmacy'
        : undefined,
  }

  //TODO - is this the right place to clean up the prices for cash scenarios?
  if (
    waytoSave.type === 'pharmacy_change' &&
    waytoSave.pharmacyInfo &&
    waytoSave.pharmacyInfo.type === 'PARTNER' &&
    waytoSave.pharmacyInfo.isCash === true
  ) {
    alternative.priceCopay = undefined
    alternative.priceBeforeDed = undefined
    alternative.priceAfterDed = undefined
  }

  return {
    newRx: alternative,
    currentRx: original,
  }
}

//NOTE - this means - as so far- Coupons and Pharmacy Change values for strategy
//designated its a strategy for original aka drug A
export function needsToDiscussReportWithDoctor(
  wayToSaveItem: WayToSaveItem,
): boolean {
  //no need to talk to DR for OTC
  if (wayToSaveItem.savingStrategy === SavingStrategy.OTC) {
    return false
  }

  //for Coupons on Original
  if (wayToSaveItem.savingStrategy === SavingStrategy.Coupons) {
    return false
  }

  //for pharmacy change on original
  if (wayToSaveItem.savingStrategy === SavingStrategy.PharmacySwitch) {
    return false
  }

  return true
}

//these 3 have to be named correctly to match the backend to resolve their logos
export function isMarkCubanCostPlustName(name?: string): boolean {
  //mccpd or cost plus or cost plus drugs or Mark Cuban Cost Plus
  // do not use .includes() IE11????

  if (!name) return false
  //use index of
  if (name.toLowerCase().indexOf('cost plus') > -1) return true
  if (name.toLowerCase().indexOf('mark cuban') > -1) return true
  if (name.toLowerCase().indexOf('mccpd') > -1) return true
  if (name.toLowerCase().indexOf('cost plus drugs') > -1) return true

  return false
}

export function isJpsPharmacyName(name?: string): boolean {
  if (!name) return false
  return name.toLowerCase().indexOf('jps') > -1
}
export function isAmazonPharmacyName(name?: string): boolean {
  if (!name) return false
  return name.toLowerCase().indexOf('amazon') > -1
}
// export const COST_PLUS_WELL_KNOWN_PHARMACY_NAME = 'Marc Cuban Cost Plus'
//TODO - edge cases of missing i.e. pharmacy names
export function getPharmacyInfoFromInputAlt(
  inType: 'report_alt' | 'search_alt',
  alt: MemberPrescriptionAlternative | SearchResultsAlternative,
): PharmacyInfo {
  let name = alt['pharmacy-name'] ? alt['pharmacy-name'] : undefined
  //
  let logoUrl = undefined
  let redirectUrl = undefined

  if (isJpsPharmacyName(name)) {
    if (
      (name &&
        name
          .toLowerCase()
          .indexOf('JPS Maxor Specialty Pharmacy'.toLowerCase()) > -1) ||
      (name &&
        name.toLowerCase().indexOf('Maxor Specialty Pharmacy'.toLowerCase()) >
          -1)
    ) {
      logoUrl =
        'https://refills.maxorspecialty.com/app/assets/customs/maxor-specialty/logo.svg'
      redirectUrl = 'https://www.maxor.com/maxor-specialty/'
    } else {
      logoUrl =
        'https://scripta-public-assets.s3.amazonaws.com/memberapp/custom-pharmacies/jps.svg'
      redirectUrl = 'https://jpsmychart.jpshealth.org/'
    }
  } else if (isMarkCubanCostPlustName(name)) {
    //

    logoUrl =
      'https://scripta-public-assets.s3.amazonaws.com/memberapp/custom-pharmacies/costplus.svg'
    redirectUrl = 'https://costplusdrugs.com/'
  } else if (isAmazonPharmacyName(name)) {
    logoUrl =
      'https://scripta-public-assets.s3.amazonaws.com/memberapp/custom-pharmacies/amazon.svg'
    redirectUrl = 'https://pharmacy.amazon.com/'
  }
  //

  const pharmacyInfo: PharmacyInfo = {
    name: name,
    type: alt['pharmacy-type'] ? alt['pharmacy-type'] : 'RETAIL',
    logoUrl: logoUrl,

    mailOrderRedirectUrl: redirectUrl,
    isCash: false,
    isMailOrder: false,
  }
  //since we only have pharmacy-npi in search results
  if ((alt as any)['pharmacy-npi']) {
    pharmacyInfo.pharmacyNpi = (alt as any)['pharmacy-npi']
  }

  let physicalAddress: string | undefined = ''
  if (inType === 'report_alt') {
    let reportAlt = alt as MemberPrescriptionAlternative
    pharmacyInfo.isCash =
      reportAlt['cash-only'] && reportAlt['cash-only'] === true ? true : false
    pharmacyInfo.isMailOrder =
      reportAlt['mail-order'] && reportAlt['mail-order'] === true ? true : false
    physicalAddress = buildPharmcyPhysicalAddress(reportAlt)
    pharmacyInfo.physicalAddress = physicalAddress
  } else if (inType === 'search_alt') {
    let searchAlt = alt as SearchResultsAlternative
    pharmacyInfo.isCash =
      searchAlt.pharmacyDetailsResolvedByNpi &&
      searchAlt.pharmacyDetailsResolvedByNpi['cash-only'] &&
      searchAlt.pharmacyDetailsResolvedByNpi['cash-only'] === true
        ? true
        : false
    pharmacyInfo.isMailOrder =
      searchAlt.pharmacyDetailsResolvedByNpi &&
      searchAlt.pharmacyDetailsResolvedByNpi['mail-order'] &&
      searchAlt.pharmacyDetailsResolvedByNpi['mail-order'] === true
        ? true
        : false
    physicalAddress = searchAlt.pharmacyDetailsResolvedByNpi
      ? buildPharmacyAddressFromNpiLookup(
          searchAlt.pharmacyDetailsResolvedByNpi,
        )
      : undefined
    pharmacyInfo.physicalAddress = physicalAddress
  }

  return pharmacyInfo
}

function buildPharmcyPhysicalAddress(
  alt: MemberPrescriptionAlternative,
): string | undefined {
  let address = ''
  if (alt['pharmacy-address1']) {
    address += alt['pharmacy-address1']
  }
  if (alt['pharmacy-address2']) {
    address += ', ' + alt['pharmacy-address2']
  }
  if (alt['pharmacy-address3']) {
    address += ', ' + alt['pharmacy-address3']
  }
  if (alt['pharmacy-city']) {
    address += ', ' + alt['pharmacy-city']
  }
  if (alt['pharmacy-state']) {
    address += ', ' + alt['pharmacy-state']
  }
  if (alt['pharmacy-zip']) {
    address += ', ' + alt['pharmacy-zip']
  }
  return address
}

//backend returns a different model here
export function buildPharmacyAddressFromNpiLookup(
  resp: AdditionalPharmacyDetailsByNpiLookupResp,
) {
  let address = ''
  if (resp['address1']) {
    address += resp['address1']
  }
  if (resp['address2']) {
    address += ', ' + resp['address2']
  }
  if (resp['address3']) {
    address += ', ' + resp['address3']
  }
  if (resp['city']) {
    address += ', ' + resp['city']
  }
  if (resp['state']) {
    address += ', ' + resp['state']
  }
  if (resp['zip']) {
    address += ', ' + resp['zip']
  }
  return address
}

//we dont get logo for inhouse pharmacy from the backend yet
// export const JPS_Pharmacy_Name_Match = 'JPS Pharmacy'
// export const JPS_Pharmacy_Npi_Match: string[] = [
//   '1194801753',
//   '1588748610',
//   '1427133719',
//   '1104991785',
//   '1831388024',
// ]
// export const COST_PLUST_Pharmacy_Name_Match = 'CostPlus'
// export interface PharmacyIdentifier {
//   name?: string
//   type?: string
//   npi?: string
// }
// export function todo_getUIHardcodedPharmaLogoIfMissing(
//   pharmacyIdentifier: PharmacyIdentifier,
// ) {
//   if (
//     pharmacyIdentifier.name === JPS_Pharmacy_Name_Match ||
//     (pharmacyIdentifier.npi &&
//       JPS_Pharmacy_Npi_Match.includes(pharmacyIdentifier.npi))

//     //dont limit yet just to INHOUSe bc current pharmacy change on orignal doesnt have it

//     // &&alt['pharmacy-type'] === 'INHOUSE'
//   )
//     return getJpsPharmaLogo()

//   if (pharmacyIdentifier.name === COST_PLUST_Pharmacy_Name_Match)
//     return getMarkCubanharmaLogo()
// }
// export function todo_getUiHardcodedPhysicalAddressIfMissing(
//   pharmacyIdentifier: PharmacyIdentifier,
// ) {
//   if (
//     pharmacyIdentifier.name === JPS_Pharmacy_Name_Match ||
//     (pharmacyIdentifier.npi &&
//       JPS_Pharmacy_Npi_Match.includes(pharmacyIdentifier.npi))
//     // && alt['pharmacy-type'] === 'INHOUSE'
//   )
//     return '1500 S Main St, Fort Worth, TX 76104'
// }
// export function todo_getOnlineSignupUrlForCashPharmaIfMissing(
//   pharmacyIdentifier: PharmacyIdentifier,
// ) {
//   if (pharmacyIdentifier.name === COST_PLUST_Pharmacy_Name_Match)
//     return 'https://costplusdrugs.com/'
// }
// function getMarkCubanharmaLogo() {
//   return 'https://upload.wikimedia.org/wikipedia/commons/0/0c/Mark_Cuban_Cost_Plus_Drug_Company_logo.svg'
// }
// function getJpsPharmaLogo() {
//   return 'https://upload.wikimedia.org/wikipedia/en/a/a4/JPS_Health_Network_logo.svg'
// }
export const ZIP_CODE_LENGTH: number = 5

export function isValidZipLength(zipCodeInput?: string) {
  if (!zipCodeInput) return false
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCodeInput)
}

export interface DrugPriceInfoDict {
  beforeDed?: DrugPriceInfo
  afterDed?: DrugPriceInfo
  copay?: DrugPriceInfo
}

export function getDrugInfoDict(
  drugInfoArray: DrugPriceInfo[],
): DrugPriceInfoDict {
  let drugPriceInfoDict: DrugPriceInfoDict = {}

  drugInfoArray.forEach((info) => {
    if (info.type === 'PRE_DEDUCTIBLE') {
      drugPriceInfoDict.beforeDed = info
    } else if (info.type === 'POST_DEDUCTIBLE') {
      drugPriceInfoDict.afterDed = info
    } else if (info.type === 'COPAY') {
      drugPriceInfoDict.copay = info
    }
  })

  return drugPriceInfoDict
}

export function getCashCouponItems(
  allWaysToSave: WayToSaveItem[],
  sameOrNewMedChoice: SameOrNewMedChoiceType,
  filterByDrugName?: string,
): WayToSaveItem[] {
  //first get all coupons manually
  const allCouponItems = allWaysToSave.filter(
    (wayToSaveItem) => wayToSaveItem.type === 'coupon',
  )

  //

  const filteredCouponItems = getFilteredItems(
    allCouponItems,
    sameOrNewMedChoice,
    'cash',
    filterByDrugName,
    undefined,
  )
  return filteredCouponItems
}
export function getCashPharmacyItems(
  allWaysToSave: WayToSaveItem[],
  sameOrNewMedChoice: SameOrNewMedChoiceType,
  filterByDrugName?: string,
): WayToSaveItem[] {
  //need to get cash pharmacy items only before doing additional filtering here
  const allCashPharmacyItems = allWaysToSave.filter(
    (wayToSaveItem) =>
      wayToSaveItem.type === 'pharmacy_change' &&
      wayToSaveItem.pharmacyInfo?.isCash === true,
  )
  //
  const filteredCashPharmacyItems = getFilteredItems(
    allCashPharmacyItems,
    sameOrNewMedChoice,
    'cash',
    filterByDrugName,
    undefined,
  )
  return filteredCashPharmacyItems
}

export function getInsurancePharmacyStayItems(
  allWaysToSave: WayToSaveItem[],
  sameOrNewMedChoice: SameOrNewMedChoiceType,
  filterByDrugName?: string,
): WayToSaveItem[] {
  let paymentTypeFilter: PaymentType = 'insurance'
  const insuranceSamePharmacyItems = getFilteredItems(
    allWaysToSave,
    sameOrNewMedChoice,
    paymentTypeFilter,
    filterByDrugName,
    'same_pharmacy',
  )

  return insuranceSamePharmacyItems
}

export function getInsurancePharmacyChangeItems(
  allWaysToSave: WayToSaveItem[],
  sameOrNewMedChoice: SameOrNewMedChoiceType,
  filterByDrugName?: string,
): WayToSaveItem[] {
  let paymentTypeFilter: PaymentType = 'insurance'
  const insuranceChangePharmacyItems = getFilteredItems(
    allWaysToSave,
    sameOrNewMedChoice,
    paymentTypeFilter,
    filterByDrugName,
    'change_pharmacy',
  )

  return insuranceChangePharmacyItems
}

export function getFilteredItems(
  allWaysToSave: WayToSaveItem[],
  filterByNewOrStaySameMed: SameOrNewMedChoiceType,
  filterAdditionallyByPaymentType?: PaymentType,
  filterAdditionallyByNewMedicationName?: string,
  filterAdditionallyByStayOrChangePharmacy?:
    | 'same_pharmacy'
    | 'change_pharmacy',
  // saving: MemberPrescriptionSaving,
): WayToSaveItem[] {
  //

  const sortedByAmouontFirst = sortWaysToSaveByAmount(allWaysToSave)

  let filteredWaysToSave = sortedByAmouontFirst.filter(
    (item) => item.sameOrNewMedChoice === filterByNewOrStaySameMed,
  )
  if (filterAdditionallyByPaymentType) {
    filteredWaysToSave = filteredWaysToSave.filter(
      (item) => item.paymentType === filterAdditionallyByPaymentType,
    )
  }
  if (filterAdditionallyByNewMedicationName) {
    filteredWaysToSave = filteredWaysToSave.filter(
      (item) => item.altName === filterAdditionallyByNewMedicationName,
    )
  }
  if (filterAdditionallyByStayOrChangePharmacy) {
    filteredWaysToSave = filteredWaysToSave.filter(
      filterAdditionallyByStayOrChangePharmacy === 'change_pharmacy'
        ? (item) => item.type === 'pharmacy_change'
        : (item) => item.type !== 'pharmacy_change',
    )
  }

  //

  //resort again in case something got filtered out that was higher?
  const resorted = sortWaysToSaveByAmount(filteredWaysToSave)

  return resorted
}
//need to compare two lists, get the highsest option
//handle cases where they are equal
//or if the option doesnt exist, making it false to render ui Best Price tag labels correctly
export function getHigestOptionInTwo(
  listA: WayToSaveItem[],
  listB: WayToSaveItem[],
) {
  listA = sortWaysToSaveByAmount(listA)
  listB = sortWaysToSaveByAmount(listB)
  const highestAItem = listA && listA.length > 0 ? listA[0] : undefined

  const highestBItem = listB && listB.length > 0 ? listB[0] : undefined

  const highestAValue = highestAItem
    ? highestAItem.saveAmountResolved
    : undefined

  const highestBValue = highestBItem
    ? highestBItem.saveAmountResolved
    : undefined

  let hasA = highestAItem != undefined
  let hasB = highestBItem != undefined

  let isABestPrice = false
  let isBBestPrice = false

  if (hasA && hasB) {
    isABestPrice = highestAValue! > highestBValue!
    isBBestPrice = highestBValue! > highestAValue!
  } else {
    if (hasA && !hasB) {
      isABestPrice = true
    }
    if (hasB && !hasA) {
      isBBestPrice = true
    }
  }

  // //strings to display for controls
  // //TODO - move these to controls, and just display NA?
  let highestAAmountStr: string | undefined = undefined
  if (highestAItem) {
    highestAAmountStr = getDollarsPerMonthStr(highestAValue)
  }

  let highestBAmountStr: string | undefined = undefined
  if (highestBItem) {
    highestBAmountStr = getDollarsPerMonthStr(highestBValue)
  }
  return {
    hasA,
    hasB,
    isABestPrice,
    isBBestPrice,
    highestAAmountStr,
    highestBAmountStr,
  }
}

//this happens because backedn reaturns two items when there is a coupon on B
//a1) the alternate itself (strategy=Related, coupon:fals,pharmcy:Retail)
//b1) the coupon on the alternate (strategy=Related,coupon:true)

//in case of in-house pharmacies, we also get the same
//a2) the alternate itself (strategy=Releted,coupon:false,pharmacy:InHouse)
//b2) the coupon on alternate (strategy=Related,coupon:true,Pharmacy:inHouse)

//from the UI (and member) perspective, these b1) and b2) itemes are duplicate so we
//should add it only once
export function isThisSameCouponForDrugBItemAlreadyAddedDueToDifferentPharmacy(
  couponBWayToSaveItem: WayToSaveItem,
  previouslyAddedWayToSaveItems: WayToSaveItem[],
) {
  //
  //
  //
  let isAddedAlready = false
  //get only the coupons from the previously added items to use when comparing
  const previousCoupons = previouslyAddedWayToSaveItems.filter(
    (item) => item.type === 'coupon',
  )
  for (let i = 0; i < previousCoupons.length; i++) {
    const prevItem = previousCoupons[i]
    //compare JSON objects
    const a = { ...couponBWayToSaveItem }
    const b = { ...prevItem }

    if (JSON.stringify(a) === JSON.stringify(b)) {
      isAddedAlready = true
    }
  }

  return isAddedAlready
}
