import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import clsx from 'clsx'
import askSwitchPrescriptionUrl from '../../images/ask_switch_prescription.svg'
import discussWithDoctorUrl from '../../images/discuss_with_doctor.svg'
import fillPharmacyUrl from '../../images/fill_pharmacy.svg'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useState } from 'react'
import { FOOTER_MAX_WIDTH } from '../styles/scripta-theme'
import { GAService } from '../application/ga/GAService'
import { Theme } from '@mui/material'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'

export const useSwitchAndSaveStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstSectionText: {
      fontSize: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        marginTop: '2.75rem',
      },
    },
    secondSectionText: {
      fontSize: '.8rem',
      marginLeft: '.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '.8rem',
        marginTop: '.5rem',
      },
    },
    secondSectionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        textAlign: 'center',
        marginTop: '2rem',
      },
    },
    learnMoreButton: {
      height: '3.125rem',
      width: '10rem',
      border: '1.5px solid #4D008C',
      borderRadius: '4px',
      padding: '10px 15px 10px 15px',
      marginTop: '2.1rem',
      marginBottom: '2.1rem',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '16.25rem',
        marginTop: '1.5rem',
        marginBottom: '4rem',
      },
    },
  }),
)

export function SwitchAndSave() {
  const gaService = new GAService()
  const classes = useSwitchAndSaveStyles()
  const { t } = useSTranslate(['footer'])
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
    gaService.trackEvent(gaService.eventMap.home_page_learn_more)
  }
  const handleClose = () => setOpen(false)
  return (
    <>
      <Box
        style={{
          background: isSmallScreen
            ? // ? 'linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 9.63%)'
              '#FFFFFF'
            : '#FFFFFF',
          minHeight: '7.5rem',
          paddingLeft: 10,
          paddingRight: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          minHeight={'7.5rem'}
          sx={{
            maxWidth: FOOTER_MAX_WIDTH,
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              padding: 2,
            }}
          >
            <Typography
              className={clsx(classes.firstSectionText)}
              tab-index={0}
              aria-label={t('firstSectionText')}
            >
              {t('firstSectionText')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ display: 'flex', justifyContent: 'space-around', gap: '5px' }}
          >
            <Box className={clsx(classes.secondSectionContainer)}>
              <ThemedSVGRemote
                importUrl={discussWithDoctorUrl}
                alt="Discuss with doctor"
                width={50}
                height={50}
              />
              <Typography
                className={clsx(classes.secondSectionText)}
                tab-index={0}
                aria-label={t('firstStep')}
              >
                {t('firstStep')}
              </Typography>
            </Box>
            <Box className={clsx(classes.secondSectionContainer)}>
              <ThemedSVGRemote
                importUrl={askSwitchPrescriptionUrl}
                alt="Ask switch prescription"
                width={50}
                height={50}
              />
              <Typography
                className={clsx(classes.secondSectionText)}
                tab-index={0}
                aria-label={t('secondStep')}
              >
                {t('secondStep')}
              </Typography>
            </Box>
            <Box className={clsx(classes.secondSectionContainer)}>
              <ThemedSVGRemote
                importUrl={fillPharmacyUrl}
                alt="Fill at pharmacy"
                width={50}
                height={50}
              />

              <Typography
                className={clsx(classes.secondSectionText)}
                tab-index={0}
                aria-label={t('thirdStep')}
              >
                {t('thirdStep')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent={'center'}>
            {/* <Button
              className={clsx(classes.learnMoreButton)}
              tab-index={0}
              aria-label={t('learnMoreButton')}
              onClick={handleOpen}
            >
              <img src={learnMoreButton} />
              <Typography
                fontWeight={500}
                fontSize={18}
                sx={{ marginLeft: '0.6rem', color: '#4D008C' }}
              >
                {t('learnMoreButton')}
              </Typography>
            </Button> */}
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="learn-more-video"
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          width={isSmallScreen ? '330px' : '750px'}
          height="auto"
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <video
            width={isSmallScreen ? '330px' : '750px'}
            height="auto"
            preload="metadata"
            controlsList="nodownload"
            controls
          >
            <source src={t('learnMoreVideo')} type="video/mp4" />
            {t('footer:browserNotSupportVideoTag')}
          </video>
        </Box>
      </Modal>
    </>
  )
}
