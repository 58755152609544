import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel'
import StepConnector, {
  StepConnectorProps,
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'
import { useSColors } from 'src/app/styles/scripta-theme'
import { FlowStep } from './types'
import { SPACING } from 'src/app/system/theme2'
import { useMemo } from 'react'
import STypography from 'src/app/system/customcomponents/STypography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

const MOBILE_SIZE = 32
const DESKTOP_SIZE = 32
const STEPPER_CONNECTOR_WIDTH = 4
type SIZE = 'mobile' | 'tabletordesktop'

interface CustomStepperConnectorProps extends StepConnectorProps {
  size?: SIZE
}

const CustomStepperConnector = styled(
  StepConnector,
)<CustomStepperConnectorProps>(({ theme, size }) => ({
  [`&.${stepConnectorClasses.root}`]: {},
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top:
      (size === 'mobile' ? MOBILE_SIZE / 2 : DESKTOP_SIZE / 2) -
      STEPPER_CONNECTOR_WIDTH / 2,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.customized.colors.PRIMARY_BRAND,
      transition: 'background-color 0.25s ease-in-out',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.customized.colors.PRIMARY_BRAND,
      transition: 'background-color 0.25s ease-in-out',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: STEPPER_CONNECTOR_WIDTH,
    border: 0,
    backgroundColor: theme.customized.colors.BORDER_GREY_LIGHT,
    borderRadius: 1,
    transition: 'background-color 0.25s ease-in-out',
  },
}))

interface CustomStepIconRootProps {
  ownerState: { completed?: boolean; active?: boolean }
  size?: SIZE
}

const CustomStepIconRoot = styled('div')<CustomStepIconRootProps>(
  ({ theme, ownerState, size }) => ({
    backgroundColor: theme.customized.colors.BACKGROUND_GREY_LIGHT,
    zIndex: 1,
    color: ownerState.active
      ? theme.customized.colors.PRIMARY_BRAND
      : theme.customized.colors.TEXT_GREY_LIGHT,
    width: size === 'mobile' ? MOBILE_SIZE : DESKTOP_SIZE,
    height: size === 'mobile' ? MOBILE_SIZE : DESKTOP_SIZE,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundColor: theme.customized.colors.PRIMARY_10,
      boxShadow: `inset 0 0 0 3px ${theme.customized.colors.PRIMARY_BRAND}`,
    }),
    ...(ownerState.completed && {
      backgroundColor: theme.customized.colors.PRIMARY_BRAND,
      color: theme.customized.colors.TEXT_LIGHT_PRIMARY_WHITE,
    }),
  }),
)

interface CustomStepIconProps extends StepIconProps {
  step: FlowStep
  size?: SIZE
}

function CustomStepIcon(props: CustomStepIconProps) {
  const { active, completed, className, step, size } = props
  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      size={size}
    >
      {step.defaultIcon}
    </CustomStepIconRoot>
  )
}

export default function MultiStepFlowProgressBar({
  steps,
  activeStep,
  sizeVariant,
  shouldShowLabels = false,
}: {
  steps: FlowStep[]
  activeStep: number
  sizeVariant?: SIZE
  shouldShowLabels?: boolean
}) {
  const { t } = useSTranslate('newComponents')
  const stepCount = useMemo(() => steps.length, [steps])
  const { COLORS } = useSColors()

  return (
    <Stack sx={{ width: '100%' }} spacing={SPACING._1}>
      <Stepper
        activeStep={activeStep}
        connector={<CustomStepperConnector size={sizeVariant} />}
      >
        {steps.map((step, index) => (
          <Step key={step.stepName} sx={{ padding: 0 }}>
            <StepLabel
              sx={{
                [`& .${stepLabelClasses.iconContainer}`]: {
                  paddingRight: 0,
                },
                [`& .${stepLabelClasses.label}`]: {
                  paddingLeft: 1,
                  paddingRight: 1,
                },
              }}
              StepIconComponent={(props) => (
                <CustomStepIcon {...props} step={step} size={sizeVariant} />
              )}
            >
              {shouldShowLabels ? step.stepName : undefined}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <STypography variant="bodybig_bold" color={COLORS.TEXT_BRAND}>
        {t('step')} {Math.min(stepCount, activeStep + 1)}/{stepCount}
      </STypography>
    </Stack>
  )
}
