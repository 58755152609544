import { AppBar, Box, Theme, useTheme } from '@mui/material'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import {
  IS_NATIVE,
  nativeApi_setStatusBarBackgroundOverride,
} from '../apiexec/utils'
import { useMemberAppContext } from '../MemberAppContext'
import { useSColors } from '../styles/scripta-theme'
import { CenteredLogo } from '../v2/NewPageContainer'
import STypography, {
  STypographyCombination,
} from './customcomponents/STypography'
import { ICON_FONT_SIZE, SPACING } from './theme2'
type NavBarVariant =
  | 'logo'
  | 'bodybig_title_center'
  | 'bodybig_title_left'
  | 'title2_title_left'
  | 'title2_title_center'
  | 'title3_title_left'
  | 'title3_title_center'
export type NavBarColorVariant = 'white' | 'purple'
export type NavBarCombination = `${NavBarVariant}_${NavBarColorVariant}`
export function getNavbarColorVariant(
  variant: NavBarCombination,
): NavBarColorVariant {
  return variant.includes('white') ? 'white' : 'purple'
}
export interface NavBarProps {
  variant: NavBarCombination
  title?: string
  customOnClickBack?: () => void
  backButtonHidden?: boolean
  fixedTop?: boolean
  rightAction?: React.ReactNode
  customSx?: any
  transparent?: boolean
}

export function NavBar(props: NavBarProps) {
  const { history } = useMemberAppContext()
  const {
    variant,
    title,
    customOnClickBack,
    backButtonHidden,
    rightAction,
    transparent,
  } = props
  const colorVariant = getNavbarColorVariant(variant)
  const theme = useTheme()
  const { background, color } = getNavBarColors(theme, colorVariant)

  useEffect(() => {
    if (IS_NATIVE()) {
      if (colorVariant === 'white') {
        nativeApi_setStatusBarBackgroundOverride('white')
      } else {
        nativeApi_setStatusBarBackgroundOverride(undefined)
      }
    }
  }, [colorVariant, transparent])

  return (
    <AppBar
      elevation={0}
      color={'transparent'}
      position={props.fixedTop ? 'fixed' : 'static'}
    >
      <Box
        sx={{
          padding: SPACING._1,
          display: 'flex',
          alignItems: 'center',
          background: transparent ? 'transparent' : background,
          color: color,
          ...props.customSx,
        }}
      >
        {/* left */}
        <Box>
          {!backButtonHidden && (
            <NavBarBackButton
              onClick={() => {
                if (customOnClickBack) {
                  customOnClickBack()
                } else {
                  //default is history.goback
                  history.goBack()
                }
              }}
              colorVariant={colorVariant}
            />
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {title && (
            <STypography
              sx={{
                textAlign: variant.includes('center') ? 'center' : undefined,
              }}
              variant={getSTypographyVariant(variant)}
            >
              {title}
            </STypography>
          )}
          {(variant === 'logo_purple' || variant === 'logo_white') && (
            <NavBarLogo colorVariant={colorVariant} />
          )}
        </Box>
        <Box sx={{ marginLeft: 'auto' }}>
          {rightAction && <>{rightAction}</>}
        </Box>
      </Box>
    </AppBar>
  )
}

function getSTypographyVariant(
  variant: NavBarCombination,
): STypographyCombination {
  if (variant.includes('bodybig')) {
    return 'bodybig_bold'
  }
  if (variant.includes('title2')) {
    return 'title2_bold'
  }
  if (variant.includes('title3')) {
    return 'title3_bold'
  }
  return 'body_bold'
}

function getNavBarColors(
  theme: Theme,
  colorVariant: NavBarColorVariant,
): {
  background: string
  color: string
} {
  const COLORS = theme.customized.colors
  switch (colorVariant) {
    case 'white':
      return {
        background: COLORS.BACKGROUND_LIGHT,
        color: COLORS.TEXT_BLACK,
      }
    case 'purple':
      return {
        background: COLORS.BACKGROUND_GRADIENT,
        color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
      }
  }
}

export function NavBarLogo({
  colorVariant,
}: {
  colorVariant: NavBarColorVariant
}) {
  const theme = useTheme()
  const name = theme.customized.name

  return (
    <Box
      // onClick={() => history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* want the light logo on the purple background */}
      <CenteredLogo variant={colorVariant === 'purple' ? 'light' : 'dark'} />
    </Box>
  )
}

function NavBarBackButton({
  colorVariant,
  onClick,
}: {
  colorVariant: NavBarColorVariant
  onClick: () => void
}) {
  const { COLORS } = useSColors()
  return (
    <Box
      data-testid="navbar-back-button"
      sx={{
        cursor: 'pointer',
        paddingRight: '10px',
        paddingLeft: '10px',
        marginLeft: '-10px',
      }}
      onClick={() => {
        onClick()
      }}
    >
      <FontAwesomeIcon
        color={
          colorVariant === 'white'
            ? COLORS.TEXT_BLACK
            : COLORS.TEXT_LIGHT_PRIMARY_WHITE
        }
        fontSize={ICON_FONT_SIZE.SMALL_16px}
        icon={faChevronLeft}
      ></FontAwesomeIcon>
    </Box>
  )
}
