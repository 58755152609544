import { DialogContent, Grid, TextField, Typography } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
} from '../member/phone-field-utils'
// import { PrescriberData } from './types'
import { useEffect, useState } from 'react'
import { BottomActions, DESKTOP_DIALOG_SX, MOBILE_DIALOG_SX } from './Wizard'
import { render } from '@testing-library/react'
import { UiFormData, ValidationResult, validateUiData } from './types'

export function PrescriberDetails({
  prescriberData,
  onPrescriberDataChange,
  validationResult,
  setValidationResult,
  isSmallDisplay,
  onContinue,
  onBack,
  //sent by desktop component upon click of submit
  forceTriggerValidation,
}: {
  prescriberData: UiFormData
  onPrescriberDataChange: (prescriberData: UiFormData) => void
  isSmallDisplay: boolean
  onContinue: () => void
  onBack: () => void
  validationResult: ValidationResult
  setValidationResult: (validationResult: ValidationResult) => void
  //sent by desktop component upon click of submit
  forceTriggerValidation: boolean
}) {
  const { t } = useSTranslate('contactMyPrescriber')
  let renderBottomActions = isSmallDisplay

  //dont validate immediatelly, let user enter some data first and click continue once
  const [enableValidation, setEnableValidation] = useState<boolean>(false)

  useEffect(() => {
    if (forceTriggerValidation) {
      setEnableValidation(true)
    }
  }, [forceTriggerValidation])

  useEffect(() => {
    if (enableValidation) {
      const validationResult = validateUiData(prescriberData)
      setValidationResult(validationResult)
    }
  }, [prescriberData, enableValidation])

  return (
    <>
      <DialogContent sx={isSmallDisplay ? MOBILE_DIALOG_SX : DESKTOP_DIALOG_SX}>
        <Grid
          item
          container
          spacing={isSmallDisplay ? 2 : 1}
          // sx={{ backgroundColor: 'red' }}
        >
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '1.15rem' }}>
              {t('prescriberInfoTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              size="small"
              autoComplete="no"
              placeholder={t('prescriberNameLabel')}
              label={t('prescriberNameLabel')}
              // style={{ marginBottom: '20px', height: '48px' }}
              value={
                prescriberData.prescriberName
                  ? prescriberData.prescriberName
                  : ''
              }
              onChange={(e) => {
                onPrescriberDataChange({
                  ...prescriberData,
                  prescriberName: e.target.value,
                })
              }}
              error={enableValidation && !validationResult.validPrescriberName}
              helperText={
                enableValidation && !validationResult.validPrescriberName
                  ? t('errorPrescriberNameField')
                  : ''
              }
              inputProps={{
                autoComplete: 'nope',
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              placeholder={t('prescriberPhoneLabel')}
              label={t('prescriberPhoneLabel')}
              autoComplete="no"
              value={prescriberData.prescriberPhone}
              fullWidth
              onChange={(e) => {
                //process and format input to display friendly form
                //up to 10 digits
                if (toDigitsOnly(e.target.value).length <= 10) {
                  let displayNumberValue = toDisplayFriendlyString(
                    e.target.value,
                  )
                  const isValid = isDisplayFriendlyStringValidNumber(
                    e.target.value,
                  )
                  const v = { ...validationResult, validPhone: isValid }
                  setValidationResult(v)

                  onPrescriberDataChange({
                    ...prescriberData,
                    prescriberPhone: displayNumberValue,
                  })
                }
              }}
              error={enableValidation && !validationResult.validPrescriberPhone}
              helperText={
                enableValidation && !validationResult.validPrescriberPhone
                  ? t('errorPrescriberPhoneField')
                  : ''
              }
              style={{ height: 30 }}
              inputProps={{
                autoComplete: 'nope',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {renderBottomActions && (
        <BottomActions
          isSmallDisplay={isSmallDisplay}
          onContinue={() => {
            setEnableValidation(true)
            const ret = validateUiData(prescriberData)
            setValidationResult(ret)
            if (ret.validPrescriberName && ret.validPrescriberPhone) {
              onContinue()
            }
          }}
          onCancel={onBack}
          // useBackLabelForCancel={true}
        />
      )}
    </>
  )
}
