import { motion } from 'framer-motion'
import { ReactNode } from 'react'

interface WithAnimationProps {
  children: ReactNode
  variant?: 'fade'
}

const WithAnimationWrapper = ({ children, variant = 'fade' }: WithAnimationProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.25 }}
  >
    {children}
  </motion.div>
)

export default WithAnimationWrapper
