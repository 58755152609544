import Box from '@mui/material/Box'
import React, { useState } from 'react'
import {
  BOTTOM_NAV_HEIGHT,
  // COLORS,
  ICON_FONT_SIZE,
  MARGIN,
  RADIUS,
  SPACING,
} from '../system/theme2'
import { useAppLocationFromRoute, useSizes } from './useNewHooks'

import { useMemberAppContext } from '../MemberAppContext'

// import scriptaLogoWhite from '../system/images/scripta_logo_white.svg'
import {
  faBell,
  faCircleUser,
  faFilePrescription,
  faMagnifyingGlass,
  faTicket,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import MemberSupportButton from '../system/MemberSupportButton'
import SpeechTooltip from '../system/SpeechTooltip'
import WaysToSave, { WaysToSaveSectionTitle } from '../system/WaysToSave'
import { SDialog } from '../system/customcomponents/SDialog'
//old import
// import STypography from '../system/customcomponents/STypography'
import STypography from 'src/app/system/customcomponents/STypography'

import speechPersonUrl from './../system/images/medical_prescription_amico.svg'
import {
  MainAppSectionName,
  ROUTE_NEW_COUPONS_MAIN,
  ROUTE_NEW_MY_ACCOUNT_MAIN,
  ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN,
  ROUTE_NEW_SEARCH,
} from './NewMemberPortalMain'
import { getSectionTitle } from './utils'
import { ThemedSVGRemote } from './ThemedSVGRemote'
import { useSColors } from 'src/app/styles/scripta-theme'
import { useAmazon } from 'src/app/hooks/useAmazon'

export const DESKTOP_MAX_HEIGHT = 755
export const DESKTOP_MAX_WIDHT = 1200
export const MOBILE_HEADER_HEIGHT = 50
export function DesktopCentralContentContainer({
  children,
  leftSidePanel,
  rightSidePanel,
}: {
  children: React.ReactNode
  leftSidePanel?: React.ReactNode
  rightSidePanel?: React.ReactNode
}) {
  const { COLORS } = useSColors()
  const LEFT_PANEL_OVERLAP = 20
  const LEFT_WIDTH = `${224 + LEFT_PANEL_OVERLAP}px`
  const RIGHT_WIDTH = '312px'

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '30px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: LEFT_WIDTH,

          padding: '30px 36px 20px 16px',
          // margin: '0px 0px 0px 80px',
          // height: '80vh',
          backgroundColor: COLORS.DESKTOP_LEFT_CONTAINER_BG,
          borderRadius: `${RADIUS.md} 0px 0px ${RADIUS.md}`,
          marginRight: `-${LEFT_PANEL_OVERLAP}px`,
        }}
      >
        {leftSidePanel && <>{leftSidePanel}</>}
      </Box>
      <Box
        sx={{
          padding: '40px 20px 20px 20px',
          width: '50%',
          maxWidth: DESKTOP_MAX_WIDHT,
          backgroundColor: 'white',
          borderRadius: RADIUS.md,
          boxShadow: ' 0px 4px 24px 0px rgba(62, 62, 62, 0.10)',
        }}
      >
        <Box
          sx={{
            height: DESKTOP_MAX_HEIGHT,
            maxHeight: DESKTOP_MAX_HEIGHT,
            // border: '1px solid black',
            overflow: 'auto',

            // paddingTop: SPACING._2,
            paddingRight: SPACING._2,
            paddingLeft: SPACING._15,
          }}
        >
          {children}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: RIGHT_WIDTH,
          marginLeft: '30px',
          maxWidth: RIGHT_WIDTH,
          // padding: '20px',
          // backgroundColor: COLORS.BACKGROUND_PURPLE_20,
          borderRadius: RADIUS.md,
          // minHeight: '60vh',
          paddingTop: SPACING._15,
          paddingLeft: SPACING._15,
          paddingRight: SPACING._15,
          backgroundColor: COLORS.BACKGROUND_LIGHT,
          boxShadow: ' 0px 4px 24px 0px rgba(62, 62, 62, 0.10)',
        }}
      >
        {rightSidePanel && <>{rightSidePanel}</>}
      </Box>
    </Box>
  )
}
export function MobileCentralContentContainer({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: BOTTOM_NAV_HEIGHT,
      }}
    >
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'white',
          borderRadius: RADIUS.md,
        }}
      >
        <Box
          sx={{
            height: '100%',
            maxHeight: '100%',
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export type PageBackgroundVariant = 'clear_white' | 'purple_curved_elipsis'
export function BackgroundContainer({
  variant,
  children,
}: {
  variant: PageBackgroundVariant
  children: React.ReactNode
}) {
  const { COLORS } = useSColors()

  const { isMobile } = useSizes()

  const theme = useTheme()
  //add a bit extra padding on the sides for tablets
  const addExtraLeftRightPadding = useMediaQuery(
    theme.breakpoints.between(690, 1167),
  )
  if (variant === 'clear_white') {
    return (
      <Box
        sx={
          isMobile
            ? {
                paddingLeft: addExtraLeftRightPadding
                  ? SPACING._25
                  : SPACING._1,
                paddingRight: addExtraLeftRightPadding
                  ? SPACING._25
                  : SPACING._1,
                width: '100% ',
              }
            : {
                width: '100%',
              }
        }
      >
        {children}
      </Box>
    )
  }

  // const TOTAL_SECTION_HEIGHT = 453

  //this is the height of the actual half-elipsis
  //it will increase the curvature
  //it needs tweaks together with vertical spacer and margin top to line it all
  const ELIPSIS_TOTAL_HEIGHT = isMobile ? 180 : 220

  //this is to stretch the elipsis to the sides so its not as 'eliptical'
  const ELIPSIS_TOTAL_WIDTH = '130%'

  //this is the height of the space from the top of the appbar to the start of the elipsis
  //you should increase this and then increae the negative margin
  const VERTICAL_SPACER_HEIGHT = isMobile ? '70px' : '300px'

  //this is to pull the main content up
  //TODO - can we compute this based on above
  const MAIN_CONTENT_MARGIN_TOP = isMobile ? '-230px' : `-470px`

  return (
    <Box
      sx={{
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: isMobile ? undefined : COLORS.BACKGROUND_GREY_LIGHTER,
        // border: '1px solid red',
      }}
    >
      {variant === 'purple_curved_elipsis' && (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              minHeight: VERTICAL_SPACER_HEIGHT,
              background: COLORS.BACKGROUND_GRADIENT,
            }}
          ></Box>
          <Box
            sx={{
              width: '100%',
              background: COLORS.BACKGROUND_GRADIENT,
              //bc we have increased the total width of the elipsis
              overflow: 'hidden',
              // maxHeight: TOTAL_SECTION_HEIGHT,
            }}
          >
            {/* center it horizontally so we can stretch it to the sides a bit later */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  //this draws half the elipsis in white color
                  borderRadius: '50% / 100% 100% 0 0',
                  backgroundColor: isMobile
                    ? 'white'
                    : COLORS.BACKGROUND_GREY_LIGHTER,
                  height: ELIPSIS_TOTAL_HEIGHT,
                  //border for debug - make sure to disable overlfow hiding in box above to see it
                  // border: '1px solid black',
                  width: ELIPSIS_TOTAL_WIDTH,
                  // marginTop: '-100px',
                }}
              ></Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          marginTop:
            variant === 'purple_curved_elipsis'
              ? MAIN_CONTENT_MARGIN_TOP
              : undefined,
        }}
      >
        <Box
          sx={{
            width: '100%',

            paddingLeft: isMobile
              ? addExtraLeftRightPadding
                ? SPACING._25
                : SPACING._1
              : undefined,
            paddingRight: isMobile
              ? addExtraLeftRightPadding
                ? SPACING._25
                : SPACING._1
              : undefined,
          }}
        >
          {children}
        </Box>
      </Box>

      {/* <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop:
          
        })}
      >
        <Box
          sx={{
            width: '100%',
            paddingLeft: SPACING._1,
            paddingRight: SPACING._1,
          }}
        >
          {children}
        </Box>
      </Box> */}
    </Box>
  )
}

export function RightDesktopContent() {
  const { isAmazon } = useAmazon()
  const { COLORS } = useSColors()

  const { currentSectionId } = useAppLocationFromRoute()
  const [renderMoreDialog, setRenderMoreDialog] = React.useState(false)
  const [moreDialogType, setMoreDialogType] = useState<
    'default' | 'stayOnMedOnly' | 'provenAltOnly'
  >()

  let titleText = "We are here to help, so don't hesitate to let us guide you."

  return (
    <Grid container gap={SPACING._1}>
      <Grid item xs={12}>
        <STypography
          variant="bodybig_bold"
          sx={{ color: COLORS.DESKTOP_RIGHT_CONTENT_PRIMARY_TO_USE }}
        >
          {titleText}
        </STypography>
      </Grid>

      <Grid item xs={12} container direction={'column'}>
        <Grid item container direction="row" wrap="nowrap" spacing={0}>
          <Grid item>
            <ThemedSVGRemote
              importUrl={speechPersonUrl}
              height={153}
              width={72}
              // height={153}
              alt="Learn More"
              disableTheming={isAmazon}
            />
            {/* <ThemedSVG
              SVGComponent={SpeechPersonImageSvg}
              width={72}
              height={153}
              alt="Learn More"
            /> */}
          </Grid>

          <Grid item>
            <SpeechTooltip
              content={
                <STypography variant="body_regular">
                  Learn more about Scripta's ways to help you save
                </STypography>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        onClick={() => {
          setRenderMoreDialog(true)
          setMoreDialogType('stayOnMedOnly')
        }}
        item
        xs={12}
        sx={{ width: '100%', cursor: 'pointer' }}
      >
        <WaysToSaveSectionTitle titleText="Stay on your med" />
      </Grid>

      <Grid item xs={12}>
        <STypography variant="body_regular">
          Use a coupon, fill at a different pharmacy, or ask your doctor to
          change the dose or form.
        </STypography>
      </Grid>
      <Grid item xs={12}>
        <STypography
          variant="body_bold"
          sx={{
            color: COLORS.TEXT_SECONDARY,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => {
            setRenderMoreDialog(true)
            setMoreDialogType('stayOnMedOnly')
          }}
        >
          See More
        </STypography>
      </Grid>
      {renderMoreDialog && moreDialogType === 'stayOnMedOnly' && (
        <SDialog
          open={renderMoreDialog}
          onClose={() => setRenderMoreDialog(false)}
        >
          <WaysToSave variant="stayOnMedOnly"></WaysToSave>
        </SDialog>
      )}

      <Grid
        onClick={() => {
          setRenderMoreDialog(true)
          setMoreDialogType('provenAltOnly')
        }}
        item
        xs={12}
        sx={{ width: '100%', cursor: 'pointer' }}
      >
        <WaysToSaveSectionTitle titleText="Switch to a proven alternative" />
      </Grid>

      <Grid item xs={12}>
        <STypography variant="body_regular">
          Ask your doctor for a new script. Your new med may also have coupons
          or a cheaper pharmacy.
        </STypography>
      </Grid>
      <Grid item xs={12}>
        <STypography
          variant="body_bold"
          sx={{
            color: COLORS.TEXT_SECONDARY,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => {
            setRenderMoreDialog(true)
            setMoreDialogType('provenAltOnly')
          }}
        >
          See More
        </STypography>
      </Grid>
      {renderMoreDialog && moreDialogType === 'provenAltOnly' && (
        <SDialog
          open={renderMoreDialog}
          onClose={() => setRenderMoreDialog(false)}
        >
          <WaysToSave variant="provenAltOnly"></WaysToSave>
        </SDialog>
      )}
    </Grid>
  )
}

export function LeftDesktopNavbar() {
  const { history } = useMemberAppContext()

  //check if coupons feature are disabled
  const { featureConfig } = useMemberAppContext()
  const { isAmazon } = useAmazon()
  const { currentSectionId } = useAppLocationFromRoute()

  const [value, setValue] = React.useState<MainAppSectionName | undefined>(
    currentSectionId,
  )

  if (featureConfig === undefined || featureConfig.isInitialized === false) {
    return null
  }

  //this is copied from bottom nav

  const handleChange = (newValue: MainAppSectionName) => {
    setValue(newValue)

    // setMainSection(newValue as MainAppSectionName)

    switch (newValue) {
      case 'my_opportunities':
        history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)

        break

      case 'my_account':
        history.push(ROUTE_NEW_MY_ACCOUNT_MAIN)

        break

      case 'my_coupons':
        history.push(ROUTE_NEW_COUPONS_MAIN)

        break

      case 'search':
        history.push(ROUTE_NEW_SEARCH)

        break

      default:
        history.push('/')
    }
  }

  return (
    <Grid
      container
      height={'100%'}
      direction={'column'}
      justifyContent={'space-between'}
    >
      <Grid container spacing={2}>
        {/* //need this small tweak for amazon as their logo is bigger */}
        <Grid item xs={12} sx={{ margin: isAmazon ? undefined : MARGIN.sm }}>
          <DesktopLeftnavLogo />
        </Grid>

        <Grid item xs={12}>
          <LeftMenuItem
            icon={faFilePrescription}
            sectionId="my_opportunities"
            onClick={() => {
              handleChange('my_opportunities')
            }}
          />
        </Grid>

        {featureConfig?.isCouponDisabled === false && (
          <Grid item xs={12}>
            <LeftMenuItem
              icon={faTicket}
              sectionId="my_coupons"
              onClick={() => {
                handleChange('my_coupons')
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <LeftMenuItem
            icon={faMagnifyingGlass}
            sectionId="search"
            onClick={() => {
              handleChange('search')
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <LeftMenuItem
            icon={faCircleUser}
            sectionId="my_account"
            onClick={() => {
              handleChange('my_account')
            }}
          />
        </Grid>
      </Grid>

      <Grid item>
        <MemberSupportButton />
      </Grid>
    </Grid>
  )
}

export function LeftMenuItem({
  icon,

  sectionId,

  onClick,
}: {
  icon: any

  sectionId: MainAppSectionName

  onClick?: () => void
}) {
  const { COLORS } = useSColors()

  const { history } = useMemberAppContext()

  const { currentSectionId } = useAppLocationFromRoute()

  let isActive = currentSectionId === sectionId

  let isActiveSx = {
    backgroundColor: COLORS.DESKTOP_LEFT_MENU_ACTIVE_BG,

    color: COLORS.DESKTOP_LEFT_MENU_ACTIVE_TEXT,
  }

  let notActiveSx = {
    backgroundColor: COLORS.DESKTOP_LEFT_MENU_BG,

    color: COLORS.DESKTOP_LEFT_MENU_TEXT,
  }

  let title = getSectionTitle(sectionId)

  return (
    <Box
      sx={{
        padding: SPACING._1,

        borderRadius: RADIUS.sm,

        cursor: 'pointer',

        textAlign: 'left',

        '&:hover': {
          backgroundColor: COLORS.DESKTOP_LEFT_MENU_ACTIVE_BG,

          color: COLORS.DESKTOP_LEFT_MENU_ACTIVE_TEXT,
        },

        ...(isActive ? isActiveSx : notActiveSx),

        display: 'flex',
      }}
      onClick={onClick}
      data-testid={`left-menu-item-${sectionId}`}
    >
      <FontAwesomeIcon
        width={'16px'}
        fontSize={ICON_FONT_SIZE.SMALL_16px}
        icon={icon}
      ></FontAwesomeIcon>

      <STypography sx={{ marginLeft: SPACING._1 }} variant="bodysmall_regular">
        {title}
      </STypography>
    </Box>
  )
}

export function NotificationsMenu() {
  const { COLORS } = useSColors()

  return (
    <FontAwesomeIcon
      color={COLORS.TEXT_LIGHT_PRIMARY_WHITE}
      // fontSize={ICON_FONT_SIZE.MEDIUM_38px}

      fontSize="32px"
      icon={faBell}

      // icon="fa-solid fa-coffee"
    ></FontAwesomeIcon>
  )
}

export function CenteredLogo({ variant }: { variant?: 'light' | 'dark' }) {
  const { history } = useMemberAppContext()
  const theme = useTheme()
  const whiteLogoUrl = theme.customized.whiteOnDarkLogoUrl
  const darkOnWhiteLogoUrl = theme.customized.darkOnWhiteLogoUrl

  //need a bit different width & height for amaon logo
  let width = 132
  let height = 40
  if (theme.customized.name === 'amazon-blue') {
    width = 94
    height = 40
  }

  return (
    <Box
      onClick={() => history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)}
      sx={{
        display: 'flex',

        flexDirection: 'row',

        alignItems: 'center',

        justifyContent: 'center',
        paddingTop: SPACING._1,
      }}
    >
      <img
        // src={scriptaLogoPurple}

        src={variant === 'dark' ? darkOnWhiteLogoUrl : whiteLogoUrl}
        width={width}
        height={height}
        alt="Logo"
      />
    </Box>
  )
}

export function DesktopLeftnavLogo() {
  const { history } = useMemberAppContext()
  const theme = useTheme()
  const darkOnWhiteLogoUrl = theme.customized.darkOnWhiteLogoUrl

  //need a bit different width & height for amazon logo
  let width = 132
  let height = 40
  if (theme.customized.name === 'amazon-blue') {
    width = 94
    height = 40
  }

  return (
    <Box
      onClick={() => history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)}
      sx={{
        display: 'flex',

        flexDirection: 'row',

        alignItems: 'center',

        justifyContent: 'center',
        paddingTop: SPACING._1,
      }}
    >
      <img
        // src={scriptaLogoPurple}

        src={darkOnWhiteLogoUrl}
        width={width}
        height={height}
        alt="Logo"
      />
    </Box>
  )
}
