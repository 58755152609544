export enum CLASS_NAMES {
  checkOutOtherStrategiesLink = 'check-out-other-strategies-link',
  savingsStrategyVideoOverlay = 'savings-strategy-video-overlay',
  saveNowButton = 'save-now-button',

  saveThisMedButton = 'save-this-med-button',
  removeThisMedButton = 'remove-this-med-button',

  updatePricingDrugFilterButton = 'update-pricing-drug-filter-button',
  drugPricingAltRow = 'drug-pricing-alt-row',
  getDrugPricingButton = 'get-drug-pricing-button',

  saveCouponButton = 'save-coupon-button',
  getCouponButton = 'get-coupon-button',
  updateCouponZipButton = 'update-coupon-zip-button',

  navMenuItemLogout = 'nav-menu-item-logout',
  navMenuItem = 'nav-menu-item',

  hideThisMedButton = "hide-this-med-button",
  unhideThisMedButton = "unhide-this-med-button",

  removeBookmarkButton = "remove-bookmark-button",

  updatePasswordButton = "update-password-button",
  updateContactInfoButton = "update-contact-info-info",

  inviteDepButton = "dep-invite-invite-dep-button",
  editDepEmailButton = "dep-invite-edit-dep-email-button",
  enterDepEmailButton = "dep-invite-enter-dep-email-button",

  ellipsisViewDrugInfoItem = "view-drug-info-menu-item",
  ellipsisGetPricingItem = "get-my-pricing-menu-item",
  ellipsisHideDrugItem = "hide-this-med-menu-item",
  ellipsisUnhideDrugItem = "unhide-this-med-menu-item",

  faqLanguageToggle = "faq-language-toggle",

  feedbackPopupSubmit = "feedback-popup-submit",
  feedbackPopupCancel = "feedback-popup-cancel"
}
