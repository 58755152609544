import { SavingStrategy } from './strategies/types'
//need to move some util function here to reuse them in tests
//in tests we dont't have DOM and any import chain that opens up scripta-browser-history.ts fails

export function isProvenAlternativeGroup(strategy: SavingStrategy): boolean {
  return arrayIncludes(PROVEN_ALTTERNATIVES_STRATEGIES, strategy)
}

export function isSameMedicineGroup(strategy: SavingStrategy): boolean {
  return arrayIncludes(SAME_MEDICINE_STRATEGIES_GROUP, strategy)
}

//in case of ie11
function arrayIncludes<T>(arr: T[], item: T): boolean {
  return arr.indexOf(item) > -1
}

export const SAME_MEDICINE_STRATEGIES_GROUP: SavingStrategy[] = [
  SavingStrategy.Coupons,
  SavingStrategy.FormSwitch,
  SavingStrategy.FrequencyChange,
  SavingStrategy.MultiPill,
  SavingStrategy.PillSplit,
  SavingStrategy.PharmacySwitch,
  SavingStrategy.$4$9Program,
]
export const PROVEN_ALTTERNATIVES_STRATEGIES: SavingStrategy[] = [
  SavingStrategy.AlternateGeneric,
  SavingStrategy.Generic,
  SavingStrategy.Related,
  SavingStrategy.NewBrand,
  SavingStrategy.Separate,
  SavingStrategy.OTC,
]
