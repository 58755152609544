import {
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  AccountSettings,
  isServerErrorResponse,
  isValidResponse,
  MemberProfile,
} from '../../types'
import { useNotifier } from '../hooks/useNotify'
import { useMemberAppContext } from '../MemberAppContext'
import { EditEmailData } from '../registration/EditContactInfo'
import { REG_ROUTE_SERVER_ERROR } from '../registration/RegistrationSubRoutes'
import { CLASS_NAMES } from '../shared/constants'
import SButton from '../system/customcomponents/SButton'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import { useSizes } from '../v2/useNewHooks'
import { CommunicationPreferences } from './CommunicationPreferences'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
  toFullNumberFromDisplayFriendlyString,
} from './phone-field-utils'
import { useChangePasswordStyles, useMyAccountStyles } from './styles'
import { useMemberService } from './useMemberService'

export function MyAccount({
  forceMobileMode = false,
  variant,
}: {
  forceMobileMode?: boolean
  variant: 'preferences'
}) {
  const service = useMemberService()
  const { sendMsg } = useNotifier()
  const { currentMemberProfile, setCurrentMemberProfile, history, isSsoLogin } =
    useMemberAppContext()
  if (!currentMemberProfile)
    throw Error('cannot update, profile not found in context')
  const { t } = useSTranslate('account')
  const [newMobileNumberDisplayed, setNewMobileNumberDisplayed] = useState(
    //from current member profile it comes with country code 12223334444
    //so change it for ui display purposes to (NNN)NNN-NNNN
    currentMemberProfile?.phoneNumber
      ? toDisplayFriendlyString(currentMemberProfile.phoneNumber)
      : '',
  )
  const [newEmail, setNewEmail] = useState(
    currentMemberProfile?.email ? currentMemberProfile.email : '',
  )
  //allow user to trigger depes dialog manually
  const [isLoading, setIsLoading] = useState(false)
  const [settings, setSettings] = useState<AccountSettings>()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) || forceMobileMode
  const isMobileMode: boolean = isSmall
  const { isDesktop } = useSizes()

  const settingsStyles = useMyAccountStyles()
  const styles = useChangePasswordStyles()
  async function loadSettingsAsync() {
    const s = await service.getMemberSettingsApi()
    if (isValidResponse<AccountSettings>(s)) {
      setSettings(s)
    }
  }

  useEffect(() => {
    loadSettingsAsync()
  }, [])

  const [isNewEmailValid, setIsNewEmailValid] = useState(true)
  const [isNewPhoneValid, setIsNewPhoneValid] = useState(true)

  async function saveProfileAsync(updatedProfile: MemberProfile) {
    setIsLoading(true)
    const saveResponse = await service.saveMemberProfileApi(updatedProfile)
    setIsLoading(false)
    if (isValidResponse<any>(saveResponse)) {
      //
      //then reload the latest profile again to get the changes
      const s = await service.getMemberProfileApi()
      sendMsg('Your profile has been updated successfully.', 'success')
      if (isValidResponse<MemberProfile>(s)) {
        setCurrentMemberProfile(s)
      } else {
        console.error('failed to read back profile after update...')
      }
    } else {
      try {
        //
        // const errorJson = await (saveResponse as any).json()
        sendMsg(
          saveResponse
            ? (saveResponse as any).description
            : 'Failed to updated your profile.',
          'error',
        )
      } catch (error) {
        setIsLoading(false)
        throw Error('Error in chng pwd api exec')
      }
    }
  }

  async function loadLatestProfileAsync() {
    const resp = await service.getMemberProfileApi()
    if (isServerErrorResponse<MemberProfile>(resp)) {
      history.push(REG_ROUTE_SERVER_ERROR)
      return
    }

    if (isValidResponse<MemberProfile>(resp)) {
      setCurrentMemberProfile(resp)
    }
  }
  useEffect(() => {
    loadLatestProfileAsync()
  }, [])

  useEffect(() => {
    document.title = 'My Account - Scripta Insights'
  })

  const doSaveProfile = useCallback(() => {
    if (!isNewEmailValid || !isNewPhoneValid) {
      return
    }
    const updatedProfile: MemberProfile = {
      ...currentMemberProfile,
      email: newEmail,
      //normalize the phone number here
      phoneNumber: toFullNumberFromDisplayFriendlyString(
        newMobileNumberDisplayed,
      ),
    }

    //small fix to prevent the empty phone number ("1") being saved
    //as this prevent the profile save (i.e. updating just the email)
    if (updatedProfile.phoneNumber === '1') {
      updatedProfile.phoneNumber = ''
    }

    saveProfileAsync(updatedProfile)
  }, [newMobileNumberDisplayed, newEmail, isNewEmailValid, isNewPhoneValid])

  if (variant === 'preferences') {
    return (
      <Grid
        container
        spacing={SPACING._1}
        justifyContent="center"
        direction={'column'}
      >
        <Grid item>
          <STypography variant="body_bold"> {t('titleContact')}</STypography>
        </Grid>
        <Grid item>
          <EditEmailData
            initEmailValue={newEmail}
            onEmailContactChanged={(newEmail: string) => {
              setNewEmail(newEmail)
            }}
            onIsEmailValidChanged={(valid) => {
              setIsNewEmailValid(valid)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            autoComplete="off"
            placeholder={t('mobile')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+1</InputAdornment>
              ),
            }}
            fullWidth
            label={t('mobile')}
            value={newMobileNumberDisplayed}
            onChange={(e) => {
              //process and format input to display friendly form
              //up to 10 digits
              if (toDigitsOnly(e.target.value).length <= 10) {
                let displayNumberValue = toDisplayFriendlyString(e.target.value)
                setNewMobileNumberDisplayed(displayNumberValue)
                const isValid = isDisplayFriendlyStringValidNumber(
                  e.target.value,
                )
                setIsNewPhoneValid(isValid)
              }
            }}
          />
        </Grid>
        <Grid item>
          <Grid item container justifyContent="center">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <SButton
                className={CLASS_NAMES.updateContactInfoButton}
                variant="contained"
                fullWidth
                disabled={!isNewEmailValid || !isNewPhoneValid || isLoading}
                onClick={(e) => {
                  doSaveProfile()
                }}
                noCaps
                sx={isDesktop ? { maxWidth: '300px' } : undefined}
              >
                {t('saveProfile')}
              </SButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CommunicationPreferences variant="saving_report" />
        </Grid>
        <Grid item>
          <CommunicationPreferences variant="marketing" />
        </Grid>
      </Grid>
    )
  }

  //only used one left is variant='preferences' with the new portal design
  return null
}
