import React from 'react'
import Tab, { TabProps, tabClasses } from '@mui/material/Tab'
import Tabs, { tabsClasses, TabsProps } from '@mui/material/Tabs'
import { FONT_WEIGHT, SPACING } from '../theme2'
import { useSColors } from 'src/app/styles/scripta-theme'

export interface STabsProps extends TabsProps {
  labels: string[]
  activeTabIndex: number
  onTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

export default function STabs({
  labels,
  activeTabIndex,
  onTabChange,
  ...props
}: STabsProps) {
  const { COLORS } = useSColors()

  return (
    <Tabs
      value={activeTabIndex}
      onChange={onTabChange}
      sx={{
        borderRadius: 10,
        width: 'max-content',
        minHeight: 'auto',
        backgroundColor: COLORS.PRIMARY_10,
        padding: '0px 2px',
        [`& .${tabsClasses.indicator}`]: {
          top: 2,
          bottom: 2,
          height: 'auto',
          borderRadius: '29px',
          backgroundColor: '#fff',
          boxShadow:
            '0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.06)',
        },
      }}
      {...props}
    >
      {labels.map((label, index) => (
        <STab key={index} label={label} />
      ))}
    </Tabs>
  )
}

export function STab({ label, ...props }: TabProps) {
  const { COLORS } = useSColors()

  return (
    <Tab
      disableRipple
      label={label}
      {...props}
      sx={{
        textTransform: 'none',
        position: 'relative',
        zIndex: 2,
        textAlign: 'center',
        transition: 'all .5s',
        padding: `6px ${SPACING._075}`,
        color: COLORS.TEXT_BRAND,
        minHeight: 'auto',
        height: 'auto',
        float: 'none',
        fontSize: '13px',
        fontWeight: FONT_WEIGHT.SEMI_BOLD,
        lineHeight: '16px',
        [`&.${tabClasses.selected}`]: {
          color: COLORS.TEXT_BLACK,
        },
        '&:not(.Mui-selected):hover': {
          color: COLORS.TEXT_BLACK,
        },
      }}
    />
  )
}
