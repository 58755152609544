import { Box } from '@mui/material'
import STypography, { STypographyProps } from './customcomponents/STypography'
import { useSColors } from '../styles/scripta-theme'

interface SaveUpToProps {
  upToPrefix: string
  upToAmount: string
  upToSuffix?: string
  isBestPrice: boolean
  isOptionMode?: boolean
  upToAmountTextVariant?: STypographyProps['variant']
}

export default function SaveUpTo({
  upToPrefix,
  upToAmount,
  upToSuffix,
  isBestPrice,
  isOptionMode,
  upToAmountTextVariant = 'bodysmall_bold',
}: SaveUpToProps) {
  const { COLORS } = useSColors()
  if (isOptionMode) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <STypography
          variant="bodysmall_regular"
          sx={{
            color: isBestPrice ? COLORS.TEXT_BRAND : COLORS.TEXT_BLACK,
          }}
        >
          {upToPrefix}{' '}
        </STypography>
        <STypography
          variant="bodybig_bold"
          sx={{
            color: isBestPrice ? COLORS.TEXT_BRAND : COLORS.TEXT_BLACK,
          }}
        >
          {upToAmount}
        </STypography>
        {upToSuffix && (
          <STypography variant="bodysmall_regular"> {upToSuffix}</STypography>
        )}
      </Box>
    )
  }

  return (
    <Box>
      <STypography component={'span'} variant="bodysmall_regular">
        {upToPrefix}{' '}
      </STypography>
      <STypography
        component={'span'}
        variant={upToAmountTextVariant}
        sx={{
          color: isBestPrice ? COLORS.TEXT_BRAND : COLORS.TEXT_BLACK,
        }}
      >
        {upToAmount}
      </STypography>
      {upToSuffix && (
        <STypography component={'span'} variant="bodysmall_regular">
          {' '}
          {upToSuffix}
        </STypography>
      )}
    </Box>
  )
}
