import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
} from '@mui/material'
import CardContainer from './CardContainer'
import SButton from './customcomponents/SButton'
import { SPACING } from './theme2'
import STypography from './customcomponents/STypography'
import bookmarkImgSrc from './images/bookmark_illustration.svg'
import helpSwitchImgSrc from './images/sammy-line-service-support.svg'
import feedbackImgSrc from './images/feedback.svg'

import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { SDialog } from './customcomponents/SDialog'
import { useState, FunctionComponent, SVGProps } from 'react'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'

export function HelperCardBookmark({
  onButtonClick,
}: {
  onButtonClick: () => void
}) {
  const { t } = useSTranslate('newComponents')
  const bookmarkDescription = t('bookmarkDescription')
  const bookmarkButtonText = t('bookmarkButtonText')

  return (
    <HelperCard
      imageSrc={bookmarkImgSrc}
      description={bookmarkDescription}
      buttonText={bookmarkButtonText}
      buttonOnClick={onButtonClick}
    />
  )
}

export function HelperCardHelpSwitch({
  onButtonClick,
}: {
  onButtonClick: () => void
}) {
  const { t } = useSTranslate('newComponents')
  const helpSwitchDescription = t('helpSwitchDescription')
  const helpSwitchButtonText = t('helpSwitchButtonText')

  return (
    <HelperCard
      imageSrc={helpSwitchImgSrc}
      description={helpSwitchDescription}
      buttonText={helpSwitchButtonText}
      buttonOnClick={onButtonClick}
    />
  )
}

export function HelperCardSurvey() {
  const { t } = useSTranslate('newComponents')
  const surveyDescription = 'Help us improve! Share your thoughts'
  const surveyButtonText = 'Help Us Help You'
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <HelperCard
        imageSrc={feedbackImgSrc}
        description={surveyDescription}
        buttonText={surveyButtonText}
        buttonOnClick={() => {
          setIsModalOpen(true)
        }}
      />
      <SwitchFeedBackModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
      />
    </>
  )
}

interface Option {
  id: string
  label: string
  followUp?: string
}

interface Question {
  id: string
  question: string
  options: Option[]
}

interface Survey {
  title: string
  questions: Question[]
}

function SwitchFeedBackModal({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const { COLORS } = useSColors()

  const SURVEY_CONTENT: Survey = {
    title: 'Help us Improve',
    questions: [
      {
        id: 'q1',
        question:
          'How likely are you to make a switch based on the savings opportunity presented?',
        options: [
          {
            id: 'q1a',
            label: 'I don’t plan on switching',
          },
          {
            id: 'q1b',
            label: 'I plan on making the switch',
          },
          {
            id: 'q1c',
            label: 'I’m unsure if I would like to switch',
            followUp: 'Please tell us why you’re unsure',
          },
        ],
      },
      {
        id: 'q2',
        question:
          'If you had the option to speak to a pharmacist to help support you with your med opportunities, would you utilize this option?',
        options: [
          {
            id: 'q2a',
            label: 'Yes',
          },
          {
            id: 'q2b',
            label: 'No',
          },
        ],
      },
    ],
  }
  const OPTIONS_BY_ID: { [key: string]: Option } = {
    q1a: SURVEY_CONTENT.questions[0].options[0],
    q1b: SURVEY_CONTENT.questions[0].options[1],
    q1c: SURVEY_CONTENT.questions[0].options[2],
    q2a: SURVEY_CONTENT.questions[1].options[0],
    q2b: SURVEY_CONTENT.questions[1].options[1],
  }

  const submitButtonText = 'Submit feedback'

  const [selectedSwitchLikelyValue, setSelectedSwitchLikelyValue] = useState('')
  const [selectedWouldUtilizePharmacist, setSelectedWouldUtilizePharmacist] =
    useState('')
  const [switchLikelyFollowUp, setSwitchLikelyFollowUp] = useState('')
  const isSubmitDisabled =
    !selectedSwitchLikelyValue || !selectedWouldUtilizePharmacist

  const handleSwitchLikelyOption = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedSwitchLikelyValue((event.target as HTMLInputElement).value)
  }

  const handleWouldUtilizePharmacist = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedWouldUtilizePharmacist((event.target as HTMLInputElement).value)
  }

  const handleOnSubmit = () => {
    const switchLikelyOption = OPTIONS_BY_ID[selectedSwitchLikelyValue]
    const wouldUtilizePharmacistOption =
      OPTIONS_BY_ID[selectedWouldUtilizePharmacist]
    let isSwitchLikelyOptionHasFollowUp = false

    if (selectedSwitchLikelyValue === 'q1c') {
      isSwitchLikelyOptionHasFollowUp = true
    }

    onClose()
  }

  return (
    <SDialog open={open} onClose={onClose}>
      <Grid container direction={'column'} gap={SPACING._1}>
        <Grid item alignSelf={'center'}>
          <STypography variant="title3_bold" sx={{ color: COLORS.TEXT_BRAND }}>
            {SURVEY_CONTENT.title}
          </STypography>
        </Grid>
        <Grid item>
          <STypography variant="body_semibold">
            {SURVEY_CONTENT.questions[0].question}
          </STypography>
          <FormControl component="fieldset" sx={{ width: 'calc(100% - 35px)' }}>
            <RadioGroup
              aria-label={SURVEY_CONTENT.questions[0].question + ' options'}
              name="how likely make switch options"
              value={selectedSwitchLikelyValue}
              onChange={handleSwitchLikelyOption}
              sx={{ paddingLeft: SPACING._05 }}
            >
              {SURVEY_CONTENT.questions[0].options.map((option) => (
                <>
                  <FormControlLabel
                    value={option.id}
                    control={<Radio />}
                    label={option.label}
                  />
                  {option.followUp !== undefined &&
                    selectedSwitchLikelyValue === 'q1c' && (
                      <TextField
                        multiline
                        minRows={3}
                        maxRows={4}
                        placeholder={option.followUp}
                        fullWidth
                        sx={{ paddingLeft: SPACING._05 }}
                        value={switchLikelyFollowUp}
                        onChange={(event) => {
                          setSwitchLikelyFollowUp(event.target.value)
                        }}
                      />
                    )}
                </>
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <STypography variant="body_semibold">
            {SURVEY_CONTENT.questions[1].question}
          </STypography>
          <FormControl component="fieldset" sx={{ paddingLeft: SPACING._05 }}>
            <RadioGroup
              aria-label={SURVEY_CONTENT.questions[1].question + ' options'}
              name="would utilize pharmacist options"
              value={selectedWouldUtilizePharmacist}
              onChange={handleWouldUtilizePharmacist}
            >
              {SURVEY_CONTENT.questions[1].options.map((option) => (
                <FormControlLabel
                  value={option.id}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item width={'100%'}>
          <SButton
            disabled={isSubmitDisabled}
            variant="contained"
            fullWidth
            onClick={handleOnSubmit}
            sx={{ textTransform: 'none' }}
          >
            {submitButtonText}
          </SButton>
        </Grid>
      </Grid>
    </SDialog>
  )
}

export interface HelperCardProps {
  imageSrc: string
  description: string
  buttonText: string
  buttonOnClick: () => void
}

export default function HelperCard({
  imageSrc,
  description,
  buttonText,
  buttonOnClick,
}: HelperCardProps) {
  return (
    <CardContainer
      sx={{
        width: '240px',
        minHeight: '240px',
        boxShadow: '0px 4px 24px 0px rgba(5, 5, 5, 0.15)',
        paddingTop: SPACING._1,
        paddingBottom: SPACING._1,
        paddingLeft: SPACING._05,
        paddingRight: SPACING._05,
      }}
    >
      <Grid
        container
        direction={'column'}
        alignItems={'center'}
        gap={SPACING._025}
      >
        <Grid item>
          <ThemedSVGRemote
            importUrl={imageSrc}
            alt={description}
            height={120}
            width={120}
          />
          {/* <img src={image} alt={'helper card image'} height={120} /> */}
        </Grid>
        <Grid
          item
          sx={{
            paddingLeft: SPACING._075,
            paddingRight: SPACING._075,
          }}
        >
          <STypography variant="body_regular" sx={{ textAlign: 'center' }}>
            {description}
          </STypography>
        </Grid>
        <Grid
          item
          sx={{
            width: '100%',
          }}
        >
          <SButton
            variant="outlined"
            size="small"
            fullWidth
            onClick={buttonOnClick}
            sx={{
              fontStyle: 'none',
              textTransform: 'none',
            }}
          >
            {buttonText}
          </SButton>
        </Grid>
      </Grid>
    </CardContainer>
  )
}
