import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import LikeIcon from '../../images/like_icon.png'

import {
  isServerErrorResponse,
  isValidResponse,
  RegistrationData,
  Tenant,
} from '../../types'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useRegistration, PrevValuesCache } from './RegistrationContext'
import {
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_REGISTER_TENANT_ACCOUNT,
  REG_ROUTE_SERVER_ERROR,
  REG_ROUTE_LOGIN,
} from './RegistrationSubRoutes'
import {
  ClientNotFoundSupportRequest,
  useRegistrationService,
} from './useRegistrationService'
import { validateEmail } from './EditContactInfo'
import { IS_DEV, checkMicroServiceResponseStatus } from '../apiexec/utils'
import { TenantIdWithOptions } from '../../types'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import {
  CustomTextField,
  CustomTextFieldLabelNode,
} from './components/CustomTextField'
import { useMemberAppContext } from '../MemberAppContext'
import { GAService } from '../application/ga/GAService'
import { RegistrationFormFieldsBox } from './RegistrationContainer'
import { RESPONSE_CODE_COMPANY_NOT_FOUND } from './RegistrationErrorHandler'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import ComapnyNotFoundImage from '../../images/company_not_found_header_icon.png'
import MemberNotFoundImage from '../../images/member_not_found_image.png'
import CustomEmojiRocket from '../../images/custom_emoji_rocket.svg'

import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
} from '../member/phone-field-utils'
import { COMMON_BOLD_WEIGHT, useSColors } from '../styles/scripta-theme'
import { LOG } from '../v2/applog'
import { STrans, useSTranslate } from 'src/app/hooks/useSTranslate'

export type FindTenantSearchType = 'companyName' | 'email'

export function FindTenantComponent() {
  const { COLORS } = useSColors()
  const gaService = new GAService()
  const { t } = useSTranslate(['register', 'common'])
  const { prevValuesCache, setPrevValuesCache } = useRegistration()
  const { setApiResponse, setMatchedTenant, history } = useRegistration()
  const { setClientIdInRegistration, setClientUuid } = useMemberAppContext()
  const service = useRegistrationService()
  const searchValueCache = prevValuesCache?.findTenantSearchValue || ''
  const searchEmailCache = prevValuesCache?.findTenantEmailValue || ''
  const searchValue = IS_DEV() ? 'az' : searchValueCache
  const [companyName, setCompanyName] = useState(searchValue)
  const [companyEmail, setCompanyEmail] = useState(searchEmailCache)
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)
  const inputsAreEmpty = !companyEmail && !companyName
  const shouldDisableNextButton = inputsAreEmpty || isInvalidEmail
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const [renderClientNotFound, setRenderClientNotFound] = useState(false)

  //if no client found and they gave us their email address
  const [renderOkGotIt, setRenderOkGotIt] = useState(false)

  useEffect(() => {
    if (!!companyEmail) setIsInvalidEmail(!validateEmail(companyEmail))
    else setIsInvalidEmail(false)
  }, [companyEmail])

  //clean up previous logo in case they click back from member identification
  useEffect(() => {
    setClientUuid(undefined)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  async function asyncFindTenant(registrationData: RegistrationData) {
    try {
      const resp = await service.findTenantApi(registrationData)

      if (isServerErrorResponse<Tenant>(resp)) {
        LOG.error('registration', 'server error in find tenant', {
          companyName,
          companyEmail,
        })
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<TenantIdWithOptions>(resp)) {
        setApiResponse(resp)
        setMatchedTenant(resp)
        //init the log now that we know who it is
        LOG.initMember(undefined as any, resp?.id)

        if (setClientIdInRegistration) {
          setClientIdInRegistration(resp.id)
        }
        if (setClientUuid) {
          setClientUuid(resp.clientUuid)
        }
        
        history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
        gaService.trackEvent({
          ...gaService.eventMap.step_1_reg_next,
          value:
            companyName && companyName.length > 0
              ? companyName
              : 'company email',
        })
      } else {
        setClientIdInRegistration && setClientIdInRegistration(undefined)
        setClientUuid && setClientUuid(undefined)
        // console.error('not a valid response tenanat, full resp = ', resp)
        setApiResponse(resp)

        if (
          resp &&
          resp.code &&
          resp.code === RESPONSE_CODE_COMPANY_NOT_FOUND
        ) {
          gaService.trackEvent({
            ...gaService.eventMap.step_1_reg_failed,
            value:
              companyName && companyName.length > 0
                ? companyName
                : 'company email',
          })
          setRenderClientNotFound(true)
          LOG.error('registration', 'company not found', {
            companyName,
            companyEmail,
          })
        } else {
          LOG.error('registration', 'error in find tenant', {
            companyName,
            companyEmail,
            resp,
          })
          history.push(REG_ROUTE_ERROR_HANDLER)
        }
      }
    } catch (e) {
      gaService.trackEvent({
        ...gaService.eventMap.step_1_reg_failed,
        value:
          companyName && companyName.length > 0 ? companyName : 'company email',
      })
      throw new Error('error in api execution')
    }
  }

  const onClickNextButton = async () => {
    const registrationData: RegistrationData = {
      companyName: companyName || undefined,
      primaryEmail: companyEmail || undefined,
    }
    await asyncFindTenant(registrationData)
  }

  const onChangeCompanyNameTextField = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setCompanyName(event.target.value)
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      findTenantSearchValue: event.target.value,
    }
    setPrevValuesCache(updatedCache)
  }

  const onChangeCompanyEmailTextField = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setCompanyEmail(event.target.value)
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      findTenantEmailValue: event.target.value,
    }
    setPrevValuesCache(updatedCache)
  }

  //preseect back the tab onlogin page
  const onClickCancelButton = () => {
    history.push(REG_ROUTE_LOGIN, {
      previouslySelectedTab: 'register',
    })
  }

  const LabeledDivider = () => (
    <Divider
      sx={{
        marginTop: '1vh',
        marginBottom: '0.2vh',
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: COMMON_BOLD_WEIGHT,
        }}
      >
        {t('common:or')}
      </Typography>
    </Divider>
  )

  if (renderClientNotFound) {
    return (
      <CompanyOrMemberNotFoundHandler
        variant="company"
        onClickBack={() => {
          setRenderClientNotFound(false)
        }}
        onClickSubmitFeedback={async (email, phone) => {
          const supportRequest: ClientNotFoundSupportRequest = {
            'support-type': 'Company not found',
            'company-data': {
              'search-term': `${companyName}---${companyEmail}`.trim(),
              'user-email': email,
              'user-phone': phone ? toDigitsOnly(phone) : undefined,
            },
          }

          const resp = await service.submitCompanyNotFoundSupportRequest(
            supportRequest,
          )
          const respStatus = checkMicroServiceResponseStatus(resp)
          if (!respStatus.isError) {
            setRenderClientNotFound(false)
            setRenderOkGotIt(true)
          } else {
            LOG.error(
              'registration',
              'EKS error in submit support request',
              resp,
            )
            setRenderClientNotFound(false)
          }
        }}
      />
    )
  }

  return (
    <RegistrationStepCard
      registrationType="registration"
      progressBarStep={1}
      onClickNextButton={onClickNextButton}
      onClickCancelButton={onClickCancelButton}
      nextButtonDisabled={shouldDisableNextButton}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 700,
            marginTop: '10px',
            paddingBottom: isSmall ? '10px' : '20px',
          }}
        >
          {t('welcomeToScripta')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: '10px',
          lineHeight: '20px',
          marginBottom: '15px',
          textAlign: 'center',
          paddingLeft: isSmall ? '20px' : '10px',
          paddingRight: isSmall ? '20px' : '10px',
        }}
      >
        <Typography
          component={'span'}
          sx={{
            fontSize: '1rem',
            fontWeight: 600,
            color: COLORS.TEXT_BRAND,
          }}
        >
          {t('greatNews')}
        </Typography>
        <Typography
          component={'span'}
          sx={{
            fontSize: '1rem',
            fontWeight: 400,
            paddingLeft: '3px',
            // lineHeight: isSmall ? '20px' : '20px',
            lineHeight: '20px',
          }}
        >
          <STrans
            i18nKey={'welcomeDesc1'}
            namespace={['register', 'common']}
          ></STrans>
        </Typography>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 400,
            paddingTop: '20px',
            // lineHeight: isSmall ? '20px' : '20px',
            lineHeight: '20px',
            paddingBottom: '20px',
          }}
        >
          <STrans
            i18nKey={'welcomeDesc2'}
            namespace={['register', 'common']}
          ></STrans>
          <img
            alt="rocket emoji"
            src={CustomEmojiRocket}
            style={{ verticalAlign: 'middle' }}
          />
        </Typography>
      </Grid>
      <RegistrationFormFieldsBox>
        <CustomTextField
          data-testid="company-name-input"
          value={companyName}
          onChange={onChangeCompanyNameTextField}
          label={t('companyName')}
          placeholder={t('enterYourCompanyName')}
        />
        <LabeledDivider />
        <CustomTextField
          data-testid="company-email-input"
          value={companyEmail}
          onChange={onChangeCompanyEmailTextField}
          label={t('companyEmail')}
          placeholder={t('enterYourCompanyEmail')}
          error={isInvalidEmail}
          helperText={isInvalidEmail ? t('invalidComapanyEmail') : ''}
        />
        <Box sx={{ minHeight: isSmall ? '10px' : '20px' }}></Box>
      </RegistrationFormFieldsBox>
      {renderOkGotIt && (
        <GotItDialogForSupportRequest
          onOk={() => {
            setRenderOkGotIt(false)
          }}
        />
      )}
    </RegistrationStepCard>
  )
}

export function CompanyOrMemberNotFoundHandler({
  variant,
  onClickBack,
  onClickSubmitFeedback,
}: {
  variant: 'company' | 'member'
  onClickBack: () => void
  onClickSubmitFeedback: (email: string, mobile: string) => void
}) {
  const { COLORS } = useSColors()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useSTranslate(['register', 'common'])
  //allow user to enter their email address and phone so we can follow up with them
  //and also allow them to try again
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [enableValidation, setEnableValidation] = useState(false)
  const [hasProvidedAtLeastOne, setHasProvidedAtLeastOne] = useState(false)

  useEffect(() => {
    if (email || mobile) {
      if (validateEmail(email) || isDisplayFriendlyStringValidNumber(mobile)) {
        setHasProvidedAtLeastOne(true)
      } else {
        setHasProvidedAtLeastOne(false)
      }
    } else {
      setHasProvidedAtLeastOne(false)
    }
  }, [email, mobile])

  return (
    <NewPortalDialogDesign
      title={
        variant === 'company' ? t('oopsCantRegister') : t('memberCantRegister')
      }
      customTitleSx={{
        color: COLORS.TEXT_BLACK,
        paddingTop: '20px',
        paddingLeft: '0px',
        paddingRight: '0px',
        lineHeight: '29.23px',
      }}
      customPaperSx={{
        width: isSmall ? '90%' : 560,
      }}
      customContentSx={{
        paddingLeft: isSmall ? '15px' : '30px',
        paddingRight: isSmall ? '15px' : '30px',
      }}
      open={true}
      headerImageImport={
        variant === 'company' ? ComapnyNotFoundImage : MemberNotFoundImage
      }
      onClose={() => {
        onClickBack()
      }}
      dualActionProps={{
        onYes: () => {
          setEnableValidation(true)
          if (hasProvidedAtLeastOne) {
            onClickSubmitFeedback(email, mobile)
          }
        },
        yesDisabled: enableValidation && !hasProvidedAtLeastOne,

        onNo: () => {
          onClickBack()
        },
        yesText: 'Submit',
        noText: 'Back',
      }}
      upperRightX={true}
    >
      <Grid container>
        {/* <Grid item xs={12} sx={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <Typography
            sx={{ fontSize: '1.5rem', fontWeight: 700, textAlign: 'center' }}
          ></Typography>
        </Grid> */}
        {variant === 'company' && (
          <>
            <Grid
              item
              xs={12}
              sx={{ padding: isSmall ? '5px' : '20px', marginTop: '10px' }}
            >
              <Typography
                sx={{ fontSize: isSmall ? '1' : '1.2rem', textAlign: 'center' }}
              >
                {t('compNameDoesntAlign')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: '0px', marginTop: '10px' }}>
              <Typography
                sx={{ fontSize: isSmall ? '1' : '1.2rem', textAlign: 'center' }}
              >
                {t('ifTypo')}
              </Typography>
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          sx={{ padding: isSmall ? '5px' : '10px', marginTop: '10px' }}
        >
          <Typography
            sx={{
              fontSize: '1.125rem',
              lineHeight: '24px',
              // paddingLeft: '30px',
              // paddingRight: '30px',
              textAlign: 'center',
              //only italic for company not found
              fontStyle: variant === 'company' ? 'italic' : 'normal',
            }}
          >
            {variant === 'company' ? t('needHand') : t('memberNeedHand')}
          </Typography>
        </Grid>
        <RegistrationFormFieldsBox customWidth="318px">
          {/* //show custom error if at least one is not provided */}
          {enableValidation && !hasProvidedAtLeastOne && (
            <Grid item xs={12} sx={{ paddingTop: '10px' }}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  fontStyle: 'italic',
                  color: 'red',
                }}
              >
                {t('atLeastOneRequired')}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{ paddingTop: '10px' }}>
            <CustomTextField
              label={t('userEmailLabel')}
              placeholder={t('userEmailPlaceholder')}
              value={email ? email : ''}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{ paddingTop: '10px' }}>
            <CustomTextField
              customLabelNode={
                <CustomTextFieldLabelNode
                  label={t('mobileNumberLabel')}
                  instructions={t('mobileNumberFormat')}
                />
              }
              placeholder={t('mobileNumberPlaceholder')}
              value={mobile ? mobile : ''}
              onChange={(e) => {
                if (toDigitsOnly(e.target.value).length <= 10) {
                  let displayNumberValue = toDisplayFriendlyString(
                    e.target.value,
                  )
                  setMobile(displayNumberValue)
                }
              }}
            />
          </Grid>
        </RegistrationFormFieldsBox>
      </Grid>
    </NewPortalDialogDesign>
  )
}

export function GotItDialogForSupportRequest({ onOk }: { onOk: () => void }) {
  const { t } = useSTranslate(['register', 'common'])
  //single action new portal dialog with just the ok button and thumbs up icon header
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <NewPortalDialogDesign
      headerImageImport={LikeIcon}
      // customPaperSx={{ backgroundColor: 'red' }}
      customContentSx={{
        padding: '0px',
      }}
      customPaperSx={{
        width: isSmall ? '85%' : 550,
      }}
      // title="Company Not Found"
      open={true}
      // onClose={() => {
      //   //TODO - go back to login page?
      // }}
      singleActionProps={{
        onClick: () => {
          onOk()
        },
        buttonSx: {
          width: '60px',
        },

        text: 'ok',

        // : 'Ok',
      }}
      // upperRightX={true}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 700,
              textAlign: 'center',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
          >
            {t('gotIt')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
          <Typography
            sx={{
              fontSize: isSmall ? '0.9rem' : '1rem',
              color: 'black',

              textAlign: 'center',
              fontStyle: 'italic',
            }}
          >
            {t('gotItThanks')}
          </Typography>
        </Grid>
      </Grid>
    </NewPortalDialogDesign>
  )
}
