import { SavingStrategy } from '../strategies/types'
import program49IconSrc from './images/strategies/Scripta_4-9Program.svg'
import alternateGenericIconSrc from './images/strategies/Scripta_Alternate Generic.svg'
import couponsIconSrc from './images/strategies/Scripta_Coupons.svg'
import formSwitchIconSrc from './images/strategies/Scripta_FormSwitch.svg'
import frequencyChangeIconSrc from './images/strategies/Scripta_FrequencyChange.svg'
import genericIconSrc from './images/strategies/Scripta_Generic.svg'
import multiPillsIconSrc from './images/strategies/Scripta_Multi-Pills.svg'
import newBrandIconSrc from './images/strategies/Scripta_NewBrand.svg'
import otcIconSrc from './images/strategies/Scripta_OTC.svg'
import pillSplitIconSrc from './images/strategies/Scripta_PillSplit.svg'
import relatedIconSrc from './images/strategies/Scripta_Related.svg'
import separateIconSrc from './images/strategies/Scripta_Separate.svg'
import pharmacySwitchIconSrc from './images/strategies/Scripta_pharmacy-change.svg'

export function getIconForSavingStrategy(strategy: SavingStrategy): string {
  const savingsStrategyIconMap: Record<SavingStrategy, string> = {
    [SavingStrategy.AlternateGeneric]: alternateGenericIconSrc,
    [SavingStrategy.Coupons]: couponsIconSrc,
    [SavingStrategy.$4$9Program]: program49IconSrc,
    [SavingStrategy.FormSwitch]: formSwitchIconSrc,
    [SavingStrategy.FrequencyChange]: frequencyChangeIconSrc,
    [SavingStrategy.Generic]: genericIconSrc,
    [SavingStrategy.MultiPill]: multiPillsIconSrc,
    [SavingStrategy.NewBrand]: newBrandIconSrc,
    [SavingStrategy.OTC]: otcIconSrc,
    [SavingStrategy.PillSplit]: pillSplitIconSrc,
    [SavingStrategy.Related]: relatedIconSrc,
    [SavingStrategy.Separate]: separateIconSrc,
    //return alternateGenericIconSrc for now for unknown strategy to prevent any blowsups
    [SavingStrategy.Unknown]: alternateGenericIconSrc,
    [SavingStrategy.PharmacySwitch]: pharmacySwitchIconSrc,
  }

  return savingsStrategyIconMap[strategy]
}

export function hexToRGBA(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
