import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { List, ListItem, ListItemText } from '@mui/material'
import STypography from './customcomponents/STypography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useState } from 'react'
import { useSColors } from '../styles/scripta-theme'

const PHARMACIES_WITH_COUPONS = [
  'Albertsons',
  'Amber Speciality Pharmacy',
  'Benzer Pharmacy',
  'Big Y Foods',
  'Brookshire Grocery',
  'Capsule Pharmacy',
  'Costco',
  'CVS',
  'Fairview Pharmacy',
  'Food City',
  'Giant Eagle',
  'H-E-B',
  'Hartig Drug',
  'HY-VEE',
  'Ingles',
  'Kinney Drugs',
  'Kroger',
  'Lewis Drugs',
  'Medly Pharmacy',
  'Meijer',
  'Publix',
  'Rite Aid',
  'Spartan Nash',
  'Tops Market',
  'Wakefern',
  'Walgreens',
  'Walmart',
  'Wegman’s',
]

export default function PharmaciesWithCoupons() {
  const { t } = useSTranslate('howToSaveUsingCoupons')
  const [expanded, setExpanded] = useState<boolean>(true)
  const { COLORS } = useSColors()

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, isExpanded) => setExpanded(isExpanded)}
      sx={{ border: 'none', boxShadow: 'none' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <STypography variant="bodybig_bold" sx={{ color: COLORS.TEXT_BRAND }}>
          {t('pharmaciesWithCoupons')}
        </STypography>
      </AccordionSummary>
      <AccordionDetails>
        <List
          component="ul"
          disablePadding
          sx={{
            listStyle: 'disc',
            fontFamily: 'Proxima Nova',
            paddingLeft: 3,
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            marginTop: '-16px',
          }}
        >
          {PHARMACIES_WITH_COUPONS.map((pharmacy, index) => (
            <ListItem
              key={pharmacy}
              disablePadding
              sx={{ display: 'list-item' }}
            >
              <ListItemText primary={`${pharmacy}`} />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
