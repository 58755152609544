import { useEffect, useRef, useState } from 'react'

import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { DrugIdParams } from '../drugprofile/types'

import { DosageOptionsResp } from '../search/types'
import { useDrugSearchService } from '../search/useDrugSearchService'
import SSelect from '../system/customcomponents/SSelect'
import SButton from '../system/customcomponents/SButton'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import { OriginalDrugPricingErrorCard } from 'src/app/v2/NewSearchPage'

interface SimpleOption {
  label: string | number
  value: string | number
}

//for almost fatal error where we dont get most popular id from rxsesne at all (no dosages)
//eventually it really should never happen
//TODO - ask the user for this in the no form error form
const DEFAULT_QUANTITY_WHEN_NO_DOSAGES_RETURNED = 10

export function NewSearchDrugPricingFilters({
  dosagesRespData,
  //the initial drug id and quantity are needed before calling pricing on original / alternatives
  onInitialMostPopularDrugIdResolved,
  onInitialMostPopularQuantityResolved,
  onUpdateClick,
  onFormUpdated,
  onDoseUpdated,
  onQuantityUpdated,
  //this is the drug id that we started with - we may want to replace the most popular id with this one in certain cases
  startingDrugIdParams,
}: {
  dosagesRespData: DosageOptionsResp
  onInitialMostPopularDrugIdResolved: (drugId: number) => void
  onInitialMostPopularQuantityResolved: (quant: number) => void
  onUpdateClick: () => void
  onFormUpdated: (form: string) => void
  onDoseUpdated: (dose: string) => void
  onQuantityUpdated: (quant: number) => void
  startingDrugIdParams?: DrugIdParams
}) {
  const [fallbackQuantityToUseIfNoDosagesAvailable, setFallbackQuantityToUse] =
    useState<number>(DEFAULT_QUANTITY_WHEN_NO_DOSAGES_RETURNED)
  const [switchToUiMock, setSwitchToUiMock] = useState<false>()
  const drugService = useDrugSearchService()

  const [forms, setForms] = useState<string[]>([])
  const [selectedForm, setSelectedForm] = useState<SimpleOption>()

  const [doses, setDoses] = useState<string[]>()
  const [selectedDose, setSelectedDose] = useState<SimpleOption>()

  const [quants, setQuants] = useState<number[]>()
  const [selectedQuantity, setSelectedQuantity] = useState<SimpleOption>()

  const [selectedManualDebug, setSelectedManualDebug] = useState<SimpleOption>()

  //track locally for internal error msg
  const [localDosesErrorResponse, setLocalDosesErrorResp] =
    useState<UiDosagesValidation>({ isValid: true })

  const theme = useTheme()
  const { t: tNew } = useSTranslate('newSearch')

  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isTabletMode: boolean = isMedium
  const isMobileMode: boolean = isSmall

  //for edge case errors
  const [unknownPopular, setUnknownPopular] = useState<boolean>(false)

  //for setting focus automateically to next select as the user is interacting
  const dosageFilterRef = useRef(null)
  const quantityFilterRef = useRef(null)

  useEffect(() => {
    console.log('dosagesRespData', dosagesRespData)
    const v = validateDosageOptionsResponse(dosagesRespData)
    setLocalDosesErrorResp(v)
    if (v.isValid === false && startingDrugIdParams) {
      processUsingStartingDrugId(startingDrugIdParams)
      //lets add some sample placeholder options for the dialog
      const backupQuantityOps = getLastResortOptionsList()
      setQuants(backupQuantityOps)
      //select 30 pills by default,it may be incorrect but nothing should fail at least
      setSelectedQuantity({ value: 30, label: 30 })
    }
    async function processUsingStartingDrugId(drugIdParams: DrugIdParams) {
      //

      if (drugIdParams) {
        const drugIdToUse = await drugService.resolveDrugIdParams(drugIdParams)
        if (drugIdToUse) {
          onInitialMostPopularDrugIdResolved(drugIdToUse)
          //we don't even know the quantity of this drug (i.e. we came from bookmars for example)
          onInitialMostPopularQuantityResolved(
            DEFAULT_QUANTITY_WHEN_NO_DOSAGES_RETURNED,
          )
        }
      }
    }
  }, [dosagesRespData, startingDrugIdParams])

  useEffect(() => {
    //check if exists and its array at least
    if (
      dosagesRespData &&
      dosagesRespData['drug-dosages'] &&
      dosagesRespData['drug-dosages'].length > 0
    ) {
      //
      const mostPopularOption = dosagesRespData['drug-dosages']
        .filter((x) => x['is-popular'] === true)
        .pop()
      //

      //we cannot do anything if no popular is known
      if (!mostPopularOption) {
        setUnknownPopular(true)
        return
      }

      onInitialMostPopularDrugIdResolved(mostPopularOption.id)

      const formList = [
        ...Array.from(
          new Set(dosagesRespData['drug-dosages'].map((d) => d.form)),
        ),
      ]
      setForms(formList)
      const mostPopularForm = mostPopularOption.form
      setSelectedForm({ label: mostPopularForm, value: mostPopularForm })
      onFormUpdated(mostPopularForm)

      //build list of doses for this form
      const dosesListForMostPopularForm = [
        ...Array.from(
          new Set(
            dosagesRespData['drug-dosages']
              .filter((d) => d.form === mostPopularForm)
              .map((d) => d.dosage),
          ),
        ),
      ]
      setDoses(dosesListForMostPopularForm)
      //and get the most popular one
      const mostPopularDose = mostPopularOption.dosage
      setSelectedDose({ label: mostPopularDose, value: mostPopularDose })
      onDoseUpdated(mostPopularDose)

      const quantsList = mostPopularOption.quantity
      setQuants(quantsList)
      //by design it should be the first one
      const mostPopularQuantity = quantsList[0]
      //
      setSelectedQuantity({
        label: mostPopularQuantity,
        value: mostPopularQuantity,
      })
      onQuantityUpdated(mostPopularQuantity)
      onInitialMostPopularQuantityResolved(mostPopularQuantity)
    }
  }, [dosagesRespData])

  const formOps: any[] = forms.map((f) => {
    return { value: f, label: f }
  })
  const doseOps: any[] = doses
    ? doses.map((d) => {
        return { value: d, label: d }
      })
    : []
  const quantOps: any[] = quants
    ? quants.map((q) => {
        return { value: q, label: q }
      })
    : []

  const FORM_SELECT = (
    <SSelect
      components={{
        IndicatorSeparator: null,
      }}
      // autoFocus
      options={formOps}
      isDisabled={
        localDosesErrorResponse && localDosesErrorResponse.isValid === false
          ? true
          : false
      }
      //prevent keyboard input, these are predefined select values
      isSearchable={false}
      placeholder="Form"
      value={selectedForm}
      onChange={(option: any, actionMeta: any) => {
        // const focusToDosage = true
        onFormUpdated(option.value)
        setSelectedForm({ label: option.value, value: option.value })
        // handleFormChanged(option, focusToDosage)

        const newDoses = [
          ...Array.from(
            new Set(
              dosagesRespData['drug-dosages']
                .filter((d) => d.form === option.value)
                .map((d) => d.dosage),
            ),
          ),
        ]
        setDoses(newDoses)
        setSelectedDose(undefined)

        //focus user to next select
        if (dosageFilterRef.current !== null) {
          ;(dosageFilterRef.current as any).focus()
        }

        //we don't know the quantity yet - they need to also select the dose
        //so we can find the row for that form/dose combo and use that
        //so we wait untill they select dose
        setQuants([])
        setSelectedQuantity(undefined)

        // setSelectedDose({ label: newDoses[0], value: newDoses[0] })
      }}
      aria-label={
        'You can use the form combobox to specify a different form of the drug you are currently taking.'
      }
    />
  )

  const DOSE_SELECT = (
    <SSelect
      components={{
        IndicatorSeparator: null,
      }}
      //TODO - check with jiaan how to pass ref?
      // ref={dosageFilterRef}
      options={doseOps}
      isDisabled={
        localDosesErrorResponse && localDosesErrorResponse.isValid === false
          ? true
          : false
      }
      //prevent keyboard input, these are predefined select values
      isSearchable={false}
      placeholder="Dosage"
      value={selectedDose ? selectedDose : null}
      onChange={(option: any, actionMeta: any) => {
        onDoseUpdated(option.value)

        //now we have form and dose so find the corresponding entry to get quantity
        setSelectedDose({ label: option.value, value: option.value })
        //this should never be but fail early for now
        if (!selectedForm) {
          throw new Error(
            'Error - cannot select dose without picking form first',
          )
        }
        const matcheDosageObject = dosagesRespData['drug-dosages']
          .filter((d) => d.form === selectedForm.value)
          .filter((d) => d.dosage === option.value)
          //TODO - add some checks here to make sure its always one?
          .pop()
        if (matcheDosageObject) {
          setQuants(matcheDosageObject.quantity)
          setSelectedQuantity(undefined)

          //focus user automatically
          if (quantityFilterRef.current !== null) {
            ;(quantityFilterRef.current as any).focus()
          }
        }
      }}
      aria-label={
        'You can use the dosage combobox to specify if you are taking a different dosage of the drug.'
      }
    />
  )

  const QUANTITY_SELECT = (
    <SSelect
      // isSearchable={true}
      // components={{
      //   IndicatorSeparator: null,
      // }}
      // ref={quantityFilterRef}
      //prevent keyboard input, these are predefined select values
      // isSearchable={false}

      options={quantOps}
      value={selectedQuantity ? selectedQuantity : null}
      placeholder="Quantity"
      onChange={(option: any, actionMeta: any) => {
        setSelectedQuantity({
          label: option.value,
          value: option.value,
        })
        onQuantityUpdated(option.value)
        // handleQuantityChanged(option)
      }}
      aria-label={
        'You can use the quantity combobox to specify a different quantity of the drug you might be taking'
      }
    />
  )

  return (
    <Grid container>
      <Grid container sx={{ height: '100%' }} spacing={1}>
        <SelectWrapper
          selectControl={FORM_SELECT}
          label={tNew('form')}
          isMobileMode={isMobileMode}
        />
        <SelectWrapper
          selectControl={DOSE_SELECT}
          label={tNew('dosage')}
          isMobileMode={isMobileMode}
        />
        <SelectWrapper
          selectControl={QUANTITY_SELECT}
          label={tNew('quantity')}
          isMobileMode={isMobileMode}
        />
        <Grid item xs={12}>
          <STypography variant="bodysmall_regular">
            {tNew('adjustFilterTip')}
          </STypography>
        </Grid>

        <Grid
          item
          xs={12}
          // sm={3}
          // md={3}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          sx={{ marginTop: SPACING._075 }}
        >
          <SButton
            onClick={() => {
              onUpdateClick()
            }}
            noCaps
          >
            {tNew('updateFiltersButton')}
          </SButton>
        </Grid>
        {/* //if we have the carry over error code i..e 691 (suppressed) from the original response, use it to show the alert */}
        {localDosesErrorResponse &&
          localDosesErrorResponse.isValid === false &&
          dosagesRespData &&
          dosagesRespData.carryOverErrorCode && (
            <Box sx={{ marginTop: SPACING._075 }}>
              <OriginalDrugPricingErrorCard
                errorCode={dosagesRespData.carryOverErrorCode}
              />
            </Box>
          )}
        {/* unspecified error code - here we dont know why we got no dosages back from the backend */}

        {localDosesErrorResponse &&
          localDosesErrorResponse.isValid === false &&
          dosagesRespData &&
          !dosagesRespData.carryOverErrorCode && (
            <Grid item xs={12}>
              <Typography variant="caption">
                {tNew('noDosageOptionsFound')}
              </Typography>
            </Grid>
          )}
      </Grid>
    </Grid>
  )
}

export function SelectWrapper({
  selectControl,
  label,
  isMobileMode,
}: {
  selectControl: any
  label: string
  isMobileMode: boolean
}) {
  return (
    <Grid item xs={4} container alignItems="center">
      <Grid item xs={12}>
        <Typography
          sx={{
            // paddingTop: '12px',
            // paddingRight: '10px',
            fontSize: '11px',
            fontWeight: '400',
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {selectControl}
      </Grid>
    </Grid>
  )
}

export interface UiDosagesValidation {
  isValid: boolean
  reason?: string
}
function validateDosageOptionsResponse(
  originalDosages: DosageOptionsResp,
): UiDosagesValidation {
  //or multiple true values
  //or multiple matches by form and dosage
  let ret = { isValid: true }

  //check the raw server response itself, could be 404 etc
  const respValidation = checkMicroServiceResponseStatus(originalDosages)
  if (respValidation.isError) {
    let serverReason = respValidation.errorMsg
    return { isValid: false, reason: serverReason }
  }

  //check if data is there at all
  if (
    !originalDosages ||
    !originalDosages['drug-dosages'] ||
    originalDosages['drug-dosages'].length === 0
  ) {
    return {
      isValid: false,
      reason: 'No available dosages data can be found.',
    }
  }

  const dosages = originalDosages['drug-dosages']

  //check at least one is most popular
  const mostPopularOption = dosages
    .filter((x) => x['is-popular'] === true)
    .pop()
  //we cannot do anything if no popular is known
  if (!mostPopularOption) {
    return {
      isValid: false,
      reason: 'At least one must be marked as most popular = true.',
    }
  }

  //TODO - should we do below validation for bkank doses?

  // let missingForm = false
  // dosages.forEach((d) => {
  //
  //   if (d.form) {
  //   } else {
  //     missingForm = true
  //   }
  // })

  // if (missingForm) {
  //   return {
  //     isValid: false,
  //     reason: 'Detected a missing/blank form value',
  //   }
  // }

  //check for missing dose
  // let middingDose = false
  // dosages.forEach((d) => {
  //
  //   if (d.dosage) {
  //   } else {
  //     middingDose = true
  //   }
  // })

  // if (middingDose) {
  //   return {
  //     isValid: false,
  //     reason: 'Detected a missing/blank dose value',
  //   }
  // }

  //check for multiple matches for the same drug and dose combo
  let hasMultipleMatches = false
  let errorMsg = ''

  //for each form
  const formList = [...Array.from(new Set(dosages.map((d) => d.form)))]
  formList.forEach((formName) => {
    //get dosages for that form
    const dosesForForm = [
      ...Array.from(
        new Set(
          dosages.filter((d) => d.form === formName).map((d) => d.dosage),
        ),
      ),
    ]
    dosesForForm.forEach((singleDose) => {
      //make sure there is only one match
      const matchedDrugIds = dosages
        .filter((d) => d.form === formName)
        .filter((d) => d.dosage === singleDose)
        .map((d) => d.id)

      if (matchedDrugIds.length > 1) {
        errorMsg =
          'Invalid data returned - multiple matches found for form/dose combo, cannot resolve unique drug id,formName=' +
          formName +
          'and dosagename=' +
          singleDose +
          '.Drug Ids =' +
          matchedDrugIds
        console.error(errorMsg)
        hasMultipleMatches = true
      }
    })
  })

  if (hasMultipleMatches) {
    return {
      isValid: false,
      reason: errorMsg,
    }
  }

  return ret
}
//this is shown in the quantitie drop-down when we dont get anything from backend for dosages
function getLastResortOptionsList() {
  const quants = [1, 5, 10, 15, 30, 60, 90, 120]
  return quants
}
