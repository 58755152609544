import { Box } from '@mui/material'
import { RADIUS, SPACING } from './theme2'
import { STrans, useSTranslate } from 'src/app/hooks/useSTranslate'
import STypography from './customcomponents/STypography'
import { useSColors } from '../styles/scripta-theme'

interface SavingsSummaryCardProps {
  firstName: string
  monthToUse: string
  yearToUse: string | number
  maxSavings?: string
  isNoSavingsMode?: boolean
}

export default function SavingsSummaryCard({
  firstName,
  monthToUse,
  yearToUse,
  maxSavings,
  isNoSavingsMode = false,
}: SavingsSummaryCardProps) {
  const { COLORS } = useSColors()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        background: COLORS.BACKGROUND_LIGHT,
        borderRadius: RADIUS.sm,
        paddingLeft: SPACING._1,
        paddingTop: SPACING._1,
        paddingBottom: SPACING._1,
        paddingRight: SPACING._1,

        backgroundColor: COLORS.BACKGROUND_LIGHT,
        border: `1px solid ${COLORS.PRIMARY_40}`,
        boxShadow: `0px 4px 12px 0px rgba(77, 0, 140, 0.15)`,

        gap: SPACING._1,
      }}
    >
      <Box>
        <GreetingText
          firstName={firstName}
          dateStr={monthToUse + ' ' + yearToUse}
          isNoSavingsMode={isNoSavingsMode}
        />
      </Box>

      {maxSavings ? (
        <Box>
          <NewPurpleDot val={maxSavings} />
        </Box>
      ) : null}
    </Box>
  )
}

function GreetingText({
  firstName,
  dateStr,
  isNoSavingsMode = false,
}: {
  firstName: string
  dateStr: string
  isNoSavingsMode?: boolean
}) {
  const { COLORS } = useSColors()

  const { t } = useSTranslate('newComponents')
  const helloText = t('hello')
  const hereAreYourSavings = t('hereAreYourSavings')

  return (
    <Box>
      <STypography
        data-testid="greeting-text"
        variant="title3_semibold"
        sx={{ marginBottom: SPACING._05, color: COLORS.TEXT_BRAND }}
      >
        {helloText}, {firstName}
      </STypography>
      {isNoSavingsMode ? (
        <STypography variant="body_regular">
          <STrans
            namespace="newComponents"
            i18nKey={'noSavingsSummaryBannerText'}
          />
        </STypography>
      ) : (
        <>
          <STypography variant="body_regular">{hereAreYourSavings}</STypography>
          <STypography variant="body_bold" sx={{ color: COLORS.TEXT_BRAND }}>
            {dateStr}
          </STypography>
        </>
      )}
    </Box>
  )
}

function NewPurpleDot({ val }: { val: string }) {
  const { COLORS } = useSColors()

  const { t } = useSTranslate('savingReport')

  return (
    <Box
      sx={{
        border: `15px solid ${COLORS.RING_SAVINGS_BORDER}`,
        borderRadius: '50%',
        color: COLORS.RING_SAVINGS_TEXT,
        textAlign: 'center',
        width: '111px',
        height: '111px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
    >
      <STypography variant="bodysmall_regular" sx={{ whiteSpace: 'nowrap' }}>
        {t('saveUpTo')}
      </STypography>
      <STypography variant="bodybig_bold" aria-label={val + ' ' + t('dollars')}>
        ${val}
      </STypography>
      <STypography variant="bodysmall_regular">{t('monthly')}</STypography>
    </Box>
  )
}
