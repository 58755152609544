import { useCallback, useEffect, useState } from 'react'
import { FlowStep } from '../widgets/stepper/types'
import {
  faBriefcaseClock,
  faChevronDown,
  faCircleInfo,
  faClipboardPrescription,
  faCheck,
  faStore,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import couponsNotFoundImage from './../system/images/coupons_not_found.svg'
import speachPersonImgSrc from './../system/images/medical_prescription_amico.svg'

import {
  faBallot,
  faCommentDollar,
  faLocationDot,
  faPills,
} from '@fortawesome/pro-regular-svg-icons'

import '@fortawesome/pro-light-svg-icons'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
} from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { Route, useLocation } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { useMemberAppContext } from '../MemberAppContext'
import {
  checkMicroServiceResponseStatus,
  IS_BLUE_PROD,
  IS_DEV,
  IS_INTEGRATION,
  IS_NATIVE,
  nativeApi_setBackButtonVisible,
} from '../apiexec/utils'
import { useNotifier } from '../hooks/useNotify'
import { SavingStrategy } from '../strategies/types'
import CardContainer from '../system/CardContainer'
import DrugDetails from '../system/DrugDetails'
import {
  CouponCardItem,
  DrugListItem,
  DrugListItemProps,
  PaymentOptionStrategyListItem,
  PharmacyListCardItem,
  SaveUpToCardOptionItem,
} from '../system/ListItems'
import {
  NavBar,
  NavBarCombination,
  getNavbarColorVariant,
} from '../system/NavBar'
import PriceElement from '../system/PriceElement'
import SavingMethodModal from '../system/SavingMethodModal'
import SpeechTooltip from '../system/SpeechTooltip'
import { TagLabel } from '../system/TagLabel'
import WaysToSave from '../system/WaysToSave'
import SButton from '../system/customcomponents/SButton'
import { SDialog } from '../system/customcomponents/SDialog'
import STypography from '../system/customcomponents/STypography'
import {
  ModalCashAndInsurance,
  ModalTypesOfPharmacy,
} from '../system/modals/Modals'

import {
  // COLORS,
  ICON_FONT_SIZE,
  MARGIN,
  RADIUS,
  SPACING,
} from '../system/theme2'
import { DrugElipsisMenu, ElipsisOps } from '../widgets/DrugElipsisMenu'
import MultiStepFlowProgressBar from '../widgets/stepper/MultiStepFlowProgressBar'
import {
  HowToSaveAltRxCompare,
  HowToSaveCouponDetails,
  HowToSaveIntro,
  HowToSaveLastStep,
  HowToSavePharmacyDetails,
} from './HowToSaveSummary'
import {
  ElipsisActionMenuForSelectedOpportunity,
  MemberZipCodeLocationField,
  ROUTE_NEW_COUPONS_MAIN,
  ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN,
  ROUTE_NEW_SEARCH,
} from './NewMemberPortalMain'
import { BackgroundContainer } from './NewPageContainer'
import { LOG } from './applog'
import {
  AlternateDrugModel,
  CouponDetails,
  DEFAULT_QUANITYT_FOR_COUPONS_FETCH_IF_NOT_SPECIFIED,
  PaymentType,
  SameOrNewMedChoiceType,
  SavingGuideData,
  SavingOpportunityDataModel,
  WayToSaveItem,
  getCashCouponItems,
  getCashPharmacyItems,
  getDistanceStr,
  getDollarsPerMonthStr,
  getHigestOptionInTwo,
  getInsurancePharmacyChangeItems,
  getInsurancePharmacyStayItems,
  getNewMedTherapeuticAlternatesList,
  isMarkCubanCostPlustName,
} from './saving-utils'
import { useSizes, useXServiceTodoBettername } from './useNewHooks'
import { fixBrokenRxSensePharmacyLogoUrlInIntOrDev } from './utils'
import {
  AdditionalPharmacyDetailsByNpiLookupResp,
  useMemberService,
} from '../member/useMemberService'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from './ThemedSVGRemote'

//steps and routes for saving guide
export const ROUTE_OPPORTUNITY_ROOT = '/opportunity'
export const ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED =
  '/opportunity/same_med_or_new_med'

export const ROUTE_OPPORTUNITY_CHOSE_NEW_MED = '/opportunity/chose_new_med'

//payment type
export const ROUTE_OPPORTUNITY_PAYMENT_OPTIONS = '/opportunity/payment_options'

//cash options - coupon OR cash pharmacy change
export const ROUTE_OPPORTUNITY_CASH_OPTIONS = '/opportunity/cash_options'
export const ROUTE_OPPORTUNITY_INSURANCE_OPTIONS =
  '/opportunity/insurance_options'

export const ROUTE_OPPORTUNITY_ITEMS_LIST = '/opportunity/items_list'

//for coupons - get the list of coupons from rxsense
export const ROUTE_OPPORTUNITY_COUPONS_LIST = '/opportunity/coupons_list'

export const ROUTE_OPPORTUNITY_HOW_TO_SAVE_INTRO =
  '/opportunity/how_to_save_summary/intro'

export const ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW =
  '/opportunity/how_to_save_summary/alternative_review'

export const ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW =
  '/opportunity/how_to_save_summary/pharmacy_review'

export const ROUTE_OPPORTUNITY_HOW_TO_SAVE_COUPON_CARD_REVIEW =
  '/opportunity/how_to_save_summary/coupon_review'

export const ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP =
  '/opportunity/how_to_save_summary/final_step'

export interface SavingGuideNavbarState {
  title: string
  hidden: boolean
  variant: NavBarCombination
  rightActionName: 'opportunity_info' | 'saving_method_info' | undefined
}

export function SavingGuide({
  savingGuideData,
}: {
  savingGuideData: SavingGuideData
}) {
  const { isMobile } = useSizes()
  const { history, bookmarkedOpportunities, setIsMobileBottomNavbarVisible } =
    useMemberAppContext()

  useEffect(() => {
    if (IS_NATIVE()) {
      nativeApi_setBackButtonVisible(false)
    }
  }, [])

  const [selectedOpportunity, setSelectedOpportunity] = useState<
    SavingOpportunityDataModel | undefined
  >()

  useEffect(() => {
    setSelectedOpportunity(savingGuideData.selectedOpportunity)
  }, [savingGuideData])

  //no bottom navbar in saving guide
  useEffect(() => {
    setIsMobileBottomNavbarVisible(false)
    return () => {
      setIsMobileBottomNavbarVisible(true)
    }
  }, [])

  const [sameOrNewMedChoice, setSameOrNewMedChoice] = useState<
    SameOrNewMedChoiceType | undefined
  >()

  const [paymentChoice, setPaymentChoice] = useState<PaymentType | undefined>(
    undefined,
  )

  const [newMedDrugName, setNewMedDrugName] = useState<string | undefined>()

  const [selectedWayToSaveItem, setSelectedWayToSaveItem] =
    useState<WayToSaveItem>()

  const [itemListVariant, setItemListVariant] = useState<
    ItemListVariant | undefined
  >(undefined)

  const [selectedCouponItem, setSelectedCouponItem] = useState<CouponDetails>()

  const [renderHowToSummary, setRenderHowToSummary] = useState(false)

  const { t, ready } = useSTranslate('savingGuide')

  //shared props for navbar between saving guide and how to save
  const [navbarState, setNavbarState] = useState<SavingGuideNavbarState>({
    title: ready ? t('titleSaveOnRx') : 'Save on your Rx',

    hidden: false,
    variant: 'bodybig_title_center_white',
    rightActionName: 'opportunity_info',
  })

  const [renderHowToSaveMethodInfoModal, setRenderHowToSaveMehtodInfoModal] =
    useState(false)

  //preload for coupon on original if its set to true on saving guide
  useEffect(() => {
    if (
      savingGuideData &&
      savingGuideData.selectedOpportunity &&
      savingGuideData.autoSelectCouponItem
    ) {
      // const preselctCouponOnOriginalItem =
      //   savingGuideData.waysToSaveForOpportunity
      //     .filter((item) => {
      //       return item.savingStrategy === SavingStrategy.Coupons
      //     })
      //     .pop()

      // setIsMyCouponsOnOriginalView(true)

      setNavbarForSavingGuide(isMobile)

      //this is needed as well as its a constraint before rending the coupons list component instandard flow
      setSameOrNewMedChoice(
        savingGuideData.autoSelectCouponItem.sameOrNewMedChoice,
      )
      setSelectedWayToSaveItem(savingGuideData.autoSelectCouponItem)
      //update the selected item in top bar context (for info action
      pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_COUPONS_LIST)
    } else {
      // setIsMyCouponsOnOriginalView(false)
    }
  }, [savingGuideData, isMobile])

  const notifier = useNotifier()

  // preload for bookmark
  //TODO - what if they bookmarked a pharmacy-switch type?
  //can there be more than one match combo for altDrugId / mmaId / inouse_pharmacy
  useEffect(() => {
    if (
      savingGuideData.selectedOpportunity &&
      savingGuideData.selectedOpportunity.isBookmarked
    ) {
      //
      const bookmarkData = bookmarkedOpportunities.find(
        (b) => b.originalDrugId === savingGuideData.selectedOpportunity.drugId,
      )
      //
      //

      const debugAllPossibleMatches =
        savingGuideData.waysToSaveForOpportunity.filter(
          (w) =>
            w.altId === bookmarkData?.alternativeDrugId &&
            w.originalId === bookmarkData?.originalDrugId &&
            w.altMmaIdForHelpMeSwitch === bookmarkData?.mmaIdForHelpMeSwitch &&
            w.type === bookmarkData?.wayToSaveType,
        )
      //

      if (debugAllPossibleMatches.length !== 1) {
        LOG.error(
          'bookmarks',
          'Not one exact way wat to save item matched against bookmarked data. Disabling bookmarked option automatically.All Matches=',
          { bookmarkData, debugAllPossibleMatches },
        )
        //at this point, its better to 'expire' this bookmark and ask the user to go thru guide again
        //otherwise, they may be stuck at invalid state in howtosavesummary esp. since there is no ui to remove bookmark
        //for ex - we end up getting in the future multiple matches with same drugId/mmaId/type - but diff pharmacy location

        notifier.sendMsg(t('expiredBookmark'), 'info')

        return
      }

      //warning - make sure find is the same as filter above
      //it will have to be one by now
      const preselctedBookmarkedWayToSaveItem = debugAllPossibleMatches.pop()

      //update local state bsed on that item
      if (preselctedBookmarkedWayToSaveItem) {
        setSelectedWayToSaveItem(preselctedBookmarkedWayToSaveItem)

        setSameOrNewMedChoice(
          preselctedBookmarkedWayToSaveItem?.sameOrNewMedChoice,
        )
        //set the new medicine name if appropriate
        if (
          preselctedBookmarkedWayToSaveItem?.sameOrNewMedChoice === 'new_med'
        ) {
          setNewMedDrugName(preselctedBookmarkedWayToSaveItem?.altName)
        }
        //get coupon details from bookmarked data
        setSelectedCouponItem(bookmarkData?.selectedCoupon)

        //jump to summary
        initiateHowToSaveSummaryFlow()
      }
    } else {
      //
    }
  }, [savingGuideData])

  const location = useLocation()
  useEffect(() => {
    const path = location && location.pathname ? location.pathname : ''

    //setup navbar in case we started the how to save summary
    if (
      path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_INTRO ||
      path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW ||
      path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW ||
      path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_COUPON_CARD_REVIEW ||
      path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP
    ) {
      setNavbarForHowToSave(isMobile)
      //TODO - this case review - bc we have moved this to a separate component
      // setRenderStepper(false)
      setRenderHowToSummary(true)
    } else {
      setNavbarForSavingGuide(isMobile)
    }
  }, [location, isMobile])

  const buildUrlTrackingParams = useCallback(() => {
    let params = ''
    //just in case some of these are null...
    try {
      if (selectedOpportunity) {
        params += `?originalDrugName=${selectedOpportunity.drugName}`
      }
      if (sameOrNewMedChoice) {
        params += `&sameOrNewMedChoice=${sameOrNewMedChoice}`
      }
      if (newMedDrugName) {
        params += `&newMedicineName=${newMedDrugName}`
      }
      if (selectedWayToSaveItem) {
        params += `&altDrugFormDsgCnt=${selectedWayToSaveItem.altName}-${selectedWayToSaveItem.altForm}-${selectedWayToSaveItem.altDosage}-${selectedWayToSaveItem.altCountQtcStr}`
        params += `&strategy=${selectedWayToSaveItem.savingStrategy}`
      }
      return params
    } catch (e) {
      console.error('error building url tracking params', e)
      return ''
    }
  }, [
    selectedOpportunity,
    selectedWayToSaveItem,
    newMedDrugName,
    sameOrNewMedChoice,
  ])

  //need a make other set states (i.e. setSelectedAlternative)
  //is finished before doing standard history.push to subroute
  //so we do it by settinng a custom route and then route to it in useEffect
  const [targetRoute, setTargetRoute] = useState<TargetRoute>()
  function pushToSubrouteViaEffect(subroute: string) {
    //we use the object, not a simple string, to ensure useEffect triggers
    const route: TargetRoute = {
      //todo - this guid is probably not needed anymore, we use a object in state
      uuid: uuidv4(),
      route: subroute,
    }

    setTargetRoute(route)
  }

  //here we watch for the target route to be set and then we push to it
  useEffect(() => {
    if (targetRoute) {
      history.push(targetRoute.route + buildUrlTrackingParams())
    }
  }, [targetRoute, buildUrlTrackingParams])

  function setNavbarForSavingGuide(isMobile: boolean) {
    const s = {
      title: ready ? t('titleSaveOnRx') : 'Save on your Rx',
      hidden: false,
      variant: 'bodybig_title_center_white' as const,
      rightActionName: 'opportunity_info' as const,
    }
    setNavbarState(s)
  }
  function setNavbarForHowToSave(isMobile: boolean) {
    const s = {
      title: t('titleHowToSave'),
      hidden: false,
      variant: isMobile
        ? ('bodybig_title_center_purple' as const)
        : ('bodybig_title_center_white' as const),
      rightActionName: 'saving_method_info' as const,
    }
    setNavbarState(s)
  }

  const initiateHowToSaveSummaryFlow = useCallback(() => {
    setNavbarForHowToSave(isMobile)

    setRenderHowToSummary(true)
    //jump to pharmacy review for coupons on original
    // if (
    //   savingGuideData.autoSelectCouponItem &&
    //   savingGuideData.autoSelectCouponItem.savingStrategy ===
    //     SavingStrategy.Coupons
    // ) {
    //   pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW)
    // } else {
    //   pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_HOW_TO_SAVE_INTRO)
    //   // pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW)
    // }

    pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_HOW_TO_SAVE_INTRO)
  }, [isMobile])

  //this will also happen right now if they resize the window and trigger mobile
  //layout, bc react will rerender the very top component, and
  //i think that causes the state to be lost (selectedOpportunity)
  //so if not found, lets go back to root?
  //fix back button i.e. coming here from some other page (i.e. my coupons)
  //TODO - review this
  useEffect(() => {
    if (!selectedOpportunity && !savingGuideData) {
      console.error(
        '=======NO SELECETED OPPORTUNITY outside of root location, back to root',
      )

      // history.push(ROUTE_OPPORTUNITY_ROOT)
    }
  }, [selectedOpportunity, savingGuideData])

  //scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  //quick update to page title for analytics oobox capture
  useEffect(() => {
    const path = location && location.pathname ? location.pathname : ''
    let suffix = ''
    //based on path, use english title for analytics of the current route as suffix
    switch (path) {
      case ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED:
        suffix = 'Same or New Medication'
        break
      case ROUTE_OPPORTUNITY_CHOSE_NEW_MED:
        suffix = 'Chose New Medication'
        break
      case ROUTE_OPPORTUNITY_PAYMENT_OPTIONS:
        suffix = 'Chose Cash or Insurance'
        break
      // case ROUTE_OPPORTUNITY_WAY_TO_SAVE:
      //   suffix = 'Chose Way to Save'
      //   break
      case ROUTE_OPPORTUNITY_COUPONS_LIST:
        suffix = 'Choose Coupon to use'
        break
      // case ROUTE_OPPORTUNITY_HOW_TO_SAVE_INTRO:
      //   suffix = 'How to Save - Introduction'
      //   break
      case ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW:
        suffix = 'How to Save - Rx Compare'
        break
      case ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW:
        suffix = 'How to Save - Pharmacy Review'
        break
      case ROUTE_OPPORTUNITY_HOW_TO_SAVE_COUPON_CARD_REVIEW:
        suffix = 'How to Save - Coupon Card Review'
        break
      case ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP:
        suffix = 'How to Save - Final Step'
        break
      default:
        suffix = 'How to Save Generic Step'
    }
    let title = ''

    if (savingGuideData.autoSelectCouponItem) {
      title = `My Coupons - Saving Guide - ${suffix}`
    } else {
      if (savingGuideData.source === 'saving_report') {
        title = `Savings Report - Saving Guide - ${suffix}`
      } else if (savingGuideData.source === 'search_results') {
        title = `Search Results - Saving Guide - ${suffix}`
      }
    }

    document.title = title
  }, [
    location,
    selectedOpportunity,
    selectedWayToSaveItem,
    newMedDrugName,
    sameOrNewMedChoice,
  ])

  //back button edge case ex. - got to final step by clicking find other ways to save
  //and then browser back
  useEffect(() => {
    if (location.pathname === ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP) {
      //no selected item - because we clear it when they click find other ways to save
      if (!selectedWayToSaveItem) {
        if (savingGuideData.source === 'saving_report')
          history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)
        //can happen in search guide as well
        else if (savingGuideData.source === 'search_results') {
          history.push(ROUTE_NEW_SEARCH)
        } else {
          //should never happen enless another guide source gets added
          history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)
        }
      }
    }
  }, [location])

  return (
    <Box>
      {!navbarState.hidden && (
        <Grid item xs={12}>
          <NavBar
            variant={navbarState.variant}
            title={navbarState.title}
            //TODO - move this to a separate component so its easier to read
            //based on the current set action - it will etiehr render elipsis action for opportunity
            //or info action for selected item in the how to save flow
            rightAction={
              navbarState.rightActionName === 'saving_method_info' ? (
                selectedWayToSaveItem ? (
                  <WayToSaveInfoIconForHowToSaveSummary
                    navbarVariant={navbarState.variant}
                    wayToSaveItem={selectedWayToSaveItem}
                  />
                ) : undefined
              ) : selectedOpportunity !== undefined ? (
                <ElipsisActionMenuForSelectedOpportunity
                  opportunity={selectedOpportunity}
                  savingGuideCurrentSource={savingGuideData.source}
                ></ElipsisActionMenuForSelectedOpportunity>
              ) : undefined
            }
            customOnClickBack={() => {
              //for my coupons, when in coupons list, we need to go back to the my coupons root
              //and not the previous page
              if (
                savingGuideData &&
                savingGuideData.autoSelectCouponItem &&
                location &&
                location.pathname === ROUTE_OPPORTUNITY_COUPONS_LIST
              ) {
                history.push(ROUTE_NEW_COUPONS_MAIN)
                //similar to above, - for bookmarked opportunity, when clicking back
                //from rx compare (first page), go back home
              } else if (
                savingGuideData &&
                savingGuideData.selectedOpportunity &&
                savingGuideData.selectedOpportunity.isBookmarked &&
                location &&
                location.pathname ===
                  ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW
              ) {
                history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)
              } else {
                history.goBack()
              }
            }}
          />
        </Grid>
      )}
      <BackgroundContainer variant="clear_white">
        <SavingGuideHeader
          savingGuide={savingGuideData}
          newMedName={newMedDrugName}
          selectedWayToSave={selectedWayToSaveItem}
          sameOrNewMedChoice={sameOrNewMedChoice}
          itemListVariant={itemListVariant}
        />

        <Route path={ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED} exact>
          {selectedOpportunity && (
            <ChooseStaySameOrNewMed
              allWaysToSave={savingGuideData.waysToSaveForOpportunity}
              saving={selectedOpportunity}
              onClickNewMed={() => {
                setSameOrNewMedChoice('new_med')
                //For new medicine, we need to go to intermediate step
                //where they can select the new drug first
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_CHOSE_NEW_MED)
              }}
              //for same meds, directly we go to the list of ways to save
              onClickSameMed={() => {
                setSameOrNewMedChoice('same_med')
                //reset new med name if it was set before
                setNewMedDrugName(undefined)
                // pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_WAY_TO_SAVE)
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_PAYMENT_OPTIONS)
              }}
            />
          )}
        </Route>
        <Route path={ROUTE_OPPORTUNITY_CHOSE_NEW_MED} exact>
          {selectedOpportunity && (
            <ChooseNewAlternateMedicationFromList
              allWaysToSave={savingGuideData.waysToSaveForOpportunity}
              // saving={selectedOpportunity}
              onDrugItemClick={(drugItem) => {
                const newMedName = drugItem.drugName

                setNewMedDrugName(newMedName)
                setSameOrNewMedChoice('new_med')
                // pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_WAY_TO_SAVE)
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_PAYMENT_OPTIONS)
              }}
            />
          )}
        </Route>
        <Route path={ROUTE_OPPORTUNITY_PAYMENT_OPTIONS} exact>
          {selectedOpportunity && sameOrNewMedChoice && (
            <PaymentOptions
              sameOrNewMedChoice={sameOrNewMedChoice}
              newMedDrugName={newMedDrugName}
              allWaysToSave={savingGuideData.waysToSaveForOpportunity}
              saving={selectedOpportunity}
              onClickCash={() => {
                setPaymentChoice('cash')
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_CASH_OPTIONS)
              }}
              onClickInsurance={() => {
                setPaymentChoice('insurance')
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_INSURANCE_OPTIONS)
              }}
            />
          )}
        </Route>

        <Route path={ROUTE_OPPORTUNITY_CASH_OPTIONS} exact>
          {selectedOpportunity && sameOrNewMedChoice && paymentChoice && (
            <CashOptionsGroup
              sameOrNewMedChoice={sameOrNewMedChoice}
              newMedDrugName={newMedDrugName}
              paymentType={paymentChoice}
              allWaysToSave={savingGuideData.waysToSaveForOpportunity}
              saving={selectedOpportunity}
              onClickCoupon={() => {
                setItemListVariant('cash_coupons')
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_ITEMS_LIST)
              }}
              onClickCashPharmacy={() => {
                // setCashOrInsuranceChoice('insurance')
                // setCashBasedFilterChoice('cash_pharmacy')
                setItemListVariant('cash_pharmacies')
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_ITEMS_LIST)
              }}
            />
          )}
        </Route>

        <Route path={ROUTE_OPPORTUNITY_INSURANCE_OPTIONS} exact>
          {selectedOpportunity && sameOrNewMedChoice && paymentChoice && (
            <InsuranceOptionsGroup
              sameOrNewMedChoice={sameOrNewMedChoice}
              newMedDrugName={newMedDrugName}
              paymentType={paymentChoice}
              allWaysToSave={savingGuideData.waysToSaveForOpportunity}
              saving={selectedOpportunity}
              onClickChangePharmacy={() => {
                setItemListVariant('insurance_change_pharmacy')
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_ITEMS_LIST)
              }}
              onClickStayPharmacy={() => {
                setItemListVariant('insurance_stay_pharmacy')
                pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_ITEMS_LIST)
              }}
            />
          )}
        </Route>

        <Route path={ROUTE_OPPORTUNITY_ITEMS_LIST} exact>
          {selectedOpportunity &&
            sameOrNewMedChoice &&
            paymentChoice &&
            itemListVariant && (
              <ListOfWaysToSaveItems
                variant={itemListVariant}
                sameOrNewMedChoice={sameOrNewMedChoice}
                newMedDrugName={newMedDrugName}
                // paymentType={paymentChoice}
                allWaysToSave={savingGuideData.waysToSaveForOpportunity}
                saving={selectedOpportunity}
                // onClickCouponItem={(couponItem) => {
                //   setSelectedCouponItem(couponItem)
                //   initiateHowToSaveSummaryFlow()
                // }}
                onClickWayToSaveItem={(wayToSaveItem) => {
                  //this is to select the item representing the coupon saving

                  //still need to pick the exact coupon
                  setSelectedWayToSaveItem(wayToSaveItem)
                  //if coupons there is extra step to select the coupon
                  if (wayToSaveItem.type === 'coupon') {
                    pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_COUPONS_LIST)
                  } else {
                    initiateHowToSaveSummaryFlow()
                  }
                  //initiateHowToSaveSummaryFlow()
                }}
                //TODO from legacy <ChooseWayToSave component useeffect?
                // onComponentInitialized={() => {
                //   //this is mostly to fix up if user goes to coupons, selects something then clicks back
                //   //if we are on this page, it means we need to reset any coupons seelction
                //   setSelectedCouponItem(undefined)
                // }}
              />
            )}
        </Route>

        <Route path={ROUTE_OPPORTUNITY_COUPONS_LIST} exact>
          {selectedOpportunity &&
            sameOrNewMedChoice &&
            selectedWayToSaveItem && (
              <CouponsListFromRxSense
                showMemberZipCodeUpdateField={true}
                showSaveUpToAmount={true}
                onCouponClick={(coupon) => {
                  setSelectedCouponItem(coupon)
                  initiateHowToSaveSummaryFlow()
                }}
                // onCouponUnselected={() => {
                //   setSelectedCouponItem(undefined)
                // }}
                wayToSaveItem={selectedWayToSaveItem}
                showTryAnotherZipCodeButton={false}
                onClickTryAnotherZipCode={() => {}}
              />
            )}
        </Route>
      </BackgroundContainer>
      <Route path={ROUTE_OPPORTUNITY_HOW_TO_SAVE_INTRO} exact>
        {selectedOpportunity && selectedWayToSaveItem && renderHowToSummary && (
          <HowToSaveIntro
            selectedOpportunity={selectedOpportunity}
            selectedWayToSaveItem={selectedWayToSaveItem}
            selectedCoupon={selectedCouponItem}
            isMyCouponsView={
              savingGuideData.autoSelectCouponItem ? true : false
            }
          />
        )}
      </Route>

      <Route path={ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW} exact>
        {selectedOpportunity && selectedWayToSaveItem && renderHowToSummary && (
          <HowToSaveAltRxCompare
            selectedOpportunity={selectedOpportunity}
            selectedWayToSaveItem={selectedWayToSaveItem}
            selectedCoupon={selectedCouponItem}
            isMyCouponsView={
              savingGuideData.autoSelectCouponItem ? true : false
            }
          />
        )}
      </Route>
      <Route path={ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW} exact>
        {selectedOpportunity && selectedWayToSaveItem && renderHowToSummary && (
          <HowToSavePharmacyDetails
            selectedOpportunity={selectedOpportunity}
            selectedWayToSaveItem={selectedWayToSaveItem}
            selectedCoupon={selectedCouponItem}
            isMyCouponsView={
              savingGuideData.autoSelectCouponItem ? true : false
            }
            onNewCouponSelected={(updatedCoupon) => {
              setSelectedCouponItem(updatedCoupon)
            }}
          />
        )}
      </Route>
      <Route path={ROUTE_OPPORTUNITY_HOW_TO_SAVE_COUPON_CARD_REVIEW} exact>
        {selectedOpportunity && selectedWayToSaveItem && renderHowToSummary && (
          <HowToSaveCouponDetails
            selectedOpportunity={selectedOpportunity}
            selectedWayToSaveItem={selectedWayToSaveItem}
            selectedCoupon={selectedCouponItem}
            isMyCouponsView={
              savingGuideData.autoSelectCouponItem ? true : false
            }
          />
        )}
      </Route>
      <Route path={ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP} exact>
        {selectedOpportunity && selectedWayToSaveItem && renderHowToSummary && (
          <HowToSaveLastStep
            selectedOpportunity={selectedOpportunity}
            selectedWayToSaveItem={selectedWayToSaveItem}
            selectedCoupon={selectedCouponItem}
            isMyCouponsView={
              savingGuideData.autoSelectCouponItem ? true : false
            }
            setSelectedCoupon={setSelectedCouponItem}
            onHandleFindAnotherWayToSave={() => {
              // setIsHereViaFindOtherWaysToSave(true)
              setItemListVariant(undefined)
              setNewMedDrugName(undefined)
              setSameOrNewMedChoice(undefined)
              setSelectedCouponItem(undefined)
              setSelectedWayToSaveItem(undefined)
              pushToSubrouteViaEffect(ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED)
            }}
          />
        )}
      </Route>
      {renderHowToSaveMethodInfoModal && selectedWayToSaveItem && (
        <SavingMethodModalWrapper
          wayToSaveItem={selectedWayToSaveItem}
          isModalOpen={renderHowToSaveMethodInfoModal}
          setIsModalOpen={setRenderHowToSaveMehtodInfoModal}
        />
      )}
      {/* </Switch> */}
    </Box>
  )
}

export function ChooseStaySameOrNewMed({
  saving,
  allWaysToSave,
  onClickNewMed,
  onClickSameMed,
}: {
  saving: SavingOpportunityDataModel
  allWaysToSave: WayToSaveItem[]
  onClickNewMed: () => void
  onClickSameMed: () => void
}) {
  const { t } = useSTranslate('savingGuide')

  const staySameList = allWaysToSave.filter(
    (item) => item.sameOrNewMedChoice === 'same_med',
  )
  const newMedList = allWaysToSave.filter(
    (item) => item.sameOrNewMedChoice === 'new_med',
  )

  const {
    hasA: hasSameMedsOption,
    isABestPrice: isSameMedBestPrice,
    highestAAmountStr: sameMedAmountStr,
    hasB: hasNewMedsOption,
    isBBestPrice: isNewMedBestPrice,
    highestBAmountStr: highestNewMedAmountStr,
  } = getHigestOptionInTwo(staySameList, newMedList)

  return (
    <Box>
      <Box sx={{ marginBottom: SPACING._1 }}>
        <STypography component="span" variant={'body_regular'}>
          We found these ways for you to save,
        </STypography>
        <STypography
          sx={{ paddingLeft: '4px' }}
          component={'span'}
          variant={'body_bold'}
        >
          choose the one you prefer to continue:
        </STypography>
      </Box>

      <Box display="flex" gap={2} sx={{ cursor: 'pointer' }}>
        {/* PROVEN ALTERNATIVES AKA NEW MEDICINE */}
        {hasNewMedsOption && (
          <SaveUpToCardOptionItem
            dataTestId="saving-guide-option-new-med"
            onClick={onClickNewMed}
            isBestPrice={isNewMedBestPrice}
            upToPrefix={t('upToPrefix')}
            upToSuffix={t('upToSuffixAlt')}
            upToAmount={highestNewMedAmountStr ? highestNewMedAmountStr : 'NA'}
          />
        )}
        {/* SAME MEDICINE  */}

        {hasSameMedsOption && (
          <SaveUpToCardOptionItem
            dataTestId="saving-guide-option-stay-same-med"
            onClick={onClickSameMed}
            isBestPrice={isSameMedBestPrice}
            upToPrefix={t('upToPrefix')}
            upToSuffix={t('upToSuffixSameMed')}
            upToAmount={sameMedAmountStr ? sameMedAmountStr : 'NA'}
          />
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          // justifyContent: 'center',
          marginTop: SPACING._15, //should be one with proper image
        }}
      >
        <LearnAboutPersonTooltip variant="ways_to_save" />
      </Box>
    </Box>
  )
}

export function ChooseNewAlternateMedicationFromList({
  allWaysToSave,
  onDrugItemClick,
}: {
  allWaysToSave: WayToSaveItem[]
  onDrugItemClick: (item: AlternateDrugModel) => void
}) {
  const introText = 'These alternatives may be a good fit for you:'

  // const { setSelectedTherapeuticAltDrugName } = useSavingGuideContext()

  const altDrugs = getNewMedTherapeuticAlternatesList(allWaysToSave)
  //in case we have zero here, log it as error event
  //we should not be here bc navaigation should be disabled to this page
  if (altDrugs.length === 0) {
    LOG.error(
      'generic_error',
      'User arrived to Choose Alternative list but alternatives list is empty. This should be prevented by navigation.',
      { allWaysToSave },
    )
  }

  return (
    <>
      <Box sx={{ paddingTop: SPACING._05 }}>
        <Box sx={{ marginBottom: SPACING._1 }}>
          <STypography
            data-testid="alts-list-title"
            component="span"
            variant={'body_regular'}
          >
            These alternatives may be a good fit for you,
          </STypography>
          <STypography
            sx={{ paddingLeft: '4px' }}
            component={'span'}
            variant={'body_bold'}
          >
            select one to continue:
          </STypography>
        </Box>
      </Box>
      {altDrugs.length === 0 && <Box>No alternatives found</Box>}
      {altDrugs.map((alt, idx) => {
        let isBestPrice = idx === 0

        //we can have multiple strategies per drug, i.e. Pill-Split and Form Switch on Adderall a
        //recommended alternatives. So here we will show them all as chips
        let strategyTagControls: any[] = []
        if (isBestPrice) {
          strategyTagControls.push(
            <TagLabel
              key={'best_key_' + idx}
              size="small"
              color="green"
              text="Best Price"
            />,
          )
        }

        alt.strategies.map((strategy, idx) => {
          strategyTagControls.push(
            <TagLabel
              key={'strat_key_' + idx}
              size="small"
              color="purple"
              text={strategy}
            />,
          )
        })
        const ellipsisMenu = (
          <ElipsisActionMenuForNewAlternateMedicineItem altDrug={alt} />
        )
        const uiProps: DrugListItemProps = {
          ...alt,
          ellipsisMenu: ellipsisMenu,
          upToPrefix: 'Save Up To',
          onClick: () => {},
        }
        return (
          <Box
            data-altid={`${alt.highestSavingAltDrugId}`}
            data-alt-mmaid={`${alt.highestSavingAltMmaId}`}
            data-alt-name={`${alt.drugName}`}
            key={'box_' + idx}
            onClick={() => {
              onDrugItemClick(alt)
            }}
            sx={{ cursor: 'pointer', marginTop: MARGIN.xs }}
          >
            <DrugListItem
              key={'drug_' + idx}
              {...uiProps}
              tags={strategyTagControls}
              isBestPrice={isBestPrice}
            />
          </Box>
        )
      })}
    </>
  )
}

function SavingMethodModalWrapper({
  wayToSaveItem,
  isModalOpen,
  setIsModalOpen,
}: {
  wayToSaveItem: WayToSaveItem
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
}) {
  return (
    <SavingMethodModal
      strategy={wayToSaveItem.savingStrategy}
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
      }}
      isCashPharmacy={wayToSaveItem.pharmacyInfo?.isCash}
      isMailOrder={wayToSaveItem.pharmacyInfo?.isMailOrder}
      // is90DaysSupply
    />
  )
}

function WayToSaveInfoIconForHowToSaveSummary({
  wayToSaveItem,
  navbarVariant,
}: {
  wayToSaveItem: WayToSaveItem
  navbarVariant: NavBarCombination
}) {
  const { COLORS } = useSColors()

  const colorVariant = getNavbarColorVariant(navbarVariant)
  const [renderDialog, setRenderDialog] = useState(false)

  return (
    <>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setRenderDialog(true)
        }}
      >
        <FontAwesomeIcon
          color={
            colorVariant === 'white'
              ? COLORS.TEXT_BLACK
              : COLORS.TEXT_LIGHT_PRIMARY_WHITE
          }
          fontSize={ICON_FONT_SIZE.SMALL_16px}
          icon={faCircleInfo}
        ></FontAwesomeIcon>
      </Box>
      {renderDialog && (
        <SavingMethodModalWrapper
          wayToSaveItem={wayToSaveItem}
          isModalOpen={renderDialog}
          setIsModalOpen={setRenderDialog}
        />
      )}
    </>
  )
}

function LearnAboutPersonTooltip({
  variant,
}: {
  variant:
    | 'ways_to_save'
    | 'payment_options'
    | 'cash_reminder'
    | 'pharmacy_types'
}) {
  const { COLORS } = useSColors()

  const { history } = useMemberAppContext()
  const { t } = useSTranslate('savingGuide')
  const [renderModal, setRenderModal] = useState(false)

  let text_title = ''
  let link_title = ''
  if (variant === 'ways_to_save') {
    text_title = t('learnMoreAbout')
    link_title = t('scriptasWays')
  }
  if (variant === 'payment_options') {
    text_title =
      'Paying by cash means you are not using your insurance so it wouldn’t count towards your deductible.'
    link_title = 'Learn more about this.'
  }
  if (variant === 'cash_reminder') {
    text_title =
      'Remember, prescriptions purchased with cash do not count towards your deductible.'
    link_title = 'Learn more about this.'
  }
  if (variant === 'pharmacy_types') {
    link_title = 'Learn more about the different pharmacy types'
  }

  let TEXT_CONTROL = (
    <STypography component={'span'} variant={'bodysmall_regular'}>
      {text_title}{' '}
      <STypography
        variant={'bodysmall_bold'}
        component={'span'}
        sx={{
          cursor: 'pointer',
          color: COLORS.PRIMARY_BRAND,
          textDecoration: 'underline',
        }}
        onClick={() => {
          //
          setRenderModal(true)
        }}
      >
        {link_title}
      </STypography>
    </STypography>
  )

  return (
    <>
      <Grid item xs={12} container direction={'column'}>
        <Grid item container direction="row" wrap="nowrap" spacing={0}>
          <Grid item>
            <ThemedSVGRemote
              imgDataTestId={`learn-more-img-${variant}`}
              importUrl={speachPersonImgSrc}
              width={72}
              height={153}
              alt="Learn More"
            />
          </Grid>

          <Grid
            item
            sx={{ cursort: 'pointer' }}
            // onClick={() => {
            //   history.push(ROUTE_LEARN_ABOUT_WAYS_TO_SAVE)
            // }}
          >
            <SpeechTooltip
              content={
                <Grid
                  container
                  gap={SPACING._05}
                  justifyContent="space-between"
                  flexWrap={'nowrap'}
                  alignItems={'center'}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    gap={SPACING._05}
                    flexWrap={'nowrap'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Box
                        sx={{
                          padding: SPACING._05,
                          borderRadius: SPACING._05,
                          // backgroundColor: styleToUse.mainIconContainerBackground,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          color={COLORS.TEXT_BRAND}
                          width={'24px'}
                          fontSize={'24px'}
                          style={{ paddingTop: '5px' }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ paddingRight: '15px', lineHeight: '14px' }}
                    >
                      {TEXT_CONTROL}
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      {renderModal && variant === 'ways_to_save' && (
        <SDialog
          open={renderModal}
          onClose={() => {
            setRenderModal(false)
          }}
        >
          <WaysToSave />
        </SDialog>
      )}
      {renderModal && variant === 'payment_options' && (
        <ModalCashAndInsurance
          open={renderModal}
          onClose={() => {
            setRenderModal(false)
          }}
        />
      )}
      {/* TODO - is this the same modal? */}
      {renderModal && variant === 'cash_reminder' && (
        <ModalCashAndInsurance
          open={renderModal}
          onClose={() => {
            setRenderModal(false)
          }}
        />
      )}
      {renderModal && variant === 'pharmacy_types' && (
        <ModalTypesOfPharmacy
          open={renderModal}
          onClose={() => {
            setRenderModal(false)
          }}
        />
      )}
    </>
  )
}
//TODO?
export function CouponsListFromRxSense({
  wayToSaveItem,
  onCouponClick,
  //reset/clear selected coupon, i.e. if clicking back from coupons list
  ////TODO_is_this_still_needed_onCouponUnselected,
  //all the below 3 are to control different behavior when using this component in the how to save summary to update location

  //when showing coupons in the how to save summary update location dialog flow,
  //we already have the zip code field in the previous modal so dont show it here
  showMemberZipCodeUpdateField,
  //also do not show save up to amount via update location flow

  showSaveUpToAmount,

  //we need another button lined up in this component that is used in the how to save summary update location flow modals
  showTryAnotherZipCodeButton,
  onClickTryAnotherZipCode,
}: {
  wayToSaveItem: WayToSaveItem
  onCouponClick: (selectedCoupon: CouponDetails) => void
  //TODO_is_this_still_needed_onCouponUnselected: () => void

  showMemberZipCodeUpdateField: boolean
  showSaveUpToAmount: boolean
  showTryAnotherZipCodeButton: boolean
  onClickTryAnotherZipCode: () => void
}) {
  const { COLORS } = useSColors()

  const service = useXServiceTodoBettername()
  const { currentMemberProfile, savingGuide } = useMemberAppContext()
  const { history } = useMemberAppContext()
  const [couponItems, setCouponsItems] = useState<CouponDetails[]>([])
  const [zipCodeToUse, setZipCodeToUse] = useState('')
  useEffect(() => {
    if (currentMemberProfile && currentMemberProfile.zip) {
      setZipCodeToUse(currentMemberProfile.zip)
    }
  }, [currentMemberProfile])

  const [isLoading, setIsLoading] = useState(true)

  async function fetchCouponItems(
    zipToUse: string,
    qtcToUse: number,
    drugId: number,
  ) {
    setIsLoading(true)
    try {
      const res = await service.getCouponItemsForMember(
        zipToUse,
        qtcToUse,
        drugId,
      )
      // manually fix broken pharmacy logo URLS in integration
      if (IS_DEV() || IS_INTEGRATION() || IS_BLUE_PROD()) {
        res.forEach((coupon) => {
          if (coupon.pharmacyLogoUrl) {
            coupon.pharmacyLogoUrl = fixBrokenRxSensePharmacyLogoUrlInIntOrDev(
              coupon.pharmacyLogoUrl,
            )
          }
        })
      }
      setCouponsItems(res)
      setIsLoading(false)
    } catch (e) {
      LOG.error('coupons', 'Got UI exception trying to fetch coupons,e=', e)
      setIsLoading(false)
    }
    // setCouponsItems(res)
  }

  // useEffect(() => {
  //   onCouponUnselected()
  // }, [])

  useEffect(() => {
    //no zip or invalid zip, nothing to do
    if (!zipCodeToUse || zipCodeToUse.length !== 5) {
      //
      return
    }
    let quantity = wayToSaveItem.altCountNumber
    if (!quantity) {
      quantity = DEFAULT_QUANITYT_FOR_COUPONS_FETCH_IF_NOT_SPECIFIED
    }
    let isCouponOnOriginal =
      wayToSaveItem.savingStrategy === SavingStrategy.Coupons

    //TODO - coupons on original vs alternative
    let drugId = isCouponOnOriginal
      ? wayToSaveItem.originalId
      : wayToSaveItem.altId

    // fetchCouponItems(zipCodeToUse, quantity, drugId)
    fetchCouponItems(zipCodeToUse, quantity, drugId)
  }, [wayToSaveItem, zipCodeToUse])

  const upToAmount = getDollarsPerMonthStr(wayToSaveItem.saveAmountResolved)

  //this is "no coupons anymore" bc it means between the time savings report was generated and right now,
  //coupon on the recommendations is no longer available
  const hasCoupons = !isLoading && couponItems && couponItems.length > 0

  const { t } = useSTranslate('savingGuide')

  return (
    <>
      <Grid container gap={SPACING._1}>
        <Grid item xs={12}>
          {hasCoupons && showSaveUpToAmount && (
            <Box>
              <STypography
                component="span"
                color={COLORS.TEXT_BRAND}
                variant={'body_bold'}
              >
                {t('cardSaveUpToPrefix')}
                {upToAmount}
              </STypography>
              <STypography
                component={'span'}
                variant={'body_regular'}
                sx={{ color: COLORS.TEXT_BLACK }}
              >
                {' '}
                {t('cardByOneOfTheseCoupons')} for{' '}
              </STypography>
              {/* {savingGuide && savingGuide.autoSelectCouponItem && ( */}
              <STypography
                component="span"
                color={COLORS.TEXT_BRAND}
                variant={'body_bold'}
              >
                {wayToSaveItem.altName}
              </STypography>
              {/* )} */}
            </Box>
          )}
        </Grid>
        {/* when triggered from the how to save summary location update screen, we have the input field in the other dialog */}
        {showMemberZipCodeUpdateField && (
          <Grid item xs={12}>
            <MemberZipCodeLocationField
              showChangeWillReflectNextMonthDialog={false}
            />
          </Grid>
        )}
      </Grid>

      {isLoading && (
        <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && couponItems && couponItems.length === 0 && (
        <Box sx={{ marginTop: SPACING._2 }}>
          <NoCouponsAvailable
            //in the savings guide during initial pick of coupon, do not enable
            //try another zip code - bc we already have location input above
            enableTryAnotherZipCode={showTryAnotherZipCodeButton}
            onClickFindOtherWaysToSave={() => {
              history.push(ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED)
            }}
            onClickTryAnotherZipCode={() => {
              if (onClickTryAnotherZipCode) {
                onClickTryAnotherZipCode()
              }
            }}
          />
        </Box>
      )}

      {!isLoading && couponItems && couponItems.length > 0 && (
        <Box>
          {/* Number of coupons: {couponItems.length} */}
          {couponItems.map((couponItem, idx) => {
            // let uiProps = { ...couponItem }
            const distanceStr = getDistanceStr(couponItem)
            const priceStr = `$${couponItem.priceCash}`
            const pharmacyName = couponItem.pharmacyName
            const pharmacyLogoUrl = couponItem.pharmacyLogoUrl

            const tags = []
            if (idx === 0) {
              tags.push(
                <TagLabel
                  key="bestPriceTag"
                  size="small"
                  color="green"
                  text={t('tagBestPrice')}
                />,
              )
            }
            tags.push(
              <TagLabel
                key="standardTag"
                size="small"
                color="blue"
                text={t('tagCoupon')}
              />,
            )
            return (
              <Box
                key={idx}
                // onClick={() => {
                //   onCouponClick(couponItem)
                // }}
                sx={{ cursor: 'pointer', marginTop: SPACING._1 }}
              >
                <CouponCardItem
                  dataTestId={'coupon-item'}
                  tags={tags}
                  distance={distanceStr}
                  onClick={() => {
                    onCouponClick(couponItem)
                  }}
                  pharmacyName={pharmacyName}
                  pharmacyLogoUrl={pharmacyLogoUrl}
                  price={priceStr}
                />
              </Box>
            )
          })}
        </Box>
      )}
    </>
  )
}

export function NoCouponsAvailable({
  //when in modal (launched from the how to save summary find another location)
  //it is in dialog, so we want this button?
  enableTryAnotherZipCode,
  onClickFindOtherWaysToSave,
  onClickTryAnotherZipCode,
}: {
  enableTryAnotherZipCode: boolean
  onClickFindOtherWaysToSave: () => void
  onClickTryAnotherZipCode: () => void
}) {
  const { t } = useSTranslate('savingGuide')
  return (
    <Grid container gap={SPACING._1}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img alt="No Coupons Available image" src={couponsNotFoundImage} />
      </Grid>
      <Grid item xs={12}>
        <STypography variant="body_regular">{t('noCoupons')}</STypography>
      </Grid>
      {enableTryAnotherZipCode && (
        <Grid item xs={12} container justifyContent={'center'}>
          <SButton
            noCaps
            fullWidth
            onClick={() => {
              onClickTryAnotherZipCode()
            }}
          >
            {t('tryAnotherZip')}
          </SButton>
        </Grid>
      )}
      <Grid item xs={12} container justifyContent={'center'}>
        <SButton
          variant="outlined"
          fullWidth
          sx={{
            textTransform: 'none',
          }}
          onClick={() => {
            onClickFindOtherWaysToSave()
          }}
        >
          {t('findOtherWays')}
        </SButton>
      </Grid>
    </Grid>
  )
}

export function ElipsisActionMenuForNewAlternateMedicineItem({
  altDrug,
}: {
  altDrug: AlternateDrugModel
}) {
  const { COLORS } = useSColors()
  const [renderMenu, setRenderMenu] = useState(false)
  //TODO - remove all this none sense wiht mma ids etc and enteir drug params
  const altActionMenuOps: ElipsisOps = {
    drugContext: 'alternative',
    actions: {
      showDrugProfile: true,
      showMyPricing: false,
      showHideDrug: true,
    },
    drugIdParams: {
      mmaId: altDrug.highestSavingAltMmaId,
      drugId: altDrug.highestSavingAltDrugId,
      separatesParams: altDrug.separatesParams,
    },
    // pricingQuantityDefault: 30,
    // useLatestForLabelOnDrugProfile: true,
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: COLORS.PRIMARY_10,
          borderRadius: RADIUS.sm,
          padding: SPACING._025,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <DrugElipsisMenu ops={altActionMenuOps}></DrugElipsisMenu>
      </Box>
    </>
  )
}

// TODO: internationalize language text
export function OriginalDrugCard({
  savingOpportunity,
  expanded,
  setExpanded = () => {},
  variant = 'accordion',
}: {
  savingOpportunity: SavingOpportunityDataModel
  expanded?: boolean
  setExpanded?: (expanded: boolean) => void
  variant?: 'card' | 'accordion'
}) {
  const { COLORS } = useSColors()

  const { t } = useSTranslate('savingGuide')
  const yourMedText = t('yourMed')

  const {
    drugName,
    drugCount,
    drugDosage,
    drugForm,
    priceAfterDed,
    priceBeforeDed,
    priceCopay,
  } = savingOpportunity
  const hasPrice: boolean =
    (priceAfterDed !== undefined && priceBeforeDed !== undefined) ||
    priceCopay !== undefined

  if (variant === 'accordion') {
    return (
      <Accordion
        expanded={expanded}
        onChange={(e, expanded) => {
          setExpanded(expanded)
        }}
        sx={{
          boxShadow: 'none',
          '&.MuiPaper-rounded': {
            borderRadius: RADIUS.sm,
          },
          border: `1px solid ${COLORS.BORDER_GREY_LIGHT}`,
          backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
        }}
      >
        <AccordionSummary
          expandIcon={
            <FontAwesomeIcon
              icon={faChevronDown}
              color={COLORS.TEXT_BLACK}
              style={{ lineHeight: '16px' }}
            />
          }
          sx={{
            // alignItems: 'flex-start',
            padding: `${SPACING._05} ${SPACING._1}`,
            minHeight: 'max-content', // This removes the default minHeight
            '&.MuiAccordionSummary-root': {
              minHeight: 'max-content',
            },
            '&.MuiAccordionSummary-root.Mui-expanded': {
              minHeight: 'max-content',
            },
            '& .MuiAccordionSummary-content': {
              margin: 0, // This removes the default margins
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: 0, // This removes the default margins when expanded
            },
          }}
        >
          {expanded ? (
            <STypography
              variant="bodysmall_semibold"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              {yourMedText}
            </STypography>
          ) : (
            <STypography
              variant="body_bold"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
              }}
            >
              {drugName}
            </STypography>
          )}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: `${SPACING._05} ${SPACING._1}`,
            paddingTop: 0,
          }}
        >
          <STypography variant="title3_bold">{drugName}</STypography>
          <DrugDetails
            drugCount={drugCount}
            drugDosage={drugDosage}
            drugForm={drugForm}
            sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
          />
          {hasPrice ? (
            <PriceElement
              priceCopay={priceCopay}
              priceBeforeDed={priceBeforeDed}
              priceAfterDed={priceAfterDed}
              priceType={priceCopay ? 'insurance_copay' : 'insurance_ded'}
              priceVariant="bodysmall_bold"
              suffixVariant="bodysmall_regular"
              sx={{ marginTop: SPACING._05 }}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <CardContainer
      sx={{
        padding: `${SPACING._05} ${SPACING._1}`,
        border: `1px solid ${COLORS.BORDER_GREY_LIGHT}`,
        backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
      }}
    >
      <Grid container direction={'column'} gap={SPACING._05}>
        <Grid item>
          <STypography variant="bodybig_bold">{drugName}</STypography>
        </Grid>
        <DrugDetails
          variant="labels"
          drugCount={drugCount}
          drugDosage={drugDosage}
          drugForm={drugForm}
          sx={{ color: COLORS.TEXT_GREY_DARK }}
        />
        {hasPrice ? (
          <PriceElement
            priceCopay={priceCopay}
            priceBeforeDed={priceBeforeDed}
            priceAfterDed={priceAfterDed}
            priceType={priceCopay ? 'insurance_copay' : 'insurance_ded'}
            priceVariant="body_bold"
            suffixVariant="body_regular"
          />
        ) : null}
      </Grid>
    </CardContainer>
  )
}

export interface TargetRoute {
  uuid: string
  route: string
}

export function PaymentOptions({
  sameOrNewMedChoice,
  newMedDrugName,

  saving,
  allWaysToSave,
  onClickCash,
  onClickInsurance,
}: {
  sameOrNewMedChoice: SameOrNewMedChoiceType
  newMedDrugName?: string
  saving: SavingOpportunityDataModel
  allWaysToSave: WayToSaveItem[]
  onClickCash: () => void
  onClickInsurance: () => void
}) {
  //
  //

  const { t } = useSTranslate('savingGuide')

  let originalDrugName = saving.drugName

  const allCash = getCashCouponItems(
    allWaysToSave,
    sameOrNewMedChoice,
    newMedDrugName,
  ).concat(
    getCashPharmacyItems(allWaysToSave, sameOrNewMedChoice, newMedDrugName),
  )

  const allInsurance = getInsurancePharmacyChangeItems(
    allWaysToSave,
    sameOrNewMedChoice,
    newMedDrugName,
  ).concat(
    getInsurancePharmacyStayItems(
      allWaysToSave,
      sameOrNewMedChoice,
      newMedDrugName,
    ),
  )

  const {
    hasA: hasCash,
    highestAAmountStr: highestCashAmountStr,
    isABestPrice: isCashBestPrice,
    hasB: hasInsurance,
    highestBAmountStr: insuranceMedAmountStr,
    isBBestPrice: isInsuranceMedBestPrice,
  } = getHigestOptionInTwo(allCash, allInsurance)

  return (
    <Box>
      {newMedDrugName && (
        <Box sx={{ marginBottom: SPACING._1 }}>
          <STypography component="span" variant={'body_regular'}>
            Select your preferences regarding the payment options for
          </STypography>
          <STypography
            sx={{ paddingLeft: '4px' }}
            component={'span'}
            variant={'body_bold'}
          >
            {newMedDrugName}
          </STypography>
        </Box>
      )}
      {!newMedDrugName && (
        <Box sx={{ marginBottom: SPACING._1 }}>
          <STypography component="span" variant={'body_regular'}>
            Select your preferences regarding the payment options for
          </STypography>
          <STypography
            sx={{ paddingLeft: '4px' }}
            component={'span'}
            variant={'body_bold'}
          >
            {originalDrugName}
          </STypography>
        </Box>
      )}

      <Box display="flex" gap={2} sx={{ cursor: 'pointer' }}>
        {/* PROVEN ALTERNATIVES AKA NEW MEDICINE */}
        {hasCash && (
          <SaveUpToCardOptionItem
            dataTestId="saving-guide-option-cash-payment"
            onClick={onClickCash}
            isBestPrice={isCashBestPrice}
            upToPrefix={t('upToPrefix')}
            upToSuffix={'by paying cash'}
            upToAmount={highestCashAmountStr ? highestCashAmountStr : 'NA'}
            variant="cash"
          />
        )}
        {/* SAME MEDICINE  */}

        {hasInsurance && (
          <SaveUpToCardOptionItem
            dataTestId="saving-guide-option-insurance-payment"
            onClick={onClickInsurance}
            isBestPrice={isInsuranceMedBestPrice}
            upToPrefix={t('upToPrefix')}
            upToSuffix={'by using your insurance'}
            upToAmount={insuranceMedAmountStr ? insuranceMedAmountStr : 'NA'}
            variant="insurance"
          />
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          // justifyContent: 'center',
          marginTop: SPACING._15, //should be one with proper image
        }}
      >
        <LearnAboutPersonTooltip variant="payment_options" />
      </Box>
    </Box>
  )
}

export function CashOptionsGroup({
  saving,
  allWaysToSave,
  sameOrNewMedChoice,
  newMedDrugName,
  paymentType,
  onClickCoupon,
  onClickCashPharmacy,
}: {
  saving: SavingOpportunityDataModel
  allWaysToSave: WayToSaveItem[]
  sameOrNewMedChoice: SameOrNewMedChoiceType
  newMedDrugName?: string
  paymentType: PaymentType
  onClickCoupon: () => void
  onClickCashPharmacy: () => void
}) {
  const couponItems = getCashCouponItems(
    allWaysToSave,
    sameOrNewMedChoice,
    newMedDrugName,
  )
  const cashPharmaItems = getCashPharmacyItems(
    allWaysToSave,
    sameOrNewMedChoice,
    newMedDrugName,
  )

  const {
    hasA: hasCoupon,
    highestAAmountStr: couponAmountStr,
    isABestPrice: isCouponBestPrice,
    hasB: hasCashPharma,
    highestBAmountStr: cashPharmaAmountStr,
    isBBestPrice: isCashPharmaBestPrice,
  } = getHigestOptionInTwo(couponItems, cashPharmaItems)

  return (
    <Grid container spacing={SPACING._1}>
      <Grid item xs={12}>
        <STypography component="span" variant={'body_regular'}>
          We found these ways for you to save by
        </STypography>
        <STypography
          sx={{ paddingLeft: '4px' }}
          component={'span'}
          variant={'body_bold'}
        >
          paying by cash, select the one you prefer:
        </STypography>
      </Grid>
      {hasCoupon && (
        <Grid item xs={12}>
          <PaymentOptionStrategyListItem
            dataTestId="saving-guide-option-cash-coupon"
            variant="cash_coupons"
            upToAmount={couponAmountStr!}
            isBestPrice={isCouponBestPrice}
            onClick={onClickCoupon}
          />
        </Grid>
      )}
      {hasCashPharma && (
        <Grid item xs={12}>
          <PaymentOptionStrategyListItem
            dataTestId="saving-guide-option-cash-pharmacy"
            variant="cash_pharmacies"
            upToAmount={cashPharmaAmountStr!}
            isBestPrice={isCashPharmaBestPrice}
            onClick={onClickCashPharmacy}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <LearnAboutPersonTooltip variant="cash_reminder" />
      </Grid>
    </Grid>
  )
}

export function InsuranceOptionsGroup({
  saving,
  allWaysToSave,
  sameOrNewMedChoice,
  newMedDrugName,
  onClickStayPharmacy,
  onClickChangePharmacy,
}: {
  saving: SavingOpportunityDataModel
  allWaysToSave: WayToSaveItem[]
  sameOrNewMedChoice: SameOrNewMedChoiceType
  newMedDrugName?: string
  paymentType: PaymentType
  onClickStayPharmacy: () => void
  onClickChangePharmacy: () => void
}) {
  const samePharmaListA = getInsurancePharmacyStayItems(
    allWaysToSave,
    sameOrNewMedChoice,
    newMedDrugName,
  )
  const changePharmaListB = getInsurancePharmacyChangeItems(
    allWaysToSave,
    sameOrNewMedChoice,
    newMedDrugName,
  )

  const {
    hasA: hasSamePharma,
    highestAAmountStr: samePharmaAmountStr,
    isABestPrice: isSamePharmaBestPrice,

    hasB: hasChangePharma,
    highestBAmountStr: changePharmaAmountStr,
    isBBestPrice: isChangePharmaBestPrice,
  } = getHigestOptionInTwo(samePharmaListA, changePharmaListB)
  return (
    <Grid container spacing={SPACING._1}>
      <Grid item xs={12}>
        <Box>
          <STypography component="span" variant={'body_regular'}>
            We found these ways for you to save by
          </STypography>
          <STypography
            sx={{ paddingLeft: '4px' }}
            component={'span'}
            variant={'body_bold'}
          >
            using your insurance, select the one you prefer:
          </STypography>
        </Box>
      </Grid>
      {hasSamePharma && (
        <Grid item xs={12}>
          <PaymentOptionStrategyListItem
            dataTestId="saving-guide-option-insurance-stay-pharmacy"
            variant="insurance_stay_pharmacy"
            upToAmount={samePharmaAmountStr!}
            isBestPrice={isSamePharmaBestPrice}
            onClick={onClickStayPharmacy}
          />
        </Grid>
      )}
      {hasChangePharma && (
        <Grid item xs={12}>
          <PaymentOptionStrategyListItem
            dataTestId="saving-guide-option-insurance-change-pharmacy"
            variant="insurance_change_pharmacy"
            upToAmount={changePharmaAmountStr!}
            isBestPrice={isChangePharmaBestPrice}
            onClick={onClickChangePharmacy}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <LearnAboutPersonTooltip variant="pharmacy_types" />
      </Grid>
    </Grid>
  )
}
export type ItemListVariant =
  | 'insurance_stay_pharmacy'
  | 'insurance_change_pharmacy'
  | 'cash_coupons'
  | 'cash_pharmacies'
//TODO - if current IN HOUSE but going to retail
export function ListOfWaysToSaveItems({
  variant,
  saving,
  allWaysToSave,
  sameOrNewMedChoice,
  newMedDrugName,
  onClickWayToSaveItem,
}: {
  variant: ItemListVariant
  saving: SavingOpportunityDataModel
  allWaysToSave: WayToSaveItem[]
  sameOrNewMedChoice: SameOrNewMedChoiceType
  newMedDrugName?: string
  onClickWayToSaveItem: (wayToSaveItem: WayToSaveItem) => void
}) {
  let prefixText = 'We found these ways for you to save on your med '
  let suffixText = ''

  let filteredWaysToSave: WayToSaveItem[] = []
  if (variant === 'insurance_stay_pharmacy') {
    filteredWaysToSave = getInsurancePharmacyStayItems(
      allWaysToSave,
      sameOrNewMedChoice,
      newMedDrugName,
    )
    suffixText = 'by staying on your pharmacy, select one:'
  } else if (variant === 'insurance_change_pharmacy') {
    filteredWaysToSave = getInsurancePharmacyChangeItems(
      allWaysToSave,
      sameOrNewMedChoice,
      newMedDrugName,
    )
    suffixText = 'by changing your pharmacy, select one:'
  } else if (variant === 'cash_coupons') {
    filteredWaysToSave = getCashCouponItems(
      allWaysToSave,
      sameOrNewMedChoice,
      newMedDrugName,
    )
    suffixText = 'by using a coupon:'
  } else if (variant === 'cash_pharmacies') {
    filteredWaysToSave = getCashPharmacyItems(
      allWaysToSave,
      sameOrNewMedChoice,
      newMedDrugName,
    )
    suffixText = 'by changing your pharmacy, select one:'
  }

  const [enableShowMoreOptionsLink, setEnableShowMoreOptionsLink] =
    useState(false)
  //once clicked not to show it agian as we will be showibgn all items
  const [showMoreClickedOnce, setShowMoreClickedOnce] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const [itemsToShow, setItemsToShow] = useState<WayToSaveItem[]>([])
  useEffect(() => {
    if (filteredWaysToSave.length > 3) {
      setEnableShowMoreOptionsLink(true)
      setItemsToShow(filteredWaysToSave.slice(0, 3))
    } else {
      setEnableShowMoreOptionsLink(false)
      setItemsToShow(filteredWaysToSave)
    }

    if (showAll) {
      setItemsToShow(filteredWaysToSave)
    }
  }, [allWaysToSave, showAll])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ marginBottom: SPACING._05 }}>
          <STypography
            data-testid={`saving-items-list-for-${variant}`}
            component="span"
            variant={'body_regular'}
          >
            {prefixText}
          </STypography>
          <STypography
            component={'span'}
            variant={'body_bold'}
          >
            {suffixText}
          </STypography>
        </Box>
      </Grid>
      {itemsToShow.map((wayToSaveItem, idx) => {
        return (
          <WayToSaveItemDetails
            wayToSaveItem={wayToSaveItem}
            idx={idx}
            onClickWayToSaveItem={onClickWayToSaveItem}
          />
        )
      })}
      {enableShowMoreOptionsLink && !showMoreClickedOnce && (
        <Grid
          item
          container
          xs={12}
          sx={{ padding: SPACING._1 }}
          justifyContent={'center'}
        >
          <STypography
            onClick={() => {
              setShowAll(!showAll)
              setShowMoreClickedOnce(true)
            }}
            sx={{ cursor: 'pointer', textAlign: 'center', marginRight: '10px' }}
            variant="bodybig_bold"
          >
            See more options
          </STypography>
          <FontAwesomeIcon icon={faChevronDown} />
        </Grid>
      )}
    </Grid>
  )
}

export function WayToSaveItemDetails({
  wayToSaveItem,
  idx,
  onClickWayToSaveItem,
}: {
  wayToSaveItem: WayToSaveItem
  idx: number
  onClickWayToSaveItem: (wayToSaveItem: WayToSaveItem) => void
}) {
  //

  let isAnyPharmacy = true
  //THIS IS NOT TRUE IF its PARTNER or in house for now
  if (
    wayToSaveItem.pharmacyInfo &&
    wayToSaveItem.pharmacyInfo.type === 'PARTNER'
  ) {
    isAnyPharmacy = false
  }
  if (
    wayToSaveItem.pharmacyInfo &&
    wayToSaveItem.pharmacyInfo.type === 'INHOUSE'
  ) {
    isAnyPharmacy = false
  }
  let isCoupon = wayToSaveItem.type === 'coupon' ? true : false

  if (isCoupon) {
    isAnyPharmacy = false
  }

  let tags: JSX.Element[] = []

  if (idx === 0) {
    tags.push(
      <TagLabel
        key="bestpricetag"
        color="green"
        size="small"
        text="Best Price"
      />,
    )
  }

  //no new RX for original Coupons or Pharmacy Change
  if (
    wayToSaveItem.savingStrategy === SavingStrategy.Coupons ||
    wayToSaveItem.savingStrategy === SavingStrategy.PharmacySwitch
  ) {
    tags.push(
      <TagLabel
        key="nonewrxtag"
        color="purple"
        size="small"
        text="No New RX"
      />,
    )
  }

  //if its a coupon
  if (isCoupon) {
    tags.push(
      <TagLabel key="coupontag" color="blue" size="small" text="Coupon" />,
    )
  }

  //if mail order
  let isMailOrder = false
  if (
    wayToSaveItem.pharmacyInfo &&
    wayToSaveItem.pharmacyInfo.isMailOrder === true
  ) {
    isMailOrder = true
    tags.push(
      <TagLabel
        key="mailordertag"
        color="blue"
        size="small"
        text="Mail Order"
      />,
    )
  }

  //if cash pharmacy
  let isCashPharmacy = false
  if (wayToSaveItem.pharmacyInfo && wayToSaveItem.pharmacyInfo.isCash) {
    isCashPharmacy = true
    tags.push(
      <TagLabel
        key="cashpricetag"
        color="blue"
        size="small"
        text="Cash Price Pharmacy"
      />,
    )
  }

  if (isAnyPharmacy) {
    //custom tag in case of $4/$9 to direct users to Walmar specifically
    if (wayToSaveItem.savingStrategy === SavingStrategy.$4$9Program) {
      tags.push(
        <TagLabel
          key="anyretailtag"
          color="blue"
          size="small"
          text="Walmart Pharmacy"
        />,
      )
    } else {
      tags.push(
        <TagLabel
          key="anyretailtag"
          color="blue"
          size="small"
          text="Any Retail Pharmacy"
        />,
      )
    }
  }

  //in house tag on pharmacy change and nOT coupon
  if (
    wayToSaveItem.type === 'basic_strategy' &&
    wayToSaveItem.pharmacyInfo &&
    wayToSaveItem.pharmacyInfo.type === 'INHOUSE'
  ) {
    tags.push(
      <TagLabel
        key="inhousetag"
        color="blue"
        size="small"
        text="In-House Pharmacy"
      />,
    )
  }

  //add our strategy tag except on orginal (i.e. coupons or pharmacy swithc on A)
  if (
    wayToSaveItem.savingStrategy !== SavingStrategy.Coupons &&
    wayToSaveItem.savingStrategy !== SavingStrategy.PharmacySwitch
  ) {
    tags.push(
      <TagLabel
        key="savingstrategytag"
        color="purple"
        size="small"
        text={wayToSaveItem.savingStrategy}
      />,
    )
  }

  //comment out days supply until fully ready, including the 30days one
  // if (wayToSaveItem.daysSupply === 90) {
  //   tags.push(
  //     <TagLabel color="purple" size="small" text="90 days supply" />,
  //   )
  // } else if (wayToSaveItem.daysSupply === 30) {
  //   tags.push(
  //     <TagLabel color="grey" size="small" text="30 days supply" />,
  //   )
  // }

  let upToAmount = getDollarsPerMonthStr(wayToSaveItem.saveAmountResolved)
  if (!upToAmount) {
    LOG.error(
      'saving_report_data',
      'upToAmount is undefined in InsuranceStaySamePharmacy and item=',
      wayToSaveItem,
    )
    upToAmount = ''
  }
  let upToSuffix = 'by using your insurance'

  if (isCoupon || isCashPharmacy) {
    upToSuffix = 'by paying cash'
  }

  let pharmacyLogoUrl = ''
  if (wayToSaveItem.pharmacyInfo && wayToSaveItem.pharmacyInfo.logoUrl) {
    pharmacyLogoUrl = wayToSaveItem.pharmacyInfo.logoUrl
  }
  let pharmacyName = ''
  if (
    wayToSaveItem.pharmacyInfo &&
    wayToSaveItem.pharmacyInfo.name &&
    wayToSaveItem.pharmacyInfo.name.toLowerCase() !== 'retail'
  ) {
    pharmacyName = wayToSaveItem.pharmacyInfo.name
  }

  let pricingShipAndTaxNote = undefined
  //if cash mccpd inlucde the disclaimer
  if (
    isCashPharmacy &&
    isMarkCubanCostPlustName(wayToSaveItem.pharmacyInfo?.name)
  ) {
    pricingShipAndTaxNote =
      'Pricing does not include a standard shipping fee of $5 + taxes'
  }

  //in case of search - we get the NPI in the results, then we have to fetch addditional
  //details such as address etc using it
  const { savingGuide } = useMemberAppContext()
  const memberService = useMemberService()
  const [additionalPharmacyDetails, setAdditionalPharmacyDetails] = useState<
    AdditionalPharmacyDetailsByNpiLookupResp | undefined
  >(undefined)

  //TODO - add a simple cache of pharmacyNpi -> details in app context
  async function loadAdditionalPharmaDetailsViaNpi(npi: string) {
    try {
      const resp = await memberService.getPharmacyDetails(npi)
      const status = checkMicroServiceResponseStatus(resp)
      if (!status.isError) {
        setAdditionalPharmacyDetails(resp)
      } else {
        LOG.error(
          'search_results_data',
          'MS error response getting additional pharmacy details by NPI for npi=' +
            npi +
            ',resp=',
          resp,
        )
      }
    } catch (e) {
      LOG.error(
        'search_results_data',
        'Error getting additional pharmacy details by npi=' + npi + ',error=',
        e,
      )
    }
  }

  //fetch additional pharmacy details in case of search
  useEffect(() => {
    if (
      savingGuide?.source === 'search_results' &&
      wayToSaveItem.pharmacyInfo?.pharmacyNpi
    ) {
      //load additional details
      loadAdditionalPharmaDetailsViaNpi(wayToSaveItem.pharmacyInfo.pharmacyNpi)
    }
  }, [savingGuide, wayToSaveItem])

  //initial default from the way to save item
  const [pharmacyNameToUse, setPharmacyNameToUse] = useState(pharmacyName)

  //if we got additional details via NPI (i.e. Jps Location 1 vs Jps Location 2)
  useEffect(() => {
    if (additionalPharmacyDetails) {
      if (additionalPharmacyDetails.name) {
        setPharmacyNameToUse(additionalPharmacyDetails.name)
      }
      // let address = buildPharmacyAddressFromNpiLookup(additionalPharmacyDetails)
      // setTempPharmacyAddress(titleCase(address))
    }
  }, [additionalPharmacyDetails])

  return (
    <Grid key={idx} item xs={12} sx={{ marginTop: SPACING._1 }}>
      <PharmacyListCardItem
        //for now all of them will be the same so at least we can click each
        //see later how to handle different item types
        dataTestId={`saving-guide-final-item`}
        drugCount={wayToSaveItem.altCountQtcStr}
        drugDosage={wayToSaveItem.altDosage}
        drugForm={wayToSaveItem.altForm}
        drugName={wayToSaveItem.altName}
        //comment out until 90 days supply is ready
        //is90DaysSupply={wayToSaveItem.daysSupply === 90 ? true : false}

        isAnyPharmacy={isAnyPharmacy}
        isMailOrder={isMailOrder}
        isCashPharmacy={isCashPharmacy}
        isCoupon={isCoupon}
        pharmacyLogoUrl={pharmacyLogoUrl}
        pharmacyName={pharmacyNameToUse}
        tags={tags}
        upToAmount={upToAmount}
        upToPrefix="Save Up to"
        upToSuffix={upToSuffix}
        pricingShipAndTaxNote={pricingShipAndTaxNote}
        onClick={() => {
          onClickWayToSaveItem(wayToSaveItem)
        }}
        pharmacyType={wayToSaveItem.pharmacyInfo?.type}
        //only one standard strategies other than coupons or pharmacy switch
        strategy={wayToSaveItem.savingStrategy}
        isWalmartVariantFor49Strategy={
          wayToSaveItem.savingStrategy === SavingStrategy.$4$9Program
        }
        // wayToSaveItem.type === 'basic_strategy'
        //   ? wayToSaveItem.savingStrategy
        //   : undefined
      />
    </Grid>
  )
}

function SavingGuideHeader({
  savingGuide,
  selectedWayToSave,
  sameOrNewMedChoice,
  newMedName,
  itemListVariant,
}: {
  savingGuide: SavingGuideData
  selectedWayToSave?: WayToSaveItem
  sameOrNewMedChoice?: SameOrNewMedChoiceType
  newMedName?: string
  itemListVariant?: ItemListVariant
}) {
  const [steps, setSteps] = useState<FlowStep[]>([])
  const [activeStep, setActiveStep] = useState(0)

  const { t } = useSTranslate('savingGuide')

  const [renderStepper, setRenderStepper] = useState(false)
  const [expandedOriginalHeader, setExpandedOriginalHeader] = useState(true)
  //update steps based on current selection + location
  //NOTE - this is just number of steps, the actaul ACTIVE step is set above
  //TODO - maybe consolidate to same place where we check?

  //for alts, we have intermediate step to chose the new medication

  const location = useLocation()

  useEffect(() => {
    const path = location && location.pathname ? location.pathname : ''

    if (!path || path === ROUTE_OPPORTUNITY_ROOT) {
      setRenderStepper(false)
      return
    }

    //
    if (path === ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED) {
      // setRenderGreeting(false)
      setRenderStepper(true)
      setActiveStep(STEP_OPPORTUNITY_SAME_MED_OR_NEW_MED)
    } else if (path === ROUTE_OPPORTUNITY_CHOSE_NEW_MED) {
      setActiveStep(STEP_OPPORTUNITY_CHOSE_NEW_MED)
      setExpandedOriginalHeader(true)
    } else if (path === ROUTE_OPPORTUNITY_PAYMENT_OPTIONS) {
      if (sameOrNewMedChoice === 'same_med') {
        setActiveStep(STEP_OPPORTUNITY_CASH_OR_INSURANCE - 1)
      } else {
        setActiveStep(STEP_OPPORTUNITY_CASH_OR_INSURANCE)
      }
      setExpandedOriginalHeader(false)
    } else if (path === ROUTE_OPPORTUNITY_CASH_OPTIONS) {
      if (sameOrNewMedChoice === 'same_med') {
        setActiveStep(STEP_OPPORTUNITY_CASH_OPTIONS - 1)
      } else {
        setActiveStep(STEP_OPPORTUNITY_CASH_OPTIONS)
      }
      setExpandedOriginalHeader(false)
    } else if (path === ROUTE_OPPORTUNITY_INSURANCE_OPTIONS) {
      if (sameOrNewMedChoice === 'same_med') {
        setActiveStep(STEP_OPPORTUNITY_INSURANCE_OPTIONS - 1)
      } else {
        setActiveStep(STEP_OPPORTUNITY_INSURANCE_OPTIONS)
      }
      setExpandedOriginalHeader(false)
    } else if (path === ROUTE_OPPORTUNITY_ITEMS_LIST) {
      //first compute the step based on medicine variant
      if (sameOrNewMedChoice === 'same_med') {
        if (itemListVariant === 'cash_coupons') {
          setActiveStep(4)
        } else {
          setActiveStep(5)
        }
      } else {
        setActiveStep(5)
      }
      // //   let baseStep = sameOrNewMedChoice === 'same_med' ? 4 : 5
      // // if (itemListVariant === 'cash_coupons') {
      // //   baseStep = baseStep + 1
      // //   baseStep = 4
      // }
      // setActiveStep(baseStep)
    } else if (path === ROUTE_OPPORTUNITY_COUPONS_LIST) {
      if (sameOrNewMedChoice === 'same_med') {
        setActiveStep(STEP_OPPORTUNITY_COUPONS_LIST - 1)
      } else {
        setActiveStep(STEP_OPPORTUNITY_COUPONS_LIST)
      }
      setExpandedOriginalHeader(false)
      setRenderStepper(true)
      //update the top bar title and actions for saving guide flow (in case of back button)
    }

    //setup navbar in case we started the how to save summary
    // if (
    //   path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW ||
    //   path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW ||
    //   path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_COUPON_CARD_REVIEW ||
    //   path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP
    // ) {
    //   setNavbarForHowToSave(isMobile)
    //   setRenderStepper(false)
    //   setRenderHowToSummary(true)
    // } else {
    //   // if (path === ROUTE_OPPORTUNITY_HOW_TO_SAVE_INTRO) {
    //   //   setNavbarState({
    //   //     ...navbarState,
    //   //     hidden: true,
    //   //   })
    //   // } else {
    //   // }
    // }

    //
    if (path && path.indexOf('how_to_save_summary') > -1) {
      setRenderStepper(false)
    } else {
      setRenderStepper(true)
    }
  }, [location, savingGuide])

  useEffect(() => {
    if (!savingGuide) return

    const steps = getAvailableSteps(
      t,
      savingGuide.waysToSaveForOpportunity,
      sameOrNewMedChoice,
      newMedName,
      itemListVariant,
    )

    // //add extra steps for coupons
    // if (
    //   (location && location.pathname === ROUTE_OPPORTUNITY_CASH_COUPONS) ||
    //   location.pathname === ROUTE_OPPORTUNITY_COUPONS_LIST
    // ) {
    //
    //   steps.push({
    //     stepName: t('s6'),
    //     defaultIcon: <FontAwesomeIcon icon={faLocationDot} />,
    //   })
    // }

    setSteps(steps)
  }, [savingGuide, sameOrNewMedChoice, location, newMedName, itemListVariant])

  return (
    <Grid container>
      {renderStepper && (
        <>
          <Grid item xs={12}>
            <OriginalDrugCard
              savingOpportunity={savingGuide.selectedOpportunity}
              expanded={
                expandedOriginalHeader ||
                savingGuide.autoSelectCouponItem !== undefined
              }
              setExpanded={setExpandedOriginalHeader}
              // setExpaned={setExpandedOriginalHeader}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: SPACING._15,
              marginBottom: SPACING._15,
              // border: '1px solid black',
            }}
          >
            <MultiStepFlowProgressBar
              steps={steps}
              activeStep={activeStep - 1}
              sizeVariant={'tabletordesktop'}
              // sizeVariant={isMobile ? 'mobile' : 'tabletordesktop'}
              // shouldShowLabels={shouldShowLabels}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export interface StepConsMap {
  uuid: string
  number: number
  step: FlowStep
}

const STEP_OPPORTUNITY_SAME_MED_OR_NEW_MED = 1
const STEP_OPPORTUNITY_CHOSE_NEW_MED = 2
const STEP_OPPORTUNITY_CASH_OR_INSURANCE = 3
const STEP_OPPORTUNITY_CASH_OPTIONS = 4
const STEP_OPPORTUNITY_INSURANCE_OPTIONS = 4

const STEP_OPPORTUNITY_COUPONS_LIST = 6

function getAvailableSteps(
  t: any,
  allWaysToSave: WayToSaveItem[],
  sameOrNewMedChoice?: SameOrNewMedChoiceType,
  newMedName?: string,
  itemListVariant?: ItemListVariant,
): FlowStep[] {
  //
  //
  //
  //3 steps will always be there no matter what
  //choose same or new med
  //choose paymetn type
  //list of way to save items
  let allPossibleSteps: FlowStep[] = []

  //same or new med step will always be there
  allPossibleSteps.push({
    // stepName: t('s1'),
    stepName: 'same or new med  ',
    defaultIcon: <FontAwesomeIcon icon={faBallot} />,
  })
  //if we have a new med to choose, there is a step where user picks it from the list
  if (sameOrNewMedChoice === 'new_med') {
    allPossibleSteps.push({
      // stepName: t('s2'),
      stepName: 'chose new med',
      defaultIcon: <FontAwesomeIcon icon={faPills} />,
    })
  }

  //the payment step will alwasy be there
  allPossibleSteps.push({
    // stepName: t('s3'),
    stepName: 'choose payment',
    defaultIcon: <FontAwesomeIcon icon={faCommentDollar} />,
  })

  //the step for eitehr a) cash options or b) insurance options
  allPossibleSteps.push({
    // stepName: t('s3'),
    stepName: 'cash-options or insurance-options',
    defaultIcon: <FontAwesomeIcon icon={faStore} />,
  })

  //final list of actual steps to choose from
  //i..e if insurance payment & stay pharmacy for ex.
  allPossibleSteps.push({
    // stepName: t('s3'),
    stepName: 'list of items',
    defaultIcon: <FontAwesomeIcon icon={faLocationDot} />,
  })

  //if we don't have same or new med choice, we'll assume it's same med?
  if (!sameOrNewMedChoice) {
    sameOrNewMedChoice = 'same_med'
  }

  if (itemListVariant === 'cash_coupons') {
    allPossibleSteps.push({
      // stepName: t('s3'),
      stepName: 'list of coupons',
      defaultIcon: <FontAwesomeIcon icon={faCheck} />,
    })
  }

  //the last step icon - regardles of the number of steps is alwys checkbox
  //get the last step and update the icon
  allPossibleSteps[allPossibleSteps.length - 1].defaultIcon = (
    <FontAwesomeIcon icon={faCheck} />
  )

  //fix to update in case of cash coupons and alternate med, the second to last icon is prescription
  if (itemListVariant === 'cash_coupons' && sameOrNewMedChoice === 'new_med')
    //find it by stePanem = list of items
    allPossibleSteps.forEach((step, idx) => {
      if (step.stepName === 'list of items') {
        step.defaultIcon = <FontAwesomeIcon icon={faClipboardPrescription} />
      }
    })

  //

  return allPossibleSteps
}
