import BookmarkAddedSharpIcon from '@mui/icons-material/BookmarkAddedSharp'
import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  useMediaQuery,
  useTheme,
} from '@mui/material'
// import ExpandCircleUpIcon from '@mui/icons-material/ExpandCircleUp'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import expandArrowSrc from '../../images/down_arrow_circle.svg'
import collapseArrowSrc from '../../images/up_arrow_circle.svg'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { useDrugSearchService } from '../search/useDrugSearchService'

import { COMMON_BOLD_WEIGHT, useSColors } from '../styles/scripta-theme'
import DataNotFoundImage from './../../images/data_not_found.png'

import { useMemberAppContext } from '../MemberAppContext'
import { FeatureConfig } from '../config/features'

import { Theme } from '@mui/material'
import Button from '@mui/material/Button'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { CernerLinkWithDialog } from '../drugprofile/CernerTermsDialog'
import { DrugIdParams } from '../drugprofile/types'
import { useNotifier } from '../hooks/useNotify'
import { useMemberService } from '../member/useMemberService'
import PointerEventBlocker from '../shared/PointerEventBlocker'
import { CLASS_NAMES } from '../shared/constants'
import { NavBar } from '../system/NavBar'
import { SPACING } from '../system/theme2'
import ExternalLink from '../widgets/ExternalLink'
import { useSizes } from './useNewHooks'
import SButton from '../system/customcomponents/SButton'
import { ThemedSVGRemote } from './ThemedSVGRemote'
import { LOG } from 'src/app/v2/applog'
export const useProfileStyles = makeStyles((theme: Theme) =>
  createStyles({
    basicText: {
      fontSize: '1rem',
      whiteSpace: 'pre-line',
    },
    sectionTitle: {
      fontSize: '1.5rem',
      fontWeight: COMMON_BOLD_WEIGHT,
      color: theme.customized.colors.PRIMARY_BRAND,
    },

    factsDrugName: {
      fontSize: '1.3rem',
      fontWeight: COMMON_BOLD_WEIGHT,
    },
    factsLabel: {
      fontWeight: COMMON_BOLD_WEIGHT,
      fontSize: '1rem',
      color: theme.customized.colors.TEXT_BLACK,
    },
    factsText: {
      fontSize: '1rem',
    },
    factsWhatIsTitle: {
      fontSize: '1.5rem',
      fontWeight: COMMON_BOLD_WEIGHT,
    },

    subHeading: {
      fontWeight: COMMON_BOLD_WEIGHT,
      fontSize: '1.25rem',
      paddingBottom: '10px',
    },
  }),
)

export function NewDrugProfilePage() {
  const { isMobile } = useSizes()
  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar
          fixedTop={isMobile ? true : false}
          variant="title3_title_center_white"
          title="Drug Profile"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          padding: SPACING._1,
          marginTop: isMobile ? '50px' : undefined,
        }}
      >
        <NewDrugProfileContainer />
      </Grid>
    </Grid>
  )
}

export function NewDrugProfileContainer() {
  const location = useLocation()

  const [drugIdParams, setDrugIdParams] = useState<DrugIdParams | undefined>()

  const { lastSeenDrugProfileIdParams, setLastSeenDrugProfileIdParams } =
    useMemberAppContext()

  useEffect(() => {
    //only look if its not defind already
    if (location.state && (location.state as any).drugIdParams) {
      const drugIdParams = (location.state as any).drugIdParams
      setDrugIdParams(drugIdParams)
    } else {
      //  dont panic on # click due to navigation on the same page
      if (location && !location.hash) {
        //this should never happen only for hash clicks
        if (!drugIdParams) {
          throw new Error(
            'Invalid drug parameter - unable to navigate to my pricing',
          )
        }
      }
      //no drug id params found
      //if we go to my pricing after using a section nav (i.e. #warnings),
      //we loose drug id when clicking back button on my pricing page

      if (lastSeenDrugProfileIdParams) {
        setDrugIdParams(lastSeenDrugProfileIdParams)
      }
    }
  }, [location])

  useEffect(() => {
    if (drugIdParams) {
      setLastSeenDrugProfileIdParams(drugIdParams)
    }
  }, [])

  const [mainDetailContainerHeight, setMainDetailContainerHeight] = useState(0)

  function isInvalidParam(drugParams: DrugIdParams) {
    if (!drugParams.drugId && !drugParams.mmaId) {
      return true
    } else return false
  }

  return (
    <Box>
      {(!drugIdParams || isInvalidParam(drugIdParams)) && (
        <Grid
          container
          sx={{ height: '60vh', widht: '50vw' }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6}>
            <Typography sx={{ fontSize: '1.3rem' }}>
              Sorry, there was a problem with your request. Please try again.
            </Typography>
          </Grid>
        </Grid>
      )}

      {drugIdParams && !isInvalidParam(drugIdParams) && (
        <DrugProfileComp
          drugIdParams={drugIdParams}
          // containerHeight={mainDetailContainerHeight}
        />
      )}
    </Box>
  )
}

export function DrugProfileComp({
  drugIdParams,
}: {
  drugIdParams: DrugIdParams
}) {
  // return <div>helloaaaa</div>
  const { featureConfig } = useMemberAppContext()
  const service = useDrugSearchService()

  const [drugProfile, setDrugProfile] = useState<DrugProfileRespV2>()
  const [isLoading, setIsLoading] = useState<boolean>()

  const [errorData, setErrorData] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>()

  // const [selectedProfileData, setSelectedProfileData] =
  //   useState<DrugProfileData>()
  const history = useHistory()

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  async function fetchDrugProfile(drugIdParams: DrugIdParams) {
    setIsLoading(true)
    try {
      const drugProfile = await service.getDrugProfileApiApiV2(drugIdParams)
      setDrugProfile(drugProfile)
      setIsLoading(false)
      const respValid = checkMicroServiceResponseStatus(drugProfile)
      if (respValid.isError) {
        setErrorData(true)
        setErrorMsg(respValid.errorMsg)
        LOG.error(
          'drug_profile',
          'Error getting drug profile for drugIdParams,resp',
          { drugIdParams, drugProfile },
        )
      } else {
        setErrorData(false)
        setErrorMsg(undefined)
      }
    } catch (e) {
      console.error('error getting drug profile', e)
      setIsLoading(false)
      setErrorData(true)
      LOG.error('drug_profile', 'Error cought in api drug profile', {
        drugIdParams,
        e,
      })
    }
  }

  useEffect(() => {
    fetchDrugProfile(drugIdParams)
  }, [
    drugIdParams.disableMyPricingFromProfile,
    drugIdParams.drugId,
    drugIdParams.mmaId,
    drugIdParams.pricingQuantityDefault,
    drugIdParams.pricingUseRxSenseForDefaultDosages,
    drugIdParams.seoCode,
    drugIdParams.useLatestForLabelOnDrugProfile,
  ])

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '20vh' }}
      >
        <Grid item xs={12} textAlign="center">
          <Typography>Please wait, loading drug profile data ...</Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <CircularProgress />
        </Grid>
      </Grid>
    )
  }

  let ERROR_CONTROL = null
  if (errorData || !drugProfile) {
    ERROR_CONTROL = (
      <DrugProfileErrorComponentV2
        drugIdParams={drugIdParams}
        featureConfig={featureConfig}
      />
    )
  }

  return (
    <Box
      sx={
        {
          // backgroundColor: PORTAL_COLOR_GENERIC_GREY_BACKGROUND,
        }
      }
    >
      {ERROR_CONTROL}

      {!errorData && drugProfile && (
        <Grid container>
          <Grid item xs={12}>
            <NewDrugProfileDetails
              isSmall={isSmall}
              drugIdParams={drugIdParams}
              profile={drugProfile}
            ></NewDrugProfileDetails>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

function getTitle(t: any, section: ViewSection) {
  const sectionTransKey = `section_${section}`
  const title = t(sectionTransKey)
  return title
}
function getDrugDisplayName(profile: DrugProfileRespV2): string {
  const basicName = profile['drug-name']
  return basicName
}

export type ViewSection =
  | 'facts'
  | 'directions'
  | 'warnings'
  | 'side_effects'
  | 'interactions'
const SECTION: ViewSection[] = [
  'facts',
  'directions',
  'warnings',
  'side_effects',
  'interactions',
]

export function NewDrugProfileDetails({
  isSmall,
  drugIdParams,
  profile,
}: {
  isSmall: boolean
  drugIdParams: DrugIdParams
  profile: DrugProfileRespV2
}) {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ marginBottom: SPACING._2 }}>
          <NavButtons
            isSmall={isSmall}
            onButtonClicked={(section: ViewSection) => {
              const element = document.getElementById(section)
              if (element != null && element !== undefined) {
                element?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                  inline: 'start',
                })
              }
            }}
          />
        </Grid>
        <Grid
          // ref={mainContentRef}
          id="drugProfileDetailsContainer"
          item
          xs={12}
        >
          <DrugFacts
            profile={profile}
            drugIdParams={drugIdParams}
            isSmall={isSmall}
          />

          <Grid item xs={12} sx={{ marginTop: '20px' }}></Grid>

          <DSection sectionId="directions" isSmall={isSmall}>
            <Directions profile={profile} />
          </DSection>
          <DSection sectionId="warnings" isSmall={isSmall}>
            <Warnings profile={profile} />
          </DSection>
          <DSection sectionId="side_effects" isSmall={isSmall}>
            <SideEffects profile={profile} />
          </DSection>
          <DSection sectionId="interactions" isSmall={isSmall}>
            <Interactions profile={profile} />
          </DSection>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: '20px',
            }}
          >
            <Divider />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginTop: '20px',
              textAlign: 'center',
            }}
          >
            <Typography sx={{ textAlign: 'center', fontSize: '0.85rem' }}>
              Copyright Cerner Multum LLC All rights reserved.
            </Typography>
            <CernerLinkWithDialog
              cernerDialogType="disclaimer"
              customLinkSx={{ fontSize: '0.85rem', fontWeight: 700 }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginTop: '20px',
            }}
          >
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function DSection({
  sectionId,
  children,
  isSmall,
}: // hideTitle,
{
  sectionId: ViewSection
  children: any
  isSmall: boolean
  // hideTitle?: boolean
}) {
  const { t } = useSTranslate('drugProfile')

  const title = getTitle(t, sectionId)
  const classes = useProfileStyles()
  const [isExpanded, setIsExpanded] = useState<boolean>(true)

  return (
    <>
      <a id={sectionId}></a>

      <Grid container sx={{ marginTop: '20px' }}>
        <Grid
          item
          xs={12}
          sx={{
            paddingRight: isSmall ? '0px' : '60px',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          <Divider />
        </Grid>
        <Grid item xs={11}>
          <Typography className={classes.sectionTitle}>{title}</Typography>
        </Grid>
        {/* )} */}
        <Grid item xs={1}>
          {isExpanded && (
            <ThemedSVGRemote
              importUrl={collapseArrowSrc}
              width={24}
              height={24}
              onClick={() => {
                setIsExpanded(isExpanded ? false : true)
              }}
              alt={'Collapse'}
              styleButNoWidthOrHeight={{
                cursor: 'pointer',
              }}
            />
          )}
          {!isExpanded && (
            <ThemedSVGRemote
              importUrl={expandArrowSrc}
              width={24}
              height={24}
              alt={'Expand'}
              onClick={() => {
                setIsExpanded(isExpanded ? false : true)
              }}
              styleButNoWidthOrHeight={{
                cursor: 'pointer',
              }}
            />
          )}
        </Grid>

        <Collapse timeout={isExpanded ? 750 : 250} in={isExpanded}>
          <Grid item xs={11} sx={{ paddingRight: isSmall ? '0px' : '50px' }}>
            {children}
          </Grid>
          <Grid item xs={12}></Grid>
        </Collapse>
      </Grid>
    </>
  )
}

function NavButtons({
  activeSection,
  onButtonClicked,
  isSmall,
}: {
  activeSection?: ViewSection
  onButtonClicked: (section: ViewSection) => void
  isSmall: boolean
}) {
  const t = useSTranslate('drugProfile')

  //for mobile, we need to force two top buttons in the same row
  //Drug Facts | Directions
  //then Warningss | Side Effects | Interactions
  //do this for tablet as well
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))

  if (isSmall || isMedium) {
    const topRowButtons = SECTION.filter(
      (s) => s === 'facts' || s === 'directions',
    ).map((s) => {
      return (
        <NavButton
          key={s}
          isSmall={isSmall}
          section={s}
          onClick={(section: ViewSection) => {
            onButtonClicked(section)
          }}
          isActive={activeSection && activeSection === s ? true : false}
        />
      )
    })
    const bottomRowButtons = SECTION.filter(
      (s) => s !== 'facts' && s !== 'directions',
    ).map((s) => {
      return (
        <NavButton
          isSmall={isSmall}
          key={s}
          section={s}
          onClick={(section: ViewSection) => {
            onButtonClicked(section)
          }}
          isActive={activeSection && activeSection === s ? true : false}
        />
      )
    })
    return (
      <Grid container justifyContent="center" spacing={isSmall ? 0 : 1}>
        {topRowButtons}
        <Grid item xs={12}></Grid>
        {bottomRowButtons}
      </Grid>
    )
  }

  const sectionButtons = SECTION.map((s) => {
    return (
      <NavButton
        isSmall={isSmall}
        key={s}
        section={s}
        onClick={(section: ViewSection) => {
          onButtonClicked(section)
        }}
        isActive={activeSection && activeSection === s ? true : false}
      />
    )
  })

  return (
    <Grid container justifyContent="center" spacing={isSmall ? 1 : 1}>
      {sectionButtons}
    </Grid>
  )
}

function NodeList({ nodes }: { nodes: string[] }) {
  if (!nodes) {
    return null
  }
  if (!Array.isArray(nodes)) {
    return null
  }
  const listElements = nodes.map((n, idx) => {
    return <li key={idx}>{n}</li>
  })
  return (
    <ul style={{ listStyle: 'outside', paddingLeft: '15px' }}>
      {listElements}
    </ul>
  )
}
function NodeText({ node }: { node: any }) {
  const classes = useProfileStyles()

  //if its a simple direct text, show it
  if (typeof node === 'string') {
    return <Typography className={classes.basicText}>{node}</Typography>
  }

  //if its simple array of strings, show them
  if (Array.isArray(node) && node.every((n) => typeof n === 'string')) {
    return <NodeList nodes={node} />
  }

  //if its object, check under each key as another node
  if (typeof node === 'object') {
    //
    const subNodes = Object.keys(node).map((k, idx) => {
      const subNode = node[k]
      return <NodeText key={idx} node={subNode} />
    })
    return <>{subNodes}</>
  }

  //unknown node? maybe dont show so we dont display true/false if its boolean fox ex
  return null
  // return <div>{JSON.stringify(node)}</div>
}

function NavButton({
  section,
  onClick,
  isActive,
  isSmall,
}: {
  section: ViewSection
  onClick: (section: ViewSection) => void
  isActive: boolean
  isSmall: boolean
}) {
  const { t } = useSTranslate('drugProfile')
  const title = getTitle(t, section)
  const { COLORS } = useSColors()

  //trigger build
  return (
    <Grid item sx={{ padding: isSmall ? '3px' : '0px' }}>
      <Link
        aria-label={title}
        to={`#${section}`}
        style={{
          color: COLORS.BACKGROUND_SECONDARY,
          fontWeight: 500,
          textDecoration: 'none',
          display: 'button',
        }}
      >
        <Button
          onClick={() => {
            onClick(section)
          }}
          sx={{
            padding: isSmall ? '5px 10px 5px 10px' : '5px 10px 5px 10px',
            fontSize: isSmall ? '0.8rem' : '1rem',
            backgroundColor: isActive
              ? COLORS.PRIMARY_BRAND
              : COLORS.PRIMARY_10,
            '&:hover': {
              backgroundColor: isActive
                ? COLORS.PRIMARY_BRAND
                : COLORS.PRIMARY_10,
              boxShadow: 'none',
            },
          }}
          variant={isActive ? 'contained' : 'outlined'}
        >
          {title}
        </Button>
      </Link>
    </Grid>
  )
}

function DrugFacts({
  profile,
  drugIdParams,
  isSmall,
}: {
  profile: DrugProfileRespV2
  drugIdParams: DrugIdParams
  isSmall: boolean
}) {
  const displayName = getDrugDisplayName(profile)

  return (
    <>
      <a id="facts"></a>
      <Grid container alignItems="flex-start">
        {!isSmall && (
          <DrugFactsDesktopLayout
            profile={profile}
            drugIdParams={drugIdParams}
            displayName={displayName}
          />
        )}
        {isSmall && (
          <DrugFactsMobileLayout
            profile={profile}
            drugIdParams={drugIdParams}
            displayName={displayName}
          />
        )}
      </Grid>
    </>
  )
}

function DrugFactsMobileLayout({
  profile,
  drugIdParams,
  displayName,
}: {
  profile: DrugProfileRespV2
  drugIdParams: DrugIdParams
  displayName: string
}) {
  const { t } = useSTranslate('drugProfile')

  const classes = useProfileStyles()
  const hasImage = profile && profile['image-url']

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.sectionTitle}>
          {t('section_facts')}
        </Typography>
      </Grid>
      {hasImage && (
        <Grid item xs={2}>
          <DrugImage profile={profile} isSmall={true} />
        </Grid>
      )}

      <Grid item xs={hasImage ? 10 : 12} container>
        <Grid item xs={12}>
          <Typography className={classes.factsDrugName}>
            {displayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BookmarkThisDrugV2
            drugProfile={profile}
            drugIdParams={drugIdParams}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <NamesAndClasses profile={profile} isSmall={true} />
      </Grid>
      <Grid item xs={12}>
        <WhatIsThisDrug profile={profile} />
      </Grid>
      <Grid item xs={12}>
        <ControlledSubstanceClassification profile={profile} isSmall={true} />
      </Grid>
      <Grid item xs={12}>
        <DrugDosages profile={profile} />
      </Grid>
    </Grid>
  )
}

function DrugFactsDesktopLayout({
  profile,
  drugIdParams,
  displayName,
}: {
  profile: DrugProfileRespV2
  drugIdParams: DrugIdParams
  displayName: string
}) {
  const { t } = useSTranslate('drugProfile')

  const classes = useProfileStyles()

  return (
    <>
      <Grid item xs={12} container>
        <Grid item xs={8}>
          <Typography className={classes.sectionTitle}>
            {t('section_facts')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid
          item
          container
          xs={12}
          sx={{ marginTop: '20px', marginBottom: '20px' }}
        >
          <Typography className={classes.factsDrugName}>
            {displayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <NamesAndClasses profile={profile} isSmall={false} />
        </Grid>
        <Grid item xs={12}>
          <WhatIsThisDrug profile={profile} />
        </Grid>
        <Grid item xs={12}>
          <ControlledSubstanceClassification
            profile={profile}
            isSmall={false}
          />
        </Grid>
        <Grid item xs={12}>
          <DrugDosages profile={profile} />
        </Grid>
      </Grid>
      <Grid item xs={4} container alignItems="flex-start">
        <Grid item xs={12} sx={{ paddingTop: '10px', paddingLeft: '10px' }}>
          <BookmarkThisDrugV2
            drugProfile={profile}
            drugIdParams={drugIdParams}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: '20px' }}>
          <DrugImage profile={profile} isSmall={false} />
        </Grid>
      </Grid>
    </>
  )
}

function WhatIsThisDrug({ profile }: { profile: DrugProfileRespV2 }) {
  const displayName = getDrugDisplayName(profile)
  const classes = useProfileStyles()
  const { t } = useSTranslate('drugProfile')

  return (
    <>
      <Grid
        item
        xs={12}
        className={classes.factsWhatIsTitle}
        sx={{ marginTop: '20px' }}
      >
        {t('whatIsPrefix')} {displayName}?
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '25px' }}>
        <NodeText node={profile['what-is-this-drug']} />
      </Grid>
    </>
  )
}

function DrugDosages({ profile }: { profile: DrugProfileRespV2 }) {
  const { t } = useSTranslate('drugProfile')
  const classes = useProfileStyles()

  const { COLORS } = useSColors()
  const [showDetailedUses, setShowDetailedUses] = useState<boolean>(false)

  const hasAdditionalDosageInfo =
    profile &&
    profile.dosages &&
    Array.isArray(profile.dosages) &&
    profile.dosages.length > 0

  if (!hasAdditionalDosageInfo) return null

  return (
    <Grid item xs={12} sx={{ marginTop: '20px' }}>
      <>
        {/* <span className={classes.factsLabel} style={{ wordWrap: 'break-word' }}>
          Dosing Information:
        </span> */}
        <span className={classes.factsLabel}>{t('dosagesMoreInfoPrefix')}</span>
        <MuiLink
          onClick={() => {
            setShowDetailedUses(true)
          }}
          sx={{
            fontWeight: 700,
            cursor: 'pointer',
            color: COLORS.PRIMARY_BRAND,
            textDecoration: 'none',
          }}
        >
          {t('dosagesMoreInfoClickHere')}
        </MuiLink>
      </>
      {showDetailedUses && (
        <DrugProfileDialog2
          onClosed={() => {
            setShowDetailedUses(false)
          }}
          dialogContent={
            <>
              <Grid item xs={10}>
                <NodeText node={profile['dosages']} />
              </Grid>
            </>
          }
          dialogTitle={t('dosagesTitle')}
        />
      )}
    </Grid>
  )
}

function DrugImage({
  profile,
  isSmall,
}: {
  profile: DrugProfileRespV2
  isSmall: boolean
}) {
  const { t } = useSTranslate('drugProfile')
  if (!profile['image-url']) {
    return null
  }
  return (
    <img
      width={isSmall ? '50px' : '163px'}
      height={isSmall ? '74px' : '198px'}
      src={profile['image-url']}
      alt={getDrugDisplayName(profile)}
    />
  )
}

function NamesAndClasses({
  profile,
  isSmall,
}: {
  profile: DrugProfileRespV2
  isSmall: boolean
}) {
  const { t } = useSTranslate('drugProfile')

  const brandNames =
    profile['brand-names'] && Array.isArray(profile['brand-names'])
      ? profile['brand-names'].join(', ')
      : ''

  const drugClasses =
    profile['therapeutic-categories'] && Array.isArray(profile['brand-names'])
      ? profile['therapeutic-categories'].join(', ')
      : ''

  let fullName = profile['generic-name']
  let spelling = profile.pronunciation
  if (spelling && spelling.length > 0) {
    fullName = fullName + ' (' + spelling.replaceAll(' ', '-') + ')'
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FactsLabelWithText
          label={t('genericName')}
          text={fullName}
          inlineTogether={isSmall ? true : false}
        />
      </Grid>
      <Grid item xs={12}>
        <FactsLabelWithText
          label={t('brandNames')}
          text={brandNames}
          inlineTogether={isSmall ? true : false}
        />
      </Grid>
      <Grid item xs={12}>
        <FactsLabelWithText
          label={t('drugClasses')}
          text={drugClasses}
          inlineTogether={isSmall ? true : false}
        />
      </Grid>
    </Grid>
  )
}

function ControlledSubstanceClassification({
  profile,
  isSmall,
}: {
  profile: DrugProfileRespV2
  isSmall: boolean
}) {
  const { t } = useSTranslate('drugProfile')
  const { COLORS } = useSColors()

  let scheduleText = ''
  if (profile['csa-schedule']) {
    if (profile['csa-schedule'].code === 0) {
      scheduleText = t('notControlledDrug')
    } else {
      scheduleText = profile['csa-schedule']['display-code']
    }
  }
  return (
    <>
      <FactsLabelWithText
        label={t('controlledSubstanceClass')}
        text={scheduleText}
        inlineTogether={true}
      />

      <Grid
        item
        xs={12}
        container
        alignContent="center"
        sx={{
          backgroundColor: 'white',
          borderRadius: '7px',
          borderColor: COLORS.BACKGROUND_GREY_LIGHTER,
          // borderColor: PORTAL_COLOR_GREY_BORDER,
          border: '3px solid' + COLORS.BACKGROUND_GREY_LIGHTER,
          // border: '3px solid' + PORTAL_COLOR_GREY_BORDER,
          padding: '10px',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
        style={{ marginTop: '20px', marginLeft: '3px' }}
      >
        <Grid
          item
          sx={{ minWidth: '15px', width: '15px', marginTop: '5px' }}
          xs={0.7}
        >
          <InfoIcon sx={{ color: COLORS.BACKGROUND_GREY_DARKER }} />
        </Grid>
        <Grid item xs={11.3} sx={{ paddingLeft: '15px' }}>
          <Typography sx={{ fontSize: '0.9rem' }}>
            {t('controlledSubstanceAlertText')}
            <ExternalLink url="https://www.dea.gov/drug-information/drug-scheduling">
              https://www.dea.gov/drug-information/drug-scheduling
            </ExternalLink>
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

function FactsLabelWithText({
  label,
  text,
  inlineTogether,
}: {
  label: string
  text: string
  inlineTogether: boolean
}) {
  const classes = useProfileStyles()

  if (inlineTogether) {
    return (
      <>
        <span className={classes.factsLabel} style={{ wordWrap: 'break-word' }}>
          {label}
        </span>
        <span
          className={classes.factsText}
          style={{ wordWrap: 'break-word', marginLeft: '5px' }}
        >
          {text}
        </span>
      </>
    )
  }

  return (
    <Box
      display={'flex'}
      // alignItems="center"
      // sx={{ marginTop: '5px', marginBottom: '5px' }}
    >
      <Typography
        sx={{ minWidth: '140px' }}
        className={classes.factsLabel}
        // style={{ wordWrap: 'break-word' }}
      >
        {label}
      </Typography>
      <Typography
        className={classes.factsText}
        style={{ wordWrap: 'break-word', marginLeft: '5px' }}
      >
        {text}
      </Typography>
    </Box>
  )
}

function Directions({ profile }: { profile: DrugProfileRespV2 }) {
  const classes = useProfileStyles()
  const { t } = useSTranslate('drugProfile')
  const howToUseDrugTitle =
    t('directionsHowShouldIUsePrefix') + ' ' + getDrugDisplayName(profile)

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <Typography className={classes.subHeading}>
          {t('directionsBeforeTaking')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* <NodeText node={profile['the-most-important-information']} /> */}
        <NodeText node={profile['what-should-i-do-before-taking-this-drug']} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <Typography className={classes.subHeading}>
          {howToUseDrugTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NodeText node={profile['how-should-i-take-this-drug']} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <Typography className={classes.subHeading}>
          {t('directionsIfIMissADose')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NodeText node={profile['what-happens-if-i-miss-a-dose']} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <Typography className={classes.subHeading}>
          {t('directionsIfIOverdose')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NodeText node={profile['what-happens-if-i-overdose']} />
      </Grid>
    </Grid>
  )
}

function Warnings({ profile }: { profile: DrugProfileRespV2 }) {
  const { COLORS } = useSColors()
  const classes = useProfileStyles()
  const { t } = useSTranslate('drugProfile')
  const translatedSuffix = t('warningsSuffix')
  const drugWarningsTitle = `${getDrugDisplayName(profile)} ${translatedSuffix}`

  const pregnancySuffix = t('warningsPregnancySuffix')
  const pregnancyWarningsTitle = `${getDrugDisplayName(
    profile,
  )} ${pregnancySuffix}`
  const breastFeedingSuffix = t('warningsBreastfeedingSuffix')
  const breastFeedingWarningsTitle = `${getDrugDisplayName(
    profile,
  )} ${breastFeedingSuffix}`

  const hadAddiotionalPregnancyWarnings =
    profile &&
    profile['pregnancy-warnings'] &&
    Array.isArray(profile['pregnancy-warnings']) &&
    profile['pregnancy-warnings'].length > 0

  const hadAddiotionalLactationWarnings =
    profile &&
    profile['lactation-warnings'] &&
    Array.isArray(profile['lactation-warnings']) &&
    profile['lactation-warnings'].length > 0

  const hasAdditional =
    hadAddiotionalPregnancyWarnings || hadAddiotionalLactationWarnings

  const [showDetailedWarnings, setShowDetailedWarnigns] =
    useState<boolean>(false)

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <Typography className={classes.subHeading}>
          {drugWarningsTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NodeText node={profile['the-most-important-information']} />
      </Grid>
      {/* <Grid item xs={12}>
        <NodeText node={profile['warnings']} />
      </Grid> */}

      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <Typography className={classes.subHeading}>
          {t('warningsPregnancyAndBreastFeeding')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <ul style={{ listStyle: 'outside', paddingLeft: '15px' }}>
          <li>{t('warningsPregnancyTell')}</li>
          {hasAdditional && (
            <li>
              {t('warningsPregnancyMoreInfoPrefix')}
              <MuiLink
                onClick={() => {
                  setShowDetailedWarnigns(true)
                }}
                sx={{
                  fontWeight: 700,
                  cursor: 'pointer',
                  color: COLORS.PRIMARY_BRAND,
                  textDecoration: 'none',
                }}
              >
                {t('warningsPregnancyMoreInfoClickHere')}
              </MuiLink>
            </li>
          )}
        </ul>
      </Grid>
      {showDetailedWarnings && (
        <DrugProfileDialog2
          onClosed={() => {
            setShowDetailedWarnigns(false)
          }}
          dialogContent={
            <>
              <Grid
                item
                xs={12}
                sx={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <Typography className={classes.subHeading}>
                  {pregnancyWarningsTitle}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <NodeText node={profile['pregnancy-warnings']} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <Typography className={classes.subHeading}>
                  {breastFeedingWarningsTitle}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <NodeText node={profile['lactation-warnings']} />
              </Grid>
            </>
          }
          dialogTitle={t('warningsPregnancyAndBreastFeeding')}
        />
      )}
    </Grid>
  )
}
function SideEffects({ profile }: { profile: DrugProfileRespV2 }) {
  const classes = useProfileStyles()
  const { t } = useSTranslate('drugProfile')
  const translatedSuffix = t('sideEffectsSuffix')
  const drugSideEffectsTitle = `${getDrugDisplayName(
    profile,
  )} ${translatedSuffix}`

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <Typography className={classes.subHeading}>
          {drugSideEffectsTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NodeText node={profile['what-are-possible-side-effects']} />
      </Grid>
    </Grid>
  )
}
function Interactions({ profile }: { profile: DrugProfileRespV2 }) {
  const classes = useProfileStyles()
  const { t } = useSTranslate('drugProfile')
  const translattedPrefix = t('interactionsWhatOthersWillAffectPrefix')
  const interactionsTitle = `${translattedPrefix} ${getDrugDisplayName(
    profile,
  )}`
  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: '10px' }}>
        <Typography className={classes.subHeading}>
          {interactionsTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NodeText node={profile['what-other-drugs-will-affect']} />
      </Grid>
    </Grid>
  )
}

export function DrugProfileErrorComponentV2({
  drugIdParams,
  // drugProfileRespV2,
  featureConfig,
}: {
  drugIdParams?: DrugIdParams
  // drugProfileRespV2: DrugProfileRespV2
  featureConfig: FeatureConfig
}) {
  const history = useHistory()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '40vh' }}
    >
      <Grid item xs={12} textAlign="center">
        <img src={DataNotFoundImage} alt="Data Not Found" />
      </Grid>
      <Grid item xs={12} textAlign="center" style={{ marginTop: '10px' }}>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 500 }}>
          Sorry, we can’t find additional information about this drug.
        </Typography>
      </Grid>
    </Grid>
  )
}

function BookmarkThisDrugV2({
  drugProfile,
  drugIdParams,
}: {
  drugProfile: DrugProfileRespV2
  drugIdParams: DrugIdParams
}) {
  const { t } = useSTranslate('drugProfile')
  const memberService = useMemberService()
  const notifier = useNotifier()
  const [isBookmarked, setIsBookmarked] = useState<boolean>(
    drugProfile['is-favorite'],
  )
  useEffect(() => {
    setIsBookmarked(drugProfile['is-favorite'])
  }, [drugProfile])
  return (
    <>
      <IconButton
        className={
          !isBookmarked
            ? CLASS_NAMES.saveThisMedButton
            : CLASS_NAMES.removeThisMedButton
        }
        aria-label="save this med"
        color="primary"
        onClick={async (e) => {
          try {
            let apiResp = undefined

            if (!isBookmarked) {
              apiResp = await memberService.addDrugToFavorites(drugIdParams)
            } else {
              apiResp = await memberService.removeDrugFromFavorites(
                drugIdParams,
              )
            }
            //this api can return undefined for successfull reponses (i.e. empty body)
            const allowUndefined = true
            const validation = checkMicroServiceResponseStatus(
              apiResp,
              allowUndefined,
            )

            if (validation.isError) {
              notifier.sendMsg(
                `Error ${
                  isBookmarked ? 'removing' : 'adding'
                } this drug to saved meds.`,
                'error',
              )
              LOG.error(
                'drug_profile',
                'Error adding/removing drug to/from favorites',
                {
                  drugIdParams,
                  apiResp,
                },
              )
            } else {
              notifier.sendMsg(
                `Successfully ${
                  isBookmarked
                    ? 'removed this drug from saved meds.'
                    : 'added this drug to saved meds.'
                }`,
                'success',
              )
              setIsBookmarked(!isBookmarked)
            }
          } catch (error) {
            console.error('error extracing details from add favorits api')
            notifier.sendMsg(
              `Error processing saved meds. Please contact member support.`,
              'error',
            )
            LOG.error(
              'drug_profile',
              'Error adding/removing drug to/from favorites',
              {
                drugIdParams,
                error,
              },
            )
          }
        }}
      >
        <PointerEventBlocker>
          {!isBookmarked && (
            <Grid container alignItems="center">
              <Typography variant="caption">Save this med</Typography>
              <BookmarkBorderSharpIcon />
            </Grid>
          )}
          {isBookmarked && (
            <Grid container alignItems="center">
              <Typography variant="caption">Remove favorite</Typography>
              <BookmarkAddedSharpIcon />
            </Grid>
          )}
        </PointerEventBlocker>
      </IconButton>
    </>
  )
}

function DrugProfileDialog2({
  dialogTitle,
  dialogContent,
  onClosed,
}: {
  dialogTitle: string
  dialogContent: React.ReactNode
  onClosed: () => void
}) {
  const { COLORS } = useSColors()
  let isSmallDisplay = false
  const [open, setOpen] = useState(true)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    onClosed()
  }

  return (
    <div>
      <Dialog
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: '15px',
            padding: isSmallDisplay ? '0px' : '15px',
          },
        }}
        onClose={handleClose}
        aria-labelledby="packaging-dialog-title"
        open={open}
      >
        {/* <DrugProfileDialogTitle
          id="packaging-dialog-title"
          onClose={handleClose}
        >
          {dialogTitle}
        </DrugProfileDialogTitle> */}
        <Box sx={{ display: 'flex', marginBottom: '10px' }}>
          <Box sx={{ width: '100%' }}>
            <Typography
              textAlign="center"
              sx={{
                fontWeight: '600',
                fontSize: '1.2rem',
                color: COLORS.PRIMARY_BRAND,
              }}
            >
              {dialogTitle}
            </Typography>
          </Box>

          <Box sx={{ marginLeft: 'auto' }}>
            <CloseIcon
              style={{ marginLeft: 'auto' }}
              height={'20px'}
              width={'20px'}
              sx={{ cursor: 'pointer' }}
              onClick={onClosed}
            />
          </Box>
        </Box>

        <DialogContent dividers>{dialogContent}</DialogContent>
        <DialogActions>
          <Grid item container xs={12} justifyContent="center">
            <SButton
              variant="contained"
              onClick={() => {
                handleClose()
              }}
            >
              Close
            </SButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export interface DrugProfileRespV2 {
  'drug-id': number
  'drug-name': string
  'generic-name': string
  pronunciation: string
  'brand-names': string[]
  'image-url'?: string
  'the-most-important-information': KeyedDrugProfileSection
  'what-is-this-drug': KeyedDrugProfileSection
  'what-should-i-do-before-taking-this-drug': KeyedDrugProfileSection
  'how-should-i-take-this-drug': KeyedDrugProfileSection
  'what-happens-if-i-miss-a-dose': KeyedDrugProfileSection
  'what-happens-if-i-overdose': KeyedDrugProfileSection
  'what-should-i-avoid': KeyedDrugProfileSection
  'what-are-possible-side-effects': KeyedDrugProfileSection
  'what-other-drugs-will-affect': KeyedDrugProfileSection
  'therapeutic-categories': string[]
  dosages: DosageDesc[]
  warnings: WarningDesc[]
  'pregnancy-warnings': PregnancyWarnDesc[]
  'lactation-warnings': PregnancyWarnDesc[]
  'drug-drug-interactions': any[]
  'csa-schedule': Schedule
  'is-favorite': boolean
  'is-hidden': boolean
  'is-customer-suppressed': boolean
}

interface DosageDesc {
  heading: string
  conditions: string[]
  'clinical-description': string
}
interface WarningDesc {
  severity: string
  'clinical-description': string
}
interface PregnancyWarnDesc {
  severity: string
  'clinical-description': string
  'is-specific-warning': boolean
}
// export interface DrugDrugInteraction {

// }
interface Schedule {
  code: number
  'display-code': string
  'vendor-description': string
  'internal-description': string
}

interface KeyedDrugProfileSection {
  [key: string]: string | string[]
}
