import { Theme, useTheme } from '@mui/material'
import debounce from 'lodash/debounce'
import { Fragment, useCallback, useState } from 'react'
import { components } from 'react-select'
import { isServerErrorResponse, isValidResponse } from '../../types'

import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useMemberAppContext } from '../MemberAppContext'

import { DrugIdParams } from '../drugprofile/types'
import {
  getSelectControlStyles,
  useSearchResultStyles,
} from '../search/search-result-styles'
import { useDrugSearchService } from '../search/useDrugSearchService'
import { RecentSearch, useRecentSearches } from '../search/useRecentSearches'
import SSelect from '../system/customcomponents/SSelect'
import STypography, {
  STypographyCombination,
} from '../system/customcomponents/STypography'
import {
  DrugSuggestion,
  DrugSuggestionDataReturn,
  SuggesterStyleOps,
} from '../search/types'

export function NewSearchSuggester({
  customLabelSx,
  customLabelVariant,
  customSelectStyles,
  //new for v2, dont route to drug profile
  onSuggestionPicked,
  //new flag - used when we jump from the search entry page to the search opportunity
  //to reinit the state of the search suggester as it seems to be lost in the transition
  preinitPlaceholderToSelectedSuggestion,
}: {
  customLabelSx?: any
  customLabelVariant?: STypographyCombination
  customSelectStyles?: SuggesterStyleOps
  onSuggestionPicked: (suggestion: DrugSuggestion | undefined) => void
  preinitPlaceholderToSelectedSuggestion?: boolean
}) {
  const theme = useTheme() as Theme
  const { setSelectedSuggestion, history, selectedSuggestion } =
    useMemberAppContext()

  const [suggestions, setSuggestions] = useState<any[]>([])
  const [removedOptions, setRemovedOptions] = useState<any[]>([])
  const { t } = useSTranslate('common')
  const { t: searchT } = useSTranslate('newSearch')
  const service = useDrugSearchService()
  const { recentSearches, removeById, removeAll, addNew } = useRecentSearches()

  async function asyncGetDrugNameSuggestions(prefix: string) {
    //TODO - maybe not, rx sense seems fast enough
    if (!prefix || prefix.length < 1) {
      setSuggestions([])
    }
    try {
      let resp = await service.getDrugNameSuggestionApi(prefix)
      //TODO - search error handling
      if (isServerErrorResponse<any>(resp)) {
        // history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<DrugSuggestionDataReturn>(resp)) {
        return (resp as DrugSuggestionDataReturn)['search-result']
      } else {
        console.error('error in getting drug names')
      }
    } catch (e) {
      throw new Error('error in api execution')
    }
  }

  const loadSuggestedOptions = useCallback(
    debounce((inputValue: any, callback: any) => {
      asyncGetDrugNameSuggestions(inputValue).then((options) =>
        callback(options),
      )
    }, 500),
    [],
  )

  const selectStyles = getSelectControlStyles(theme, customSelectStyles, false)
  const searchClasses = useSearchResultStyles()

  const Option = (props: any) => {
    // check if this is a recent search option and render with X accordingly
    if (props.data?.isRecentSearch) {
      return (
        <Fragment>
          {recentSearches
            .map((item: any) => item.id)
            .findIndex((item: any) => item == props.data.id) > -1 ? (
            <div>
              {recentSearches.findIndex((item) => item.id === props.data.id) ===
                0 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontWeight: 400,
                      color: '#6D6D6D',
                      fontSize: 11,
                      marginLeft: '10px',
                    }}
                  >
                    {' '}
                    {searchT('recentSearches')}
                  </div>
                  <div
                    onClick={() => removeAll()}
                    style={{
                      color: '#6D6D6D',
                      marginLeft: '5px',
                      marginRight: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    x
                  </div>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <components.Option {...props} />
                <div
                  onClick={() => removeById(props.data.id)}
                  style={{
                    color: 'black',
                    marginLeft: '5px',
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                >
                  x
                </div>
              </div>
            </div>
          ) : (
            <components.Option {...props} />
          )}
        </Fragment>
      )
    }

    // user is searching
    return <components.Option {...props} />
  }

  return (
    <div>
      <span className={searchClasses.srOnly}>
        {t('showFilters')}
        {/* most popular drug. Use tab to navigate between combo to choose
        different form, dosage and quantit of the drug you might be taking{' '} */}
      </span>
      <SSelect
        labelSx={customLabelSx}
        labelVariant={customLabelVariant}
        label={searchT('suggesterLabel')}
        // placeholder=""
        isAsync={true}
        isSearchable={true}
        isClearable={true}
        loadOptions={loadSuggestedOptions}
        defaultOptions={recentSearches.map((item: any) => {
          item['seo-code'] = item['code']
          return item
        })}
        //cacheOptions
        placeholder={
          preinitPlaceholderToSelectedSuggestion && selectedSuggestion ? (
            <STypography variant="body_bold">
              {selectedSuggestion.name}
            </STypography>
          ) : (
            ''
          )
        }
        // blurInputOnSelect={true}
        //TODO - should we auto-focus in special cases, i.e. no savings
        // autoFocus={renderAsPoppingDialog ? true : false}
        openMenuOnClick={true}
        onChange={(newValue: any, actionMeta: any) => {
          //

          //if undefined, means they "cleared", so don't trigger rerouting
          if (newValue) {
            let drugIdParams: DrugIdParams = {
              drugId: newValue.id,
              //here we make sure that when we get to my pricing we want to rely on rxsense for form/dosage/quantity defaults
              pricingUseRxSenseForDefaultDosages: true,
              seoCode: newValue.code,
            }
            //add a new recent search
            const newSearch: RecentSearch = {
              id: newValue.id,
              code: newValue.code,
              name: newValue.name,
              isRecentSearch: true,
            }

            if (
              !recentSearches.find((search: any) => search.id === newSearch.id)
            ) {
              addNew(newSearch)
            }

            onSuggestionPicked(newValue)
          }
        }}
        getOptionLabel={(option: DrugSuggestion) => {
          let label = ''
          if (option && option.name) {
            label = option.name
            if (option['is-generic']) {
              label = label + ' (GENERIC)'
            }
          }

          return label
        }}
        noOptionsMessage={(obj: any) => {
          const inputValue = obj['inputValue']
          if (inputValue && inputValue.length > 0) {
            return (
              <div>
                <label aria-live="assertive">{t('noMatchingDrugFound')}</label>
              </div>
            )
          }

          return null
        }}
        getOptionValue={(option: DrugSuggestion) => {
          return option && option['code'] ? option['code'] : 'NA display_name'
        }}
        customSelectComponents={{
          // DropdownIndicator: null,
          // IndicatorSeparator: null,
          // Control: CustomSelectControlWithIcon,
          Option: Option,
        }}
        // styles={selectStyles}
        aria-label="
        Start typing the first few characters of the drug you are currently taking into the combo edit.
        Select the matching drug to see the list of cheaper alternatives and savings strategies."
        // ariaLiveMessages={}
        // theme={selectTheme}
      />
    </div>
  )
}
