import { IS_BLUE_PROD, IS_DEV, IS_INTEGRATION } from '../apiexec/utils'
import { DosageOptionsResp } from '../search/types'
import { MainAppSectionName } from './NewMemberPortalMain'

export function getSectionTitle(sectionId: MainAppSectionName) {
  //translate from strings using t
  switch (sectionId) {
    case 'my_opportunities':
      return 'My Savings'
    case 'my_account':
      return 'My Account'
    case 'my_coupons':
      return 'My Coupons'
    case 'search':
      return 'Search'
    case 'provider_patients':
      return 'My Patients'
    default:
      return ''
  }
}
export function fixBrokenRxSensePharmacyLogoUrlInIntOrDev(
  pharmacyLogoUrl: string,
): string {
  if (IS_DEV() || IS_INTEGRATION() || IS_BLUE_PROD()) {
    if (pharmacyLogoUrl.includes('images.qa.singlecare.com')) {
      return pharmacyLogoUrl.replace(
        'images.qa.singlecare.com',
        'static-singlecare.s3.amazonaws.com',
      )
    }
    //correct in prod: https://static-singlecare.s3.amazonaws.com/content/images/pharmacy/logo-color-safeway.svg"
    //incorreint in uat;  images.qa.singlecare.com/content/images/pharmacy/logo-color-kroger.svg
  }
  return pharmacyLogoUrl
}

export function getDrugIdToUseForUpdatedSearch(
  dosagesData: DosageOptionsResp | undefined,
  updatedForm: string | undefined,
  updatedDose: string | undefined,
) {
  let updateId = undefined
  if (
    !dosagesData ||
    !dosagesData?.['drug-dosages'] ||
    dosagesData?.['drug-dosages'].length <= 0
  ) {
    return updateId
  }

  let matchedDrugIds = dosagesData['drug-dosages']
    .filter((d) => d.form === updatedForm)
    .filter((d) => d.dosage === updatedDose)
    .map((d) => d.id)

  if (matchedDrugIds && matchedDrugIds.length > 1) {
    console.warn(
      'WARNING - multiple matched drug ids found for this form/dosage. Will use the first one...',
    )
  }
  if (matchedDrugIds.length === 0) {
    return undefined
  }

  updateId = matchedDrugIds[0]

  return updateId
}
