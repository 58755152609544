import { Box, Grid, IconButton, ThemeProvider } from '@mui/material'
import { useHistory } from 'react-router'
import { useSColors } from 'src/app/styles/scripta-theme'
import CardContainer from 'src/app/system/CardContainer'
import SDotsStepper from 'src/app/system/customcomponents/SDotsStepper'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'
import useEmblaCarousel from 'embla-carousel-react'
import 'src/app/system/onboarding/OnboardingContainer.css'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { EmblaCarouselType } from 'embla-carousel'
import { motion } from 'framer-motion'
import pillBottleIconSrc from 'src/app/system/images/pill_bottle.svg'
import cashCouponSrc from 'src/app/system/images/cash_coupon.svg'
import dependentsSrc from 'src/app/system/images/dependents.svg'
import pharmacySrc from 'src/app/system/images/pharmacy.svg'
import pillBottleTabletSrc from 'src/app/system/images/pill_bottle_tablets.svg'
import listLineSrc from 'src/app/system/images/list_line.svg'
import { ThemedSVGRemote } from 'src/app/v2/ThemedSVGRemote'
import { SDialog } from 'src/app/system/customcomponents/SDialog'
import WaysToSave from 'src/app/system/WaysToSave'
import {
  ModalCashAndInsurance,
  ModalTypesOfPharmacy,
} from 'src/app/system/modals/Modals'
import { ROUTE_NEW_MY_ACCOUNT_SETTINGS_DEPENDENTS } from 'src/app/v2/NewMemberPortalMain'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import InfoCard from 'src/app/system/InfoCard'
import { LOG } from 'src/app/v2/applog'
import { getScriptaTheme } from 'src/app/styles/theme-config-scripta'

const CONTENT_MAX_WIDTH = '600px'
const NAVIGATION_BUTTONS_MAX_WIDTH = '400px'

// TODO: Internationalize the texts

export default function OnboardingContainer({
  isCollapsible = false,
  isCollapsed = false,
  onCollapse = undefined,
  shouldShowCloseButton = false,
  onClose,
}: {
  isCollapsible?: boolean
  isCollapsed?: boolean
  onCollapse?: () => void
  shouldShowCloseButton?: boolean
  onClose?: () => void
}) {
  const history = useHistory()
  const { COLORS } = useSColors()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

  const [isWaysToSaveModalOpen, setIsWaysToSaveModalOpen] = useState(false)
  const [isCashAndInsuranceModalOpen, setIsCashAndInsuranceModalOpen] =
    useState(false)
  const [isPharmacyTypesModalOpen, setIsPharmacyTypesModalOpen] =
    useState(false)

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false)

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  function handleContainerCloseButtonClick() {
    if (onCollapse) {
      onCollapse()
    } else if (onClose) {
      onClose()
    } else {
      LOG.error(
        'generic_error',
        'Handle onBoardingContainer close button click error: onCollapse and onClose are both undefined',
      )
    }
  }

  function handleCollapsedCardClick() {
    setIsOnboardingModalOpen(true)
  }

  if (isCollapsed) {
    return (
      <ThemeProvider theme={getScriptaTheme()}>
        <SDialog
          onClose={() => {
            setIsOnboardingModalOpen(false)
          }}
          open={isOnboardingModalOpen}
          upperRightX={false}
          customPaperSx={{ padding: 0, margin: 0, paddingBottom: 0 }}
          customContentSx={{ padding: 0, margin: 0, border: 'none' }}
        >
          <OnboardingContainer
            shouldShowCloseButton={true}
            onClose={() => {
              setIsOnboardingModalOpen(false)
            }}
          />
        </SDialog>
        <motion.div
          key={'1'}
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            opacity: { ease: 'easeInOut' },
            layout: { duration: 1 },
          }}
          style={{ width: '100%' }}
        >
          <InfoCard
            variant="navigation_primary"
            title={'How we help you save'}
            onClick={handleCollapsedCardClick}
          />
        </motion.div>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={getScriptaTheme()}>
      <motion.div
        key={'2'}
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          opacity: { ease: 'easeInOut' },
          layout: { duration: 1 },
        }}
        style={{ width: '100%' }}
      >
        <CardContainer
          sx={{
            background: COLORS.BACKGROUND_GRADIENT,
            padding: 0,
            color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
            boxShadow: '0px 4px 12px 0px rgba(77, 0, 140, 0.15)',
            textAlign: 'center',
            width: '100%',
            border: 'unset',
          }}
        >
          <SDialog
            open={isWaysToSaveModalOpen}
            onClose={() => {
              setIsWaysToSaveModalOpen(false)
            }}
          >
            <WaysToSave />
          </SDialog>

          <ModalCashAndInsurance
            open={isCashAndInsuranceModalOpen}
            onClose={() => {
              setIsCashAndInsuranceModalOpen(false)
            }}
          />

          <ModalTypesOfPharmacy
            open={isPharmacyTypesModalOpen}
            onClose={() => {
              setIsPharmacyTypesModalOpen(false)
            }}
          />

          {isCollapsible || shouldShowCloseButton ? (
            <Grid
              container
              justifyContent={'flex-end'}
              sx={{ marginBottom: '-26px' }}
            >
              <Grid item>
                <IconButton
                  onClick={handleContainerCloseButtonClick}
                  sx={{
                    padding: '10px',
                    color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{
                      width: '26px',
                      height: '24px',
                      lineHeight: '16px',
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}

          <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
              <div className="embla__container">
                <OnboardingSlide>
                  <Grid item>
                    <STypography variant={'title3_bold'}>
                      How we help you save
                    </STypography>
                  </Grid>
                  <Grid item>
                    <STypography variant={'body_regular'}>
                      We analyze the various factors that impact prescription
                      pricing to find you savings opportunities.
                    </STypography>
                  </Grid>
                  <Grid item>
                    <OnboardingInfoCard
                      selectedIndex={selectedIndex}
                      iconIndex={0}
                    />
                  </Grid>
                </OnboardingSlide>

                <OnboardingSlide>
                  <Grid item>
                    <OnboardingInfoCard
                      selectedIndex={selectedIndex}
                      iconIndex={1}
                    />
                  </Grid>
                  <Grid item>
                    <STypography variant={'body_regular'}>
                      We offer lower-cost alternatives that are the same or
                      clinically equivalent to your current medications. You can
                      also save by adjusting the form, dose, or frequency of
                      your prescription.
                    </STypography>
                  </Grid>
                  <Grid item>
                    <OnBoardingLink
                      onClick={() => {
                        setIsWaysToSaveModalOpen(true)
                      }}
                    >
                      Learn about Scripta's ways to save.
                    </OnBoardingLink>
                  </Grid>
                </OnboardingSlide>

                <OnboardingSlide>
                  <Grid item>
                    <OnboardingInfoCard
                      selectedIndex={selectedIndex}
                      iconIndex={2}
                    />
                  </Grid>
                  <Grid item>
                    <STypography variant={'body_regular'}>
                      Medication prices can vary depending on whether you use
                      your insurance or explore alternatives like cash
                      pharmacies or savings options such as coupons. Make sure
                      to compare for the best deal!
                    </STypography>
                  </Grid>
                  <Grid item>
                    <OnBoardingLink
                      onClick={() => {
                        setIsCashAndInsuranceModalOpen(true)
                      }}
                    >
                      Learn about cash alternatives
                    </OnBoardingLink>
                  </Grid>
                </OnboardingSlide>

                <OnboardingSlide>
                  <Grid item>
                    <OnboardingInfoCard
                      selectedIndex={selectedIndex}
                      iconIndex={3}
                    />
                  </Grid>
                  <Grid item>
                    <STypography variant={'body_regular'}>
                      Also med prices vary across pharmacies, and some even
                      offer benefits like mail-order options at lower prices.
                      We’ll help you find the best options for your needs!
                    </STypography>
                  </Grid>
                  <Grid item>
                    <OnBoardingLink
                      onClick={() => {
                        setIsPharmacyTypesModalOpen(true)
                      }}
                    >
                      Learn about pharmacy types
                    </OnBoardingLink>
                  </Grid>
                </OnboardingSlide>

                <OnboardingSlide>
                  <Grid item>
                    <OnboardingInfoCard
                      selectedIndex={selectedIndex}
                      iconIndex={4}
                    />
                  </Grid>
                  <Grid item>
                    <STypography variant={'body_regular'}>
                      Make the most of your Scripta support by adding your
                      dependents. Go to the 'My Account' section and send
                      invites so they can register.
                    </STypography>
                  </Grid>
                  <Grid item>
                    <OnBoardingLink
                      onClick={() => {
                        if (history) {
                          history.push(ROUTE_NEW_MY_ACCOUNT_SETTINGS_DEPENDENTS)
                        }
                      }}
                    >
                      Go to 'Manage dependents'
                    </OnBoardingLink>
                  </Grid>
                </OnboardingSlide>
              </div>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <SDotsStepper
                  color="secondary"
                  activeStep={selectedIndex}
                  onStepChange={(step: number) => {
                    if (step > selectedIndex) {
                      scrollNext()
                    } else {
                      scrollPrev()
                    }
                  }}
                  backButton={undefined}
                  nextButton={undefined}
                  shouldAutoDisableButtons={false}
                  steps={5}
                  style={{
                    background: 'none',
                    padding: `${0}px ${SPACING._1} ${SPACING._1} ${SPACING._1}`,
                    maxWidth: NAVIGATION_BUTTONS_MAX_WIDTH,
                  }}
                />
              </Box>
            </div>
          </section>
        </CardContainer>
      </motion.div>
    </ThemeProvider>
  )
}

function OnboardingSlide({ children }: { children: React.ReactNode }) {
  return (
    <Box
      className="embla__slide"
      sx={{
        padding: SPACING._1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        container
        direction={'column'}
        alignContent={'center'}
        gap={SPACING._1}
        sx={{ maxWidth: CONTENT_MAX_WIDTH }}
      >
        {children}
      </Grid>
    </Box>
  )
}

function OnboardingInfoCard({
  selectedIndex,
  iconIndex,
}: {
  selectedIndex: number
  iconIndex: number
}) {
  const FADED_LIST_ITEM_OPACITY = 0.2
  const COMMON_ANIMATION_DURATION = 0.5

  const LIST_ICONS = [pillBottleTabletSrc, cashCouponSrc, pharmacySrc]
  const ICONS = [
    pillBottleIconSrc,
    pillBottleTabletSrc,
    cashCouponSrc,
    pharmacySrc,
    dependentsSrc,
  ]

  const ICON_HEIGHT = 92

  const LIST_ITEMS = ['What to buy', 'How to buy it', 'Where to buy it']

  function getAnimatedListSettings(index: number) {
    if (index === 0 && iconIndex === 0) {
      return [
        {
          animate: { opacity: 1, fontSize: '16px', fontWeight: 700 },
          transition: { duration: COMMON_ANIMATION_DURATION },
        },
        {
          animate: { opacity: 1, fontSize: '16px', fontWeight: 700 },
          transition: { duration: COMMON_ANIMATION_DURATION, delay: 0.25 },
        },
        {
          animate: { opacity: 1, fontSize: '16px', fontWeight: 700 },
          transition: { duration: COMMON_ANIMATION_DURATION, delay: 0.5 },
        },
      ]
    } else if (index >= 1 && index <= 3 && iconIndex !== 0) {
      // Generalized case for indices 1, 2, and 3
      const opacityArray = [
        FADED_LIST_ITEM_OPACITY,
        FADED_LIST_ITEM_OPACITY,
        FADED_LIST_ITEM_OPACITY,
      ]
      opacityArray[index - 1] = 1 // Set the opacity of the active item to 1

      return opacityArray.map((opacity) => ({
        animate: {
          opacity,
          fontSize: opacity === 1 ? '18px' : '16px',
          fontWeight: opacity === 1 ? 700 : 600,
        },
        transition: { duration: COMMON_ANIMATION_DURATION },
      }))
    } else {
      return undefined
    }
  }

  const animatedListSettings = useMemo(
    () => getAnimatedListSettings(selectedIndex),
    [selectedIndex],
  )

  const yOffset = (-selectedIndex + 1) * ICON_HEIGHT + (-selectedIndex + 1) * 8

  return (
    <CardContainer
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.20)',
        border: 'none',
        boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.25);',
        padding: SPACING._1,
      }}
    >
      <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        gap={SPACING._05}
        flexWrap={'nowrap'}
      >
        <Grid item sx={{ height: `${ICON_HEIGHT}px`, overflow: 'hidden' }}>
          {iconIndex === 0 || iconIndex === 4 ? (
            <ThemedSVGRemote
              key={ICONS[iconIndex]}
              importUrl={ICONS[iconIndex]}
              alt={`icon`}
              width={iconIndex === 4 ? 96 : 56}
              height={iconIndex === 4 ? 96 : ICON_HEIGHT}
            />
          ) : (
            <motion.div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              animate={{ y: yOffset }}
              transition={{
                duration: COMMON_ANIMATION_DURATION,
                ease: 'anticipate',
              }}
            >
              {LIST_ICONS.map((iconSrc, index) => (
                <ThemedSVGRemote
                  key={iconSrc}
                  importUrl={iconSrc}
                  alt={`icon ${index}`}
                  width={96}
                  height={ICON_HEIGHT}
                />
              ))}
            </motion.div>
          )}
        </Grid>

        {iconIndex === 4 ? (
          <Grid item>
            <STypography variant={'bodybig_bold'} sx={{ textAlign: 'left' }}>
              Last but not least
              <br />
              ...Get the whole
              <br />
              family saving!
            </STypography>
          </Grid>
        ) : (
          <Grid item>
            <Grid
              container
              direction={'column'}
              gap={SPACING._05}
              sx={{ textAlign: 'left' }}
            >
              {LIST_ITEMS.map((item, i) => (
                <Grid item key={item}>
                  <Grid container gap={SPACING._05}>
                    <Grid item sx={{ width: '20px', height: '10px' }}>
                      <ThemedSVGRemote
                        importUrl={listLineSrc}
                        alt={'list line icon'}
                        width={20}
                        height={10}
                      />
                    </Grid>
                    <Grid item>
                      <motion.div
                        initial={{
                          opacity: FADED_LIST_ITEM_OPACITY,
                          fontSize: '16px',
                          fontWeight: iconIndex === 0 ? 700 : 600,
                        }}
                        animate={
                          animatedListSettings
                            ? animatedListSettings[i].animate
                            : {}
                        }
                        transition={
                          animatedListSettings
                            ? {
                                ...animatedListSettings[i].transition,
                                ease: 'anticipate',
                              }
                            : {}
                        }
                      >
                        {item}
                      </motion.div>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </CardContainer>
  )
}

function OnBoardingLink({
  onClick,
  children,
}: {
  onClick: () => void
  children: React.ReactNode
}) {
  const { COLORS } = useSColors()
  return (
    <STypography
      variant={'body_semibold'}
      component={'span'}
      sx={{
        cursor: 'pointer',
        color: COLORS.PRIMARY_20_ILLUSTRATIONS,
        textDecoration: 'underline',
      }}
      onClick={onClick}
    >
      {children}
    </STypography>
  )
}
