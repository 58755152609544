import { Box, Typography } from '@mui/material'
import { FONT_SIZE, FONT_WEIGHT, SPACING, RADIUS } from './theme2'
import { useSColors } from '../styles/scripta-theme'

export function TagLabel({
  size,
  color,
  text,
}: {
  //tag only has two size variants - small and medium
  //medimum is only used in last page of the wizard - Summary Of How to swtich
  //which is being redesigned
  size: 'small' | 'big'
  //this is different than theme colors - tag labels only come in these 3 colors
  color: 'blue' | 'purple' | 'green' | 'grey' | 'red'
  text: string
}) {
  const { COLORS } = useSColors()

  let bgColor = ''
  let textColor = ''
  if (color === 'blue') {
    bgColor = COLORS.BACKGROUND_LIGHT_TAG_BLUE
    textColor = COLORS.TEXT_SECONDARY
  } else if (color === 'green') {
    bgColor = COLORS.BACKGROUND_LIGHT_TAG_GREEN
    textColor = COLORS.TEXT_GREEN
  } else if (color === 'purple') {
    bgColor = COLORS.PRIMARY_10
    textColor = COLORS.TEXT_BRAND
  } else if (color === 'grey') {
    bgColor = COLORS.BACKGROUND_GREY_LIGHT
    textColor = COLORS.TEXT_GREY_MEDIUM
  } else if (color === 'red') {
    bgColor = COLORS.BACKGROUND_GREY_LIGHT
    textColor = COLORS.ERROR_RED
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        backgroundColor: bgColor,
        color: textColor,
        borderRadius: RADIUS.xs,
        // width: '100%',
        // maxWidth: size === 'big' ? '150px' : '100px',
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: FONT_WEIGHT.EXTRA_BOLD,
          fontSize: FONT_SIZE.BODY_SMALL,
          paddingTop: size === 'small' ? SPACING._0 : SPACING._025,
          paddingRight: size === 'small' ? SPACING._025 : SPACING._075,
          paddingBottom: size === 'small' ? SPACING._0 : SPACING._025,
          paddingLeft: size === 'small' ? SPACING._025 : SPACING._075,
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}
