import { Grid, SxProps } from '@mui/material'
import STypography, { STypographyProps } from './customcomponents/STypography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

export type PriceElementType =
  | 'coupon'
  | 'cash'
  | 'insurance_ded'
  | 'insurance_copay'

interface PriceElementProps {
  priceBeforeDed?: string
  priceAfterDed?: string
  priceCopay?: string //i.e. $8.30
  priceCoupon?: string
  priceCash?: string
  priceType: PriceElementType
  priceVariant?: STypographyProps['variant']
  suffixVariant?: STypographyProps['variant']
  priceColor?: string
  suffixColor?: string
  shouldShowSuffixAsterisk?: boolean
  sx?: SxProps
}

const PriceElement: React.FC<PriceElementProps> = ({
  priceBeforeDed,
  priceAfterDed,
  priceCopay,
  priceCoupon,
  priceCash,
  priceType,
  priceVariant = 'bodybig_bold',
  suffixVariant = 'body_regular',
  priceColor,
  suffixColor,
  shouldShowSuffixAsterisk,
  sx,
}) => {
  const { t } = useSTranslate('newComponents')
  const isInsurancePrice =
    priceType === 'insurance_ded' || priceType === 'insurance_copay'

  function getFirstPrice(): string | undefined {
    if (priceBeforeDed !== undefined) {
      return priceBeforeDed
    } else if (priceCopay !== undefined) {
      return priceCopay
    } else if (priceCoupon !== undefined) {
      return priceCoupon
    } else if (priceCash !== undefined) {
      return priceCash
    }
  }

  function priceTypeToLabel(): string[] {
    switch (priceType) {
      case 'coupon':
        return [t('priceElePriceWithCoupon')]
      case 'cash':
        return [t('priceElePayingByCash')]
      case 'insurance_ded':
        return [t('priceEleBeforeDed'), t('priceEleAfterDed')]
      case 'insurance_copay':
        return [t('priceElePriceWithCopay')]
    }
  }

  return (
    <Grid container item gap={'4px'} sx={sx}>
      <Grid item>
        <STypography
          component={'span'}
          variant={priceVariant}
          color={priceColor}
        >
          {getFirstPrice() ?? ''}
        </STypography>
        <STypography
          component={'span'}
          variant={suffixVariant}
          color={suffixColor}
        >
          {' '}
          {priceTypeToLabel()[0]}
          {shouldShowSuffixAsterisk && isInsurancePrice ? '*' : ''}
        </STypography>
      </Grid>
      {priceType === 'insurance_ded' && priceAfterDed ? (
        <Grid item>
          <STypography
            component={'span'}
            variant={priceVariant}
            color={priceColor}
          >
            {priceAfterDed}
          </STypography>
          <STypography
            component={'span'}
            variant={suffixVariant}
            color={suffixColor}
          >
            {' '}
            {priceTypeToLabel()[1]}
            {shouldShowSuffixAsterisk && isInsurancePrice ? '*' : ''}
          </STypography>
        </Grid>
      ) : null}
    </Grid>
  )
}

export default PriceElement
