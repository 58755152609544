import { Box, BoxProps } from '@mui/material'
import { SPACING } from './theme2'
import { useSColors } from '../styles/scripta-theme'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

export function CircleWrappedFontIcon({
  icon,
  sx,
  //for whatever reason when passing a themed svg (and not a font awesome icon) the icon is not centered
  //so we need to add this wrapper
  padForThemedSVG = false,
}: {
  icon: React.ReactElement<FontAwesomeIconProps>
  sx?: BoxProps['sx']
  padForThemedSVG?: boolean
}) {
  const { COLORS } = useSColors()
  return (
    <Box
      sx={{
        position: 'relative', // Add position property
        backgroundColor: COLORS.PRIMARY_20,
        borderRadius: '50%',
        width: '56px',
        height: '56px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: SPACING._075,
        fontSize: '24px',
        ...(padForThemedSVG ? { paddingTop: '25px' } : {}),
        ...sx,
      }}
    >
      {icon}
    </Box>
  )
}
