import { Grid, Box, Theme, useTheme, alpha } from '@mui/material'
import STypography from './customcomponents/STypography'
import CardContainer from './CardContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleInfo,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { SPACING } from './theme2'
import { getScriptaTheme } from 'src/app/styles/theme-config-scripta'

interface InfoCardStyleProps {
  containerCursor: 'auto' | 'pointer'
  containerBorder?: string | undefined
  containerBackground: string
  cardBoxShadow?: string
  titleColor: string
  bodyColor: string
  mainIconColor: string
  mainIconContainerBackground: string
  navigationIconColor?: string
}

export type InfoCardVariant =
  | 'default_primary'
  | 'navigation_primary'
  | 'default_secondary'
  | 'navigation_secondary'

function getVariantStyles(
  theme: Theme,
  disableTheming?: boolean,
): Record<InfoCardVariant, InfoCardStyleProps> {
  const COLORS = disableTheming
    ? getScriptaTheme().customized.colors
    : theme.customized.colors
  return {
    default_primary: {
      containerCursor: 'auto',
      containerBorder: undefined,
      containerBackground: COLORS.PRIMARY_10,
      titleColor: COLORS.PRIMARY_BRAND,
      bodyColor: COLORS.PRIMARY_BRAND,
      mainIconColor: COLORS.PRIMARY_BRAND,
      mainIconContainerBackground: COLORS.PRIMARY_20,
    },
    navigation_primary: {
      containerCursor: 'pointer',
      containerBorder: `1px solid ${COLORS.PRIMARY_BRAND}`,
      containerBackground: COLORS.PRIMARY_10,

      cardBoxShadow: `0px 4px 8px 0px ${alpha(COLORS.PRIMARY_BRAND, 0.1)}`,
      // cardBoxShadow: '0px 4px 8px 0px rgba(77, 0, 140, 0.10)',

      titleColor: COLORS.PRIMARY_BRAND,
      bodyColor: COLORS.PRIMARY_BRAND,
      mainIconColor: COLORS.PRIMARY_BRAND,
      mainIconContainerBackground: COLORS.PRIMARY_20,
      navigationIconColor: COLORS.PRIMARY_BRAND,
    },
    default_secondary: {
      containerCursor: 'auto',
      containerBorder: undefined,
      containerBackground: COLORS.BACKGROUND_GREY_LIGHTER,
      titleColor: COLORS.TEXT_GREY_DARK,
      bodyColor: COLORS.TEXT_GREY_DARK,
      mainIconColor: COLORS.TEXT_GREY_DARK,
      mainIconContainerBackground: COLORS.BACKGROUND_GREY_LIGHT,
    },
    navigation_secondary: {
      containerCursor: 'pointer',
      containerBorder: `1px solid ${COLORS.BORDER_GREY_LIGHT}`,
      containerBackground: '#FFF',
      titleColor: COLORS.TEXT_BLACK,
      bodyColor: COLORS.TEXT_GREY_DARK,
      mainIconColor: COLORS.TEXT_BLACK,
      mainIconContainerBackground: COLORS.BACKGROUND_GREY_LIGHTER,
      navigationIconColor: COLORS.TEXT_GREY_DARK,
    },
  }
}

export interface InfoCardProps {
  title: string
  body?: string
  customBodyNode?: React.ReactNode
  variant?: InfoCardVariant
  icon?: IconDefinition
  onClick?: () => void
  disableClickAndCursorPointer?: boolean
  disableTheming?: boolean
}

export default function InfoCard({
  title,
  variant = 'default_primary',
  icon,
  body,
  customBodyNode,
  onClick,
  disableTheming,
}: InfoCardProps) {
  const theme = useTheme()
  const styleToUse = getVariantStyles(theme, disableTheming)[variant]

  return (
    <CardContainer
      sx={{
        padding: `${SPACING._05} ${SPACING._075}`,
        cursor: styleToUse.containerCursor,
        border: styleToUse.containerBorder,
        backgroundColor: styleToUse.containerBackground,
        boxShadow: styleToUse.cardBoxShadow,
      }}
      onClick={onClick}
    >
      <Grid
        container
        gap={SPACING._05}
        justifyContent="space-between"
        flexWrap={'nowrap'}
        alignItems={'center'}
      >
        <Grid
          item
          container
          direction="row"
          gap={SPACING._05}
          flexWrap={'nowrap'}
          alignItems={'center'}
        >
          <Grid item alignSelf={'flex-start'}>
            <Box
              sx={{
                padding: SPACING._05,
                borderRadius: SPACING._05,
                backgroundColor: styleToUse.mainIconContainerBackground,
                height: '40px',
                boxSizing: 'border-box',
              }}
            >
              <FontAwesomeIcon
                icon={icon ?? faCircleInfo}
                color={styleToUse.mainIconColor}
                height={'16px'}
                fontSize={'16px'}
                style={{ padding: '4px' }}
              />
            </Box>
          </Grid>
          <Grid item>
            <STypography
              variant={'body_bold'}
              sx={{ color: styleToUse.titleColor }}
            >
              {title}
            </STypography>
            <STypography
              variant={'bodysmall_regular'}
              sx={{ color: styleToUse.bodyColor }}
            >
              {body}
            </STypography>
          </Grid>
          {customBodyNode && <>{customBodyNode}</>}
        </Grid>

        {variant === 'navigation_primary' ||
        variant == 'navigation_secondary' ? (
          <Grid item>
            <FontAwesomeIcon
              icon={faChevronRight}
              height={'12px'}
              fontSize={'12px'}
              style={{ padding: '4px', marginTop: '3.5px' }}
              color={styleToUse.navigationIconColor}
            />
          </Grid>
        ) : null}
      </Grid>
    </CardContainer>
  )
}
