import CardContainer from './CardContainer'
import { Grid, Link, Box, alpha } from '@mui/material'
import { SPACING } from './theme2'
import STypography from './customcomponents/STypography'
import helpYouSaveUrl from './images/piggy_bank.svg'
import couponsSaveUrl from './images/discount_illustration.svg'
import helpSwitchRxUrl from './images/sammy-line-service-support-3.svg'
import dependentsUrl from './images/dependents.svg'
import { STrans, useSTranslate } from 'src/app/hooks/useSTranslate'
import { useMemberAppContext } from '../MemberAppContext'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'
import { ROUTE_NEW_MY_ACCOUNT_SETTINGS_DEPENDENTS } from 'src/app/v2/NewMemberPortalMain'
import { useHistory } from 'react-router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SDialog } from 'src/app/system/customcomponents/SDialog'
import './onboarding/OnboardingContainer.css'
import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import SDotsStepper from 'src/app/system/customcomponents/SDotsStepper'

export function BannerCardHelpYouSave({
  onButtonClick,
}: {
  onButtonClick: () => void
}) {
  const { t } = useSTranslate('newComponents')

  return (
    <BannerCard
      image={
        <ThemedSVGRemote
          importUrl={helpYouSaveUrl}
          alt={'Help you save'}
          width={112}
          height={112}
        />
      }
      title={t('bannerCardHelpYouSaveTitle')}
      body={t('bannerCardHelpYouSaveBody')}
      buttonLabel={t('bannerCardHelpYouSaveButton')}
      onButtonClick={onButtonClick}
    />
  )
}

export function BannerCardCoupons({
  onButtonClick,
}: {
  onButtonClick: () => void
}) {
  const { t } = useSTranslate('newComponents')

  //disable if coupons are disabled as a feature
  const { featureConfig } = useMemberAppContext()
  if (
    featureConfig &&
    featureConfig.isInitialized &&
    featureConfig.isCouponDisabled
  ) {
    return null
  }

  return (
    <BannerCard
      image={
        <ThemedSVGRemote
          importUrl={couponsSaveUrl}
          alt={'How to save with coupons'}
          width={112}
          height={112}
        />
      }
      title={t('bannerCardCouponsTitle')}
      body={t('bannerCardCouponsBody')}
      buttonLabel={t('bannerCardCouponsButton')}
      onButtonClick={onButtonClick}
    />
  )
}

export function BannerCardHelpSwitchRx({
  onButtonClick,
  onModalClosed,
  isHelpMeSwitchButton = false,
}: {
  onButtonClick: () => void
  onModalClosed?: () => void
  isHelpMeSwitchButton?: boolean
}) {
  const { t } = useSTranslate('newComponents')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { COLORS } = useSColors()

  return (
    <>
      <SDialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          onModalClosed && onModalClosed()
        }}
      >
        <Grid
          container
          direction={'column'}
          alignItems={'center'}
          gap={SPACING._025}
        >
          <Grid item sx={{ width: '112px', height: '112px' }}>
            <ThemedSVGRemote
              importUrl={helpSwitchRxUrl}
              alt={'Help you switch Rx info'}
              width={112}
              height={112}
            />
          </Grid>
          <Grid item>
            <STypography
              variant="title3_bold"
              sx={{ color: COLORS.TEXT_BRAND }}
            >
              Switch assistance
            </STypography>
          </Grid>
          <Grid item>
            <STypography variant="body_regular" sx={{ whiteSpace: 'pre-line' }}>
              <STrans
                i18nKey="switchAssistanceDescription"
                namespace={'newComponents'}
              />
            </STypography>
          </Grid>
        </Grid>
      </SDialog>
      <BannerCard
        image={
          <ThemedSVGRemote
            importUrl={helpSwitchRxUrl}
            alt={'Help you switch Rx info'}
            width={112}
            height={112}
          />
        }
        title={t('bannerCardHelpSwitchRxTitle')}
        body={t('bannerCardHelpSwitchRxBody')}
        buttonLabel={t(
          isHelpMeSwitchButton
            ? 'bannerCardHelpSwitchRxButton'
            : 'bannerCardHelpSwitchRxLearnMoreButton',
        )}
        onButtonClick={(e: any) => {
          e.stopPropagation()
          if (isHelpMeSwitchButton) {
            onButtonClick()
          } else {
            setIsModalOpen(true)
          }
        }}
      />
    </>
  )
}

export function BannerCardDependents({
  onButtonClick,
}: {
  onButtonClick: () => void
}) {
  const { t } = useSTranslate('newComponents')
  const history = useHistory()

  return (
    <BannerCard
      image={
        <ThemedSVGRemote
          importUrl={dependentsUrl}
          alt={'Dependents info'}
          width={112}
          height={112}
        />
      }
      title={t('bannerCardDependentsTitle')}
      body={t('bannerCardDependentsBody')}
      buttonLabel={t('bannerCardDependentsButton')}
      onButtonClick={() => {
        if (history) {
          history.push(ROUTE_NEW_MY_ACCOUNT_SETTINGS_DEPENDENTS)
        }
      }}
    />
  )
}

// TODO: refactor carousel logic to be a reusable component
export function BannerCardMySavingsPageCarousel({
  onViewCouponsClick,
}: {
  onViewCouponsClick: () => void
}) {
  const { COLORS } = useSColors()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ playOnInit: true, delay: 5000 }),
  ])
  const [isMouseOver, setIsMouseOver] = useState(false)
  const { featureConfig } = useMemberAppContext()

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const togglePlay = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    autoplay.play()
  }, [emblaApi])

  const toggleStop = useCallback(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    autoplay.stop()
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return

    const autoplay = emblaApi?.plugins()?.autoplay
    if (!autoplay) return

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  if (!featureConfig || !featureConfig.isInitialized) {
    return null
  }

  return (
    <Box
      sx={{ width: 'calc(100% + 32px)', marginLeft: '-16px' }}
      component={'div'}
    >
      <section
        className="embla"
        onMouseEnter={() => {
          setIsMouseOver(true)
          toggleStop()
        }}
        onMouseLeave={() => {
          setIsMouseOver(false)
          togglePlay()
        }}
      >
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {!featureConfig.isCouponDisabled ? (
              <Box
                className="embla__slide"
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                style={{ padding: SPACING._1 }}
              >
                <BannerCardCoupons onButtonClick={onViewCouponsClick} />
              </Box>
            ) : null}
            <Box
              className="embla__slide"
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              style={{ padding: SPACING._1 }}
            >
              <BannerCardHelpSwitchRx
                onButtonClick={() => {}}
                onModalClosed={() => {
                  setIsMouseOver(false)
                  togglePlay()
                }}
              />
            </Box>
            <Box
              className="embla__slide"
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              style={{ padding: SPACING._1 }}
            >
              <BannerCardDependents onButtonClick={() => {}} />
            </Box>
          </div>
        </div>
      </section>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: '-6px',
        }}
        onMouseEnter={() => {
          setIsMouseOver(true)
          toggleStop()
        }}
        onMouseLeave={() => {
          setIsMouseOver(false)
          togglePlay()
        }}
      >
        <SDotsStepper
          color="primary"
          activeStep={selectedIndex}
          shouldAutoDisableButtons={false}
          onStepChange={(step: number) => {
            if (step > selectedIndex) {
              scrollNext()
            } else {
              scrollPrev()
            }
          }}
          buttonSx={{
            backgroundColor: 'transparent',
            color: COLORS.TEXT_BRAND,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          backButton={undefined}
          nextButton={undefined}
          steps={!featureConfig.isCouponDisabled ? 3 : 2}
          style={{
            background: 'none',
            padding: `${0}px ${SPACING._1} ${SPACING._1} ${SPACING._1}`,
            maxWidth: 400,
          }}
        />
      </Box>
    </Box>
  )
}

export interface BannerCardProps {
  image: React.ReactElement
  title: string
  body: string
  buttonLabel: string
  onButtonClick: (e: any) => void
}

export default function BannerCard({
  image,
  title,
  body,
  buttonLabel,
  onButtonClick,
}: BannerCardProps) {
  const { COLORS } = useSColors()
  return (
    <CardContainer
      sx={{
        padding: `${SPACING._075} ${SPACING._1}`,
        backgroundColor: COLORS.PRIMARY_10,
        boxShadow: `0px 4px 12px 0px ${alpha(COLORS.PRIMARY_BRAND, 0.15)}`,
        border: `1px solid ${COLORS.PRIMARY_BRAND}`,
      }}
    >
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap={SPACING._05}
        alignItems={'center'}
      >
        <Grid item container direction={'column'} gap={SPACING._05}>
          <Grid item>
            <STypography
              variant="body_bold"
              sx={{ color: COLORS.PRIMARY_BRAND }}
            >
              {title}
            </STypography>
          </Grid>

          <Grid item>
            <STypography variant="bodysmall_regular">{body}</STypography>
          </Grid>

          <Grid item>
            <Link
              sx={{ cursor: 'pointer', color: COLORS.PRIMARY_BRAND }}
              onClick={onButtonClick}
            >
              <STypography variant="bodysmall_semibold">
                {buttonLabel}
              </STypography>
            </Link>
          </Grid>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center">
            {image}
          </Box>
        </Grid>
      </Grid>
    </CardContainer>
  )
}
