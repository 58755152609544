import { DialogContent, Grid, TextField, Typography } from '@mui/material'

import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
} from '../member/phone-field-utils'
import { BottomActions, DESKTOP_DIALOG_SX, MOBILE_DIALOG_SX } from './Wizard'
import {
  UiFormData,
  ValidationResult,
  validateUiData,
  autoformatDob,
} from './types'

export function PatientDetails({
  patientData,
  onPatientDataChange,
  isSmallDisplay,
  onContinue,
  onBack,
  //sent by desktop component upon click of submit
  forceTriggerValidation,
  validationResult,
  setValidationResult,
}: {
  patientData: UiFormData
  onPatientDataChange: (patientData: UiFormData) => void
  isSmallDisplay: boolean
  onContinue: () => void
  onBack: () => void
  forceTriggerValidation: boolean
  validationResult: ValidationResult
  setValidationResult: (validationResult: ValidationResult) => void
}) {
  const { t } = useSTranslate('contactMyPrescriber')

  //for now this is always true, but we might want to change this in the future
  let renderBottomActions = isSmallDisplay

  //dont validate immediatelly, let user enter some data first and click continue once
  const [enableValidation, setEnableValidation] = useState<boolean>(false)

  useEffect(() => {
    if (forceTriggerValidation) {
      setEnableValidation(true)
    }
  }, [forceTriggerValidation])

  useEffect(() => {
    if (enableValidation) {
      const validationResult = validateUiData(patientData)
      setValidationResult(validationResult)
    }
  }, [patientData, enableValidation])

  const isRepresented = patientData && patientData.authorityType

  return (
    <>
      <DialogContent sx={isSmallDisplay ? MOBILE_DIALOG_SX : DESKTOP_DIALOG_SX}>
        {/* //we have more spacing in mobile because its multiple pages so we use
        large */}
        <Grid
          item
          container
          spacing={isSmallDisplay ? 2 : 1}
          // sx={{ height: '80vh', minHeight: '80vh' }}
        >
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '1.15rem' }}>
              {t('patientYourContactInfo')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              //we actually have more space in mobile because its multiple pages so we use large
              // size={isSmallDisplay ? 'medium' : 'small'}
              size={'small'}
              fullWidth
              placeholder={
                isRepresented
                  ? t('patientFullNameLabelWhenRepresented')
                  : t('patientFullNameLabel')
              }
              label={
                isRepresented
                  ? t('patientFullNameLabelWhenRepresented')
                  : t('patientFullNameLabel')
              }
              value={patientData.fullName}
              onChange={(e: any) => {
                onPatientDataChange({
                  ...patientData,
                  fullName: e.target.value,
                })
              }}
              error={enableValidation && !validationResult.validName}
              helperText={
                enableValidation && !validationResult.validName
                  ? t('errorPatientFullNameField')
                  : ''
              }
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              size={'small'}
              fullWidth
              placeholder={t('patientEmailLabel')}
              label={t('patientEmailLabel')}
              value={patientData.email}
              onChange={(e: any) => {
                onPatientDataChange({
                  ...patientData,
                  email: e.target.value,
                })
              }}
              error={enableValidation && !validationResult.validEmail}
              helperText={
                enableValidation && !validationResult.validEmail
                  ? t('errorPatientEmailField')
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              size={'small'}
              fullWidth
              placeholder={t('patientPhoneLabel')}
              // fullWidth
              label={t('patientPhoneLabel')}
              value={patientData.phone}
              onChange={(e: any) => {
                //process and format input to display friendly form
                //up to 10 digits
                if (toDigitsOnly(e.target.value).length <= 10) {
                  let displayNumberValue = toDisplayFriendlyString(
                    e.target.value,
                  )
                  const isValid = isDisplayFriendlyStringValidNumber(
                    e.target.value,
                  )
                  const updatedValidation = {
                    ...validationResult,
                    validPhone: isValid,
                  }
                  setValidationResult(updatedValidation)
                  onPatientDataChange({
                    ...patientData,
                    phone: displayNumberValue,
                  })
                }
              }}
              error={enableValidation && !validationResult.validPhone}
              helperText={
                enableValidation && !validationResult.validPhone
                  ? t('errorPatientPhoneField')
                  : ''
              }
              inputProps={{
                autoComplete: 'nope',
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ fontSize: '1.15rem' }}>
            <TextField
              size={'small'}
              fullWidth
              autoComplete="off"
              value={patientData.dob}
              onChange={(e) => {
                const formatted = autoformatDob(e.target.value)
                onPatientDataChange({
                  ...patientData,
                  dob: formatted,
                })
              }}
              label={t('patientDobLabel')}
              placeholder={t('patientDobLabel')}
              error={enableValidation && !validationResult.validDob}
              helperText={
                enableValidation && !validationResult.validDob
                  ? t('errorPatientDobField')
                  : ''
              }
              inputProps={{
                maxLength: 10,
                autoComplete: 'nope',
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {renderBottomActions && (
        <BottomActions
          isSmallDisplay={isSmallDisplay}
          onContinue={() => {
            setEnableValidation(true)
            const ret = validateUiData(patientData)
            setValidationResult(ret)
            if (
              ret.validDob &&
              ret.validEmail &&
              ret.validName &&
              ret.validPhone
            ) {
              onContinue()
            } else {
            }
          }}
          onCancel={onBack}
        />
      )}
    </>
  )
}
