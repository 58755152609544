import {
  DrugBase,
  MemberPrescriptionAlternative,
  MemberPrescriptionSaving,
  MemberSavingReport,
} from '../../types'
import { monthNumberToMonthName } from '../shared/utils'
import { DrugPriceInfo } from '../search/useDrugSearchService'
import { FeatureConfig } from '../config/features'

//new util to try safer way to get the first drug
//we used to have drugs node in the savings report which now seems to be gone
//this will check if the node is there, if not, it will built the first drug using the parent node
export function safeGetDrugBasesNode(
  prescription?: MemberPrescriptionSaving,
): DrugBase[] {
  let drugBases: DrugBase[] = []
  if (!prescription) return drugBases
  if (
    prescription.prescription &&
    prescription.prescription.drug &&
    prescription.prescription.drug.drugs &&
    prescription.prescription.drug.drugs.length > 0
  ) {
    drugBases = prescription.prescription.drug.drugs
  } else {
    // console.error(
    //   'WARNING WARNING - no drugs subnode found under the expected prescription node, will use parent node to rebuild it',
    // )
    //build the first drug using the parent node
    if (prescription.prescription && prescription.prescription.drug) {
      const drugBase: DrugBase = prescription.prescription.drug as any
      drugBases.push(drugBase)
    }
    // 
  }
  return drugBases
}

//anoter helper like above - this time for alternative in case drugs[] is missing
export function safeGetDrugBasesNodeFromAlternative(
  alt: MemberPrescriptionAlternative,
): DrugBase[] {
  let drugBases: DrugBase[] = []
  if (!alt) return drugBases
  if (alt.drug && alt.drug.drugs && alt.drug.drugs.length > 0) {
    drugBases = alt.drug.drugs
  } else {
    // console.error(
    //   'WARNING WARNING - no drugs subnode found under the expected alternative node, will use parent node to rebuild it',
    // )
    //build the first drug using the parent node
    if (alt.drug) {
      const drugBase: DrugBase = alt.drug as any
      drugBases.push(drugBase)
    }
    // 
  }
  return drugBases
}

export function hasCouponOnOriginal(
  prescription: MemberPrescriptionSaving,
  featureConfig: FeatureConfig,
) {
  //in json, a coupon on the original means that there is alternative listed with strategy = Coupons and mmaId = 1 + (hasCoupon=true)

  if (featureConfig && featureConfig.isCouponDisabled) {
    return false
  }

  let hasCoupon = false
  if (prescription.alternatives && prescription.alternatives.length > 0) {
    //check if there is an alternative with strategy = Coupons and mmaId = 1
    for (const alternative of prescription.alternatives) {
      // 
      if (isAlternativeTheCouponOnOriginal(alternative)) {
        hasCoupon = true
        break
      }
    }
  }
  return hasCoupon
}

//find the exact alternative that represents coupon on original (so we can preselect it as a regular alternative)
//when clicking "get coupon" button (it may be third of fourth in the list)
export function findExactCouponOnOriginalAlternative(
  prescription?: MemberPrescriptionSaving,
) {
  if (!prescription) return undefined
  let originalCouponAlternative: MemberPrescriptionAlternative | undefined
  if (prescription.alternatives && prescription.alternatives.length > 0) {
    //check if there is an alternative with strategy = Coupons and mmaId = 1
    for (const alternative of prescription.alternatives) {
      // 
      if (isAlternativeTheCouponOnOriginal(alternative)) {
        originalCouponAlternative = alternative
        break
      }
    }
  }
  return originalCouponAlternative
}

export function isAlternativeTheCouponOnOriginal(
  alternative: MemberPrescriptionAlternative,
) {
  let isCouponOnOriginal = false
  if (
    alternative.strategy === 'Coupons' &&
    alternative.drug.mmaIdValidOnlyUnderAlternatives &&
    alternative.drug.mmaIdValidOnlyUnderAlternatives === 1
  ) {
    isCouponOnOriginal = true
  }
  return isCouponOnOriginal
}

//temp fix untill we have backend returning plan type on member profile and/or savings report
export function tempDeterminePlanTypeFromPriceInfoArray(
  priceInfos?: DrugPriceInfo[],
): 'COPAY' | 'DEDUCTIBLE' {
  if (priceInfos && priceInfos.length > 0) {
    const hasCopayPrice = priceInfos?.filter((p) => p.type === 'COPAY').pop()
    if (hasCopayPrice) {
      return 'COPAY'
    } else {
      return 'DEDUCTIBLE'
    }
  } else {
    //we don't even have price info, nothing we can do
    return 'COPAY'
  }
}

export function isZeroSavingsReport(savingReport: MemberSavingReport) {
  return savingReport.maxSavings === 0
}

export function concatDrugNames(drugs: DrugBase[]) {
  let name = ''
  drugs.forEach((d, idx) => {
    name = idx === 0 ? name + d.simpleName : name + '+' + d.simpleName
  })
  return name
}

export function concatDrugDosages(drugs: DrugBase[]) {
  let dosage = ''
  drugs.forEach((d, idx) => {
    dosage = idx === 0 ? dosage + d.dosage : dosage + '+' + d.dosage
  })
  return dosage
}

export function concatDrugForms(drugs: DrugBase[]) {
  let form = ''
  drugs.forEach((d, idx) => {
    form = idx === 0 ? form + d.form : form + '+' + d.form
  })
  return form
}

export function concatDrugQuantity(drugs: DrugBase[]) {
  let quantity = ''
  drugs.forEach((d, idx) => {
    if (d.quantity && d.quantity > 0) {
      quantity = idx === 0 ? quantity + d.quantity : quantity + '+' + d.quantity
    }
  })
  return quantity
}

export const getGreetingTranslationKey = (): string => {
  const d = new Date()
  const time = d.getHours()

  if (time < 12) {
    return 'morningGreeting'
  } else if (time < 18) {
    return 'afternoonGreeting'
  } else {
    return 'eveningGreeting'
  }
}
/**
 * input "2022-12-01"
 */
export function extractMonthNumberFromDateString(
  dateString: string,
): number | undefined {
  return Number(dateString.split('-')[1])
}

export function getMonthNameFromSavingsReport(
  dateString: string,
): string | undefined {
  return monthNumberToMonthName(extractMonthNumberFromDateString(dateString))
}
export function getYearFromSavingReport(
  dateString: string | undefined,
): string | number | undefined {
  if (!dateString) return new Date().getFullYear()

  let year: string | number = new Date().getFullYear()
  try {
    year = dateString.split('-')[0].toString()
  } catch (e) {
    
  }
  return year
}

export function getOriginalId(
  prescription: MemberPrescriptionSaving,
): string | undefined {
  // if (prescription.prescription && prescription.prescription?.drug.drugs[0]) {
  if (prescription.prescription && safeGetDrugBasesNode(prescription)[0]) {
    return (
      safeGetDrugBasesNode(prescription)[0].name +
      safeGetDrugBasesNode(prescription)[0].dosage
    )
  }
}

// two decimals for non-zero amount
export function getDollarDisplay(
  amount: number | undefined,
): string | undefined {
  if (amount !== undefined) {
    if (amount === 0) {
      return '$' + amount
    }

    return '$' + amount.toFixed(2)
  }

  return undefined
}
