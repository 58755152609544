import {
  useMediaQuery,
  Grid,
  Typography,
  RadioGroup,
  Box,
  Radio,
  useTheme,
} from '@mui/material'
import { useState, useEffect, useCallback } from 'react'
import { GAService } from 'src/app/application/ga/GAService'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useMemberAppContext } from 'src/app/MemberAppContext'
import { RegistrationStepCard } from 'src/app/registration/components/RegistrationStepCard'
import { useRegistration } from 'src/app/registration/RegistrationContext'
import {
  REG_ROUTE_REGISTER_TENANT_ACCOUNT,
  REG_ROUTE_SERVER_ERROR,
  REG_ROUTE_COMPLETE_PREREG_PROFILE,
  REG_ROUTE_EDIT_CONTACT_INFO,
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_IDENTITY_VALIDATION,
} from 'src/app/registration/RegistrationSubRoutes'
import { useRegistrationService } from 'src/app/registration/useRegistrationService'
import { useSColors } from 'src/app/styles/scripta-theme'
import {
  RegistrationChallengeResponse,
  isServerErrorResponse,
  VerifyRegistrationIdentityResponse,
  isValidResponse,
} from 'src/types'

export function RegChallengeQuestions() {
  const {
    history,
    member,
    setApiResponse,
    setRegistrationJwt,
    regChallenge,
    matchedTenant,
    setMember,
  } = useRegistration()
  const { isFirstTimePreregMemberAccess } = useMemberAppContext()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const gaService = new GAService()
  const service = useRegistrationService()
  const [hasAnyUnanswered, setHasAnyUnanswered] = useState(true)
  const [challengeResponse, setChallengeResponse] =
    useState<RegistrationChallengeResponse>({
      tenantId: member?.tenantId || 0,
      memberId: member?.id || 0,
      addressResponse: UNSELECTED_ANSWER_VALUE,
      drugResponse: UNSELECTED_ANSWER_VALUE,
      prescriberResponse: UNSELECTED_ANSWER_VALUE,
    })

  const { t } = useSTranslate(['register'])

  const hasSelectedAddress = challengeResponse.addressResponse > -1
  const hasSelectedDrug = challengeResponse.drugResponse > -1
  const hasSelectedPrescriber = challengeResponse.prescriberResponse > -1
  const shouldDisableNextButton =
    !hasSelectedAddress || !hasSelectedDrug || !hasSelectedPrescriber

  if (!member || !member.tenantId || !member.id) {
    //this happens in local dev during restarts

    history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)

    // history.push
    // throw new Error(
    //   'Cannot continue - member.id or member.tenantId are not set',
    // )
    //set member and tenant id for dev
    // if (IS_DEV()) {
    //   setMember({
    //     ...member,
    //     tenantId: 24,
    //     id: 11723,
    //   })
    // }
  }

  const addresses = regChallenge?.challengeAddresses || []
  const drugs = regChallenge?.challengeDrugs || []
  const docs = regChallenge?.challengePrescribers || []
  if (!addresses || !drugs || !docs)
    throw Error('Mising challenge questions...')

  async function asyncVerifyResponse(
    regResponses: RegistrationChallengeResponse,
  ) {
    try {
      const resp = await service.verifyChallengeQuestionsApi(regResponses)

      if (isServerErrorResponse<VerifyRegistrationIdentityResponse>(resp)) {
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<VerifyRegistrationIdentityResponse>(resp)) {
        const registrationJwt = resp.registrationJwt

        setRegistrationJwt(registrationJwt)
        setApiResponse(resp)

        if (isFirstTimePreregMemberAccess) {
          history.push(REG_ROUTE_COMPLETE_PREREG_PROFILE)
        } else {
          history.push(REG_ROUTE_EDIT_CONTACT_INFO)
        }
      } else {
        setApiResponse(resp)
        history.push(REG_ROUTE_ERROR_HANDLER)
      }
    } catch (e) {
      throw new Error('error in api execution')
    }
  }

  useEffect(() => {
    if (
      challengeResponse.addressResponse !== UNSELECTED_ANSWER_VALUE &&
      challengeResponse.drugResponse !== UNSELECTED_ANSWER_VALUE &&
      challengeResponse.prescriberResponse !== UNSELECTED_ANSWER_VALUE
    ) {
      setHasAnyUnanswered(false)
    } else {
      setHasAnyUnanswered(true)
    }
  }, [challengeResponse])

  useEffect(() => {
    document.title = 'Challenge Questions - Scripta Insights'
  })

  const doVerifyQuestions = useCallback(() => {
    gaService.trackEvent(gaService.eventMap.step_4_reg_next)
    asyncVerifyResponse(challengeResponse)
  }, [challengeResponse])

  return (
    <RegistrationStepCard
      registrationType="registration"
      progressBarStep={4}
      // step={t('register:step', { current: 4, total: 7 })}
      // title={title}
      // message={t('register:scriptaRegistrationDescription')}
      cancelButtonTitle={t('common:back')}
      onClickNextButton={doVerifyQuestions}
      onClickCancelButton={() => {
        //EDGE CASE - if user doesnt have email or eligibility info, they will
        //be redirected here automatically
        //so if they click back they would be stuck in a loop
        //if we dont have email and phone we will go all the way back to new tenant then
        if (!member!.enrollmentEmail && !member!.enrollmentMobilePhone) {
          history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
        } else {
          history.push(REG_ROUTE_IDENTITY_VALIDATION)
        }
      }}
      nextButtonDisabled={false}
      // enableOverflow={isSmall ? true : false}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              textAlign: 'center',
              fontSize: isSmall ? '1.125rem' : '1.5rem',
              fontWeight: 700,
              paddingBottom: '20px',
              paddingTop: '0px',
              // marginTop: isSmall ? '-15px' : '0px',
              lineHeight: '21.92px',
            })}
          >
            {t('register:answerQuestions')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'center',
              paddingLeft: isSmall ? '20px' : undefined,
              paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            {t('register:answerQuestionDesc')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '20px',
            paddingLeft: isSmall ? '20px' : '10px',
            paddingRight: isSmall ? '20px' : undefined,
          }}
        >
          <StringArrayNumberValuedBasedRadioGroup
            isSmall={isSmall}
            questionNumber="1"
            questionText={t('regChallengeAddress')}
            data={addresses}
            onValueChanged={(addNum: number) => {
              setChallengeResponse({
                ...challengeResponse,
                addressResponse: addNum,
              })
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '20px',
            paddingLeft: isSmall ? '20px' : '10px',
            paddingRight: isSmall ? '20px' : undefined,
          }}
        >
          <StringArrayNumberValuedBasedRadioGroup
            isSmall={isSmall}
            questionNumber="2"
            questionText={t('regChallengeMeds')}
            data={drugs}
            onValueChanged={(drugNum: number) => {
              setChallengeResponse({
                ...challengeResponse,
                drugResponse: drugNum,
              })
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: '20px',
            paddingLeft: isSmall ? '20px' : '10px',
            paddingRight: isSmall ? '20px' : '10px',
          }}
        >
          <StringArrayNumberValuedBasedRadioGroup
            isSmall={isSmall}
            questionNumber="3"
            questionText={t('regChallengePrescriber')}
            data={docs}
            onValueChanged={(docNum: number) => {
              setChallengeResponse({
                ...challengeResponse,
                prescriberResponse: docNum,
              })
            }}
          />
        </Grid>
      </Grid>
    </RegistrationStepCard>
  )
}

type UNSELECTED_ANSWER = -1
const UNSELECTED_ANSWER_VALUE: UNSELECTED_ANSWER = -1
function StringArrayNumberValuedBasedRadioGroup({
  isSmall,
  questionNumber,
  questionText,
  data,
  onValueChanged,
}: {
  isSmall: boolean
  questionNumber: string
  questionText: string
  data: string[]
  onValueChanged: (newVal: number | UNSELECTED_ANSWER) => void
}) {
  const [val, setVal] = useState<string>('')

  useEffect(() => {
    //response numbers are not 0-based
    if (val) {
      onValueChanged(data.indexOf(val) + 1)
    } else {
      onValueChanged(UNSELECTED_ANSWER_VALUE)
    }
  }, [val])

  return (
    <Grid item xs={12} container>
      {/* //need to separate the question number from the rest for styling */}
      <Grid item xs="auto">
        <Typography
          sx={{
            fontSize: isSmall ? 16 : 18,
            fontWeight: '400',
            lineHeight: isSmall ? '19.49px' : '21.92px',
          }}
        >
          {`${questionNumber}.`}&nbsp;
        </Typography>
      </Grid>
      <Grid item container xs={11}>
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              fontSize: isSmall ? 16 : 18,
              fontWeight: '400',
              lineHeight: isSmall ? '19.49px' : '21.92px',
              paddingLeft: isSmall ? undefined : '7px',
            })}
          >
            {questionText}
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <RadioGroup
            sx={{
              marginTop: '10px',
              // paddingLeft: '20px',
              // paddingRight: '20px',
            }}
            aria-label={questionText}
            name={questionText}
            value={val}
            onChange={(e) => {
              setVal(e.target.value as any)
            }}
          >
            {data.map((item, index) => {
              return (
                <RadioOptionWithCentralAlignment
                  key={index}
                  optionValue={item}
                  optionLabel={item}
                />
                // <FormControlLabel
                //   key={index}
                //   value={item}
                //   control={<Radio sx={{ color: PORTAL_COLOR_MAIN_PURPLE }} />}
                //   label={item}
                // />
              )
            })}
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
  )
}

//need radio option + label to be vertically aligned to the top,
//seems imposible with mui FormControlLabel for some reason

function RadioOptionWithCentralAlignment({
  optionValue,
  optionLabel,
}: {
  optionValue: string
  optionLabel: string
}) {
  const { COLORS } = useSColors()
  return (
    <Box
      // key={option.id}
      // value={option.value}
      sx={{
        // marginTop: '5px',
        padding: '5px 5px 5px 5px',
        // border: '1px solid black',
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'flex-start', //TO ALIGN TO TOP
        alignItems: 'center',
        // justifyContent: 'flex-start',
      }}
    >
      <Radio
        value={optionValue}
        sx={{
          padding: '0px',
          alignSelf: 'flext-start',
          color: COLORS.PRIMARY_BRAND,
        }}
      />
      <Typography
        align="left"
        sx={{
          paddingLeft: '5px',
          display: 'flex',
        }}
      >
        <Typography
          component="span"
          // wordWrap="break-word"
          style={{ wordWrap: 'break-word' }}
          sx={{
            fontSize: '0.95rem',
            lineHeight: '24px',
            // paddingLeft: '10px',
            paddingRight: '0px',
            wordBreak: 'break-word',
            // overflowWrap: 'break-word',
            // inlineSize: 'fit-content',
          }}
        >
          {optionLabel}
        </Typography>
      </Typography>
    </Box>
  )
}
