import {
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  CreateMemberAccountData,
  isServerErrorResponse,
  isValidResponse,
  MemberPolicyAcceptData,
  MemberTOUAcceptData,
  PrivacyPolicy,
  TermsOfUse,
} from '../../types'
import {
  getMicroserviceApiDetails,
  PortalApiEx,
} from '../apiexec/portalapiexec'
import { checkMicroServiceResponseStatus, IS_DEV } from '../apiexec/utils'
import { GAService } from '../application/ga/GAService'
import { useConfigService } from '../config/useConfigService'
import { acceptCernerPolicyEndpoint } from '../member/useMemberService'
import { useMemberAppContext } from '../MemberAppContext'
import { All3LicensesAgreement } from './All3Licenses'
import { CustomTextField } from './components/CustomTextField'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import { checkPasswordMatchesRequirements } from './PreReg'
import { RegistrationFormFieldsBox } from './RegistrationContainer'
import { useRegistration } from './RegistrationContext'
import { RESPONSE_CODE_USERNAME_ALREADY_USED } from './RegistrationErrorHandler'
import {
  REG_ROUTE_EDIT_CONTACT_INFO,
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_LOGIN,
  REG_ROUTE_SERVER_ERROR,
  REG_ROUTE_REGISTER_TENANT_ACCOUNT,
} from './RegistrationSubRoutes'
import { useRegistrationService } from './useRegistrationService'
import { WelcomePreregDialog } from './WelcomePreregDialog'
import { LOG } from '../v2/applog'

export function CreateProfile() {
  const gaService = new GAService()

  const { setRenderDepInviteUponLoginAfterRegistration } = useMemberAppContext()

  const [userName, setUserName] = useState('')
  const [isUsernameError, setIsUserNameError] = useState(false)
  const [isUsernameTouched, setIsUsernameTouched] = useState(false)
  const [usernameErrorMsg, setUsernameErrorMsg] = useState('')

  const [password, setPassword] = useState('')
  const [isPasswordError, setIsPasswordError] = useState(false)
  const [isPasswordTouched, setIsPasswordTouched] = useState(false)
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('')

  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isPasswordConfirmTouched, setIsPasswordConfirmTouched] =
    useState(false)

  const [acceptedAll3, setAcceptedAll3] = useState(false)

  const configService = useConfigService()
  const [termsOfUse, setTermsOfUse] = useState<TermsOfUse>()
  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolicy>()

  const [isMainSubmitDisabled, setIsMainSubmitDisabled] =
    useState<boolean>(true)

  const [profileCreated, setProfileCreated] = useState(false)

  //main button effect to determine if enabled
  useEffect(() => {
    if (
      password &&
      passwordConfirm &&
      userName &&
      acceptedAll3 &&
      privacyPolicy &&
      privacyPolicy.id &&
      termsOfUse &&
      termsOfUse.id &&
      !isPasswordError &&
      !isUsernameError
    ) {
      setIsMainSubmitDisabled(false)
    } else {
      setIsMainSubmitDisabled(true)
    }
  }, [
    password,
    userName,
    acceptedAll3,
    isPasswordError,
    isUsernameError,
    privacyPolicy,
    termsOfUse,
  ])

  const { t } = useSTranslate(['common', 'settings', 'register'])
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  //need a bit smaller font size for showing the 3 licenses text in very small screens
  const isExtraSmall = useMediaQuery(theme.breakpoints.down(360))

  useEffect(() => {
    document.title = 'Create Profile - Scripta Insights'
  }, [])
  useEffect(() => {
    loadLatestPrivacyPolicyAsync()
    loadLatestTermsOfUseAsync()
  }, [])

  const { regSetupData, history, setApiResponse, member, registrationJwt } =
    useRegistration()
  const { setClientUuid } = useMemberAppContext()

  const service = useRegistrationService()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  async function loadLatestPrivacyPolicyAsync() {
    try {
      const privacyPolicy = await configService.getPrivacyPolicyApi()

      if (isValidResponse<PrivacyPolicy>(privacyPolicy)) {
        setPrivacyPolicy(privacyPolicy)
      } else {
        LOG.error(
          'registration',
          'Error loading privacy policy config.',
          privacyPolicy,
        )
      }
    } catch (error) {
      LOG.error(
        'registration',
        'Error cought loading privacy policy config.',
        error,
      )
    }
  }

  async function loadLatestTermsOfUseAsync() {
    try {
      const termsOfUse = await configService.getTermsOfUseApi()

      if (isValidResponse<TermsOfUse>(termsOfUse)) {
        setTermsOfUse(termsOfUse)
      } else {
        LOG.error(
          'registration',
          'Error loading terms of use config.',
          termsOfUse,
        )
      }
    } catch (error) {
      LOG.error('registration', 'Error cought loading terms of use.', error)
    }
  }

  async function asyncCreateProfile(createData: CreateMemberAccountData) {
    try {
      const resp = await service.createMemberAccountApi(createData)

      if (isServerErrorResponse<void>(resp)) {
        LOG.error(
          'registration',
          'Server error response creating member account.',
          resp,
        )
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<void>(resp)) {
        // setApiResponse(resp)

        const policyAcceptData: MemberPolicyAcceptData = {
          memberId: member?.id,
          tenantId: member?.tenantId,
          policyId: privacyPolicy?.id,
        }

        //

        const policyAcceptResp = await service.memberAcceptPolicyApi(
          policyAcceptData,
        )
        //
        if (isValidResponse<void>(policyAcceptResp)) {
          setApiResponse(policyAcceptResp)
        } else {
          LOG.error(
            'policy_and_terms',
            'Failed to accept privacy policy as part of create profile. Invalid response returned by the API',
            policyAcceptResp,
          )
          //comment this out for now till backend is fixed to handle concurrent writes
          // setApiResponse(policyAcceptResp)
          // history.push(REG_ROUTE_ERROR_HANDLER)
        }

        //TODO - now call bckend to acknoweldge we accepted policy id and terms
        const touAcceptData: MemberTOUAcceptData = {
          memberId: member?.id,
          tenantId: member?.tenantId,
          versionId: termsOfUse?.id,
        }

        //

        const touAcceptResp = await service.memberAcceptTOUApi(touAcceptData)
        //
        if (isValidResponse<void>(touAcceptResp)) {
          setApiResponse(touAcceptResp)
        } else {
          LOG.error(
            'policy_and_terms',
            'Failed to accept terms of use as part of create profile. Invalid response returned by the API',
            touAcceptResp,
          )
          // setApiResponse(touAcceptResp)
          // history.push(REG_ROUTE_ERROR_HANDLER)
        }

        //accept cerner multum policy. to do that, we need JWT for this user

        try {
          //need to manually prepare api exec details for this one
          const tempJwt = (await service.authenticateMemberApi(
            userName,
            password,
            'returnAsString',
          )) as string
          //make sure its a valid string
          if (typeof tempJwt === 'string' && tempJwt.length > 0) {
            const portalApi = new PortalApiEx()
            portalApi.setJwt(tempJwt)

            let { url, ops } = getMicroserviceApiDetails(
              acceptCernerPolicyEndpoint,
            )
            const msResp = await portalApi.postMicro<any>(url, {}, ops)

            let allowUndefined = true
            const respCheck = checkMicroServiceResponseStatus(
              msResp,
              allowUndefined,
            )

            if (!respCheck.isError) {
              //debug only
              if (IS_DEV()) {
                console.log('Cerner User Agreement accepted')
              }
            } else {
              LOG.error(
                'registration',
                'Error accepting Cerner User Agreement',
                msResp,
              )
            }
          } else {
            LOG.error(
              'registration',
              'Unable to get required JWT in create profile as authenticate member API failed. Will not be able to accept cerner policy.',
            )
          }
        } catch (e) {
          LOG.error(
            'registration',
            'Network level error in flow - Unable to get required JWT in create profile as authenticate member API failed. ',
            e,
          )
        }

        //add a flag so we show deps invite once the user logs in after registration
        setRenderDepInviteUponLoginAfterRegistration(true)

        //dont push immediatelly, tell user they are created and give link
        setProfileCreated(true)
      } else {
        if (resp && resp.code === RESPONSE_CODE_USERNAME_ALREADY_USED) {
          setIsUserNameError(true)
          setUsernameErrorMsg('Username is taken, please try another')
        } else {
          //non-specific error, so send to standard registration route error handler
          LOG.error(
            'registration',
            'not a valid response creating member account.',
            resp,
          )
          setApiResponse(resp)
          history.push(REG_ROUTE_ERROR_HANDLER)
        }
      }
    } catch (e) {
      LOG.error(
        'registration',
        'Error cought in asyncCreateProfile execution',
        e,
      )
      throw new Error(
        'Error creating profile - please try again or contact support',
      )
    }
  }

  const doCreateProfile = useCallback(() => {
    if (!registrationJwt) {
      gaService.trackEvent(gaService.eventMap.step_7_reg_next_error)
      //as of SIS-1729 - this is now a fatal error, dont even try
      let additionalDetails = `
        memberId: ${member?.id},
        tenantId: ${member?.tenantId},
        userName: ${userName},
        privacyPolicyId: ${privacyPolicy?.id},
        termsOfUseId: ${termsOfUse?.id},
        acceptedAll3: ${acceptedAll3},
        isPasswordError: ${isPasswordError},
        isUsernameError: ${isUsernameError},
        registrationJwt: ${registrationJwt},
        isMainSubmitDisabled: ${isMainSubmitDisabled},
        isPasswordError: ${isPasswordError},
        isUsernameError: ${isUsernameError},
      `
      LOG.error(
        'registration',
        'Cannot create account bc this identity is not verified - missing registration JWT. Additional details in current context=',
        additionalDetails + '.Will send the user back to find account page.',
      )
      //send the user back to find account step.
      history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
      // throw new Error(
      //   'Cannot create account bc this identity is not verified - missing registration JWT',
      // )
    }

    const createAccountData: CreateMemberAccountData = {
      userName: userName,
      password: password,
      tenantId: member?.tenantId!,
      memberId: member?.id!,
      registrationJwt: registrationJwt!,
    }

    asyncCreateProfile(createAccountData)
    gaService.trackEvent(gaService.eventMap.step_7_reg_next)
  }, [
    member,
    regSetupData,
    registrationJwt,
    userName,
    password,
    privacyPolicy,
    termsOfUse,
  ])

  if (profileCreated) {
    return (
      <WelcomePreregDialog
        onGoHomeFromThankYouClicked={() => {
          //wipe out the client uuid to remove the logo from the client container
          //TODO - maybe we should do this on the login screen
          const autoLogin: any = {
            justRegisteredUserName: userName,
            justRegisteredPassword: password,
          }
          setClientUuid(undefined)
          history.push(REG_ROUTE_LOGIN, { autoLogin: autoLogin })
        }}
        mode="register"
      />
    )
  }

  return (
    <RegistrationStepCard
      registrationType="registration"
      progressBarStep={6}
      // message={t('createAUsernameDescription')}
      nextButtonTitle={t('register:btnSubmit')}
      cancelButtonTitle={t('common:back')}
      onClickNextButton={doCreateProfile}
      onClickCancelButton={() => history.push(REG_ROUTE_EDIT_CONTACT_INFO)}
      nextButtonDisabled={isMainSubmitDisabled}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            data-testid="create-profile-page-title"
            sx={{
              textAlign: 'center',
              fontSize: isSmall ? '1.125rem' : '1.5rem',
              fontWeight: 700,
              paddingBottom: '20px',
              paddingTop: '0px',
              // marginTop: isSmall ? '-15px' : '0px',
              lineHeight: '21.92px',
            }}
          >
            {t('register:createProfileStepTitle')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: isSmall ? '1rem' : '1.125rem',
              fontWeight: 300,
              lineHeight: '20px',
              textAlign: 'center',
              paddingLeft: isSmall ? '20px' : undefined,
              paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            {t('register:createProfileStepDesc')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ minHeight: '30px' }}></Grid>
      </Grid>
      <RegistrationFormFieldsBox>
        <Grid item xs={12}>
          <CustomTextField
            data-testid="create-profile-username-input"
            label={t('userName')}
            value={userName}
            onChange={(e) => {
              setIsUsernameTouched(true)
              setUserName(e.target.value)
              if (e.target.value.length < 8) {
                setIsUserNameError(true)
                setUsernameErrorMsg(
                  'Username must be at least 8 characters long',
                )
              } else {
                setIsUserNameError(false)
                setUsernameErrorMsg('')
              }
            }}
            error={isUsernameTouched && isUsernameError ? true : false}
            helperText={
              isUsernameTouched && isUsernameError ? usernameErrorMsg : ''
            }
            // error={userName.length < 8}
            // sx={{ marginBottom: 3.8 }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '20px' }}>
          <CustomTextField
            data-testid="create-profile-password-input"
            label={t('password')}
            type={'password'}
            value={password}
            onChange={(e) => {
              setIsPasswordTouched(true)
              setPassword(e.target.value)
              if (!checkPasswordMatchesRequirements(e.target.value)) {
                setIsPasswordError(true)
                setPasswordErrorMsg('Password does not meet requirements')
              } else {
                setIsPasswordError(false)
                setPasswordErrorMsg('')
              }
            }}
            error={isPasswordTouched && isPasswordError ? true : false}
            helperText={
              isPasswordTouched && isPasswordError ? passwordErrorMsg : ''
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '20px' }}>
          <CustomTextField
            data-testid="create-profile-password-confirm-input"
            label={t('settings:confirmPassword')}
            value={passwordConfirm}
            // error={passwordsDontMatch}
            type={'password'}
            error={
              isPasswordConfirmTouched && password !== passwordConfirm
                ? true
                : false
            }
            helperText={
              isPasswordConfirmTouched && password !== passwordConfirm
                ? t('register:confirmPasswordNotMatched')
                : ''
            }
            onChange={(e) => {
              setIsPasswordConfirmTouched(true)
              setPasswordConfirm(e.target.value)
            }}
          />
        </Grid>
      </RegistrationFormFieldsBox>

      <Grid
        item
        container
        xs={12}
        sx={{ lineHeight: '15px', marginTop: '20px' }}
        justifyContent={!isExtraSmall ? 'center' : undefined}
        // sx={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
      >
        <Grid item xs="auto">
          <Checkbox
            data-testid="create-profile-all-3-licenses-checkbox"
            checked={acceptedAll3}
            onChange={(e) => {
              setAcceptedAll3(e.target.checked)
              // setRegForm({
              //   ...regForm,
              //   acceptedFinalTermsAndConditions: e.target.checked,
              // })
            }}
            sx={{
              //   float: 'left',
              fontSize: '1rem',
              padding: '2px 0px 0px 0px',
              margin: '2px',
              paddingRight: '5px',
              '& .MuiSvgIcon-root': { fontSize: 28 },
            }}
          />
        </Grid>
        <Grid
          item
          sx={{
            width: isSmall ? '260px' : '300px',
            minWidth: isSmall ? '260px' : '300px',
            maxWidth: isSmall ? '260px' : '300px',
          }}
        >
          <All3LicensesAgreement customFontSize={isSmall ? '11px' : '13px'} />
        </Grid>
      </Grid>
    </RegistrationStepCard>
  )
}
