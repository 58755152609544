import CardContainer from './CardContainer'
import { SPACING } from './theme2'
import triangleImgSrc from './images/tooltip_triangle.svg'
import { Box } from '@mui/material'
import STypography from './customcomponents/STypography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'
import { getScriptaTheme } from 'src/app/styles/theme-config-scripta'
export function SpeechTooltipWaysToSave({
  disableTheming,
}: {
  disableTheming: boolean
}) {
  let { COLORS } = useSColors()
  if (disableTheming) {
    COLORS = getScriptaTheme().customized.colors
  }

  const { t } = useSTranslate('newComponents')
  return (
    <SpeechTooltip
      content={
        <STypography variant="body_regular" component={'span'}>
          {t('waysToSave')}
          <STypography
            component={'span'}
            variant="body_semibold"
            sx={{ color: COLORS.PRIMARY_BRAND }}
          >
            {t('stayWithCurrentMedication')}
          </STypography>{' '}
          {t('or')}
          <STypography
            component={'span'}
            variant="body_semibold"
            sx={{ color: COLORS.PRIMARY_BRAND }}
          >
            {t('switchToAlternative')}
          </STypography>
          {t('letsExplore')}
        </STypography>
      }
    />
  )
}
export interface SpeechTooltipProps {
  content: React.ReactNode
}

export default function SpeechTooltip({
  content,
}: {
  content: React.ReactNode
}) {
  const { COLORS } = useSColors()

  return (
    <Box sx={{ position: 'relative' }}>
      <ThemedSVGRemote
        importUrl={triangleImgSrc}
        alt={'tooltip triangle'}
        width={13}
        height={18}
        styleButNoWidthOrHeight={{
          marginTop: '5px',
          position: 'absolute',
        }}
      />
      {/* <img
        src={triangleImage}
        style={{
          position: 'absolute',
          width: '13px',
          top: '8px',
        }}
      /> */}
      <CardContainer
        sx={{
          backgroundColor: COLORS.PRIMARY_10,
          padding: SPACING._05,
          border: 'none',
          width: 'calc(100% - 13px)',
          marginLeft: '13px',
        }}
      >
        {content}
      </CardContainer>
    </Box>
  )
}
