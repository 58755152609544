import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { CLASS_NAMES } from '../shared/constants'
import { useSColors } from '../styles/scripta-theme'
import { CircleWrappedFontIcon } from '../system/CircleWrappedFontIcon'
import SButton from '../system/customcomponents/SButton'
import { SDialog } from '../system/customcomponents/SDialog'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import { useSizes } from '../v2/useNewHooks'
import { ElipsisMenuDrugContext } from './DrugElipsisMenu'
import { useSTranlateForObjects_waitUntilReady } from '../hooks/useSTranslate'

export function DiscontinueDrugFeedbackDialog({
  data,
  drugContext,
  isOpen,
  handleClose,
  handleHideMed,
}: {
  data: any
  drugContext: ElipsisMenuDrugContext
  isOpen: boolean
  handleClose: () => void
  handleHideMed: (reason: any) => void
}) {
  const { COLORS } = useSColors()
  const { t, ready } = useSTranlateForObjects_waitUntilReady(
    'DiscontinueDrugFeedbackDialog',
  )
  const [optionSelected, setOptionSelected] = useState<string>('')

  if (!ready) return null

  const variantOriginal: any = t('variantoriginal', { returnObjects: true })
  const variantAlternative: any = t('variantalternative', {
    returnObjects: true,
  })

  const handleHideMedClick = () => {
    handleHideMed(optionSelected)
  }
  return (
    <SDialog
      onClose={handleClose}
      open={isOpen}
      customPaperSx={{
        minWidth: '300px',
        maxWidth: '343px',
        padding: '0px',
      }}
      customContentSx={
        {
          // padding: '0px',
          // paddingLeft: SPACING._1,
          // paddingRight: SPACING._1,
        }
      }
    >
      <DialogContent>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <CircleWrappedFontIcon
              icon={
                <FontAwesomeIcon
                  fontSize={'16px'}
                  icon={faEyeSlash}
                  color={COLORS.TEXT_BRAND}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <STypography
              sx={{
                color: COLORS.TEXT_BRAND,
                textAlign: 'center',
                marginTop: SPACING._1,
                marginBottom: SPACING._1,
              }}
              variant="bodybig_bold"
            >
              {t('title')}
            </STypography>
          </Grid>
          <STypography sx={{ lineHeight: '15px' }} variant="body_regular">
            {t('paragraph')}
          </STypography>

          <Grid item xs={12} sx={{ marginTop: SPACING._1 }}>
            <RadioGroup
              // className="optionList"
              onChange={(e) => setOptionSelected(e.target.value)}
              aria-labelledby="radio-buttons-group-discontinue-drug"
              name="radio-buttons-group-discontinue-drug"
            >
              {Object.keys(
                drugContext === 'original'
                  ? variantOriginal
                  : variantAlternative,
              ).map(function (key, index) {
                return (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio sx={{ padding: SPACING._025 }} />}
                    label={
                      <STypography variant="body_regular">
                        {drugContext === 'original'
                          ? variantOriginal[key]
                          : variantAlternative[key]}
                      </STypography>
                    }
                  />
                )
              })}
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: SPACING._075 }}>
            <SButton
              fullWidth
              className={CLASS_NAMES.hideThisMedButton}
              onClick={handleHideMedClick}
              disabled={optionSelected === ''}
              noCaps
            >
              {t('buttonText')}
            </SButton>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: SPACING._05 }}>
            <SButton variant="outlined" fullWidth onClick={handleClose} noCaps>
              {t('cancelButtonText')}
            </SButton>
          </Grid>
        </Grid>
      </DialogContent>
    </SDialog>
  )
}
