import React, { ReactNode } from 'react'
import { Box, BoxProps } from '@mui/material'

interface DisablePointerEventsProps extends BoxProps {
  children: ReactNode
}

const PointerEventBlocker: React.FC<DisablePointerEventsProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box sx={{ pointerEvents: 'none' }} {...rest}>
      {children}
    </Box>
  )
}

export default PointerEventBlocker
